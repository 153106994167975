import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import Cookies from "js-cookie";

function StudentManager() {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoading(false); // Set loading to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {

    // Check the condition and navigate only if not loading and role is not "1"
    if (!loading && decryptedRoleId.charAt(2) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loading]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  return (
    <div className="flex items-center justify-center h-full w-full">
      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 2,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "95.5%",
            height: "90%",
          }}
        >
          <div className="text-8xl font-bold text-slate-900">
            Student Manager
          </div>
          <div className="flex flex-row w-full justify-center pt-20">
            <div className="mx-4 p-4 bg-white rounded-lg shadow-lg w-1/4 flex flex-col justify-between">
              <div className="text-2xl font-semibold">Student Fetch</div>
              <div className="text-md py-2">
                Fetch and display all school students, showcasing their details
              </div>
              <Link
                to="FetchStudent"
                className="bg-blue-500 text-white px-4 py-2 rounded w-fit font-medium hover:bg-blue-600"
              >
                Fetch
              </Link>
            </div>
            <div className="mx-4 p-4 bg-white rounded-lg shadow-lg w-1/4 flex flex-col justify-between">
              <div className="text-2xl font-semibold">Add Student</div>
              <div className="text-md py-2">
                Add new student in School database
              </div>
              <Link
                to="AddStudent"
                className="bg-blue-500 text-white px-4 py-2 rounded w-fit font-medium hover:bg-blue-600"
              >
                Add
              </Link>
            </div>
            <div className="mx-4 p-4 bg-white rounded-lg shadow-lg w-1/4 flex flex-col justify-between">
              <div className="text-2xl font-semibold">Edit Students</div>
              <div className="text-md py-2">
                Edit details of existing students
              </div>
              <Link
                to="EditStudent1"
                className="bg-blue-500 text-white px-4 py-2 rounded w-fit font-medium hover:bg-blue-600"
              >
                Edit
              </Link>
            </div>
          </div>

          <div className="footer-bottom fixed bottom-0 w-full bg-lightblue-800 p-4 text-center text-black">
            <p>© 2023 Ed-Sanchaar. All rights reserved.</p>
          </div>
        </Box>
      </Toolbar>
    </div>
  );
}

export default StudentManager;
