import React, { useState, useEffect } from "react";

import {useNavigate } from "react-router-dom";
import {
  Box,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MaterialSelect,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";

const columns = [
  // { field: "id", headerName: "Id", width: 90 },
  { field: "fee_id", headerName: "Fee Id", width: 90 },
  { field: "username", headerName: "Username", width: 120 },
  { field: "name", headerName: "Name", width: 200 },
  {
    field: "paid_status",
    headerName: "Paid Status",
    width: 100,
    renderCell: (params) => (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          // fontWeight: "400",
          fontSize: "14px",
          height: "100%",
          backgroundColor: "green",
          color: "white",
          backgroundColor: params.value === 1 ? "green" : "red",
        }}
      >
        {params.value === 1 ? "Paid" : "Unpaid"}
      </span>
    ),
  },
  { field: "class_id", headerName: "Class ID", width: 100 },
  { field: "roll_no", headerName: "Roll No", width: 100 },
  {
    field: "boarder",
    headerName: "Boarder",
    width: 100,
    renderCell: (params) => <span>{params.value === 1 ? "Yes" : "No"}</span>,
  },
  { field: "plan", headerName: "Plan", width: 100 },
  { field: "title", headerName: "Title", width: 100 },
  { field: "base_fee", headerName: "Base Fee", width: 100 },
  {
    field: "miscellaneous_charges",
    headerName: "Miscellaneous Charges",
    width: 100,
  },
  {
    field: "total_fees",
    headerName: "Total Fees",
    width: 100,
  },
  { field: "transaction_id", headerName: "Transaction ID", width: 100 },
  // { field: "total_fees", headerName: "Total Fees", width: 100 },
];

function CoachingFeeManager() {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoading(false); // Set loading to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // Check the condition and navigate only if not loading and role is not "1"
    if (!loading && decryptedRoleId.charAt(4) !== "1") {
      // console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loading]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const [school_id, setSchoolId] = useState("");
  const [classIds, setClassIds] = useState([]);
  const [classIdsForFeeAdd, setclassIdsForFeeAdd] = useState([]);
  const [studentsForSelectedClass, setStudentsForSelectedClass] = useState([]);
  const [classStudents, setClassStudents] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [refetchData, setRefetchData] = useState(false);

  const [studentData, setStudentData] = useState([]);

  const [coachingFeeData, setCoachingFeesData] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    username: "",
  });

  const [feeAddOpen, setFeeAddOpen] = useState(false);
  const [modalData, setModalData] = useState({
    base_fee: "",
    miscellaneous_charges: "",
    totalfee: "",
    plan: "Coaching",
    title: "",
  });
  const [baseFee, setBaseFee] = useState(0);
  const [miscellaneousCharges, setMiscellaneousCharges] = useState(0);
  const [feeTitle, setFeeTitle] = useState("");
  const [feeIds, setFeeIds] = useState([]);

  // New state variables for the add fee record dialog
  const [classId, setClassId] = useState("");
  const [studentName, setStudentName] = useState("");
  const [dueDate, setDueDate] = useState("");

  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      // Redirect to login or handle accordingly if schoolId is not available
      // You may want to add additional handling, such as showing an error message
      console.error("School ID not available");
      return;
    }

    // Set the school_id in the state
    setSchoolId(schoolIdFromCookie);
  }, []);

  // Function to handle changes in the new input fields
  const handleClassIdChange = (e) => setClassId(e.target.value);
  const handleStudentNameChange = (e) => {
    const newStudentName = e.target.value;
    setStudentName(newStudentName);

    // Set the roll_no when the student name changes
    const selectedStudent = studentsForSelectedClass.find(
      (student) => student.name === newStudentName
    );
    if (selectedStudent) {
      setRollNo(selectedStudent.roll_no);
    }
  };
  const handleDueDateChange = (e) => setDueDate(e.target.value);

  const [totalFee, setTotalFee] = useState(0); // Add this line to define totalFee state

  const handleOpen = () => {
    setFeeAddOpen(true);

    // Display the data of the first selected row in the modal
    if (selectedRowsData.length > 0) {
      const firstRowData = selectedRowsData[0];
      setModalData({
        base_fee: firstRowData.base_fee,
        miscellaneous_charges: firstRowData.miscellaneous_charges,
        totalfee: firstRowData.total_fees,
        plan: firstRowData.plan,
        title: firstRowData.title,
      });
      setBaseFee(firstRowData.base_fee);
      setFeeTitle(firstRowData.title);
      setMiscellaneousCharges(firstRowData.miscellaneous_charges);

      // Set the initial totalFee based on the data
      setTotalFee(firstRowData.total_fees);
    }
  };

  const handleBaseFeeChange = (e) => {
    const newBaseFee = parseFloat(e.target.value) || 0;
    setBaseFee(newBaseFee);
    // Update totalFee when baseFee changes
    setTotalFee(newBaseFee + miscellaneousCharges);
  };

  const handelFeeTitleChange = (e) => {
    setFeeTitle(e.target.value); // Fix: add parentheses to setFeeTitle
  };

  const handleMiscellaneousChargesChange = (e) => {
    const newMiscellaneousCharges = parseFloat(e.target.value) || 0;
    setMiscellaneousCharges(newMiscellaneousCharges);
    // Update totalFee when miscellaneousCharges changes
    setTotalFee(baseFee + newMiscellaneousCharges);
  };

  const handelUpdateFeeDetails = async () => {
    // Create FormData
    const formData = new FormData();

    // Only append the title if all selected rows have the same title
    formData.append("title", feeTitle);
    formData.append("baseFee", baseFee);
    formData.append("miscellaneousCharges", miscellaneousCharges);
    formData.append("totalFee", totalFee);
    formData.append("fee_ids", JSON.stringify(feeIds));

    try {
      const response = await axios.post(
        `https://edsanchaar.in/api/api_admin/updateFeeDetails.php?school_id=${school_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // Add any other headers as needed
          },
        }
      );

      // Handle the API response as needed
      handleClose();
    } catch (error) {
      // Handle errors
      toast.error("Error sending data to API:", error);
    }
  };

  const handleClose = () => {
    setFeeAddOpen(false);
    setTotalFee(0);
  };

  // add fee record
  useEffect(() => {
    fetchStudentsForClass();
  }, [classId]);

  const fetchStudentsForClass = async () => {
    if (classId) {
      const apiUrl = `https://edsanchaar.in/api/api_admin/CoachingManager/getDistinctCoachingClassStudents.php?school_id=${school_id}&class_id=${classId}`;

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            authorization: "aV0xQRFizDkSwyzksn2lc0xi4Rytc8y4fT1ftwiL8ek",
          },
        });

        // console.log("API Response:", response.data); // Add this line to see the API response
        setClassStudents(response.data);
      } catch (error) {
        toast.error("Error fetching students data:", error);
      }
    }
  };

  const [plan, setPlan] = useState("Coaching");
  const [title, setTitle] = useState("");
  const [rollNo, setRollNo] = useState("");

  const handleTitleChange = (e) => setTitle(e.target.value);

  const [newFeeAddOpen, setNewFeeAddOpen] = useState(false);

  const handleNewFeeClose = () => {
    setNewFeeAddOpen(false);
    setStudentName("");
    setPlan("Coaching");
    setTitle("");
    setBaseFee(0);
    setMiscellaneousCharges(0);
    setTotalFee(0);
  };

  const openAddFeeDialog = () => {
    // Reset the new input fields
    setNewFeeAddOpen(false);
    setStudentName("");
    setPlan("Coaching");
    setTitle("");
    setBaseFee(0);
    setMiscellaneousCharges(0);
    setTotalFee(0);
    setClassId("");
    setStudentName("");
    setDueDate("");
    setNewFeeAddOpen(true);
  };

  const [schoolId, setSchool] = useState("");
  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      // Redirect to login or handle accordingly if schoolId is not available
      // You may want to add additional handling, such as showing an error message
      console.error("School ID not available");
      return;
    }

    // Set the school_id in the state
    setSchool(schoolIdFromCookie);
  }, []);

  const handleAddFeeRecord = async () => {
    // Create FormData
    const formData = new FormData();
    formData.append("school_id", school_id);
    formData.append("username", studentName);
    formData.append("plan", "Coaching");
    formData.append("roll_no", rollNo);

    formData.append("title", title);
    formData.append("base_fee", baseFee);
    formData.append("miscellaneous_charges", miscellaneousCharges);
    formData.append("total_fee", totalFee);
    formData.append("due_date", dueDate);

    try {
      const response = await axios.post(
        "https://edsanchaar.in/api/api_admin/CoachingManager/addCoachingFeeRecord.php",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            // Add any other headers as needed
          },
        }
      );

      // Handle the API response as needed
      setFilteredData([]);
      setStudentData([]);
      setCoachingFeesData([]);
      setRefetchData(false);
      getCoachingFeeData();

      handleNewFeeClose();

      toast.success("Fee record added successfully");
    } catch (error) {
      // Handle errors
      toast.error("Error sending data to API:", error);
    }
  };

  const deleteFeeRecord = async () => {
    // Check if there are selected rows
    if (selectedRowsData.length === 0) {
      toast.error("No rows selected");
      return;
    } else if (selectedRowsData.length > 1) {
      toast.error("You cannot select more than one row to delete at once");
      return;
    }

    // console.log(selectedRowsData);
    // Extract fee_ids from the selected row
    const feeId = selectedRowsData[0].fee_id;

    const formData = new FormData();
    formData.append("fee_ids", feeId);
    formData.append("school_id", school_id);

    try {
      const response = await axios.post(
        `https://edsanchaar.in/api/api_admin/CoachingManager/deleteCoachingFeeRecord.php`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Update the data after deletion
      setFilteredData([]);
      setStudentData([]);
      setCoachingFeesData([]);
      setRefetchData(true);
      getCoachingFeeData();

      closeDeleteConfirmation();
    } catch (error) {
      // Handle errors
      toast.error("Error deleting fee record:", error);
    }
  };

  const openDeleteConfirmation = () => {
    // console.log(selectedRowsData);
    if (selectedRowsData.length === 0) {
      toast.error("No rows selected");
      return;
    } else if (selectedRowsData.length > 1) {
      toast.error("Your cannot select more than one row to delete at once");
      return;
    }

    setDeleteConfirmation({
      isOpen: true,
    });
    // console.log(selectedRowsData);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmation({
      isOpen: false,
      username: "",
    });
  };

  const fetchClassIds = async (schoolId) => {
    const apiUrl = `https://edsanchaar.in/api/api_admin/CoachingManager/getDistinctCoachingClasses.php?school_id=${schoolId}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: "aV0xQRFizDkSwyzksn2lc0xi4Rytc8y4fT1ftwiL8ek",
        },
      });

      const data = response.data;

      if (Array.isArray(data)) {
        const sortedClassIds = data
          .map((classInfo) => classInfo.class_id)
          .sort();
        setClassIds(sortedClassIds);
        setclassIdsForFeeAdd(sortedClassIds);
      } else {
        console.error("Invalid response format for class IDs:", data);
      }
    } catch (error) {
      console.error("Error fetching class IDs:", error);
    }
  };

  const getCoachingFeeData = () => {
    axios
      .get(
        `https://edsanchaar.in/api/api_admin/CoachingManager/getCoachingFee.php?school_id=${school_id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "",
          },
        }
      )
      .then((response) => {
        setCoachingFeesData(response.data);
        setFilteredData(response.data);
        setRefetchData(false);

        const data = response.data;
        const studentDataWithIds = data.map((row, index) => ({
          ...row,
          id: index + 1, // You can adjust this based on your requirements
        }));

        setStudentData(studentDataWithIds);
      })
      .catch((error) => console.error(error));
  };

  const markFeeRecordPaid = async () => {
    // Check if there are selected rows
    if (selectedRowsData.length === 0) {
      toast.error("No rows selected");
      return;
    } else if (selectedRowsData.length > 1) {
      toast.error("You cannot select more than one row to mark paid at once");
      return;
    }
    const feeId = selectedRowsData[0].fee_id;

    if (selectedRowsData[0].paid_status === 1) {
      toast.error("The selected fee has already been paid");
      return;
    }

    const formData = new FormData();
    formData.append("fee_ids", feeId);
    formData.append("school_id", school_id);

    try {
      const response = await axios.post(
        `https://edsanchaar.in/api/api_admin/CoachingManager/markCoachingFee.php`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Check if the response indicates success
      if (response.data.success) {
        // Success message or any additional actions you want to perform
        toast.success("Fee record marked as paid successfully");

        // Update the data after marking as paid
        setFilteredData([]);
        setStudentData([]);
        setCoachingFeesData([]);
        setRefetchData(true);
        getCoachingFeeData();

        closeDeleteConfirmation();
      } else {
        // Error message from the server
        toast.error("Error marking fee record as paid:", response.data.error);
      }
    } catch (error) {
      // Handle network errors
      toast.error("Error marking fee record as paid:", error);
    }
  };

  const generateFeeReceipt = async () => {
    // Check if there are selected rows
    if (selectedRowsData.length === 0) {
      toast.error("No rows selected");
      return;
    } else if (selectedRowsData.length > 1) {
      toast.error("You cannot select more than one row to mark paid at once");
      return;
    }
    const feeId = selectedRowsData[0].fee_id;

    if (selectedRowsData[0].paid_status === 0) {
      toast.error("Can't Generate receipt of unpaid fees");
      return;
    }

    try {
      window.open(
        `https://edsanchaar.in/api/api_student/fees/feeReceiptDownload.php?school_id=${school_id}&fee_id=${feeId}&plan=${plan}`
      );
      // Check if the response indicates success
    } catch (error) {
      // Handle network errors
      toast.error("Error Generation Receipt", error);
    }
  };

  React.useEffect(() => {
    // Retrieve the selected rows based on the row indexes in rowSelectionModel
    const selectedRows = studentData.filter((data) =>
      rowSelectionModel.includes(data.fee_id)
    );
    // console.log(rowSelectionModel);
    // console.log(studentData);
    // console.log(selectedRows);
    setSelectedRowsData(selectedRows);
  }, [rowSelectionModel, studentData]);

  React.useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      console.error("School ID not available");
      return;
    }
    axios
      .get(
        `https://edsanchaar.in/api/api_admin/CoachingManager/getCoachingFee.php?school_id=${schoolIdFromCookie}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "",
          },
        }
      )
      .then((response) => {
        setCoachingFeesData(response.data);
        setFilteredData(response.data);

        const data = response.data;
        const studentDataWithIds = data.map((row, index) => ({
          ...row,
          id: index + 1, // You can adjust this based on your requirements
        }));

        setStudentData(studentDataWithIds);
      })
      .catch((error) => console.error(error));

    setSchoolId(schoolIdFromCookie);
    fetchClassIds(schoolIdFromCookie);
  }, []);

  return (
    <div className="flex items-center justify-center h-screen w-full">
      <Toaster position="top-center" reverseOrder={true} />

      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 2,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="h-full w-full flex flex-col justify-center items-center">
            <div className="text-3xl font-bold py-4">School Fee Data</div>
            <div className="w-full flex flex-row border ">
              <form
                // onSubmit={handleSubmit}
                className="w-full flex flex-row justify-evenly flex-wrap"
              >
                {/* add fee records  */}
                <div className="flex flex-row justify-center items-center ">
                  <Button onClick={openAddFeeDialog}>Add Fee Record</Button>
                </div>
                <Modal
                  open={newFeeAddOpen}
                  onClose={handleNewFeeClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                      width: "full",
                      borderRadius: 2,
                    }}
                  >
                    <DialogTitle>Add New Fee Record</DialogTitle>
                    <DialogContent>
                      {/* Add your input fields for fee record details */}
                      <TextField
                        select
                        label="Class ID"
                        name="classId"
                        value={classId}
                        onChange={handleClassIdChange}
                        fullWidth
                        margin="normal"
                      >
                        <MenuItem value="">
                          <em>Select Class ID</em>
                        </MenuItem>
                        {classIdsForFeeAdd.map((classId) => (
                          <MenuItem key={classId} value={classId}>
                            {classId}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        select
                        label="Student Name"
                        name="studentName"
                        value={studentName}
                        onChange={handleStudentNameChange}
                        fullWidth
                        margin="normal"
                      >
                        {classStudents.map((student) => (
                          <MenuItem
                            key={student.username}
                            value={student.username}
                          >
                            {student.name}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        label="Plan"
                        name="plan"
                        value="Coaching"
                        disabled
                        fullWidth
                        margin="normal"
                      ></TextField>

                      <TextField
                        label="Title"
                        type="text"
                        name="title"
                        value={title}
                        onChange={handleTitleChange}
                        fullWidth
                        margin="normal"
                      />

                      <TextField
                        label="Base Fee"
                        type="text"
                        name="baseFee"
                        value={baseFee}
                        onChange={handleBaseFeeChange}
                        fullWidth
                        margin="normal"
                      />

                      <TextField
                        label="Miscellaneous Fee"
                        type="text"
                        name="miscellaneousFee"
                        value={miscellaneousCharges}
                        onChange={handleMiscellaneousChargesChange}
                        fullWidth
                        margin="normal"
                      />

                      <TextField
                        label="Total Fee"
                        type="text"
                        name="totalFee"
                        value={totalFee}
                        disabled
                        fullWidth
                        margin="normal"
                      />

                      <TextField
                        label="Due Date"
                        type="date"
                        name="dueDate"
                        value={dueDate}
                        onChange={handleDueDateChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DialogContent>

                    <DialogActions>
                      <Button onClick={handleNewFeeClose}>Cancel</Button>
                      <Button onClick={handleAddFeeRecord} color="primary">
                        Add Fee Record
                      </Button>
                    </DialogActions>
                  </Box>
                </Modal>

                <div className="flex flex-row justify-center items-center ">
                  <Button color="primary" onClick={() => markFeeRecordPaid()}>
                    Mark Fee Record Paid
                  </Button>
                </div>

                <div className="flex flex-row justify-center items-center ">
                  <Button color="error" onClick={openDeleteConfirmation}>
                    Delete Fee Record
                  </Button>
                </div>
                <Dialog
                  open={deleteConfirmation.isOpen}
                  onClose={closeDeleteConfirmation}
                >
                  <DialogTitle>Delete Confirmation</DialogTitle>
                  <DialogContent>
                    <p>
                      Do you want to delete fee record of selected user from
                      school records?
                    </p>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closeDeleteConfirmation}>Cancel</Button>
                    <Button
                      onClick={() => deleteFeeRecord()}
                      variant="contained"
                      color="error"
                    >
                      Confirm Delete
                    </Button>
                  </DialogActions>
                </Dialog>

                <div className="flex flex-row justify-center items-center ">
                  <Button color="primary" onClick={() => generateFeeReceipt()}>
                    Generate Receipt
                  </Button>
                </div>
              </form>
            </div>
            <div className="w-full h-full overflow-x-auto flex flex-col items-center">
              <DataGrid
                className="w-full"
                rows={filteredData}
                columns={columns}
                pageSize={5}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                {...filteredData}
              />
            </div>
          </div>
        </Box>
      </Toolbar>
    </div>
  );
}

export default CoachingFeeManager;
