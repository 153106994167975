import React from "react";
import { Link } from "react-router-dom";

function DashboardModules({ decryptedRoleId }) {
  const containerClass =
    decryptedRoleId.charAt(0) === "1" ? "w-3/5" : "w-full h-fit";

  // Calculate the number of rows based on the number of elements being displayed
  const numElements = Object.values(decryptedRoleId.slice(1)).filter(
    (char) => char === "1"
  ).length;
  const numRows = Math.ceil(numElements / 2);

  return (
    <div className={containerClass}>
      <div
        className={`grid grid-cols-2 grid-rows-${numRows} gap-4 h-[90%] w-full`}
      >
        {decryptedRoleId.charAt(1) === "1" && (
          <Link to="TeacherManager">
            <div className="bg-slate-300 h-full w-full mb-10 rounded-xl flex flex-col">
              <div className="flex flex-row p-4">
                <div>
                  <i className="ri-user-line text-3xl pr-4"></i>
                </div>
                <div className="text-xl font-medium ">Teacher Manager</div>
              </div>
              <div className="px-4">
                Manage your existing school teachers, add new teachers and
                modify the existing teachers
              </div>
            </div>
          </Link>
        )}

        {decryptedRoleId.charAt(2) === "1" && (
          <Link to="studentManager">
            <div className="bg-slate-300 h-full w-full mb-10 rounded-xl flex flex-col">
              <div className="flex flex-row p-4">
                <div>
                  <i className="ri-user-4-line text-3xl pr-4"></i>
                </div>
                <div className="text-xl font-medium ">Student Manager</div>
              </div>
              <div className="px-4">
                Manage your existing school students, add new students and
                modify the existing students.
              </div>
            </div>
          </Link>
        )}

        {decryptedRoleId.charAt(3) === "1" && (
          <Link to="staffManager">
            <div className="bg-slate-300 h-full w-full mb-10 rounded-xl flex flex-col">
              <div className="flex flex-row p-4">
                <div>
                  <i className="ri-user-4-line text-3xl pr-4"></i>
                </div>
                <div className="text-xl font-medium ">Staff Manager</div>
              </div>
              <div className="px-4">
                Manage your school staffs, add new staffs and modify the
                existing staff details.
              </div>
            </div>
          </Link>
        )}

        {decryptedRoleId.charAt(4) === "1" && (
          <Link to="FeeManager">
            <div className="bg-slate-300 h-full w-full mb-10 rounded-xl flex flex-col ">
              <div className="flex flex-row p-4">
                <div>
                  <i className="ri-money-dollar-circle-line text-3xl pr-4"></i>
                </div>
                <div className="text-xl font-medium ">Fee Manager</div>
              </div>
              <div className="px-4">
                View fee status of the school filtered by Class. Update fee
                status with two options, mark ad paid and mark as unpaid.
              </div>
            </div>
          </Link>
        )}

        {decryptedRoleId.charAt(5) === "1" && (
          <Link to="PayrollManager">
            <div className="bg-slate-300 h-full w-full mb-10 rounded-xl flex flex-col ">
              <div className="flex flex-row p-4">
                <div>
                  <i className="ri-money-dollar-circle-line text-3xl pr-4"></i>
                </div>
                <div className="text-xl font-medium ">Payroll Manager</div>
              </div>
              <div className="px-4">
                View Payroll status of the school's employes filtered by
                teachers and other staff. Update payment status with multiple
                payment details.
              </div>
            </div>
          </Link>
        )}

        {decryptedRoleId.charAt(6) === "1" && (
          <Link to="ExamManager">
            <div className="bg-slate-300 h-full w-full mb-10 rounded-xl flex flex-col">
              <div className="flex flex-row p-4">
                <div>
                  <i className="ri-booklet-line text-3xl pr-4"></i>
                </div>
                <div className="text-xl font-medium ">Exam Manager</div>
              </div>
              <div className="px-4">
                View marks obtained by students of the school. Marks filtered by
                classes.
              </div>
            </div>
          </Link>
        )}

        {decryptedRoleId.charAt(7) === "1" && (
          <Link to="NoticeManager">
            <div className="bg-slate-300 h-full w-full mb-10 rounded-xl flex flex-col">
              <div className="flex flex-row p-4">
                <div>
                  <i className="ri-slideshow-line text-3xl pr-4"></i>
                </div>
                <div className="text-xl font-medium ">Notice Manager</div>
              </div>
              <div className="px-4">
                Manage all school level notices with functions such as Create
                Notice Aand Delete Notice.
              </div>
            </div>
          </Link>
        )}

        {decryptedRoleId.charAt(8) === "1" && (
          <Link to="carouselManager">
            <div className="bg-slate-300 h-full w-full mb-10 rounded-xl flex flex-col">
              <div className="flex flex-row p-4">
                <div>
                  <i className="ri-slideshow-line text-3xl pr-4"></i>
                </div>
                <div className="text-xl font-medium ">Carousel Manager</div>
              </div>
              <div className="px-4">
                Manage your school carousel which is vivible on the student app.
                Create, Edit and Delete carousels.{" "}
              </div>
            </div>
          </Link>
        )}

        {decryptedRoleId.charAt(9) === "1" && (
          <Link to="teacherAnalysis">
            <div className="bg-slate-300 h-full w-full rounded-xl flex flex-col">
              <div className="flex flex-row p-4">
                <div>
                  <i className="ri-user-4-line text-3xl pr-4"></i>
                </div>
                <div className="text-xl font-medium ">Teacher Analysis</div>
              </div>
              <div className="px-4">
                Analyse Teachers class wise performance. Performance compared to
                average class performance and other teacher's performance.{" "}
              </div>
            </div>
          </Link>
        )}

        {decryptedRoleId.charAt(10) === "1" && (
          <Link to="eventManager">
            <div className="bg-slate-300 h-full w-full mb-10 rounded-xl flex flex-col">
              <div className="flex flex-row p-4">
                <div>
                  <i className="ri-slideshow-line text-3xl pr-4"></i>
                </div>
                <div className="text-xl font-medium ">Event Manager</div>
              </div>
              <div className="px-4">
                Manage your school Events which is vivible on both, the student
                app and teacher app. Create, Edit and Delete Events.{" "}
              </div>
            </div>
          </Link>
        )}

        {decryptedRoleId.charAt(11) === "1" && (
          <Link to="attendanceManager">
            <div className="bg-slate-300 h-full w-full mb-10 rounded-xl flex flex-col">
              <div className="flex flex-row p-4">
                <div>
                  <i className="ri-slideshow-line text-3xl pr-4"></i>
                </div>
                <div className="text-xl font-medium ">Attendance Manager</div>
              </div>
              <div className="px-4">
                View monthly attendance of you school filtered by class. Mark
                Holiday for classes.{" "}
              </div>
            </div>
          </Link>
        )}

        {decryptedRoleId.charAt(12) === "1" && (
          <Link to="ComplaintManager">
            <div className="bg-slate-300 h-full w-full mb-10 rounded-xl flex flex-col">
              <div className="flex flex-row p-4">
                <div>
                  <i className="ri-slideshow-line text-3xl pr-4"></i>
                </div>
                <div className="text-xl font-medium ">Complaint Manager</div>
              </div>
              <div className="px-4">
                Manage complaints raised by students of your school. Update
                their compaint status with options such as Reviewing and Action
                Taken.
              </div>
            </div>
          </Link>
        )}
        {decryptedRoleId.charAt(13) === "1" && (
          <Link to="MarksheetGeneration">
            <div className="bg-slate-300 h-full w-full mb-10 rounded-xl flex flex-col">
              <div className="flex flex-row p-4">
                <div>
                  <i className="ri-slideshow-line text-3xl pr-4"></i>
                </div>
                <div className="text-xl font-medium ">Marksheet Generation</div>
              </div>
              <div className="px-4">
                Generate Half Yearly or Yearly marksheet for students. Select
                Exam, subjects and add individual remarksa in marksheet
              </div>
            </div>
          </Link>
        )}
        {decryptedRoleId.charAt(14) === "1" && (
          <Link to="CoachingManager">
            <div className="bg-slate-300 h-full w-full mb-10 rounded-xl flex flex-col">
              <div className="flex flex-row p-4">
                <div>
                  <i className="ri-slideshow-line text-3xl pr-4"></i>
                </div>
                <div className="text-xl font-medium ">Coaching Manager</div>
              </div>
              <div className="px-4">
                Manage you Schools students who have opted for a school
                coaching. Manage coaching fee. Generate the ledger data for
                coaching students
              </div>
            </div>
          </Link>
        )}
        {decryptedRoleId.charAt(15) === "1" && (
          <Link to="admissionRegister">
            <div className="bg-slate-300 h-full w-full mb-10 rounded-xl flex flex-col">
              <div className="flex flex-row p-4">
                <div>
                  <i className="ri-slideshow-line text-3xl pr-4"></i>
                </div>
                <div className="text-xl font-medium ">Admission Register</div>
              </div>
              <div className="px-4">
                Get you school admission records
              </div>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
}

export default DashboardModules;
