  import React, { useState, useEffect } from "react";
import { Box, Toolbar } from "@mui/material";
import Carousel from "react-elastic-carousel";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import imageCompression from "browser-image-compression";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import Modal from "@mui/material/Modal";
import Cookies from "js-cookie";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CarouselManager = () => {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(8) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };    
  const MAX_TITLE_LENGTH = 50;
  const MAX_DESCRIPTION_LENGTH = 250;

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 1400, itemsToShow: 1 },
    { width: 1500, itemsToShow: 2 },
    // { width: 1600, itemsToShow: 3 },
  ];

  const [isAddingCarousel, setIsAddingCarousel] = useState(false); // Add this line

  const [schoolId, setSchoolId] = useState("");
  const [carouselData, setCarouselData] = useState([]);

  const [selectedCarousel, setSelectedCarousel] = useState(null);
  const [open, setOpen] = useState(false);

  const [editedTitle, setEditedTitle] = useState("");
  const [editedDescription, setEditedDescription] = useState("");

  const [isImageSelected, setIsImageSelected] = useState(false); // Add this line

  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `https://edsanchaar.in/api/api_admin/deleteCarousel.php?id=${id}&school_Id=${schoolId}`,
        {
          method: "POST",
          headers: {
            Authorization: "aUBnAANvzn4CzHO76Cr+IHnjs3tvnr680eQSSZG2sgw=",
            // Add other headers if needed
          },
        }
      );

      if (response.ok) {
        toast.success("Carousel Deleted Successfully ");

        setCarouselData((prevData) =>
          prevData.filter((carousel) => carousel.ID !== id)
        );
      } else {
        const errorData = await response.json();
        console.error("Failed to delete carousel:", errorData.message);
      }
    } catch (error) {
      console.error("Error deleting carousel:", error);
    }
  };

  const handleEdit = (id) => {
    const selected = carouselData.find((item) => item.ID === id);
    setSelectedCarousel(selected);
    setEditedTitle(selected.title);
    setEditedDescription(selected.description);
    handleOpen();
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    // Clear edited values when closing the modal
    setEditedTitle("");
    setEditedDescription("");
    document.getElementById("classDescription").value = "";
  };

  const isSubmitDisabled = () => {
    const titleValid = editedTitle.trim().length > 0;
    const descriptionValid = editedDescription.trim().length > 0;

    // Retrieve the file input
    const imageInput = document.getElementById("classDescription");

    // Check if the file input is valid
    const imageValid = imageInput?.files?.length > 0;
    return !titleValid || !descriptionValid || !imageValid;
  };

  const handleConfirmEdit = async () => {
    try {
      const formData = new FormData();
      formData.append("carousel_id", selectedCarousel.ID); // assuming your server expects carousel ID
      formData.append("title", editedTitle);
      formData.append("description", editedDescription);
      formData.append("school_id", schoolId);
      // console.log(formData);

      const response = await fetch(
        // `http://localhost/react/api_admin/updateCarousel.php`,
        `https://edsanchaar.in/api/api_admin/updateCarousel.php`,
        {
          headers: {
            Authorization: "aUBnAANvzn4CzHO76Cr+IHnjs3tvnr680eQSSZG2sgw=",
            // Add other headers if needed
          },
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        // Handle success, you may want to update local state or trigger a re-fetch
        toast.success("Carousel Updated Successfully");

        setEditedTitle("");
        setEditedDescription("");
        // Clear the file input
        document.getElementById("classDescription").value = "";

        const schoolIdFromCookie = Cookies.get("schoolId");
        fetchCarouselData(schoolIdFromCookie);
      } else {
        // Handle error, you may want to show an error message
        toast.error("Failed to update carousel");
      }
    } catch (error) {
      // Handle network error
      console.error("Error updating carousel:", error);
    }

    handleClose();
  };
  const handleImageChange = (e) => {
    // Check if the file input is valid
    const imageInput = document.getElementById("classDescription");
    const isImageSelected = imageInput?.files?.length > 0;

    // Set the state based on whether an image is selected or not
    setIsImageSelected(isImageSelected);

    // If all conditions are true, enable the button
    if (!isSubmitDisabled()) {
      setIsAddingCarousel(false); // Enable the button
    }
  };

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 0.15,
      maxWidthOrHeight: 500,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image:", error);
      return file; // Return the original file if compression fails
    }
  };

  const handleAddCarousel = async (e) => {
    e.preventDefault();

    setIsAddingCarousel(true);

    const formData = new FormData();
    formData.append("title", editedTitle);
    formData.append("description", editedDescription);
    formData.append("school_id", schoolId);

    const imageInput = document.getElementById("classDescription");
    if (imageInput.files.length > 0) {
      try {
        const compressedImage = await compressImage(imageInput.files[0]);
        formData.append("image", compressedImage, "compressed_image.jpg");
      } catch (error) {
        console.error("Error compressing image:", error);
        return;
      }
    } else {
      console.error("No image selected");
      return;
    }

    // Use a promise to ensure a minimum loading duration
    const loadingPromise = new Promise((resolve) => {
      setTimeout(resolve, 1000); // 2000 milliseconds (2 seconds)
    });

    try {
      await toast.promise(
        // Combining the loadingPromise with the fetch promise
        Promise.all([
          loadingPromise,
          new Promise(async (resolve, reject) => {
            try {
              const response = await fetch(
                // "http://localhost:80/react/api_admin/addCarousel.php",
                "https://edsanchaar.in/api/api_admin/addCarousel.php",
                {
                  method: "POST",
                  headers: {
                    Authorization:
                      "aUBnAANvzn4CzHO76Cr+IHnjs3tvnr680eQSSZG2sgw=",
                  },
                  body: formData,
                }
              );

              if (response.ok) {
                resolve(); // Resolve the promise on success
                const schoolIdFromCookie = Cookies.get("schoolId");
                fetchCarouselData(schoolIdFromCookie);
              } else {
                toast.error("Failed to add carousel");
                reject(); // Reject the promise on failure
              }
            } catch (error) {
              toast.error("Error adding carousel please try again later");
              reject(); // Reject the promise on error
            }
          }),
        ]),
        {
          // Toast options
          loading: "Adding Carousel plesase wait...",
          success: <b>Carousel added successfully!</b>,
          error: <b>Failed to add carousel.</b>,
        }
      );
    } finally {
      // Enable the button after API response, whether successful or not
      setIsAddingCarousel(false);
      setEditedTitle("");
      setEditedDescription("");
      document.getElementById("classDescription").value = "";
    }
  };

  const fetchCarouselData = async (schoolId) => {
    try {
      const response = await fetch(
        // `http://localhost/react/api_admin/fetchCarousel.php?school_id=${schoolId}`,
        `https://edsanchaar.in/api/api_admin/fetchCarousel.php?school_id=${schoolId}`,
        {
          headers: {
            Authorization: "aUBnAANvzn4CzHO76Cr+IHnjs3tvnr680eQSSZG2sgw=",
            // Add other headers if needed
          },
        }
      );
      const data = await response.json();
      setCarouselData([...data]);
    } catch (error) {
      console.error("Error fetching carousel data:", error);
    }
  };

  useEffect(() => {
    const fetchSchoolId = () => {
      // Fetch schoolId from cookies
      const schoolIdFromCookie = Cookies.get("schoolId");

      if (!schoolIdFromCookie) {
        // Redirect to login or handle accordingly if schoolId is not available
        // You may want to add additional handling, such as showing an error message
        console.error("School ID not available");
        return;
      }

      setSchoolId(schoolIdFromCookie);

      // Fetch carousel data after setting school_id
      fetchCarouselData(schoolIdFromCookie);
    };

    fetchSchoolId();
  }, []);

  return (
    <div className="flex items-center justify-center w-full h-screen bg-white">
      <Toaster position="top-center" reverseOrder={true} />
      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 2,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="h-full w-full flex flex-col justify-start items-center overflow-x-auto">
            <div className="text-3xl font-bold h-10">Carousel Manager</div>

            <div className="w-full flex-grow flex flex-row justify-between items-center py-8  ">
              <div className="App flex w-full px-1 h-max">
                <Carousel
                  breakPoints={breakPoints}
                  pagination={false}
                  transitionMs={3000}
                  enableAutoPlay={true}
                  autoPlaySpeed={1500}
                  showArrows={true}
                  infiniteLoop={true}
                >
                  {carouselData.map((item, index) => (
                    <div
                      key={index}
                      className="link-container bg-slate-400 rounded-2xl w-[90%] max-w-[800px]"
                    >
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: "col",
                          width: "100%", // Set the desired fixed width
                          height: 190,
                          borderRadius: 4,
                        }}
                      >
                        <CardMedia
                          sx={{
                            height: "max",
                            minWidth: 200,
                          }}
                          // image={`http://localhost/react/api_admin/${item.url}`}
                          image={`https://edsanchaar.in/api/api_admin/${item.url}`}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {item.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {item.description}
                          </Typography>
                        </CardContent>
                      </Card>
                      <div className="flex flex-row justify-evenly">
                        <button
                          className="text-lg"
                          onClick={() => handleDelete(item.ID)}
                        >
                          Delete Carousel
                        </button>
                        <button
                          className="text-lg"
                          onClick={() => handleEdit(item.ID)}
                        >
                          Edit Carousel
                        </button>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
            {selectedCarousel && (
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="rounded-md">
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Edit Carousel
                  </Typography>
                  <form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <div>
                      <div className="flex flex-row justify-between">
                        <label htmlFor="editedTitle">Title:</label>
                        <div className="text-sm text-gray-500">
                          {editedTitle.length}/{MAX_TITLE_LENGTH} characters
                        </div>
                      </div>
                      <input
                        type="text"
                        id="editedTitle"
                        name="editedTitle"
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                        maxLength={MAX_TITLE_LENGTH} // Set the maximum length for the title
                        style={{
                          width: "100%",
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                        }}
                      />
                    </div>
                    <div>
                      <div className="flex flex-row justify-between">
                        <label htmlFor="editedDescription">Description:</label>
                        <div className="text-sm text-gray-500">
                          {editedDescription.length}/{MAX_DESCRIPTION_LENGTH}{" "}
                          characters
                        </div>
                      </div>
                      <textarea
                        id="editedDescription"
                        name="editedDescription"
                        value={editedDescription}
                        onChange={(e) => setEditedDescription(e.target.value)}
                        maxLength={MAX_DESCRIPTION_LENGTH} // Set the maximum length for the description
                        rows={6}
                        style={{
                          width: "100%",
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                        }}
                      />
                    </div>
                    {/* Add more form fields as needed */}
                    <div style={{ marginTop: "16px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirmEdit}
                        style={{ width: "100%" }}
                      >
                        Confirm Edit
                      </Button>
                    </div>
                  </form>
                </Box>
              </Modal>
            )}

            <div className="bg-white shadow-lg  w-2/5 rounded-xl flex flex-col justify-center items-center">
              <div className="w-full">
                <div
                  variant="h5"
                  gutterBottom
                  className="pt-4 pl-2 w-full text-center text-2xl font-bold"
                >
                  Add Carousal
                </div>
              </div>
              <div className="flex justify-between items-start w-full h-fit">
                <div className="flex flex-col justify-center items-center w-full">
                  <div className="w-[60%] mb-4 mt-4">
                    <div className="flex flex-row justify-between">
                      <label className="font-bold mb-1" htmlFor="name">
                        Title
                      </label>
                      <div className="text-sm text-gray-500">
                        {editedTitle.length}/{MAX_TITLE_LENGTH} characters
                      </div>
                    </div>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="w-full p-2 mt-1 border border-gray-300 rounded focus:shadow-outline focus:border-blue-300"
                      placeholder="Enter title"
                      value={editedTitle}
                      onChange={(e) => setEditedTitle(e.target.value)}
                      maxLength={MAX_TITLE_LENGTH} // Set the maximum length for the title
                    />
                  </div>

                  <div className="w-[60%] mb-4">
                    <div className="flex flex-row justify-between">
                      <label className="font-bold mb-1" htmlFor="classteacher">
                        Description
                      </label>
                      <div className="text-sm text-gray-500">
                        {editedDescription.length}/{MAX_DESCRIPTION_LENGTH}{" "}
                        characters
                      </div>
                    </div>
                    <textarea
                      id="classteacher"
                      name="classteacher"
                      className="w-full p-2 mt-1 border border-gray-300 rounded focus:shadow-outline focus:border-blue-300"
                      placeholder="description"
                      rows={4}
                      value={editedDescription}
                      onChange={(e) => setEditedDescription(e.target.value)}
                      maxLength={MAX_DESCRIPTION_LENGTH} // Set the maximum length for the description
                    />
                  </div>

                  <div className="w-[60%] mb-4 flex flex-col items-start">
                    <label
                      className="font-bold mb-1"
                      htmlFor="classDescription"
                    >
                      Image
                    </label>
                    <input
                      type="file"
                      id="classDescription"
                      name="classDescription"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      onChange={(e) => handleImageChange(e)}
                      accept=".png, .jpeg, .jpg" // Add specific file type restrictions if needed
                    />
                  </div>

                  <div>
                    <button
                      type="button"
                      style={{ width: "100%" }}
                      className={`bg-blue-500 text-white px-4 py-2 rounded ${
                        isSubmitDisabled()
                          ? "cursor-not-allowed opacity-50"
                          : ""
                      }`}
                      onClick={handleAddCarousel}
                      disabled={isSubmitDisabled()} // Ensure the correct function call here
                    >
                      Add Carousel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Toolbar>
    </div>
  );
};

export default CarouselManager;
