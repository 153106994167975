import React, { useState, useEffect } from "react";
import { Box, Toolbar } from "@mui/material";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

function FetchStaff() {

    const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {

    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(3) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const [staffData, setStaffData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const rotationDeg = 90; // Adjust the rotation degree as needed

  const fetchStaffData = (schoolId) => {
    fetch(
      `https://edsanchaar.in/api/api_admin/Staff/fetchStaff.php?school_id=${schoolId}`,
      {
        method: "GET",
        headers: {
          Authorization: "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setStaffData(data);
        setError(null);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // Fetch schoolId from cookies
    const schoolId = Cookies.get("schoolId");

    if (!schoolId) {
      // Redirect to login if schoolId is not available
      // You may want to add additional handling, such as showing an error message
      return;
    }

    // Fetch Staff data with dynamic schoolId
    fetchStaffData(schoolId);
  }, []);

  return (
    <div className="flex items-center justify-center w-full h-screen bg-white">
      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="flex flex-col justify-center items-center">
            <div className="text-3xl font-bold">Staff Data</div>
          </div>
          {staffData.length > 0 && (
            <div className="w-full h-full overflow-x-auto">
              <table className="w-full table-auto border-collapse border">
                <thead>
                  <tr className="bg-blue-200">
                    <th className="border p-2">Username</th>
                    <th className="border p-2">Name</th>
                    <th className="border p-2">Roll No</th>
                    <th className="border p-2">Gender</th>
                    <th className="border p-2">Role</th>
                    <th className="border p-2">Date of Birth</th>
                  </tr>
                </thead>
                {loading ? (
                  <div className="flex w-full items-center justify-center">
                    <CircularProgress size={60} />
                  </div>
                ) : (
                  <tbody>
                    {staffData.map((staff, key) => (
                      <tr
                        key={key}
                        className={key % 2 === 0 ? "bg-blue-100" : "bg-white"}
                      >
                        <td className="border p-2">{staff.username}</td>
                        <td className="border p-2">{staff.name}</td>
                        <td className="border p-2">{staff.roll_no}</td>
                        <td className="border p-2">{staff.gender === 0 ? "Male" : "Female"}</td>
                        <td className="border p-2">{staff.role}</td>
                        <td className="border p-2">{staff.dob}</td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          )}
        </Box>
      </Toolbar>
    </div>
  );
}

export default FetchStaff;
