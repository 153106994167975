import React, { useState, useEffect } from "react";
import {
  Box,
  Toolbar,
  Modal,
  Button,
  FormControl,
  Radio,
  FormControlLabel,
  Dialog,
  DialogContent,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";

function AddTeacher() {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    console.log("Decrypted Role ID:", decryptedRoleId);
    console.log("LoadingRoleId:", loadingRoleId);

    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(1) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const [formData, setFormData] = useState({
    school_id: "",
    roll_no: "",
    class_id: "",
    name: "",
    gender: "",
    teacher_UID: "",
    subject: "",
    qualification: "",
    contact: "",
    class_teacher: "",
  });

  const [checkedClassIds, setCheckedClassIds] = useState([]);
  const [classIds, setClassIds] = useState([]);
  const [classSubjectMap, setClassSubjectMap] = useState({});

  const [subjectModalOpen, setSubjectModalOpen] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedSubjectsString, setSelectedSubjectsString] = useState("");
  const [selectedSubjectsMap, setSelectedSubjectsMap] = useState({});
  const [availableClasses, setAvailableClasses] = useState([]);

  // setting class ids

  const handleClassSelection = (selectedClass) => {
    setCheckedClassIds((prevCheckedClassIds) => {
      if (prevCheckedClassIds.includes(selectedClass)) {
        // Remove the class if already selected
        return prevCheckedClassIds.filter(
          (classId) => classId !== selectedClass
        );
      } else {
        // Add the class if not selected
        return [...prevCheckedClassIds, selectedClass];
      }
    });
  };
  const [classModalOpen, setClassModalOpen] = useState(false);

  const handleClassModalOpen = () => {
    setClassModalOpen(true);
  };

  const handleClassModalClose = () => {
    setClassModalOpen(false);
  };
  const fetchClassIds = async (schoolId) => {
    const apiUrl = `https://edsanchaar.in/api/api_admin/TeacherManager/getSchoolClasses.php?school_id=${schoolId}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
        },
      });

      const data = response.data;

      if (Array.isArray(data)) {
        // console.log(data);
        const classSubjectMap = {};
        data.forEach((classInfo) => {
          const className = classInfo["class"];
          const subjects = classInfo["subjects"].split(",");
          classSubjectMap[className] = subjects;
        });

        setClassIds(Object.keys(classSubjectMap));
        setClassSubjectMap(classSubjectMap);
      } else {
        // console.error("Invalid response format for class IDs:", data);
      }
    } catch (error) {
      // console.error("Error fetching class IDs:", error);
    }
  };

  const fetchAvailableClasses = async (schoolId) => {
    const apiUrl = `https://edsanchaar.in/api/api_admin/TeacherManager/availableSchoolClasses.php?school_id=${schoolId}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
        },
      });

      const data = response.data;
      setAvailableClasses(data);
    } catch (error) {
      console.error("Error fetching class IDs:", error);
    }
  };

  // subject selection related functinos and code

  const handleSubjectSelection = (selectedSubject, classId) => {
    setSelectedSubjectsMap((prevSelectedSubjectsMap) => {
      // Create a copy of the previous state
      const newSelectedSubjectsMap = { ...prevSelectedSubjectsMap };
      // Get the selected subjects array for the class or initialize it if not present
      const selectedSubjectsForClass = newSelectedSubjectsMap[classId] || [];
      // Toggle the selected subject for the class
      if (selectedSubjectsForClass.includes(selectedSubject)) {
        // Remove the subject if already selected
        newSelectedSubjectsMap[classId] = selectedSubjectsForClass.filter(
          (subject) => subject !== selectedSubject
        );
      } else {
        // Add the subject if not selected
        newSelectedSubjectsMap[classId] = [
          ...selectedSubjectsForClass,
          selectedSubject,
        ];
      }
      return newSelectedSubjectsMap;
    });
  };

  const handleSubjectModalOpen = () => {
    // Open the modal to select subjects based on checked classes
    setSubjectModalOpen(true);
  };

  const handleSubjectModalClose = () => {
    // Close the modal for selecting subjects
    setSubjectModalOpen(false);
  };

  const isSubjectModalSubmitDisabled = () => {
    // Check if any of the checked classes have no selected subjects
    return checkedClassIds.some(
      (classId) =>
        !selectedSubjectsMap[classId] ||
        selectedSubjectsMap[classId].length === 0
    );
  };

  const handleSubjectModalSubmit = () => {
    // Create an array to store objects with class_id and subject
    const classSubjectPairs = [];

    // Iterate over the selected classes
    checkedClassIds.forEach((classId) => {
      // Get selected subjects for the current class
      const selectedSubjectsForClass = selectedSubjectsMap[classId] || [];
      // Map selected subjects to class_id and subject objects
      const subjectsForClass = selectedSubjectsForClass.map((subject) => ({
        class_id: classId,
        subject: subject,
      }));
      // Add the objects to the array
      classSubjectPairs.push(...subjectsForClass);
    });

    // Update the form data with the class_id and subject pairs
    setFormData((prevFormData) => ({
      ...prevFormData,
      class_id: classSubjectPairs.map((pair) => pair.class_id).join(", "),
      subject: classSubjectPairs.map((pair) => pair.subject).join(", "),
      classSubjectPairs: classSubjectPairs,
    }));

    // Close the modal after submitting
    handleSubjectModalClose();
  };

  // seuject selection related code end

  useEffect(() => {
    const schoolId = Cookies.get("schoolId");

    if (!schoolId) {
      return;
    }
    fetchClassIds(schoolId);
    fetchAvailableClasses(schoolId);

    setFormData((prevFormData) => ({
      ...prevFormData,
      school_id: schoolId,
    }));
  }, []);

  const isSubmitDisabled = () => {
    // Check if any of the required fields are empty except for "Class Teacher"
    return Object.entries(formData).some(([key, value]) => {
      // Exclude the "Class Teacher" field from the check
      if (key === "class_teacher") {
        return false;
      }
      // Check if the value is a string before calling trim
      return typeof value === "string" && value.trim() === "";
    });
  };

  const [schoolId, setSchoolId] = useState("");
  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      // Redirect to login or handle accordingly if schoolId is not available
      // You may want to add additional handling, such as showing an error message
      console.error("School ID not available");
      return;
    }

    // Set the school_id in the state
    setSchoolId(schoolIdFromCookie);
  }, []);
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const classTeacher =
      formData.class_teacher.trim() === "" ? "0" : formData.class_teacher;

    try {
      const genderValue = formData.gender.toLowerCase() === "female" ? 1 : 0;

      // Use toast.promise to handle promises
      await toast.promise(
        // The promise to execute
        new Promise(async (resolve, reject) => {
          try {
            // Simulate a 2-second delay intentionally
            setTimeout(async () => {
              try {
                const response = await axios.post(
                  `https://edsanchaar.in/api/api_admin/TeacherManager/addTeacher.php?school_id=${schoolId}`,
                  {
                    method: "POST",
                    ...formData,
                    gender: genderValue,
                    class_teacher: classTeacher, // Set class_teacher to "0" if empty
                  },
                  {
                    headers: {
                      Authorization:
                        "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
                      "Content-Type": "application/json",
                    },
                    withCredentials: true,
                  }
                );

                if (response.status === 200) {
                  // Reset the form data on success
                  setFormData({
                    school_id: "",
                    roll_no: "",
                    class_id: "",
                    name: "",
                    gender: "",
                    teacher_UID: "",
                    subject: "",
                    qualification: "",
                    contact: "",
                    class_teacher: "",
                  });
                  resolve(); // Resolve the promise on success
                } else {
                  reject(new Error("Failed to add teacher")); // Reject the promise on failure
                }
              } catch (error) {
                reject(error); // Reject the promise with the axios error
              }
            }, 2000); // Intentional 2-second delay
          } catch (error) {
            reject(error); // Reject the promise with the setTimeout error
          }
        }),
        {
          // Toast options
          loading: "Adding Teacher...",
          success: <b>Teacher added successfully!</b>,
          error: (error) => {
            if (axios.isAxiosError(error) && !error.response) {
              // Network error
              return (
                <b>
                  Network error. Check your internet connection or try again
                  later.
                </b>
              );
            }
            return <b>{`Failed to add teacher. ${error.message}`}</b>;
          },
        }
      );
    } catch (error) {
      console.error("Error in handleFormSubmit:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen bg-white">
      <Toaster position="top-center" reverseOrder={true} />
      <Toolbar
        sx={{
          height: "95vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "95.5%",
            height: "95%",
          }}
        >
          <div className="text-3xl font-bold text-slate-900 mx-0 align-center">
            Register Teacher
          </div>
          <div className="bg-white p-8 rounded shadow-lg text-center w-5/12 opacity-90% flex flex-col">
            <div>
              <h1 className="text-2xl font-bold mb-6">Teacher Details</h1>
              <form onSubmit={handleFormSubmit} method="POST">
                <p className="text-left mb-3">
                  required fields are followed by *
                </p>
                <div className="flex justify-between flex-wrap">
                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="roll_no">
                      Roll_no: *
                    </label>
                    <input
                      type="text"
                      id="roll_no"
                      name="roll_no"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter roll no"
                      required
                      value={formData.roll_no}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="w-[45%] mb-15 flex flex-col items-start flex-wrap">
                    <label className="font-bold mb-1" htmlFor="class_id">
                      Class_id: *
                    </label>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        width: "fit",
                      }}
                      onClick={handleClassModalOpen}
                    >
                      Select Classes
                    </Button>
                  </div>

                  {/* ... (existing code) */}

                  <Dialog
                    open={classModalOpen}
                    onClose={handleClassModalClose}
                    aria-labelledby="class-modal-title"
                    aria-describedby="class-modal-description"
                  >
                    <DialogContent>
                      <FormControl
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <label>Select Classes</label>
                        <div className="flex flex-column flex-wrap">
                          {classIds.map((classId) => (
                            <FormControlLabel
                              key={classId}
                              control={
                                <Checkbox
                                  checked={checkedClassIds.includes(classId)}
                                  onChange={() => handleClassSelection(classId)}
                                  color="primary"
                                />
                              }
                              label={classId}
                              style={{ marginBottom: "5px" }}
                            />
                          ))}
                        </div>
                      </FormControl>
                      <div className="flex flex-row justify-center items-center">
                        <Button
                          onClick={handleClassModalClose}
                          variant="contained"
                          color="primary"
                        >
                          Save
                        </Button>
                      </div>
                    </DialogContent>
                  </Dialog>
                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="name">
                      Name: *
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter full name"
                      required
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="name">
                      Teacher UID: *
                    </label>
                    <input
                      type="text"
                      id="teacher_UID"
                      name="teacher_UID"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter full name"
                      required
                      value={formData.teacher_UID}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row flex-wrap">
                    <label className="block font-bold mb-1" htmlFor="gender">
                      Gender: *
                    </label>
                    <select
                      name="gender"
                      id="gender"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      required
                      value={formData.gender}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled hidden>
                        Select Gender
                      </option>

                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  <div className="w-[45%] mb-15 flex flex-col items-start  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="subject">
                      Subjects: *
                    </label>
                    <div className="flex flex-col">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubjectModalOpen}
                      >
                        Select Subjects
                      </Button>
                      {selectedSubjectsString && (
                        <div className="mt-2">
                          <strong>Selected Subjects:</strong>{" "}
                          {selectedSubjectsString}
                        </div>
                      )}
                    </div>
                  </div>

                  <Dialog
                    open={subjectModalOpen}
                    onClose={handleSubjectModalClose}
                    aria-labelledby="subject-modal-title"
                    aria-describedby="subject-modal-description"
                  >
                    <DialogContent>
                      <FormControl
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <label>Select Subjects</label>
                        <div className="flex flex-column flex-wrap">
                          {checkedClassIds.map((classId) => (
                            <div key={classId}>
                              <h4>{classId}</h4>
                              {classSubjectMap[classId].map((subject) => (
                                <FormControlLabel
                                  key={subject}
                                  control={
                                    <Checkbox
                                      checked={selectedSubjectsMap[
                                        classId
                                      ]?.includes(subject)}
                                      onChange={() =>
                                        handleSubjectSelection(subject, classId)
                                      }
                                      color="primary"
                                    />
                                  }
                                  label={subject}
                                  style={{ marginBottom: "5px" }}
                                />
                              ))}
                            </div>
                          ))}
                        </div>
                      </FormControl>
                      <div className="flex flex-row justify-center items-center">
                        <Button
                          onClick={handleSubjectModalSubmit}
                          variant="contained"
                          color="primary"
                          disabled={isSubjectModalSubmitDisabled()}
                        >
                          Save
                        </Button>
                      </div>
                    </DialogContent>
                  </Dialog>
                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="qualification">
                      Qualification:*
                    </label>
                    <input
                      type="text"
                      id="qualification"
                      name="qualification"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter qualification"
                      required
                      value={formData.qualification}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="contact">
                      Contact: *
                    </label>
                    <input
                      type="number"
                      id="contact"
                      name="contact"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter contact number"
                      required
                      value={formData.contact}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row flex-wrap">
                    <label className="font-bold mb-1" htmlFor="class_teacher">
                      Class Teacher: *
                    </label>
                    <p className="text-xs">
                      Kindly leave blank if not a class teacher.
                    </p>
                    <select
                      id="class_teacher"
                      name="class_teacher"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      value={formData.class_teacher}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="0">Select Class</option>
                      {availableClasses.map((classItem, index) => (
                        <option key={index} value={classItem}>
                          {classItem}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Repeat similar input fields for other form fields */}
                </div>
                <div className="flex justify-center my-3">
                  <button
                    type="submit"
                    onClick={handleFormSubmit}
                    className={`bg-blue-500 text-white px-4 py-2 rounded ${
                      isSubmitDisabled() ? "cursor-not-allowed opacity-50" : ""
                    }`}
                    disabled={isSubmitDisabled()}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Toolbar>
      <div className="footer-bottom w-full bg-lightblue-800 p-4 text-center text-black mt-4">
        <p>© 2023 Ed-Sanchaar. All rights reserved.</p>
      </div>
    </div>
  );
}

export default AddTeacher;
