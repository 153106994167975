import React, { useState, useEffect } from "react";
import { Box, Toolbar, Modal, Button } from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const columns = [
  { field: "id", headerName: "Id", width: 90 },
  { field: "fee_id", headerName: "Fee Id", width: 90 },
  { field: "class_id", headerName: "Class Id", width: 90 },
  { field: "username", headerName: "Username", width: 120 },
  { field: "name", headerName: "Name", width: 200 },
  {
    field: "verification_status",
    headerName: "Status",
    width: 100,
    renderCell: (params) => (
      <span>{params.value === 1 ? "Verified" : "Pending"}</span>
    ),
  },
  { field: "request_date", headerName: "Request Date", width: 160 },
];

function VerifyFeePayment() {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoading(false); // Set loading to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {

    // Check the condition and navigate only if not loading and role is not "1"
    if (!loading && decryptedRoleId.charAt(4) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loading]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const [school_id, setSchoolId] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]); // State to store the fetched data
  const [originalData, setOriginalData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [receiptNo, setReceiptNo] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [totalPaid, setTotalPaid] = useState("");
  const [filterText, setFilterText] = useState("");

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const handleFilterChange = (e) => {
    const searchText = e.target.value.toLowerCase();
    setFilterText(searchText);

    // Update filteredData based on the input value
    const filteredItems = searchText
      ? originalData.filter(
          (item) =>
            item.name.toLowerCase().includes(searchText) ||
            item.username.toLowerCase().includes(searchText)
          // Add other fields you want to filter by
        )
      : [...originalData]; // If the search text is empty, spread the original data

    setFilteredData(filteredItems);
  };

  // updating the values and button flag
  const handleTotalPaidChange = (e) => {
    const value = e.target.value;
    setTotalPaid(value);
    updateSubmitDisabled(value, transactionId);
  };

  const handleTransactionIdChange = (e) => {
    const value = e.target.value;
    setTransactionId(value);
    updateSubmitDisabled(totalPaid, value);
  };

  const updateSubmitDisabled = (totalPaid, transactionId) => {
    const isDisabled = !totalPaid || !transactionId;
    setIsSubmitDisabled(isDisabled);
  };

  const fetchVerificationData = async (schoolId) => {
    const apiUrl = `https://edsanchaar.in/api/api_admin/FeeVerification/getSchoolFeeVerifications.php?school_id=${schoolId}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: "aV0xQRFizDkSwyzksn2lc0xi4Rytc8y4fT1ftwiL8ek",
        },
      });

      const fetchedData = response.data;

      if (Array.isArray(fetchedData)) {
        // Update the state with the fetched data
        setData(fetchedData);
        setOriginalData(fetchedData);
        setFilteredData(fetchedData);
      } else {
        console.error(
          "Invalid response format for fee verifications data:",
          fetchedData
        );
      }
    } catch (error) {
      console.error("Error fetching fee verifications data:", error);
    }
  };

  const handleUpdateFeeDetails = async () => {
    if (parseInt(totalPaid) > parseInt(selectedItem.total_fees)) {
      toast.error("Paid fee cannot be more than Total Fee");
      return;
    }
    // Create FormData
    const formData = new FormData();

    // Append the necessary data to the FormData object
    formData.append("fee_id", selectedItem.fee_id);
    formData.append("receipt_no", receiptNo);
    formData.append("transaction_id", transactionId);
    formData.append("total_paid", totalPaid);

    try {
      await toast.promise(
        // The promise to execute
        new Promise(async (resolve, reject) => {
          try {
            const response = await axios.post(
              `https://edsanchaar.in/api/api_admin/FeeVerification/verifyFeePayment.php?school_id=${school_id}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  authorization: "YOUR_API_KEY",
                },
              }
            );

            if (response.data.status === 1) {
              handleModalClose();
              fetchVerificationData(school_id); // Fetch verification data again
              resolve("Fee details updated successfully"); // Resolve the promise on success
            } else {
              console.error(
                "Error updating fee details:",
                response.data.message
              );
              reject(); // Reject the promise on failure
            }
          } catch (error) {
            console.error("Error sending data to API:", error);
            reject(); // Reject the promise on error
          }
        }),
        {
          // Toast options
          loading: "Updating fee details...",
          success: (props) => {
            // Additional toast for "Fee details updated successfully"
            toast.success("Fee details updated successfully");
            return <b>{props}</b>;
          },
          error: <b>Error updating fee details. Please try again.</b>,
        }
      );
    } catch (error) {
      console.error("Error handling fee details update: ", error);
    }
  };

  // fee reject function
  const handleFeeReject = async () => {
    const formData = new FormData();
    formData.append("fee_id", selectedItem.fee_id);

    try {
      await toast.promise(
        // The promise to execute
        new Promise(async (resolve, reject) => {
          // Simulate a 2-second loading duration
          setTimeout(async () => {
            try {
              const response = await axios.post(
              `https://edsanchaar.in/api/api_admin/FeeManager/rejectFeeVerification.php?school_id=${school_id}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    authorization: "YOUR_API_KEY",
                  },
                }
              );

              if (response.data.status === 1) {
                handleModalClose();
                resolve(); // Resolve the promise on success
              } else {
                console.error(
                  "Error updating fee details:",
                  response.data.message
                );
                reject(); // Reject the promise on failure
              }
            } catch (error) {
              console.error("Error sending data to API:", error);
              reject(); // Reject the promise on error
            }
          }, 2000); // Simulate a 2-second loading duration
        }),
        {
          // Toast options
          loading: "Rejecting fee verification...",
          success: (props) => {
            handleModalClose();
            fetchVerificationData(school_id); // Fetch verification data again
            return <b>{props}</b>;
          },
          error: <b>Error updating fee details. Please try again.</b>,
        }
      );
    } catch (error) {
      console.error("Error handling fee rejection: ", error);
    }
  };

  const handleModalOpen = (item) => {
    setSelectedItem(item);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      console.error("School ID not available");
      return;
    }

    setSchoolId(schoolIdFromCookie);
    fetchVerificationData(schoolIdFromCookie);
  }, []);

  return (
    <div className="flex items-center justify-center h-screen w-full">
      <Toaster position="top-center" reverseOrder={true} />

      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 2,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="h-full w-full flex flex-col justify-center items-center">
            <div className="text-3xl font-bold py-4">School Fee Data</div>

            <div className="flex flex-row ">
              <label className="w-full">Filter by Username or Name: </label>
              <input
                style={{
                  width: "100%",
                  borderRadius: "4px",
                  border: "0.5px solid #ccc",
                  padding: "2px",
                  marginLeft: "4px",
                }}
                type="text"
                name="receiptNo"
                value={filterText}
                onChange={handleFilterChange}
              />
            </div>

            <div className="w-full h-full overflow-x-auto flex flex-wrap justify-center">
              {filteredData.map((item) => (
                <div
                  key={item.id}
                  className="m-2 p-4 border rounded-xl bg-white shadow-xl h-fit"
                >
                  <div className="text-sm">Name: {item.name}</div>
                  <div className="text-sm">Username: {item.username}</div>

                  <div className="flex flex-row">
                    <div className="text-sm">Plan: {item.plan}</div>
                    <div className="mx-2 text-sm">Title: {item.title}</div>
                  </div>
                  <div className="text-sm">Class ID: {item.class_id}</div>

                  {/* Display the transaction_screenshot image */}
                  <div className="h-40 w-full flex justify-center items-center my-4">
                    <img
                      className="object-fit h-36 w-20"
                      src={`https://edsanchaar.in/api/api_student/fees/${item.transaction_screenshot}`}
                      alt="Transaction Screenshot"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  </div>
                  <div className="text-sm">
                    Status:{" "}
                    <span
                      style={{
                        color:
                          item.verification_status === 1 ? "green" : "#FEA837",
                      }}
                    >
                      {item.verification_status === 1 ? "Verified" : "Pending"}
                    </span>
                  </div>
                  <div className="text-sm">
                    Request Date: {formatDate(item.request_date)}
                  </div>
                  <div
                    className={`${
                      item.verification_status !== 1
                        ? "bg-blue-500 text-white"
                        : "bg-gray-400 text-gray-700 cursor-not-allowed"
                    } text-sm shadow-xl rounded-md py-2 my-2 flex justify-center items-center hover:cursor-pointer`}
                    onClick={() => {
                      if (item.verification_status !== 1) {
                        handleModalOpen(item);
                      }
                    }}
                  >
                    <span>
                      {item.verification_status !== 1
                        ? "Update Status"
                        : "Status Updated"}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Box>
      </Toolbar>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          height: "90vh",
          width: "100%",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: "60%",
            height: "80%",
            borderRadius: 2,
          }}
        >
          {selectedItem && (
            <div className="w-full h-full flex flex-row">
              {/* Display the data in the modal */}
              <div className="h-full px-4 w-1/2 flex justify-center items-center">
                <img
                  className="object-cover h-full rounded-xl"
                  src={`https://edsanchaar.in/api/api_student/fees/${selectedItem.transaction_screenshot}`}
                  alt="Transaction Screenshot"
                />
              </div>
              <div className="w-2/6 px-10 flex flex-col justify-center items-center">
                <div className="flex flex-col justify-center items-start">
                  <div>
                    <label style={{ marginBottom: "4px", display: "block" }}>
                      {selectedItem.name}
                    </label>
                  </div>
                  <div>
                    <label style={{ marginBottom: "4px", display: "block" }}>
                      Class {selectedItem.class_id}
                    </label>
                  </div>
                  <div>
                    <label style={{ marginBottom: "4px", display: "block" }}>
                      {selectedItem.plan} fee
                    </label>
                  </div>
                  <div>
                    <label style={{ marginBottom: "4px", display: "block" }}>
                      {selectedItem.title}
                    </label>
                  </div>

                  <div className="w-full ">
                    <label style={{ marginBottom: "4px", display: "block" }}>
                      Receipt No:
                    </label>
                    <input
                      style={{
                        padding: "8px",
                        borderRadius: "4px",
                        border: "0.5px solid #ccc",
                      }}
                      className="m-2"
                      type="text"
                      name="receiptNo"
                      value={receiptNo}
                      onChange={(e) => setReceiptNo(e.target.value)}
                    />
                  </div>
                  <div className="w-full ">
                    <label style={{ marginBottom: "4px", display: "block" }}>
                      Transaction Id:
                    </label>
                    <input
                      style={{
                        padding: "8px",
                        borderRadius: "4px",
                        border: "0.5px solid #ccc",
                      }}
                      className="m-2"
                      type="text"
                      name="transactionId"
                      value={transactionId}
                      onChange={handleTransactionIdChange}
                    />
                  </div>
                  <div>
                    <label style={{ marginBottom: "4px", display: "block" }}>
                      Total Fees: {selectedItem.total_fees}
                    </label>
                  </div>

                  <div className="w-full ">
                    <label style={{ marginBottom: "4px", display: "block" }}>
                      Total Paid:
                    </label>
                    <input
                      style={{
                        padding: "8px",
                        borderRadius: "4px",
                        border: "0.5px solid #ccc",
                      }}
                      className="m-2"
                      type="number"
                      name="totalPaid"
                      value={totalPaid}
                      onChange={handleTotalPaidChange}
                    />
                  </div>

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ width: "100%", marginBottom: 6 }}
                    onClick={handleUpdateFeeDetails}
                    disabled={isSubmitDisabled}
                  >
                    Confirm Update
                  </Button>
                  <Button
                    variant="contained"
                    // color="warning"
                    type="submit"
                    style={{ width: "100%", background: "#d50000" }}
                    onClick={handleFeeReject}
                  >
                    Reject Verification
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default VerifyFeePayment;
