import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { Link, useNavigate } from "react-router-dom";
import logo from "../Images/logo_with_white_background.png";
import aboutlight from "../Images/about-1-light.png";
import about2light from "../Images/about-2-light.png";
import about3light from "../Images/about-3-light.png";

import mobileframe from "../Images/mobile-frame.png";
import screen1light from "../Images/screen-1-light.png";
import screen2light from "../Images/screen-2-light.png";
import screen3light from "../Images/screen-3-light.png";
import ctaLight from "../Images/cta-light.png";
import playstore from "../Images/google-play-png-logo-3798.png";
import blog1 from "../Images/blog-01.jpg";
import blog2 from "../Images/blog-02.jpg";
import blog3 from "../Images/blog-03.jpg";
import heroLight from "../Images/hero-light.png";
import atuh1 from "../Images/author-1.png";
import atuh2 from "../Images/author-2.png";
import atuh3 from "../Images/author-3.png";
import atuh4 from "../Images/author-04.png";
import logowithtext from "../Images/logo_with_text-3.png";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import ReactSimplyCarousel from "react-simply-carousel";
import CallIcon from "@mui/icons-material/Call";
import ChatIcon from "@mui/icons-material/Chat";

import cimg1 from "../Images/image1.jpg";
import cimg2 from "../Images/image2.jpg";
import cimg3 from "../Images/image3.jpg";
import cimg4 from "../Images/image4.jpg";
import cimg5 from "../Images/image5.jpg";
import cimg6 from "../Images/image6.jpg";
import cimg7 from "../Images/image7.jpg";
import cimg8 from "../Images/image8.jpg";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Ed Sanchaar
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const pages = ["Home", "Features", "Pricing", "Contact Us"];
const links = ["Login for School"];
const tiers = [
  {
    title: "Free",
    price: "0",
    description: [
      "10 users included",
      "2 GB of storage",
      "Help center access",
      "Email support",
    ],
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
  },
  {
    title: "Pro",
    subheader: "Most popular",
    price: "15",
    description: [
      "20 users included",
      "10 GB of storage",
      "Help center access",
      "Priority email support",
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
  },
  {
    title: "Enterprise",
    price: "30",
    description: [
      "50 users included",
      "30 GB of storage",
      "Help center access",
      "Phone & email support",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
  },
];

const footers = [
  {
    title: "Company",
    description: ["Team", "History", "Contact us", "Locations"],
  },
  {
    title: "Features",
    description: [
      "Cool stuff",
      "Random feature",
      "Team feature",
      "Developer stuff",
      "Another one",
    ],
  },
  {
    title: "Resources",
    description: [
      "Resource",
      "Resource name",
      "Another resource",
      "Final resource",
    ],
  },
  {
    title: "Legal",
    description: ["Privacy policy", "Terms of use"],
  },
];

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

function ResponsiveAppBar() {
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [openContactUs, setOpenContactUs] = useState(false);

  const [formData, setFormData] = useState({
    message: "",
    subject: "",
  });

  const [isMenuOpen, setMenuOpen] = useState(false);

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLoginForSchool = () => {
    // Navigate to the desired page, you can replace "/connect" with your actual path
    navigate("/login");
  };

  // resize fix
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const faqStyle = {
    maxHeight: "0",
    overflow: "hidden",
    transition: "max-height 0.3s ease-in-out",
  };

  const visibleStyle = {
    maxHeight: "2000px", // Set a sufficiently large value to accommodate variable content height
  };

  const [visible, setVisible] = useState(null);

  const toggleFAQ = (index) => {
    // If the clicked FAQ is already open, close it
    // Otherwise, open the clicked FAQ
    setVisible((prevVisible) => (prevVisible === index ? null : index));
  };

  // faq section code

  const [faq, setFaq] = useState([
    {
      question: "How to create an account?",
      answer:
        'Amet minim mollit non deserunt ullamco est sit <a href="#" title="" className="text-blue-600 transition-all duration-200 hover:underline">aliqua dolor</a> do amet sint. Velit officia consequat duis enim velit mollit.',
      open: false,
    },
    {
      question: "How can I make payment using Paypal?",
      answer:
        'Amet minim mollit non deserunt ullamco est sit <a href="#" title="" className="text-blue-600 transition-all duration-200 hover:underline">aliqua dolor</a> do amet sint. Velit officia consequat duis enim velit mollit.',
      open: false,
    },
    {
      question: "Can I cancel my plan?",
      answer:
        'Amet minim mollit non deserunt ullamco est sit <a href="#" title="" className="text-blue-600 transition-all duration-200 hover:underline">aliqua dolor</a> do amet sint. Velit officia consequat duis enim velit mollit.',
      open: false,
    },
    {
      question: "How can I reach to support?",
      answer:
        'Amet minim mollit non deserunt ullamco est sit <a href="#" title="" className="text-blue-600 transition-all duration-200 hover:underline">aliqua dolor</a> do amet sint. Velit officia consequat duis enim velit mollit.',
      open: false,
    },
  ]);

  const toggleFaq = (index) => {
    setFaq(
      faq.map((item, i) => {
        if (i === index) {
          item.open = !item.open;
        } else {
          item.open = false;
        }

        return item;
      })
    );
  };

  // faq section code end
  const handleSendWriteToUs = async () => {
    try {
      const response = await axios.post(
        "https://ed-sanchar.000webhostapp.com/api/api_admin/contactUs.php",
        {
          ...formData,
          school_id: "",
          inquiry_type: "WriteToUs",
        },
        {
          headers: {
            Authorization: "aVchSlItjHUB3jD6sH/4IKdX9cC3WOyB/Qrf7v+eED8",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.status === 201) {
        console.log("Message Sent Successfully");
        alert("Message Sent Successfully");
      } else {
        console.log("Error sending Write to Us request");
        alert("Error Sending Message, please try again later");
      }
    } catch (error) {
      console.log("Error:", error);
      alert("Failed to Send Message");
    }
  };

  return (
    <>
      {/* navbar */}

      <header className="flex flex-col justify-center items-center lg:mx-4 mx-6">
        <div className="container relative max-w-[1200px]  ">
          <div className="flex items-center justify-between">
            <div className="block py-4 lg:py-0 ">
              <a
                href="index.html"
                className="block max-w-[145px] sm:max-w-[180px]"
                previewlistener="true"
              >
                <img
                  src={logowithtext}
                  width="180"
                  alt="logo"
                  className="block"
                />
              </a>
            </div>
            <div className=" hidden lg:flex h-20 w-6/12 fit items-center justify-center bg-white bg-opacity-95 text-center backdrop-blur-sm ">
              <ul className="items-center space-y-3 lg:flex lg:space-x-8 lg:space-y-0 xl:space-x-10">
                <li className="menu-item">
                  <a
                    href="#home"
                    className="menu-scroll inline-flex items-center text-base font-medium text-black hover:text-primary lg:py-7"
                  >
                    Home
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href="#features"
                    className="menu-scroll inline-flex items-center text-base font-medium text-black hover:text-primary lg:py-7"
                  >
                    Features
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href="#pricing"
                    className="menu-scroll inline-flex items-center text-base font-medium text-black hover:text-primary lg:py-7"
                  >
                    Pricing
                  </a>
                </li>
                {/* <li className="menu-item">
                  <a
                    href="#blog"
                    className="menu-scroll inline-flex items-center text-base font-medium text-black hover:text-primary lg:py-7"
                  >
                    Blog
                  </a>
                </li> */}
                <li className="menu-item">
                  <a
                    href="#support"
                    className="menu-scroll inline-flex items-center text-base font-medium text-black hover:text-primary lg:py-7"
                  >
                    Contact Us
                  </a>
                </li>
                {/* Add more menu items as needed */}
              </ul>
            </div>
            <button
              className="navbarOpen absolute right-4 top-1/2 z-50 flex h-10 w-10 -translate-y-1/2 flex-col items-center justify-center space-y-[6px] font-bold lg:hidden"
              aria-label="navbarOpen"
              name="navbarOpen"
              onClick={toggleMenu}
            >
              <span className="block h-[2px] w-7 bg-black"></span>
              <span className="block h-[2px] w-7 bg-black"></span>
              <span className="block h-[2px] w-7 bg-black"></span>
            </button>
            <div
              className={`menu-wrapper relative ${
                isMenuOpen ? "block" : "hidden"
              } lg:flex`}
            >
              <button
                className="navbarClose fixed top-10 right-10 z-[9999] flex h-10 w-10 flex-col items-center justify-center font-bold lg:hidden"
                name="navbarClose"
                aria-label="navbarClose"
                onClick={toggleMenu}
              >
                <span className="block h-[2px] w-7 rotate-45 bg-black"></span>
                <span className="-mt-[2px] block h-[2px] w-7 -rotate-45 bg-black"></span>
              </button>
              <nav
                className={`fixed top-0 left-0 z-[999] flex h-screen w-full items-center justify-center bg-white bg-opacity-95 text-center backdrop-blur-sm lg:static lg:h-auto lg:w-max lg:bg-transparent lg:backdrop-blur-none ${
                  isMenuOpen ? "block" : "hidden"
                }`}
              >
                <ul className="items-center space-y-3 lg:flex lg:space-x-8 lg:space-y-0 xl:space-x-10">
                  <li className="menu-item">
                    <a
                      href="#home"
                      className="menu-scroll inline-flex items-center text-base font-medium text-black hover:text-primary lg:py-7"
                    >
                      Home
                    </a>
                  </li>
                  <li className="menu-item">
                    <a
                      href="#features"
                      className="menu-scroll inline-flex items-center text-base font-medium text-black hover:text-primary lg:py-7"
                    >
                      Features
                    </a>
                  </li>
                  <li className="menu-item">
                    <a
                      href="#pricing"
                      className="menu-scroll inline-flex items-center text-base font-medium text-black hover:text-primary lg:py-7"
                    >
                      Pricing
                    </a>
                  </li>
                  {/* <li className="menu-item">
                    <a
                      href="#blog"
                      className="menu-scroll inline-flex items-center text-base font-medium text-black hover:text-primary lg:py-7"
                    >
                      Blog
                    </a>
                  </li> */}
                  <li className="support">
                    <a
                      href="#features"
                      className="menu-scroll inline-flex items-center text-base font-medium text-black hover:text-primary lg:py-7"
                    >
                      Contact Us
                    </a>
                  </li>
                  {/* Add more menu items as needed */}
                </ul>
              </nav>
            </div>
            <div className="mr-[60px] flex items-center justify-end lg:mr-0">
              <div
                onClick={handleLoginForSchool}
                className="hidden rounded-md bg-blue-400 py-[10px] px-[30px] text-base font-medium text-white hover:bg-opacity-90 sm:inline-block"
                previewlistener="true"
              >
                Sign In
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* navbar end */}
      {/* body 1 start*/}
      <main>
        <section
          id="home"
          class="pt-[125px] flex flex-col justify-center items-center"
        >
          <div class="container lg:max-w-[1305px] lg:px-10  ">
            <div class="flex flex-wrap items-center md:mx-auto mx-6">
              <div class="w-full px-4 lg:w-7/12">
                <div
                  class="wow fadeInUp mb-12 lg:mb-0 lg:max-w-[570px]"
                  data-wow-delay=".2s"
                  // style="visibility: visible; animation-delay: 0.2s;"
                >
                  <span class="mb-5 block text-lg font-medium leading-tight text-black sm:text-[22px] xl:text-[22px]">
                    Ed Sanchaar: A smart school solution
                  </span>
                  <h1 class="mb-6 text-3xl font-bold leading-tight text-black sm:text-[40px] md:text-[50px] lg:text-[42px] xl:text-[50px]">
                    Revolutionary app for{" "}
                    <span class="text-blue-300">
                      {""}Schools &amp; Parents.
                    </span>
                    {""}
                  </h1>
                  <p class="mb-10 max-w-[475px] text-base leading-relaxed text-body">
                    Bringing transparency, knowledge, and AI analytics with
                    least maintenance and costs!
                  </p>
                  <div class="flex flex-wrap items-center">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.venuratech"
                      class="mr-6 mb-6 inline-flex h-[60px] items-center rounded-lg bg-black py-[14px] px-[30px] text-white hover:bg-opacity-90 "
                    >
                      <span class="mr-[18px] border-r border-stroke border-opacity-40 pr-[18px] leading-relaxed ">
                        Download Now
                      </span>
                      {/* <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_3_3641)">
                            <path
                              d="M11.624 7.2221C10.748 7.2221 9.392 6.2261 7.964 6.2621C6.08 6.2861 4.352 7.3541 3.38 9.0461C1.424 12.4421 2.876 17.4581 4.784 20.2181C5.72 21.5621 6.824 23.0741 8.288 23.0261C9.692 22.9661 10.22 22.1141 11.924 22.1141C13.616 22.1141 14.096 23.0261 15.584 22.9901C17.096 22.9661 18.056 21.6221 18.98 20.2661C20.048 18.7061 20.492 17.1941 20.516 17.1101C20.48 17.0981 17.576 15.9821 17.54 12.6221C17.516 9.8141 19.832 8.4701 19.94 8.4101C18.62 6.4781 16.592 6.2621 15.884 6.2141C14.036 6.0701 12.488 7.2221 11.624 7.2221ZM14.744 4.3901C15.524 3.4541 16.04 2.1461 15.896 0.850098C14.78 0.898098 13.436 1.5941 12.632 2.5301C11.912 3.3581 11.288 4.6901 11.456 5.9621C12.692 6.0581 13.964 5.3261 14.744 4.3901Z"
                              fill="currentColor"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_3_3641">
                              <rect width="24" height="24" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span> */}
                      <img
                        src={playstore}
                        width="34"
                        height="34"
                        alt=""
                        viewBox="0 0 34 34"
                      />
                    </a>
                    <a
                      href="#support"
                      class="glightbox mb-6 inline-flex items-center py-4 text-black hover:text-primary dark:hover:text-primary"
                    >
                      <span class="mr-[22px] flex h-[60px] w-[60px] items-center justify-center rounded-full border-2 border-current">
                        <svg
                          width="32"
                          height="34"
                          viewBox="0 0 14 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <CallIcon />
                        </svg>
                      </span>
                      <span class="text-base font-medium">
                        <span class="block text-sm"> Contact Now </span>
                        Get a free demo
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="w-full px-4 lg:w-5/12">
                <div
                  class="wow fadeInUp relative z-10 mx-auto w-full max-w-[530px] pt-8 lg:mr-0"
                  data-wow-delay=".3s"
                  // style="visibility: visible; animation-delay: 0.3s;"
                >
                  <img
                    src={heroLight}
                    alt="hero image"
                    class="mx-auto max-w-full"
                    width={440}
                  />
                  <div class="max-auto absolute top-0 left-0 right-0 -z-10 aspect-square w-full rounded-full bg-gradient-1">
                    <div class="absolute top-5 right-0">
                      <svg
                        width="72"
                        height="51"
                        viewBox="0 0 72 51"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_5_3665)">
                          <path
                            d="M22.378 0.4157C22.3814 0.342879 22.3851 0.270712 22.3891 0.199219C22.3857 0.271606 22.382 0.343766 22.378 0.4157C22.0401 7.83785 25.7079 22.0514 43.163 21.2025C36.0333 21.7022 21.9045 26.7677 22.3875 43.0291C22.1659 35.9367 17.5749 21.9221 1.00683 21.8442C0.856728 21.8465 0.709534 21.8469 0.56543 21.8454C0.713499 21.8439 0.86063 21.8435 1.00683 21.8442C8.04005 21.7355 21.4537 17.3609 22.378 0.4157Z"
                            fill="#7083F5"
                          ></path>
                          <path
                            d="M59.3487 24.4888C59.3506 24.4451 59.3528 24.4018 59.3552 24.3589C59.3532 24.4023 59.351 24.4456 59.3487 24.4888C59.1459 28.942 61.3466 37.4702 71.8196 36.9608C67.5418 37.2606 59.0645 40.3 59.3543 50.0568C59.2213 45.8014 56.4667 37.3926 46.5259 37.3459C46.4359 37.3473 46.3475 37.3475 46.261 37.3466C46.3499 37.3457 46.4382 37.3454 46.5259 37.3459C50.7458 37.2807 58.794 34.6559 59.3487 24.4888Z"
                            fill="#19DEBB"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_5_3665">
                            <rect
                              width="71.2541"
                              height="49.8779"
                              fill="white"
                              transform="translate(0.56543 0.199219)"
                            ></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div class="absolute left-0 bottom-36 ">
                      <svg
                        width="65"
                        height="36"
                        viewBox="0 0 65 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M55.4149 1.83203C53.339 1.57898 51.3475 2.4214 49.2904 4.18456C45.9052 7.08611 40.0313 8.52953 34.7368 4.19769C32.4686 2.34195 30.4917 2.04856 28.8583 2.32079C27.1672 2.60264 25.7448 3.50424 24.6267 4.24961C22.8559 5.43014 20.9059 6.67067 18.66 6.9618C16.3417 7.2623 13.8664 6.54246 11.0465 4.19256C8.68539 2.22501 6.66504 1.84655 5.11312 2.08531C3.52522 2.32961 2.288 3.24185 1.57603 4.08328C1.25719 4.46008 0.69326 4.50708 0.316454 4.18824C-0.0603521 3.86941 -0.107346 3.30548 0.21149 2.92867C1.13803 1.83367 2.73868 0.642115 4.84131 0.318626C6.97991 -0.0103986 9.50274 0.579362 12.1908 2.81939C14.7333 4.93815 16.7266 5.40998 18.4302 5.18915C20.2062 4.95894 21.831 3.96513 23.6352 2.76234L24.131 3.50597L23.6352 2.76234C24.7515 2.01814 26.4572 0.908837 28.5644 0.557635C30.7295 0.196804 33.2212 0.648204 35.8687 2.81426C40.3566 6.48615 45.2562 5.28815 48.1272 2.82739C50.3886 0.889088 52.8657 -0.279434 55.6312 0.057691C58.3691 0.391448 61.1615 2.17558 64.1309 5.60179C64.4541 5.9748 64.4138 6.53924 64.0408 6.86252C63.6678 7.18579 63.1034 7.14547 62.7801 6.77246C59.9402 3.49563 57.5184 2.08846 55.4149 1.83203Z"
                          fill="#F76D8D"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M55.4149 11.2026C53.339 10.9496 51.3475 11.792 49.2904 13.5552C45.9052 16.4567 40.0312 17.9001 34.7367 13.5683C32.4686 11.7126 30.4916 11.4192 28.8583 11.6914C27.1671 11.9732 25.7447 12.8748 24.6267 13.6202C22.8559 14.8007 20.9058 16.0413 18.66 16.3324C16.3417 16.6329 13.8663 15.9131 11.0464 13.5632C8.68536 11.5956 6.66501 11.2172 5.11309 11.4559C3.52519 11.7002 2.28797 12.6125 1.576 13.4539C1.25716 13.8307 0.69323 13.8777 0.316424 13.5588C-0.0603826 13.24 -0.107377 12.6761 0.211459 12.2993C1.138 11.2043 2.73865 10.0127 4.84128 9.68923C6.97988 9.36021 9.50271 9.94997 12.1907 12.19C14.7333 14.3088 16.7266 14.7806 18.4302 14.5598C20.2061 14.3295 21.831 13.3357 23.6352 12.1329L24.1309 12.8766L23.6352 12.1329C24.7515 11.3887 26.4572 10.2794 28.5644 9.92824C30.7294 9.56741 33.2212 10.0188 35.8686 12.1849C40.3565 15.8568 45.2562 14.6588 48.1271 12.198C50.3885 10.2597 52.8657 9.09117 55.6312 9.4283C58.3691 9.76205 61.1614 11.5462 64.1308 14.9724C64.4541 15.3454 64.4138 15.9098 64.0408 16.2331C63.6678 16.5564 63.1033 16.5161 62.7801 16.1431C59.9401 12.8662 57.5184 11.4591 55.4149 11.2026Z"
                          fill="#F76D8D"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M55.4149 20.5825C53.339 20.3295 51.3475 21.1719 49.2904 22.935C45.9052 25.8366 40.0312 27.28 34.7367 22.9482C32.4686 21.0924 30.4916 20.7991 28.8583 21.0713C27.1671 21.3531 25.7447 22.2547 24.6267 23.0001C22.8559 24.1806 20.9058 25.4212 18.66 25.7123C16.3417 26.0128 13.8663 25.293 11.0464 22.9431C8.68536 20.9755 6.66501 20.597 5.11309 20.8358C3.52519 21.0801 2.28797 21.9923 1.576 22.8338C1.25716 23.2106 0.69323 23.2576 0.316424 22.9387C-0.0603826 22.6199 -0.107377 22.056 0.211459 21.6792C1.138 20.5842 2.73865 19.3926 4.84128 19.0691C6.97988 18.7401 9.50271 19.3299 12.1907 21.5699C14.7333 23.6886 16.7266 24.1605 18.4302 23.9396C20.2061 23.7094 21.831 22.7156 23.6352 21.5128L24.1309 22.2565L23.6352 21.5128C24.7515 20.7686 26.4572 19.6593 28.5644 19.3081C30.7294 18.9473 33.2212 19.3987 35.8686 21.5647C40.3565 25.2366 45.2562 24.0386 48.1271 21.5779C50.3885 19.6396 52.8657 18.4711 55.6312 18.8082C58.3691 19.1419 61.1614 20.9261 64.1308 24.3523C64.4541 24.7253 64.4138 25.2897 64.0408 25.613C63.6678 25.9363 63.1033 25.896 62.7801 25.523C59.9401 22.2461 57.5184 20.8389 55.4149 20.5825Z"
                          fill="#F76D8D"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M55.4149 29.9619C53.339 29.7089 51.3475 30.5513 49.2904 32.3144C45.9052 35.216 40.0312 36.6594 34.7367 32.3276C32.4686 30.4718 30.4916 30.1784 28.8583 30.4507C27.1671 30.7325 25.7447 31.6341 24.6267 32.3795C22.8559 33.56 20.9058 34.8006 18.66 35.0917C16.3417 35.3922 13.8663 34.6723 11.0464 32.3224C8.68536 30.3549 6.66501 29.9764 5.11309 30.2152C3.52519 30.4595 2.28797 31.3717 1.576 32.2132C1.25716 32.59 0.69323 32.637 0.316424 32.3181C-0.0603826 31.9993 -0.107377 31.4354 0.211459 31.0586C1.138 29.9635 2.73865 28.772 4.84128 28.4485C6.97988 28.1195 9.50271 28.7092 12.1907 30.9493C14.7333 33.068 16.7266 33.5399 18.4302 33.319C20.2061 33.0888 21.831 32.095 23.6352 30.8922L24.1309 31.6359L23.6352 30.8922C24.7515 30.148 26.4572 29.0387 28.5644 28.6875C30.7294 28.3267 33.2212 28.7781 35.8686 30.9441C40.3565 34.616 45.2562 33.418 48.1271 30.9573C50.3885 29.019 52.8657 27.8504 55.6312 28.1876C58.3691 28.5213 61.1614 30.3055 64.1308 33.7317C64.4541 34.1047 64.4138 34.6691 64.0408 34.9924C63.6678 35.3157 63.1033 35.2754 62.7801 34.9023C59.9401 31.6255 57.5184 30.2183 55.4149 29.9619Z"
                          fill="#F76D8D"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* body 1 end  */}

        <section
          id="features"
          class="relative z-10 pt-[110px] flex flex-col justify-center items-center"
        >
          <div class="container w-screen">
            <div class="wow fadeInUp  mb-14 max-w-[690px] text-center lg:mb-[70px] sm:px-4 lg:px-0 sm:w-[90%] md:mx-auto mx-4">
              <h2 class="mb-4 text-3xl font-bold text-black sm:text-4xl md:text-[44px] md:leading-tight">
                Innovative functionalities to virtualise school operations.{" "}
              </h2>
              <p class="text-base text-body">
                Streamline school operations with cutting-edge features,
                enhancing efficiency in administration and simplifying
                day-to-day tasks for seamless school management and
                communication.
              </p>
            </div>
          </div>

          <div class="container max-w-[1390px]">
            <div class="rounded-2xl bg-white px-5 pt-14 pb-14 drop-shadow-lg md:pb-1 lg:pt-20 lg:pb-5 xl:px-10 mx-4">
              <div class=" flex flex-wrap">
                <div class="w-full px-4 md:w-1/2 lg:w-1/3">
                  <div
                    class="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                    data-wow-delay=".2s"
                    // style="visibility: visible; animation-delay: 0.2s;"
                  >
                    <div class="mx-auto mb-8 flex h-[90px] w-[90px] items-center justify-center rounded-3xl bg-gray text-primary duration-300 text-blue-400 bg-slate-100 hover:bg-blue-400 hover:text-white">
                      <svg
                        width="44"
                        height="44"
                        viewBox="0 0 44 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_28_4)">
                          <ChatIcon />
                        </g>
                        <defs>
                          <clipPath id="clip0_28_4">
                            <rect width="44" height="44" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <h3 class="mb-4 text-xl font-semibold text-black  sm:text-[22px] xl:text-[26px]">
                      Unlimited Communication
                    </h3>
                    <p class="text-base text-body">
                      Send as many class and school specific notices, and
                      communicaiton messages, free of cost
                    </p>
                  </div>
                </div>
                <div class="w-full px-4 md:w-1/2 lg:w-1/3">
                  <div
                    class="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                    data-wow-delay=".3s"
                    // style="visibility: visible; animation-delay: 0.3s;"
                  >
                    <div class="mx-auto mb-8 flex h-[90px] w-[90px] items-center justify-center rounded-3xl bg-gray text-primary duration-300 text-blue-400 bg-slate-100 hover:bg-blue-400 hover:text-white">
                      <svg
                        width="44"
                        height="44"
                        viewBox="0 0 44 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_211_934)">
                          <path
                            d="M23.2007 4.24461L38.2241 13.2576C38.3511 13.3337 38.4562 13.4413 38.5292 13.5701C38.6022 13.6989 38.6406 13.8444 38.6406 13.9924C38.6406 14.1405 38.6022 14.286 38.5292 14.4147C38.4562 14.5435 38.3511 14.6512 38.2241 14.7272L22.3203 24.2695L6.41652 14.7272C6.28951 14.6512 6.18437 14.5435 6.11137 14.4147C6.03837 14.286 6 14.1405 6 13.9924C6 13.8444 6.03837 13.6989 6.11137 13.5701C6.18437 13.4413 6.28951 13.3337 6.41652 13.2576L21.4382 4.24461C21.7046 4.08456 22.0095 4 22.3203 4C22.6311 4 22.936 4.08456 23.2024 4.24461H23.2007Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M36.1653 19.9874L38.2241 21.2224C38.3512 21.2984 38.4563 21.4061 38.5293 21.5349C38.6023 21.6637 38.6407 21.8092 38.6407 21.9572C38.6407 22.1052 38.6023 22.2507 38.5293 22.3795C38.4563 22.5083 38.3512 22.616 38.2241 22.692L22.3204 32.2343L6.41658 22.692C6.28956 22.616 6.18443 22.5083 6.11143 22.3795C6.03843 22.2507 6.00005 22.1052 6.00005 21.9572C6.00005 21.8092 6.03843 21.6637 6.11143 21.5349C6.18443 21.4061 6.28956 21.2984 6.41658 21.2224L8.47542 19.9874L22.3204 28.2947L36.1653 19.9874ZM36.1653 28.0378L38.2241 29.2728C38.3512 29.3488 38.4563 29.4565 38.5293 29.5853C38.6023 29.714 38.6407 29.8595 38.6407 30.0076C38.6407 30.1556 38.6023 30.3011 38.5293 30.4299C38.4563 30.5587 38.3512 30.6664 38.2241 30.7424L23.2025 39.7554C22.9361 39.9154 22.6311 40 22.3204 40C22.0096 40 21.7046 39.9154 21.4382 39.7554L6.41658 30.7424C6.28956 30.6664 6.18443 30.5587 6.11143 30.4299C6.03843 30.3011 6.00005 30.1556 6.00005 30.0076C6.00005 29.8595 6.03843 29.714 6.11143 29.5853C6.18443 29.4565 6.28956 29.3488 6.41658 29.2728L8.47542 28.0378L22.3204 36.3451L36.1653 28.0378Z"
                            fill="currentColor"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_211_934">
                            <rect width="44" height="44" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <h3 class="mb-4 text-xl font-semibold text-black  sm:text-[22px] xl:text-[26px]">
                      Fees Management
                    </h3>
                    <p class="text-base text-body">
                      Manage, analyse, reflect and notify about fees status with
                      a single click to any class.
                    </p>
                  </div>
                </div>
                <div class="w-full px-4 md:w-1/2 lg:w-1/3">
                  <div
                    class="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                    data-wow-delay=".4s"
                    // style="visibility: visible; animation-delay: 0.4s;"
                  >
                    <div class="mx-auto mb-8 flex h-[90px] w-[90px] items-center justify-center rounded-3xl bg-gray text-primary duration-300 text-blue-400 bg-slate-100 hover:bg-blue-400 hover:text-white">
                      <svg
                        width="44"
                        height="44"
                        viewBox="0 0 44 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_211_971)">
                          <path
                            d="M25.3333 37H8.66667C8.22464 37 7.80072 36.8244 7.48816 36.5118C7.17559 36.1993 7 35.7754 7 35.3333V18.6667H25.3333V37ZM37 15.3333H7V8.66667C7 8.22464 7.17559 7.80072 7.48816 7.48816C7.80072 7.17559 8.22464 7 8.66667 7H35.3333C35.7754 7 36.1993 7.17559 36.5118 7.48816C36.8244 7.80072 37 8.22464 37 8.66667V15.3333Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M28.6667 37V18.6667H37V35.3333C37 35.7754 36.8244 36.1993 36.5118 36.5118C36.1993 36.8244 35.7754 37 35.3333 37H28.6667Z"
                            fill="currentColor"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_211_971">
                            <rect width="44" height="44" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <h3 class="mb-4 text-xl font-semibold text-black  sm:text-[22px] xl:text-[26px]">
                      Virtual Attendance
                    </h3>
                    <p class="text-base text-body">
                      Time and effort saving attendance feature with one click
                      atendance compilation and download.
                    </p>
                  </div>
                </div>
                <div class="w-full px-4 md:w-1/2 lg:w-1/3">
                  <div
                    class="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                    data-wow-delay=".2s"
                    // style="visibility: visible; animation-delay: 0.2s;"
                  >
                    <div class="mx-auto mb-8 flex h-[90px] w-[90px] items-center justify-center rounded-3xl bg-gray text-primary duration-300 text-blue-400 bg-slate-100 hover:bg-blue-400 hover:text-white">
                      <svg
                        width="44"
                        height="44"
                        viewBox="0 0 44 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_211_962)">
                          <path
                            d="M22.0001 3.66675C32.1256 3.66675 40.3334 11.8746 40.3334 22.0001C40.3334 32.1256 32.1256 40.3334 22.0001 40.3334C11.8746 40.3334 3.66675 32.1256 3.66675 22.0001C3.66675 11.8746 11.8746 3.66675 22.0001 3.66675ZM22.0001 7.33341C13.9004 7.33341 7.33341 13.9004 7.33341 22.0001C7.33341 30.0997 13.9004 36.6667 22.0001 36.6667C30.0997 36.6667 36.6667 30.0997 36.6667 22.0001C36.6667 13.9004 30.0997 7.33341 22.0001 7.33341ZM29.7771 11.6289L32.3712 14.2212L25.5439 21.0522C25.6246 21.3547 25.6667 21.6719 25.6667 22.0001C25.6667 24.0259 24.0259 25.6667 22.0001 25.6667C19.9742 25.6667 18.3334 24.0259 18.3334 22.0001C18.3334 19.9742 19.9742 18.3334 22.0001 18.3334C22.3282 18.3334 22.6454 18.3756 22.9479 18.4562L29.7789 11.6289H29.7771Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M22 9.16671C23.8664 9.16671 25.6392 9.56454 27.2397 10.2814L24.3742 13.145C23.617 12.9434 22.8214 12.8334 22 12.8334C16.9382 12.8334 12.8334 16.9382 12.8334 22C12.8334 24.53 13.86 26.8217 15.5174 28.4827L12.925 31.075L12.639 30.7799C10.4867 28.4845 9.16671 25.3954 9.16671 22C9.16671 14.9124 14.9124 9.16671 22 9.16671ZM33.7187 16.7622C34.4337 18.3609 34.8334 20.1355 34.8334 22C34.8334 25.5439 33.396 28.7522 31.075 31.075L28.4827 28.4827C30.14 26.8217 31.1667 24.53 31.1667 22C31.1667 21.1787 31.0585 20.383 30.855 19.6259L33.7187 16.7622Z"
                            fill="currentColor"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_211_962">
                            <rect width="44" height="44" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <h3 class="mb-4 text-xl font-semibold text-black  sm:text-[22px] xl:text-[26px]">
                      Performance analysis
                    </h3>
                    <p class="text-base text-body">
                      Single Click performance reports for teacher and student
                      compiled with advanced AI analytics.
                    </p>
                  </div>
                </div>
                <div class="w-full px-4 md:w-1/2 lg:w-1/3">
                  <div
                    class="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                    data-wow-delay=".3s"
                    // style="visibility: visible; animation-delay: 0.3s;"
                  >
                    <div class="mx-auto mb-8 flex h-[90px] w-[90px] items-center justify-center rounded-3xl bg-gray text-primary duration-300 text-blue-400 bg-slate-100 hover:bg-blue-400 hover:text-white">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_211_943)">
                          <path
                            d="M11.2535 28.9978C11.5633 28.1197 12.1379 27.3593 12.898 26.8215C13.6581 26.2837 14.5663 25.9949 15.4974 25.9949C16.4286 25.9949 17.3368 26.2837 18.0969 26.8215C18.857 27.3593 19.4316 28.1197 19.7414 28.9978H34.9927V31.9971H19.7414C19.4316 32.8751 18.857 33.6355 18.0969 34.1733C17.3368 34.7112 16.4286 35 15.4974 35C14.5663 35 13.6581 34.7112 12.898 34.1733C12.1379 33.6355 11.5633 32.8751 11.2535 31.9971H5V28.9978H11.2535ZM20.2513 18.5004C20.5611 17.6223 21.1357 16.8619 21.8958 16.3241C22.6559 15.7862 23.5641 15.4974 24.4952 15.4974C25.4264 15.4974 26.3346 15.7862 27.0947 16.3241C27.8548 16.8619 28.4294 17.6223 28.7392 18.5004H34.9927V21.4996H28.7392C28.4294 22.3777 27.8548 23.1381 27.0947 23.6759C26.3346 24.2138 25.4264 24.5026 24.4952 24.5026C23.5641 24.5026 22.6559 24.2138 21.8958 23.6759C21.1357 23.1381 20.5611 22.3777 20.2513 21.4996H5V18.5004H20.2513ZM11.2535 8.00294C11.5633 7.12486 12.1379 6.36449 12.898 5.82666C13.6581 5.28882 14.5663 5 15.4974 5C16.4286 5 17.3368 5.28882 18.0969 5.82666C18.857 6.36449 19.4316 7.12486 19.7414 8.00294H34.9927V11.0022H19.7414C19.4316 11.8803 18.857 12.6407 18.0969 13.1785C17.3368 13.7163 16.4286 14.0051 15.4974 14.0051C14.5663 14.0051 13.6581 13.7163 12.898 13.1785C12.1379 12.6407 11.5633 11.8803 11.2535 11.0022H5V8.00294H11.2535Z"
                            fill="currentColor"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_211_943">
                            <rect width="40" height="40" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <h3 class="mb-4 text-xl font-semibold text-black  sm:text-[22px] xl:text-[26px]">
                      Class Management
                    </h3>
                    <p class="text-base text-body">
                      Online class links, syllabus progress, exam marks, teacher
                      and class details, all at one place.
                    </p>
                  </div>
                </div>
                <div class="w-full px-4 md:w-1/2 lg:w-1/3">
                  <div
                    class="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                    data-wow-delay=".4s"
                    // style="visibility: visible; animation-delay: 0.4s;"
                  >
                    <div class="mx-auto mb-8 flex h-[90px] w-[90px] items-center justify-center rounded-3xl bg-gray text-primary duration-300 text-blue-400 bg-slate-100 hover:bg-blue-400 hover:text-white">
                      <svg
                        width="44"
                        height="44"
                        viewBox="0 0 44 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_211_982)">
                          <path
                            d="M10.0155 8.12727C13.3422 5.24464 17.5981 3.66062 22 3.66677C32.1255 3.66677 40.3333 11.8746 40.3333 22.0001C40.3333 25.9161 39.105 29.5461 37.015 32.5234L31.1667 22.0001H36.6667C36.6669 19.1247 35.822 16.3127 34.2369 13.9137C32.6518 11.5147 30.3965 9.63456 27.7515 8.50699C25.1064 7.37943 22.1883 7.05422 19.3599 7.5718C16.5315 8.08938 13.9177 9.42691 11.8433 11.4181L10.0155 8.12727Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M33.9845 35.8729C30.6578 38.7555 26.4018 40.3396 22 40.3334C11.8745 40.3334 3.66663 32.1256 3.66663 22.0001C3.66663 18.0841 4.89496 14.4541 6.98496 11.4767L12.8333 22.0001H7.33329C7.33306 24.8754 8.178 27.6874 9.76308 30.0864C11.3481 32.4854 13.6034 34.3656 16.2485 35.4932C18.8935 36.6207 21.8117 36.946 24.64 36.4284C27.4684 35.9108 30.0823 34.5733 32.1566 32.5821L33.9845 35.8729Z"
                            fill="currentColor"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_211_982">
                            <rect width="44" height="44" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <h3 class="mb-4 text-xl font-semibold text-black sm:text-[22px] xl:text-[26px]">
                      Effortless Usage
                    </h3>
                    <p class="text-base text-body">
                      Our parent and teacher app, and admin dashboard, all are
                      having user-friendly and intuitive interface.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="absolute top-0 right-0 -z-10">
            <svg
              width="602"
              height="1154"
              viewBox="0 0 602 1154"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.25" filter="url(#filter0_f_26_84)">
                <circle
                  cx="577"
                  cy="577"
                  r="317"
                  fill="url(#paint0_linear_26_84)"
                ></circle>
              </g>
              <defs>
                <filter
                  id="filter0_f_26_84"
                  x="0"
                  y="0"
                  width="1154"
                  height="1154"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  ></feBlend>
                  <feGaussianBlur
                    stdDeviation="130"
                    result="effect1_foregroundBlur_26_84"
                  ></feGaussianBlur>
                </filter>
                <linearGradient
                  id="paint0_linear_26_84"
                  x1="183.787"
                  y1="894"
                  x2="970.173"
                  y2="346.491"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8EA5FE"></stop>
                  <stop offset="0.541667" stop-color="#BEB3FD"></stop>
                  <stop offset="1" stop-color="#90D1FF"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div class="absolute left-0 -bottom-1/2 -z-10 hidden md:block">
            <svg
              width="622"
              height="1236"
              viewBox="0 0 622 1236"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.2" filter="url(#filter0_f_26_85)">
                <circle
                  cx="4"
                  cy="618"
                  r="368"
                  fill="url(#paint0_linear_26_85)"
                ></circle>
              </g>
              <defs>
                <filter
                  id="filter0_f_26_85"
                  x="-614"
                  y="0"
                  width="1236"
                  height="1236"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  ></feBlend>
                  <feGaussianBlur
                    stdDeviation="125"
                    result="effect1_foregroundBlur_26_85"
                  ></feGaussianBlur>
                </filter>
                <linearGradient
                  id="paint0_linear_26_85"
                  x1="-364"
                  y1="250"
                  x2="506.12"
                  y2="754.835"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FF8FE8"></stop>
                  <stop offset="1" stop-color="#FFC960"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
        </section>

        {/* body 2 end features*/}

        {/* body 3 start  */}
        {/* <div className="h-[1500px] ">
        <div className="w-full h-1/2 bg-blue-200"></div>
        <div className="w-full h-1/2 bg-red-200"></div>
      </div> */}
        <section
          id="about"
          className="relative pt-[150px] flex justify-center items-center overflow-hidden"
        >
          <div className="container lg:max-w-[1120px] ">
            <div>
              <div className="mx-4 flex flex-wrap items-center justify-between">
                <div className="w-full px-4 lg:w-1/2">
                  <div
                    className="wow fadeInUp relative z-10 mx-auto mb-14 w-full max-w-[470px] pb-6 lg:mx-0 lg:mb-0"
                    data-wow-delay=".2s"
                    // style="visibility: visible; animation-delay: 0.2s;"
                  >
                    <img
                      src={aboutlight}
                      alt="about image"
                      className="mx-auto max-w-full"
                    />
                    <div className="absolute top-0 right-5 -z-10">
                      <svg
                        width="72"
                        height="50"
                        viewBox="0 0 72 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_33_10)">
                          <path
                            d="M21.8126 0.216481C21.8159 0.143661 21.8196 0.071493 21.8237 0C21.8203 0.0723874 21.8165 0.144547 21.8126 0.216481C21.4747 7.63863 25.1425 21.8522 42.5976 21.0032C35.4678 21.503 21.3391 26.5685 21.822 42.8298C21.6005 35.7375 17.0094 21.7229 0.441399 21.645C0.291298 21.6473 0.144104 21.6477 0 21.6462C0.148069 21.6447 0.2952 21.6443 0.441399 21.645C7.47462 21.5363 20.8883 17.1617 21.8126 0.216481Z"
                            fill="#7083F5"
                          ></path>
                          <path
                            d="M58.7832 24.2896C58.7851 24.2459 58.7874 24.2025 58.7898 24.1597C58.7878 24.2031 58.7855 24.2464 58.7832 24.2896C58.5804 28.7428 60.7811 37.271 71.2541 36.7616C66.9763 37.0614 58.499 40.1008 58.7888 49.8576C58.6559 45.6022 55.9013 37.1934 45.9605 37.1467C45.8704 37.1481 45.782 37.1482 45.6956 37.1474C45.7844 37.1465 45.8727 37.1462 45.9605 37.1467C50.1803 37.0815 58.2286 34.4567 58.7832 24.2896Z"
                            fill="#7ED8FF"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_33_10">
                            <rect
                              width="71.2541"
                              height="49.8779"
                              fill="white"
                            ></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="absolute bottom-0 left-0 -z-10 h-1/2 w-full rounded-[20px] bg-gradient-1">
                      <div className="absolute left-10 -top-12 -z-10">
                        <svg
                          width="65"
                          height="36"
                          viewBox="0 0 65 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M55.4149 1.83203C53.339 1.57898 51.3475 2.4214 49.2904 4.18456C45.9052 7.08611 40.0313 8.52953 34.7368 4.19769C32.4686 2.34195 30.4917 2.04856 28.8583 2.32079C27.1672 2.60264 25.7448 3.50424 24.6267 4.24961C22.8559 5.43014 20.9059 6.67067 18.66 6.9618C16.3417 7.2623 13.8664 6.54246 11.0465 4.19256C8.68539 2.22501 6.66504 1.84655 5.11312 2.08531C3.52522 2.32961 2.288 3.24185 1.57603 4.08328C1.25719 4.46008 0.69326 4.50708 0.316454 4.18824C-0.0603521 3.86941 -0.107346 3.30548 0.21149 2.92867C1.13803 1.83367 2.73868 0.642115 4.84131 0.318626C6.97991 -0.0103986 9.50274 0.579362 12.1908 2.81939C14.7333 4.93815 16.7266 5.40998 18.4302 5.18915C20.2062 4.95894 21.831 3.96513 23.6352 2.76234L24.131 3.50597L23.6352 2.76234C24.7515 2.01814 26.4572 0.908837 28.5644 0.557635C30.7295 0.196804 33.2212 0.648204 35.8687 2.81426C40.3566 6.48615 45.2562 5.28815 48.1272 2.82739C50.3886 0.889088 52.8657 -0.279434 55.6312 0.057691C58.3691 0.391448 61.1615 2.17558 64.1309 5.60179C64.4541 5.9748 64.4138 6.53924 64.0408 6.86252C63.6678 7.18579 63.1034 7.14547 62.7801 6.77246C59.9402 3.49563 57.5184 2.08846 55.4149 1.83203Z"
                            fill="#7083F5"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M55.4149 11.2024C53.339 10.9493 51.3475 11.7918 49.2904 13.5549C45.9052 16.4565 40.0312 17.8999 34.7367 13.5681C32.4686 11.7123 30.4916 11.4189 28.8583 11.6912C27.1671 11.973 25.7447 12.8746 24.6267 13.62C22.8559 14.8005 20.9058 16.041 18.66 16.3322C16.3417 16.6327 13.8663 15.9128 11.0464 13.5629C8.68536 11.5954 6.66501 11.2169 5.11309 11.4557C3.52519 11.7 2.28797 12.6122 1.576 13.4536C1.25716 13.8304 0.69323 13.8774 0.316424 13.5586C-0.0603826 13.2398 -0.107377 12.6758 0.211459 12.299C1.138 11.204 2.73865 10.0125 4.84128 9.68899C6.97988 9.35996 9.50271 9.94972 12.1907 12.1897C14.7333 14.3085 16.7266 14.7803 18.4302 14.5595C20.2061 14.3293 21.831 13.3355 23.6352 12.1327L24.1309 12.8763L23.6352 12.1327C24.7515 11.3885 26.4572 10.2792 28.5644 9.928C30.7294 9.56717 33.2212 10.0186 35.8686 12.1846C40.3565 15.8565 45.2562 14.6585 48.1271 12.1978C50.3885 10.2594 52.8657 9.09093 55.6312 9.42805C58.3691 9.76181 61.1614 11.5459 64.1308 14.9722C64.4541 15.3452 64.4138 15.9096 64.0408 16.2329C63.6678 16.5561 63.1033 16.5158 62.7801 16.1428C59.9401 12.866 57.5184 11.4588 55.4149 11.2024Z"
                            fill="#7083F5"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M55.4149 20.5825C53.339 20.3295 51.3475 21.1719 49.2904 22.935C45.9052 25.8366 40.0312 27.28 34.7367 22.9482C32.4686 21.0924 30.4916 20.7991 28.8583 21.0713C27.1671 21.3531 25.7447 22.2547 24.6267 23.0001C22.8559 24.1806 20.9058 25.4212 18.66 25.7123C16.3417 26.0128 13.8663 25.293 11.0464 22.9431C8.68536 20.9755 6.66501 20.597 5.11309 20.8358C3.52519 21.0801 2.28797 21.9923 1.576 22.8338C1.25716 23.2106 0.69323 23.2576 0.316424 22.9387C-0.0603826 22.6199 -0.107377 22.056 0.211459 21.6792C1.138 20.5842 2.73865 19.3926 4.84128 19.0691C6.97988 18.7401 9.50271 19.3299 12.1907 21.5699C14.7333 23.6886 16.7266 24.1605 18.4302 23.9396C20.2061 23.7094 21.831 22.7156 23.6352 21.5128L24.1309 22.2565L23.6352 21.5128C24.7515 20.7686 26.4572 19.6593 28.5644 19.3081C30.7294 18.9473 33.2212 19.3987 35.8686 21.5647C40.3565 25.2366 45.2562 24.0386 48.1271 21.5779C50.3885 19.6396 52.8657 18.4711 55.6312 18.8082C58.3691 19.1419 61.1614 20.9261 64.1308 24.3523C64.4541 24.7253 64.4138 25.2897 64.0408 25.613C63.6678 25.9363 63.1033 25.896 62.7801 25.523C59.9401 22.2461 57.5184 20.8389 55.4149 20.5825Z"
                            fill="#7083F5"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M55.4149 29.9622C53.339 29.7091 51.3475 30.5515 49.2904 32.3147C45.9052 35.2162 40.0312 36.6597 34.7367 32.3278C32.4686 30.4721 30.4916 30.1787 28.8583 30.4509C27.1671 30.7328 25.7447 31.6344 24.6267 32.3797C22.8559 33.5603 20.9058 34.8008 18.66 35.0919C16.3417 35.3924 13.8663 34.6726 11.0464 32.3227C8.68536 30.3551 6.66501 29.9767 5.11309 30.2154C3.52519 30.4597 2.28797 31.372 1.576 32.2134C1.25716 32.5902 0.69323 32.6372 0.316424 32.3184C-0.0603826 31.9995 -0.107377 31.4356 0.211459 31.0588C1.138 29.9638 2.73865 28.7722 4.84128 28.4488C6.97988 28.1197 9.50271 28.7095 12.1907 30.9495C14.7333 33.0683 16.7266 33.5401 18.4302 33.3193C20.2061 33.0891 21.831 32.0953 23.6352 30.8925L24.1309 31.6361L23.6352 30.8925C24.7515 30.1483 26.4572 29.039 28.5644 28.6878C30.7294 28.3269 33.2212 28.7783 35.8686 30.9444C40.3565 34.6163 45.2562 33.4183 48.1271 30.9575C50.3885 29.0192 52.8657 27.8507 55.6312 28.1878C58.3691 28.5216 61.1614 30.3057 64.1308 33.7319C64.4541 34.1049 64.4138 34.6694 64.0408 34.9926C63.6678 35.3159 63.1033 35.2756 62.7801 34.9026C59.9401 31.6258 57.5184 30.2186 55.4149 29.9622Z"
                            fill="#7083F5"
                          ></path>
                        </svg>
                      </div>
                      <div className="absolute top-0 left-0 h-full w-full bg-texture"></div>
                    </div>
                  </div>
                </div>
                <div className="w-full px-4 lg:w-1/2">
                  <div
                    className="wow fadeInUp lg:ml-auto lg:max-w-[510px]"
                    data-wow-delay=".3s"
                    // style="visibility: visible; animation-delay: 0.3s;"
                  >
                    <span className="mb-4 block text-lg font-medium text-blue-600 md:text-[22px]">
                      Track School Activities
                    </span>
                    <h2 className="mb-4 text-3xl font-bold  sm:text-4xl md:text-[44px] md:leading-tight">
                      One-Stop Web Dashboard
                    </h2>
                    <p className="mb-[30px] text-base leading-relaxed text-body text-slate-500 ">
                      Simplest management of various school activities with a
                      single dashboard. This provides features for managing
                      school level operations and analyse student-teacher
                      performance.
                    </p>
                    <div className="mb-[30px] flex items-center">
                      <div className="mr-[22px] flex h-[60px] w-[60px] items-center justify-center rounded-full border border-stroke text-xl font-semibold text-black dark:border-stroke-dark dark:bg-dark ">
                        01
                      </div>
                      <div>
                        <h5 className="text-xl font-medium text-black ">
                          Activity Management
                        </h5>
                        <p className="text-base text-body">
                          Attendance, fees, complaints, profiles, and much more!
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="mr-[22px] flex h-[60px] w-[60px] items-center justify-center rounded-full border border-stroke text-xl font-semibold text-black dark:border-stroke-dark dark:bg-dark ">
                        02
                      </div>
                      <div>
                        <h5 className="text-xl font-medium text-black">
                          Performance Metrics
                        </h5>
                        <p className="text-base text-body">
                          Analyse class, subject, and teacher performance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-[100px]">
              <div className="mx-4 flex flex-wrap items-center justify-between">
                <div className="w-full px-4 lg:w-1/2">
                  <div
                    className="wow fadeInUp lg:max-w-[510px]"
                    data-wow-delay=".2s"
                    // style="visibility: visible; animation-delay: 0.2s;"
                  >
                    <span className="mb-4 block text-lg font-medium text-blue-600 md:text-[22px]">
                      Easy monitoring and communication
                    </span>
                    <h2 className="mb-4 text-3xl font-bold  sm:text-4xl md:text-[44px] md:leading-tight">
                      Parent & Student Mobile App
                    </h2>
                    <p className="mb-[30px] text-base leading-relaxed text-body">
                      Enabling Parents to be informed about their ward's
                      education and school activities in a modern way
                    </p>

                    <div className="mb-[30px] flex items-center mt-4">
                      <div className="mr-[22px] flex h-[60px] w-[60px] items-center justify-center rounded-full border border-stroke text-xl font-semibold text-black dark:border-stroke-dark dark:bg-dark ">
                        01
                      </div>
                      <div>
                        <h5 className="text-xl font-medium text-black ">
                          Details Management
                        </h5>
                        <p className="text-base text-body">
                          Fees, Attendance, Complaints, Doubts, Communication,
                          etc.
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="mr-[22px] flex h-[60px] w-[60px] items-center justify-center rounded-full border border-stroke text-xl font-semibold text-black dark:border-stroke-dark dark:bg-dark ">
                        02
                      </div>
                      <div>
                        <h5 className="text-xl font-medium text-black">
                          Performance Analysis
                        </h5>
                        <p className="text-base text-body">
                          Inter-class rank, average marks, syllabus coverage,
                          etc.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="order-first w-full px-4 lg:order-last lg:w-1/2">
                  <div
                    className="wow fadeInUp relative z-10 mx-auto mb-14 w-full max-w-[470px] pb-6 lg:mr-0 lg:mb-0"
                    data-wow-delay=".3s"
                    // style="visibility: visible; animation-delay: 0.3s;"
                  >
                    <img
                      src={about3light}
                      alt="about image"
                      className="mx-auto max-w-full"
                    />
                    <div className="absolute top-0 right-5 -z-10">
                      <svg
                        width="72"
                        height="50"
                        viewBox="0 0 72 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_34_10)">
                          <path
                            d="M21.8126 0.216481C21.8159 0.143661 21.8196 0.071493 21.8237 0C21.8203 0.0723874 21.8165 0.144547 21.8126 0.216481C21.4747 7.63863 25.1425 21.8522 42.5976 21.0032C35.4678 21.503 21.3391 26.5685 21.822 42.8298C21.6005 35.7375 17.0094 21.7229 0.441399 21.645C0.291298 21.6473 0.144104 21.6477 0 21.6462C0.148069 21.6447 0.2952 21.6443 0.441399 21.645C7.47462 21.5363 20.8883 17.1617 21.8126 0.216481Z"
                            fill="#FF9996"
                          ></path>
                          <path
                            d="M58.7832 24.2896C58.7851 24.2459 58.7874 24.2025 58.7898 24.1597C58.7878 24.2031 58.7855 24.2464 58.7832 24.2896C58.5804 28.7428 60.7811 37.271 71.2541 36.7616C66.9763 37.0614 58.499 40.1008 58.7888 49.8576C58.6559 45.6022 55.9013 37.1934 45.9605 37.1467C45.8704 37.1481 45.782 37.1482 45.6956 37.1474C45.7844 37.1465 45.8727 37.1462 45.9605 37.1467C50.1803 37.0815 58.2286 34.4567 58.7832 24.2896Z"
                            fill="#FFCB78"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_34_10">
                            <rect
                              width="71.2541"
                              height="49.8779"
                              fill="white"
                            ></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="absolute bottom-0 left-0 -z-10 h-1/2 w-full rounded-[20px] bg-gradient-2">
                      <div className="absolute left-10 -top-12 -z-10">
                        <svg
                          width="65"
                          height="36"
                          viewBox="0 0 65 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M55.4149 1.83203C53.339 1.57898 51.3475 2.4214 49.2904 4.18456C45.9052 7.08611 40.0313 8.52953 34.7368 4.19769C32.4686 2.34195 30.4917 2.04856 28.8583 2.32079C27.1672 2.60264 25.7448 3.50424 24.6267 4.24961C22.8559 5.43014 20.9059 6.67067 18.66 6.9618C16.3417 7.2623 13.8664 6.54246 11.0465 4.19256C8.68539 2.22501 6.66504 1.84655 5.11312 2.08531C3.52522 2.32961 2.288 3.24185 1.57603 4.08328C1.25719 4.46008 0.69326 4.50708 0.316454 4.18824C-0.0603521 3.86941 -0.107346 3.30548 0.21149 2.92867C1.13803 1.83367 2.73868 0.642115 4.84131 0.318626C6.97991 -0.0103986 9.50274 0.579362 12.1908 2.81939C14.7333 4.93815 16.7266 5.40998 18.4302 5.18915C20.2062 4.95894 21.831 3.96513 23.6352 2.76234L24.131 3.50597L23.6352 2.76234C24.7515 2.01814 26.4572 0.908837 28.5644 0.557635C30.7295 0.196804 33.2212 0.648204 35.8687 2.81426C40.3566 6.48615 45.2562 5.28815 48.1272 2.82739C50.3886 0.889088 52.8657 -0.279434 55.6312 0.057691C58.3691 0.391448 61.1615 2.17558 64.1309 5.60179C64.4541 5.9748 64.4138 6.53924 64.0408 6.86252C63.6678 7.18579 63.1034 7.14547 62.7801 6.77246C59.9402 3.49563 57.5184 2.08846 55.4149 1.83203Z"
                            fill="#FF9996"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M55.4149 11.2025C53.339 10.9495 51.3475 11.7919 49.2904 13.555C45.9052 16.4566 40.0312 17.9 34.7367 13.5682C32.4686 11.7124 30.4916 11.419 28.8583 11.6913C27.1671 11.9731 25.7447 12.8747 24.6267 13.6201C22.8559 14.8006 20.9058 16.0412 18.66 16.3323C16.3417 16.6328 13.8663 15.9129 11.0464 13.563C8.68536 11.5955 6.66501 11.217 5.11309 11.4558C3.52519 11.7001 2.28797 12.6123 1.576 13.4538C1.25716 13.8306 0.69323 13.8776 0.316424 13.5587C-0.0603826 13.2399 -0.107377 12.676 0.211459 12.2992C1.138 11.2041 2.73865 10.0126 4.84128 9.68911C6.97988 9.36008 9.50271 9.94985 12.1907 12.1899C14.7333 14.3086 16.7266 14.7805 18.4302 14.5596C20.2061 14.3294 21.831 13.3356 23.6352 12.1328L24.1309 12.8765L23.6352 12.1328C24.7515 11.3886 26.4572 10.2793 28.5644 9.92812C30.7294 9.56729 33.2212 10.0187 35.8686 12.1847C40.3565 15.8566 45.2562 14.6586 48.1271 12.1979C50.3885 10.2596 52.8657 9.09105 55.6312 9.42817C58.3691 9.76193 61.1614 11.5461 64.1308 14.9723C64.4541 15.3453 64.4138 15.9097 64.0408 16.233C63.6678 16.5563 63.1033 16.516 62.7801 16.1429C59.9401 12.8661 57.5184 11.4589 55.4149 11.2025Z"
                            fill="#FF9996"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M55.4149 20.5825C53.339 20.3295 51.3475 21.1719 49.2904 22.935C45.9052 25.8366 40.0312 27.28 34.7367 22.9482C32.4686 21.0924 30.4916 20.7991 28.8583 21.0713C27.1671 21.3531 25.7447 22.2547 24.6267 23.0001C22.8559 24.1806 20.9058 25.4212 18.66 25.7123C16.3417 26.0128 13.8663 25.293 11.0464 22.9431C8.68536 20.9755 6.66501 20.597 5.11309 20.8358C3.52519 21.0801 2.28797 21.9923 1.576 22.8338C1.25716 23.2106 0.69323 23.2576 0.316424 22.9387C-0.0603826 22.6199 -0.107377 22.056 0.211459 21.6792C1.138 20.5842 2.73865 19.3926 4.84128 19.0691C6.97988 18.7401 9.50271 19.3299 12.1907 21.5699C14.7333 23.6886 16.7266 24.1605 18.4302 23.9396C20.2061 23.7094 21.831 22.7156 23.6352 21.5128L24.1309 22.2565L23.6352 21.5128C24.7515 20.7686 26.4572 19.6593 28.5644 19.3081C30.7294 18.9473 33.2212 19.3987 35.8686 21.5647C40.3565 25.2366 45.2562 24.0386 48.1271 21.5779C50.3885 19.6396 52.8657 18.4711 55.6312 18.8082C58.3691 19.1419 61.1614 20.9261 64.1308 24.3523C64.4541 24.7253 64.4138 25.2897 64.0408 25.613C63.6678 25.9363 63.1033 25.896 62.7801 25.523C59.9401 22.2461 57.5184 20.8389 55.4149 20.5825Z"
                            fill="#FF9996"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M55.4149 29.962C53.339 29.709 51.3475 30.5514 49.2904 32.3146C45.9052 35.2161 40.0312 36.6595 34.7367 32.3277C32.4686 30.472 30.4916 30.1786 28.8583 30.4508C27.1671 30.7326 25.7447 31.6342 24.6267 32.3796C22.8559 33.5601 20.9058 34.8007 18.66 35.0918C16.3417 35.3923 13.8663 34.6725 11.0464 32.3226C8.68536 30.355 6.66501 29.9766 5.11309 30.2153C3.52519 30.4596 2.28797 31.3719 1.576 32.2133C1.25716 32.5901 0.69323 32.6371 0.316424 32.3182C-0.0603826 31.9994 -0.107377 31.4355 0.211459 31.0587C1.138 29.9637 2.73865 28.7721 4.84128 28.4486C6.97988 28.1196 9.50271 28.7094 12.1907 30.9494C14.7333 33.0682 16.7266 33.54 18.4302 33.3192C20.2061 33.0889 21.831 32.0951 23.6352 30.8923L24.1309 31.636L23.6352 30.8923C24.7515 30.1481 26.4572 29.0388 28.5644 28.6876C30.7294 28.3268 33.2212 28.7782 35.8686 30.9443C40.3565 34.6162 45.2562 33.4182 48.1271 30.9574C50.3885 29.0191 52.8657 27.8506 55.6312 28.1877C58.3691 28.5215 61.1614 30.3056 64.1308 33.7318C64.4541 34.1048 64.4138 34.6692 64.0408 34.9925C63.6678 35.3158 63.1033 35.2755 62.7801 34.9025C59.9401 31.6256 57.5184 30.2185 55.4149 29.962Z"
                            fill="#FF9996"
                          ></path>
                        </svg>
                      </div>
                      <div className="absolute top-0 left-0 h-full w-full bg-texture"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-[100px]">
              <div className="mx-4 flex flex-wrap items-center justify-between">
                <div className="w-full px-4 lg:w-1/2">
                  <div
                    className="wow fadeInUp relative z-10 mx-auto mb-14 w-full max-w-[470px] pb-6 lg:mx-0 lg:mb-0"
                    data-wow-delay=".2s"
                    // style="visibility: visible; animation-delay: 0.2s;"
                  >
                    <img
                      src={about2light}
                      alt="about image"
                      className="mx-auto max-w-full"
                    />
                    <div className="absolute top-0 right-5 -z-10">
                      <svg
                        width="72"
                        height="50"
                        viewBox="0 0 72 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_33_10)">
                          <path
                            d="M21.8126 0.216481C21.8159 0.143661 21.8196 0.071493 21.8237 0C21.8203 0.0723874 21.8165 0.144547 21.8126 0.216481C21.4747 7.63863 25.1425 21.8522 42.5976 21.0032C35.4678 21.503 21.3391 26.5685 21.822 42.8298C21.6005 35.7375 17.0094 21.7229 0.441399 21.645C0.291298 21.6473 0.144104 21.6477 0 21.6462C0.148069 21.6447 0.2952 21.6443 0.441399 21.645C7.47462 21.5363 20.8883 17.1617 21.8126 0.216481Z"
                            fill="#7083F5"
                          ></path>
                          <path
                            d="M58.7832 24.2896C58.7851 24.2459 58.7874 24.2025 58.7898 24.1597C58.7878 24.2031 58.7855 24.2464 58.7832 24.2896C58.5804 28.7428 60.7811 37.271 71.2541 36.7616C66.9763 37.0614 58.499 40.1008 58.7888 49.8576C58.6559 45.6022 55.9013 37.1934 45.9605 37.1467C45.8704 37.1481 45.782 37.1482 45.6956 37.1474C45.7844 37.1465 45.8727 37.1462 45.9605 37.1467C50.1803 37.0815 58.2286 34.4567 58.7832 24.2896Z"
                            fill="#7ED8FF"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_33_10">
                            <rect
                              width="71.2541"
                              height="49.8779"
                              fill="white"
                            ></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="absolute bottom-0 left-0 -z-10 h-1/2 w-full rounded-[20px] bg-gradient-1">
                      <div className="absolute left-10 -top-12 -z-10">
                        <svg
                          width="65"
                          height="36"
                          viewBox="0 0 65 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M55.4149 1.83203C53.339 1.57898 51.3475 2.4214 49.2904 4.18456C45.9052 7.08611 40.0313 8.52953 34.7368 4.19769C32.4686 2.34195 30.4917 2.04856 28.8583 2.32079C27.1672 2.60264 25.7448 3.50424 24.6267 4.24961C22.8559 5.43014 20.9059 6.67067 18.66 6.9618C16.3417 7.2623 13.8664 6.54246 11.0465 4.19256C8.68539 2.22501 6.66504 1.84655 5.11312 2.08531C3.52522 2.32961 2.288 3.24185 1.57603 4.08328C1.25719 4.46008 0.69326 4.50708 0.316454 4.18824C-0.0603521 3.86941 -0.107346 3.30548 0.21149 2.92867C1.13803 1.83367 2.73868 0.642115 4.84131 0.318626C6.97991 -0.0103986 9.50274 0.579362 12.1908 2.81939C14.7333 4.93815 16.7266 5.40998 18.4302 5.18915C20.2062 4.95894 21.831 3.96513 23.6352 2.76234L24.131 3.50597L23.6352 2.76234C24.7515 2.01814 26.4572 0.908837 28.5644 0.557635C30.7295 0.196804 33.2212 0.648204 35.8687 2.81426C40.3566 6.48615 45.2562 5.28815 48.1272 2.82739C50.3886 0.889088 52.8657 -0.279434 55.6312 0.057691C58.3691 0.391448 61.1615 2.17558 64.1309 5.60179C64.4541 5.9748 64.4138 6.53924 64.0408 6.86252C63.6678 7.18579 63.1034 7.14547 62.7801 6.77246C59.9402 3.49563 57.5184 2.08846 55.4149 1.83203Z"
                            fill="#7083F5"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M55.4149 11.2024C53.339 10.9493 51.3475 11.7918 49.2904 13.5549C45.9052 16.4565 40.0312 17.8999 34.7367 13.5681C32.4686 11.7123 30.4916 11.4189 28.8583 11.6912C27.1671 11.973 25.7447 12.8746 24.6267 13.62C22.8559 14.8005 20.9058 16.041 18.66 16.3322C16.3417 16.6327 13.8663 15.9128 11.0464 13.5629C8.68536 11.5954 6.66501 11.2169 5.11309 11.4557C3.52519 11.7 2.28797 12.6122 1.576 13.4536C1.25716 13.8304 0.69323 13.8774 0.316424 13.5586C-0.0603826 13.2398 -0.107377 12.6758 0.211459 12.299C1.138 11.204 2.73865 10.0125 4.84128 9.68899C6.97988 9.35996 9.50271 9.94972 12.1907 12.1897C14.7333 14.3085 16.7266 14.7803 18.4302 14.5595C20.2061 14.3293 21.831 13.3355 23.6352 12.1327L24.1309 12.8763L23.6352 12.1327C24.7515 11.3885 26.4572 10.2792 28.5644 9.928C30.7294 9.56717 33.2212 10.0186 35.8686 12.1846C40.3565 15.8565 45.2562 14.6585 48.1271 12.1978C50.3885 10.2594 52.8657 9.09093 55.6312 9.42805C58.3691 9.76181 61.1614 11.5459 64.1308 14.9722C64.4541 15.3452 64.4138 15.9096 64.0408 16.2329C63.6678 16.5561 63.1033 16.5158 62.7801 16.1428C59.9401 12.866 57.5184 11.4588 55.4149 11.2024Z"
                            fill="#7083F5"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M55.4149 20.5825C53.339 20.3295 51.3475 21.1719 49.2904 22.935C45.9052 25.8366 40.0312 27.28 34.7367 22.9482C32.4686 21.0924 30.4916 20.7991 28.8583 21.0713C27.1671 21.3531 25.7447 22.2547 24.6267 23.0001C22.8559 24.1806 20.9058 25.4212 18.66 25.7123C16.3417 26.0128 13.8663 25.293 11.0464 22.9431C8.68536 20.9755 6.66501 20.597 5.11309 20.8358C3.52519 21.0801 2.28797 21.9923 1.576 22.8338C1.25716 23.2106 0.69323 23.2576 0.316424 22.9387C-0.0603826 22.6199 -0.107377 22.056 0.211459 21.6792C1.138 20.5842 2.73865 19.3926 4.84128 19.0691C6.97988 18.7401 9.50271 19.3299 12.1907 21.5699C14.7333 23.6886 16.7266 24.1605 18.4302 23.9396C20.2061 23.7094 21.831 22.7156 23.6352 21.5128L24.1309 22.2565L23.6352 21.5128C24.7515 20.7686 26.4572 19.6593 28.5644 19.3081C30.7294 18.9473 33.2212 19.3987 35.8686 21.5647C40.3565 25.2366 45.2562 24.0386 48.1271 21.5779C50.3885 19.6396 52.8657 18.4711 55.6312 18.8082C58.3691 19.1419 61.1614 20.9261 64.1308 24.3523C64.4541 24.7253 64.4138 25.2897 64.0408 25.613C63.6678 25.9363 63.1033 25.896 62.7801 25.523C59.9401 22.2461 57.5184 20.8389 55.4149 20.5825Z"
                            fill="#7083F5"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M55.4149 29.9622C53.339 29.7091 51.3475 30.5515 49.2904 32.3147C45.9052 35.2162 40.0312 36.6597 34.7367 32.3278C32.4686 30.4721 30.4916 30.1787 28.8583 30.4509C27.1671 30.7328 25.7447 31.6344 24.6267 32.3797C22.8559 33.5603 20.9058 34.8008 18.66 35.0919C16.3417 35.3924 13.8663 34.6726 11.0464 32.3227C8.68536 30.3551 6.66501 29.9767 5.11309 30.2154C3.52519 30.4597 2.28797 31.372 1.576 32.2134C1.25716 32.5902 0.69323 32.6372 0.316424 32.3184C-0.0603826 31.9995 -0.107377 31.4356 0.211459 31.0588C1.138 29.9638 2.73865 28.7722 4.84128 28.4488C6.97988 28.1197 9.50271 28.7095 12.1907 30.9495C14.7333 33.0683 16.7266 33.5401 18.4302 33.3193C20.2061 33.0891 21.831 32.0953 23.6352 30.8925L24.1309 31.6361L23.6352 30.8925C24.7515 30.1483 26.4572 29.039 28.5644 28.6878C30.7294 28.3269 33.2212 28.7783 35.8686 30.9444C40.3565 34.6163 45.2562 33.4183 48.1271 30.9575C50.3885 29.0192 52.8657 27.8507 55.6312 28.1878C58.3691 28.5216 61.1614 30.3057 64.1308 33.7319C64.4541 34.1049 64.4138 34.6694 64.0408 34.9926C63.6678 35.3159 63.1033 35.2756 62.7801 34.9026C59.9401 31.6258 57.5184 30.2186 55.4149 29.9622Z"
                            fill="#7083F5"
                          ></path>
                        </svg>
                      </div>
                      <div className="absolute top-0 left-0 h-full w-full bg-texture"></div>
                    </div>
                  </div>
                </div>
                <div className="w-full px-4 lg:w-1/2">
                  <div
                    className="wow fadeInUp lg:ml-auto lg:max-w-[510px]"
                    data-wow-delay=".3s"
                    // style="visibility: visible; animation-delay: 0.3s;"
                  >
                    <span className="mb-4 block text-lg font-medium text-blue-600 md:text-[22px]">
                      Holistic student management
                    </span>
                    <h2 className="mb-4 text-3xl font-bold  sm:text-4xl md:text-[44px] md:leading-tight">
                      Teacher Mobile App
                    </h2>
                    <p className="mb-[30px] text-base leading-relaxed text-body text-slate-500 ">
                      Enabling teachers to micro manage the activities and
                      information at various levels of school education. Save
                      time and efforts involved in compilation and storing data.
                    </p>
                    <div className="mb-[30px] flex items-center">
                      <div className="mr-[22px] flex h-[60px] w-[60px] items-center justify-center rounded-full border border-stroke text-xl font-semibold text-black dark:border-stroke-dark dark:bg-dark ">
                        01
                      </div>
                      <div>
                        <h5 className="text-xl font-medium text-black ">
                          Class Management
                        </h5>
                        <p className="text-base text-body">
                          Student profile, communication, exams, attendance,
                          etc.
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="mr-[22px] flex h-[60px] w-[60px] items-center justify-center rounded-full border border-stroke text-xl font-semibold text-black dark:border-stroke-dark dark:bg-dark ">
                        02
                      </div>
                      <div>
                        <h5 className="text-xl font-medium text-black">
                          Student Performance
                        </h5>
                        <p className="text-base text-body">
                          Automatic analytics, result and attendance
                          compilation.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="absolute right-0 top-36 -z-10">
            <svg
              width="95"
              height="190"
              viewBox="0 0 95 190"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="95"
                cy="95"
                r="77"
                stroke="url(#paint0_linear_47_27)"
                stroke-width="36"
              ></circle>
              <defs>
                <linearGradient
                  id="paint0_linear_47_27"
                  x1="0"
                  y1="0"
                  x2="224.623"
                  y2="130.324"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FF8FE8"></stop>
                  <stop offset="1" stop-color="#FFC960"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
        </section>
        {/* body 3 end  */}

        {/* body start 4 how it works*/}

        <section
          id="work-process"
          class="relative z-10 pt-[110px] flex flex-col justify-center items-center "
        >
          <div class="container ">
            <div
              class="wow fadeInUp mb-14 max-w-[690px] text-center lg:mb-[70px] md:mx-auto mx-4"
              data-wow-delay=".2s"
              // style="visibility: visible; animation-delay: 0.2s;"
            >
              <h2 class="mb-4 text-3xl font-bold  sm:text-4xl md:text-[44px] md:leading-tight">
                How to Implement it?
              </h2>
              <p class="text-base text-body">
                Worrying about technicality, data format, compilation, storage,
                servers, management, etc ? Leave that on us! We are there for
                you.
              </p>
            </div>
          </div>
          <div class="container max-w-[1390px]">
            <div class="rounded-2xl bg-white px-5 pt-14 pb-14 shadow-card dark:bg-dark dark:shadow-card-dark md:pb-1 lg:pt-20 lg:pb-5 xl:px-10">
              <div class="mx-4 flex flex-wrap justify-center   ">
                <div class="w-full px-4 md:w-1/2 lg:w-1/3">
                  <div
                    class="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                    data-wow-delay=".2s"
                    // style="visibility: visible; animation-delay: 0.2s;"
                  >
                    <div class="mx-auto mb-8 flex h-[90px] w-[90px] items-center justify-center rounded-3xl text-blue-500 bg-slate-200">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_40_12)">
                          <path
                            d="M21.6667 16.6667H30L20 26.6667L10 16.6667H18.3333V5H21.6667V16.6667ZM6.66668 31.6667H33.3333V20H36.6667V33.3333C36.6667 33.7754 36.4911 34.1993 36.1785 34.5118C35.866 34.8244 35.442 35 35 35H5.00001C4.55798 35 4.13406 34.8244 3.8215 34.5118C3.50894 34.1993 3.33334 33.7754 3.33334 33.3333V20H6.66668V31.6667Z"
                            fill="currentColor"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_40_12">
                            <rect width="40" height="40" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <h3 class="mb-4 text-xl font-semibold  sm:text-[22px] xl:text-[26px]">
                      We setup profiles
                    </h3>
                    <p class="text-base text-body">
                      We take data in any form, upload it, develop your
                      profiles, and get you running.
                    </p>
                  </div>
                </div>
                <div class="w-full px-4 md:w-1/2 lg:w-1/3">
                  <div
                    class="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                    data-wow-delay=".3s"
                    // style="visibility: visible; animation-delay: 0.3s;"
                  >
                    <div class="mx-auto mb-8 flex h-[90px] w-[90px] items-center justify-center rounded-3xl text-blue-500 bg-slate-200">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_40_15)">
                          <path
                            d="M20 36.6667C10.795 36.6667 3.33333 29.205 3.33333 20C3.33333 10.795 10.795 3.33337 20 3.33337C29.205 3.33337 36.6667 10.795 36.6667 20C36.6667 29.205 29.205 36.6667 20 36.6667ZM11.6883 30.4267C14.0476 32.3129 16.9795 33.3382 20 33.3334C23.2833 33.3334 26.2883 32.1467 28.6117 30.18C27.5262 29.0663 26.2284 28.1815 24.7951 27.578C23.3617 26.9746 21.8219 26.6647 20.2667 26.6667C18.6543 26.6648 17.0592 26.9981 15.5824 27.6454C14.1057 28.2927 12.7796 29.2398 11.6883 30.4267ZM9.36 28.0334C10.7608 26.5468 12.4511 25.3629 14.3269 24.5546C16.2027 23.7462 18.2241 23.3306 20.2667 23.3334C22.2361 23.3308 24.1866 23.7173 26.0062 24.4707C27.8259 25.224 29.4788 26.3294 30.87 27.7234C32.2968 25.7152 33.1394 23.3511 33.3043 20.8932C33.4692 18.4354 32.9499 15.9798 31.8041 13.7991C30.6584 11.6184 28.9309 9.79775 26.8133 8.53912C24.6957 7.28049 22.2708 6.6331 19.8077 6.66879C17.3445 6.70448 14.9394 7.42185 12.8592 8.7413C10.779 10.0608 9.10493 11.9307 8.02282 14.1437C6.94071 16.3567 6.49282 18.8262 6.72886 21.2783C6.9649 23.7304 7.87562 26.0691 9.36 28.035V28.0334ZM20 21.6667C18.2319 21.6667 16.5362 20.9643 15.286 19.7141C14.0357 18.4638 13.3333 16.7682 13.3333 15C13.3333 13.2319 14.0357 11.5362 15.286 10.286C16.5362 9.03575 18.2319 8.33337 20 8.33337C21.7681 8.33337 23.4638 9.03575 24.714 10.286C25.9643 11.5362 26.6667 13.2319 26.6667 15C26.6667 16.7682 25.9643 18.4638 24.714 19.7141C23.4638 20.9643 21.7681 21.6667 20 21.6667ZM20 18.3334C20.8841 18.3334 21.7319 17.9822 22.357 17.3571C22.9821 16.7319 23.3333 15.8841 23.3333 15C23.3333 14.116 22.9821 13.2681 22.357 12.643C21.7319 12.0179 20.8841 11.6667 20 11.6667C19.1159 11.6667 18.2681 12.0179 17.643 12.643C17.0179 13.2681 16.6667 14.116 16.6667 15C16.6667 15.8841 17.0179 16.7319 17.643 17.3571C18.2681 17.9822 19.1159 18.3334 20 18.3334Z"
                            fill="currentColor"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_40_15">
                            <rect width="40" height="40" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <h3 class="mb-4 text-xl font-semibold  sm:text-[22px] xl:text-[26px]">
                      Download the app
                    </h3>
                    <p class="text-base text-body">
                      You use credentials, download mobile app, and confirm us
                      for any data changes required.
                    </p>
                  </div>
                </div>
                <div class="w-full px-4 md:w-1/2 lg:w-1/3">
                  <div
                    class="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                    data-wow-delay=".4s"
                    // style="visibility: visible; animation-delay: 0.4s;"
                  >
                    <div class="mx-auto mb-8 flex h-[90px] w-[90px] items-center justify-center rounded-3xl bg-gray text-blue-500 bg-slate-200">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_40_18)">
                          <path
                            d="M5.26834 7.44836C7.20178 5.51458 9.79501 4.38351 12.5277 4.28211C15.2603 4.18072 17.9302 5.11651 20.0017 6.9017C22.0713 5.11948 24.7377 4.18475 27.467 4.28463C30.1964 4.38452 32.7873 5.51165 34.7211 7.44037C36.6549 9.3691 37.7888 11.9571 37.8959 14.6862C38.0029 17.4153 37.0751 20.0841 35.2983 22.1584L22.3567 35.1417C21.7621 35.7365 20.9646 36.0845 20.1242 36.1161C19.2838 36.1476 18.4625 35.8603 17.825 35.3117L17.6417 35.1434L4.70168 22.1584C2.92583 20.0859 1.99764 17.4195 2.1027 14.6923C2.20776 11.9651 3.33832 9.37805 5.26834 7.44836ZM7.62501 9.80503C6.26208 11.1683 5.47643 13.0041 5.43112 14.9313C5.38581 16.8585 6.08432 18.7292 7.38168 20.155L7.62501 20.4117L20 32.7867L28.8383 23.9467L22.9467 18.055L21.18 19.8217C20.7158 20.2861 20.1646 20.6546 19.558 20.906C18.9514 21.1575 18.3012 21.287 17.6445 21.2871C16.3183 21.2874 15.0463 20.7609 14.1083 19.8234C13.1704 18.8858 12.6432 17.6141 12.6429 16.2879C12.6426 14.9617 13.1691 13.6897 14.1067 12.7517L17.61 9.2467C16.2158 8.13399 14.4707 7.55451 12.6878 7.61224C10.9049 7.66997 9.20099 8.36112 7.88168 9.5617L7.62501 9.80503ZM21.7683 14.5184C22.0809 14.2059 22.5047 14.0304 22.9467 14.0304C23.3886 14.0304 23.8125 14.2059 24.125 14.5184L31.195 21.5884L32.375 20.4117C33.7608 19.0269 34.5497 17.1549 34.5731 15.196C34.5964 13.237 33.8524 11.3467 32.5 9.92929C31.1477 8.51185 29.2944 7.67981 27.3366 7.61112C25.3787 7.54242 23.4717 8.24253 22.0233 9.5617L21.7683 9.80503L16.465 15.1084C16.1761 15.3971 16.0033 15.7818 15.9793 16.1895C15.9554 16.5972 16.0819 16.9995 16.335 17.32L16.465 17.465C16.7537 17.7539 17.1384 17.9267 17.5461 17.9507C17.9538 17.9747 18.3561 17.8481 18.6767 17.595L18.8217 17.465L21.7683 14.5184Z"
                            fill="currentColor"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_40_18">
                            <rect width="40" height="40" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <h3 class="mb-4 text-xl font-semibold -white sm:text-[22px] xl:text-[26px]">
                      School Virtualised!
                    </h3>
                    <p class="text-base text-body">
                      Enjoy the transparency, speed, and effortless features of
                      our software solution.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="absolute -top-28 left-0 -z-10 hidden md:block">
            <svg
              width="632"
              height="1179"
              viewBox="0 0 632 1179"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.25" filter="url(#filter0_f_38_24)">
                <circle
                  cx="42.5"
                  cy="589.5"
                  r="329.5"
                  fill="url(#paint0_linear_38_24)"
                ></circle>
              </g>
              <defs>
                <filter
                  id="filter0_f_38_24"
                  x="-547"
                  y="0"
                  width="1179"
                  height="1179"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  ></feBlend>
                  <feGaussianBlur
                    stdDeviation="130"
                    result="effect1_foregroundBlur_38_24"
                  ></feGaussianBlur>
                </filter>
                <linearGradient
                  id="paint0_linear_38_24"
                  x1="-366.218"
                  y1="919"
                  x2="451.176"
                  y2="349.901"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8EA5FE"></stop>
                  <stop offset="0.541667" stop-color="#BEB3FD"></stop>
                  <stop offset="1" stop-color="#90D1FF"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div class="absolute right-0 top-20 -z-10">
            <svg
              width="637"
              height="1277"
              viewBox="0 0 637 1277"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.2" filter="url(#filter0_f_38_23)">
                <circle
                  cx="638.5"
                  cy="638.5"
                  r="388.5"
                  fill="url(#paint0_linear_38_23)"
                ></circle>
              </g>
              <defs>
                <filter
                  id="filter0_f_38_23"
                  x="0"
                  y="0"
                  width="1277"
                  height="1277"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  ></feBlend>
                  <feGaussianBlur
                    stdDeviation="125"
                    result="effect1_foregroundBlur_38_23"
                  ></feGaussianBlur>
                </filter>
                <linearGradient
                  id="paint0_linear_38_23"
                  x1="250"
                  y1="250"
                  x2="1168.59"
                  y2="782.957"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FF8FE8"></stop>
                  <stop offset="1" stop-color="#FFC960"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
        </section>

        {/* body end 4 */}

        {/* body 5 new start choose a plan */}
        <section
          id="pricing"
          class="relative z-10 pt-[110px] flex flex-col justify-center items-center"
        >
          <div class="container">
            <div
              class="wow fadeInUp md:mx-auto mx-4 mb-10 max-w-[690px] text-center"
              data-wow-delay=".2s"
              // style="visibility: visible; animation-delay: 0.2s;"
            >
              <h2 class="mb-4 text-3xl font-bold text-black  sm:text-4xl md:text-[44px] md:leading-tight">
                About Our Plans
              </h2>
              <p class="text-base text-body">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                convallis tortor eros. Donec vitae tortor lacus. Phasellus
                aliquam ante in maximus.
              </p>
            </div>
          </div>
          <div class="container max-w-[1120px] overflow-hidden">
            {/* <div
              class="wow fadeInUp mb-[60px] flex items-center justify-center"
              data-wow-delay=".25s"
              // style="visibility: visible; animation-delay: 0.25s;"
            >
              <label for="togglePlan" class="inline-flex items-center">
                <input
                  type="checkbox"
                  name="togglePlan"
                  id="togglePlan"
                  class="sr-only"
                />
                <span class="monthly text-sm font-medium text-black ">
                  Monthly
                </span>
                <label
                  for="AcceptConditions"
                  class="relative h-8 w-14 cursor-pointer [-webkit-tap-highlight-color:_transparent] mx-5"
                >
                  <input
                    type="checkbox"
                    id="AcceptConditions"
                    class="peer sr-only"
                  />

                  <span class="absolute inset-0 rounded-full bg-gray-300 transition peer-checked:bg-blue-500"></span>

                  <span class="absolute inset-y-0 start-0 m-1 h-6 w-6 rounded-full bg-white transition-all peer-checked:start-6"></span>
                </label>
                <span class="yearly text-sm font-medium text-black ">
                  Yearly
                </span>
              </label>
            </div> */}
            <div class="mx-6 flex flex-wrap justify-center">
              <div class="w-full px-6 md:w-1/2 lg:w-1/3 ">
                <div
                  class="wow fadeInUp mb-10 rounded-xl bg-white py-10 px-9 shadow-md lg:mb-4 lg:px-7 xl:px-9"
                  data-wow-delay=".2s"
                  // style="visibility: visible; animation-delay: 0.2s;"
                >
                  <h3 class="mb-2 text-[22px] font-semibold leading-tight text-black ">
                    Alpha Plan
                  </h3>
                  <p class="mb-7 text-base text-body">
                    This is optimal for schools trying the solution first time!
                  </p>
                  <p class="border-b border-stroke pb-5 text-black dark:border-stroke-dark ">
                    {/* <span class="text-[40px] font-bold leading-none">
                      <sup class="text-[22px] font-medium"> $ </sup>0
                    </span>
                    <span class="text-base text-body"> /month </span> */}
                  </p>
                  <div class="space-y-4 pt-[30px] pb-10">
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Virtual Attendance
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Fees Management
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Faculty Profiles
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      50 School Notice per month
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      20 Class Notice per month
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      100 Messages per teacher
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Exam Marks Upload
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Syllabus Coverage
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M1 1L15 15M15 1L1 15"
                              stroke="#FF0000"
                              stroke-width="2"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Student Performance
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Complaint management
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M1 1L15 15M15 1L1 15"
                              stroke="#FF0000"
                              stroke-width="2"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      School News Updates
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M1 1L15 15M15 1L1 15"
                              stroke="#FF0000"
                              stroke-width="2"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Teacher Performance
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M1 1L15 15M15 1L1 15"
                              stroke="#FF0000"
                              stroke-width="2"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Online Google Class
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M1 1L15 15M15 1L1 15"
                              stroke="#FF0000"
                              stroke-width="2"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Daily School Analytics
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M1 1L15 15M15 1L1 15"
                              stroke="#FF0000"
                              stroke-width="2"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      On-Call Support
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Email Support
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M1 1L15 15M15 1L1 15"
                              stroke="#FF0000"
                              stroke-width="2"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      School Feedback
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M1 1L15 15M15 1L1 15"
                              stroke="#FF0000"
                              stroke-width="2"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Monthly Data Backup
                    </p>
                  </div>
                  <a
                    href="javascript:void(0)"
                    class="block w-full rounded-md bg-blue-400 py-[10px] px-8 text-center text-base font-medium text-white "
                  >
                    Enquire Prices
                  </a>
                </div>
              </div>
              <div class="w-full px-6 md:w-1/2 lg:w-1/3">
                <div
                  class="wow fadeInUp relative mb-10 rounded-xl bg-white py-10 px-9 shadow-md lg:mb-4 lg:px-7 xl:px-9"
                  data-wow-delay=".3s"
                  // style="visibility: visible; animation-delay: 0.3s;"
                >
                  <h3 class="mb-2 text-[22px] font-semibold leading-tight text-black ">
                    Beta Plan
                  </h3>
                  <p class="mb-7 text-base text-body">
                    Schools with good student and teacher strength
                  </p>
                  <p class="border-b border-stroke pb-5 text-black dark:border-stroke-dark ">
                    {/* <span class="text-[40px] font-bold leading-none">
                      <sup class="text-[22px] font-medium"> $ </sup>
                      35
                    </span>
                    <span class="text-base text-body"> /month </span> */}
                  </p>
                  <div class="space-y-4 pt-[30px] pb-10">
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Virtual Attendance
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Fees Management
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Faculty Profiles
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Unlimited School Notices
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      50 Class Notice per month
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      500 Messages per teacher
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Exam Marks Upload
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Syllabus Coverage
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="https://icons8.com/icon/11997/close"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Student Performance
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Complaint management
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      School News Updates
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M1 1L15 15M15 1L1 15"
                              stroke="#FF0000"
                              stroke-width="2"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Teacher Performance
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Online Google Class
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Daily School Analytics
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M1 1L15 15M15 1L1 15"
                              stroke="#FF0000"
                              stroke-width="2"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      On-Call Support
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Email Support
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M1 1L15 15M15 1L1 15"
                              stroke="#FF0000"
                              stroke-width="2"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      School Feedback
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M1 1L15 15M15 1L1 15"
                              stroke="#FF0000"
                              stroke-width="2"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Monthly Data Backup
                    </p>
                  </div>
                  <a
                    href="javascript:void(0)"
                    class="block w-full rounded-md bg-blue-400 py-[10px] px-8 text-center text-base font-medium text-white "
                  >
                    Enquire Prices
                  </a>
                </div>
              </div>
              <div class="w-full px-6 md:w-1/2 lg:w-1/3">
                <div
                  class="wow fadeInUp mb-10 rounded-xl bg-white py-10 px-9 shadow-md lg:mb-4 lg:px-7 xl:px-9"
                  data-wow-delay=".4s"
                  // style="visibility: visible; animation-delay: 0.4s;"
                >
                  <h3 class="mb-2 text-[22px] font-semibold leading-tight text-black ">
                    Gamma Plan
                  </h3>
                  <p class="mb-7 text-base text-body">
                    Schools with great student and teacher strength.
                  </p>
                  <p class="border-b border-stroke pb-5 text-black dark:border-stroke-dark ">
                    {/* <span class="text-[40px] font-bold leading-none">
                      <sup class="text-[22px] font-medium"> $ </sup>
                      59
                    </span>
                    <span class="text-base text-body"> /month </span> */}
                  </p>
                  <div class="space-y-4 pt-[30px] pb-10">
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Virtual Attendance
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Fees Management
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Faculty Profiles
                    </p>
                    
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Unlimited School Notice 
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Unlimited Class Notice 
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Unlimited Messages 
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Exam Marks Upload
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Syllabus Coverage
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Student Performance
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Complaint management
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      School News Updates
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Teacher Performance
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Online Google Class
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Daily School Analytics
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      On-Call Support
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Email Support
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      School Feedback
                    </p>
                    <p class="flex text-base text-black dark:text-body">
                      <span class="mr-[10px] mt-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_44_7)">
                            <path
                              d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
                              fill="#00BE6C"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_44_7">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Monthly Data Backup
                    </p>
                  </div>
                  <a
                    href="javascript:void(0)"
                    class="block w-full rounded-md bg-blue-400 py-[10px] px-8 text-center text-base font-medium text-white "
                  >
                    Enquire Prices
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* body 5 new end choose a plan */}

        {/* body 6 start  */}
        {/* <div className="h-[980px] max-w-screen bg-cyan-300 flex flex-col items-center justify-center">
        <div className="h-[18%] sm:w-[400px] md:w-[700px] lg:w-[1000px] bg-blue-200 rounded-2xl m-1"></div>
        <div className="h-[56%] sm:w-[400px] md:w-[700px] lg:w-[1000px] bg-pink-200 rounded-2xl m-1"></div>
        <div className="h-[18%] sm:w-[400px] md:w-[700px] lg:w-[1000px] bg-green-200 rounded-2xl m-1"></div>
      </div> */}

        <section
          id="screens"
          class="relative z-20 pt-[110px] flex flex-col items-center justify-center"
        >
          <div class="container">
            <div
              class="wow fadeInUp md:mx-auto mx-4 mb-10 max-w-[690px] text-center"
              data-wow-delay=".2s"
              // style="visibility: visible; animation-delay: 0.2s;"
            >
              <h2 class="mb-4 text-3xl font-bold  sm:text-4xl md:text-[44px] md:leading-tight">
                App Insights
              </h2>
              <p class="text-base text-body">
                Here are some of the real usage screens from our Ed Sanchaar
                Mobile Application.
              </p>
            </div>
          </div>
          <ReactSimplyCarousel
            activeSlideIndex={activeSlideIndex}
            onRequestChange={setActiveSlideIndex}
            // autoplay={true}
            // autoplayDirection="forward"
            itemsToShow={1}
            itemsToScroll={1}
            forwardBtnProps={{
              //here you can also pass className, or any other button element attributes
              style: {
                alignSelf: "center",
                // background: "black",
                border: "none",
                borderRadius: "50%",
                color: "white",
                cursor: "pointer",
                fontSize: "20px",
                height: 30,
                lineHeight: 1,
                textAlign: "center",
                width: 30,
              },
              children: (
                <span>
                  <ArrowForwardIosIcon className="text-black" />
                </span>
              ),
            }}
            backwardBtnProps={{
              //here you can also pass className, or any other button element attributes
              style: {
                alignSelf: "center",
                // background: "black",
                border: "none",
                borderRadius: "50%",
                color: "white",
                cursor: "pointer",
                fontSize: "20px",
                height: 30,
                lineHeight: 1,
                textAlign: "center",
                width: 30,
              },
              children: (
                <span>
                  <ArrowBackIosIcon className="text-black" />
                </span>
              ),
            }}
            responsiveProps={[
              {
                itemsToShow: 1,
                itemsToScroll: 1,
                maxWidth: 768,
              },
              {
                itemsToShow: 3,
                itemsToScroll: 1,
                minWidth: 768,
                maxWidth: 1480,
              },
              {
                itemsToShow: 5,
                itemsToScroll: 1,
                minWidth: 1480,
                maxWidth: 2000,
              },
            ]}
            speed={400}
            easing="linear"
          >
            {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
            <div
              class="swiper-slide swiper-slide-duplicate mx-4"
              // style="width: 306.667px; margin-right: 40px;"
              data-swiper-slide-index="3"
            >
              <div class="mx-4  lg:w-[252px] md:w-[200px] w-[280px] ">
                <img
                  src={cimg1}
                  alt="screenshot"
                  class="mx-auto w-full rounded-2xl "
                />
              </div>
            </div>
            <div
              class="swiper-slide swiper-slide-duplicate mx-4"
              // style="width: 306.667px; margin-right: 40px;"
              data-swiper-slide-index="4"
            >
              <div class="mx-4 lg:w-[252px] md:w-[200px]  w-[280px]">
                <img
                  src={cimg2}
                  alt="screenshot"
                  class="mx-auto w-full rounded-2xl"
                />
              </div>
            </div>

            <div
              class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev mx-4"
              // style="width: 306.667px; margin-right: 40px;"
              data-swiper-slide-index="5"
            >
              <div class="mx-4 lg:w-[252px] md:w-[200px] w-[280px]">
                <img
                  src={cimg3}
                  alt="screenshot"
                  class="mx-auto w-full rounded-2xl"
                />
              </div>
            </div>
            <div
              class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev mx-4"
              // style="width: 306.667px; margin-right: 40px;"
              data-swiper-slide-index="5"
            >
              <div class="mx-4 lg:w-[252px] md:w-[200px] w-[280px]">
                <img
                  src={cimg4}
                  alt="screenshot"
                  class="mx-auto w-full rounded-2xl"
                />
              </div>
            </div>
            <div
              class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev mx-4"
              // style="width: 306.667px; margin-right: 40px;"
              data-swiper-slide-index="5"
            >
              <div class="mx-4 lg:w-[252px] md:w-[200px] w-[280px]">
                <img
                  src={cimg5}
                  alt="screenshot"
                  class="mx-auto w-full rounded-2xl"
                />
              </div>
            </div>
            <div
              class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev mx-4"
              // style="width: 306.667px; margin-right: 40px;"
              data-swiper-slide-index="5"
            >
              <div class="mx-4 lg:w-[252px] md:w-[200px] w-[280px]">
                <img
                  src={cimg6}
                  alt="screenshot"
                  class="mx-auto w-full rounded-2xl"
                />
              </div>
            </div>
            <div
              class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev mx-4"
              // style="width: 306.667px; margin-right: 40px;"
              data-swiper-slide-index="5"
            >
              <div class="mx-4 lg:w-[252px] md:w-[200px] w-[280px]">
                <img
                  src={cimg7}
                  alt="screenshot"
                  class="mx-auto w-full rounded-2xl"
                />
              </div>
            </div>
            <div
              class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev mx-4"
              // style="width: 306.667px; margin-right: 40px;"
              data-swiper-slide-index="5"
            >
              <div class="mx-4 lg:w-[252px] md:w-[200px] w-[280px]">
                <img
                  src={cimg8}
                  alt="screenshot"
                  class="mx-auto w-full rounded-2xl"
                />
              </div>
            </div>
          </ReactSimplyCarousel>

          {/* <div class="container">
            <div
              class="wow fadeInUp md:mx-auto mx-2 max-w-[1000px] "
              data-wow-delay=".2s"
              // style="visibility: visible; animation-delay: 0.2s;"
            >
              <div class="swiper mySwiper relative z-20 swiper-initialized swiper-horizontal swiper-pointer-events">
                <div class="absolute top-0 left-0 right-0 z-50 mx-auto w-full md:w-1/3">
                  <img
                    src={mobileframe}
                    alt="mobile-frame"
                    class="mx-auto lg:w-[280px] md:w-[220px] w-[136px]  "
                  />
                </div>
                <div
                  class="swiper-wrapper py-2 flex flex-row justify-between"
                  // style="transform: translate3d(-3120px, 0px, 0px); transition-duration: 0ms;"
                >
                  <div
                    class="swiper-slide swiper-slide-duplicate "
                    // style="width: 306.667px; margin-right: 40px;"
                    data-swiper-slide-index="3"
                  >
                    <div class="mx-auto  lg:w-[252px] md:w-[200px] w-[120px] ">
                      <img
                        src={screen1light}
                        alt="screenshot"
                        class="mx-auto w-full rounded-2xl "
                      />
                    </div>
                  </div>
                  <div
                    class="swiper-slide swiper-slide-duplicate"
                    // style="width: 306.667px; margin-right: 40px;"
                    data-swiper-slide-index="4"
                  >
                    <div class="mx-auto lg:w-[252px] md:w-[200px]  w-[120px]">
                      <img
                        src={screen3light}
                        alt="screenshot"
                        class="mx-auto w-full rounded-2xl"
                      />
                    </div>
                  </div>
                  
                  <div
                    class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                    // style="width: 306.667px; margin-right: 40px;"
                    data-swiper-slide-index="5"
                  >
                    <div class="mx-auto lg:w-[252px] md:w-[200px] w-[120px]">
                      <img
                        src={screen2light}
                        alt="screenshot"
                        class="mx-auto w-full rounded-2xl"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex items-center justify-center space-x-4 pt-20"></div>
              </div>
            </div>
          </div> */}
        </section>
        {/* body 6 end  */}

        {/* body 7 start  */}
        {/* <div className="h-[650px] flex items-center justify-center">
        <div className="h-[80%] sm:w-[400px] md:w-[700px] lg:w-[1400px] bg-slate-200 rounded-2xl"></div>
      </div> */}
        <section
          id="cta"
          class="relative z-10 pt-[110px] flex flex-row justify-center items-center pb-[110px]"
        >
          <div class="container max-w-[1390px] ">
            <div class="rounded-2xl bg-white px-10 pt-14 drop-shadow-lg sm:px-20 lg:px-12 lg:pt-20 xl:px-20">
              <div class="mx-4 flex flex-wrap">
                <div class="w-full self-center px-4 lg:w-1/2">
                  <div
                    class="wow fadeInUp mx-auto max-w-[530px] text-center lg:ml-0 lg:text-left"
                    data-wow-delay=".2s"
                    // style="visibility: visible; animation-delay: 0.2s;"
                  >
                    <h2 class="mb-4 text-3xl font-bold text-black  sm:text-4xl md:text-[38px] md:leading-tight">
                      Get your school virtualised! Download Now!
                    </h2>
                    <p class="mb-10 text-base text-body">
                      Connect with us to start your school's plan and then
                      teachers and students n enjoy the features!
                    </p>
                    <div class="-mx-[10px] flex flex-wrap items-center justify-center lg:justify-start">
                      <div class="inline-block px-[10px]">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.venuratech"
                          class="mb-5 inline-flex items-center text-white rounded-md bg-indigo-400 py-[10px] pl-4 pr-5 "
                        >
                          <span class="mr-[10px]">
                            <img
                              src={playstore}
                              width="34"
                              height="34"
                              alt=""
                              viewBox="0 0 34 34"
                            />
                          </span>
                          <span class="text-left">
                            <span class="block text-xs opacity-70">
                              Get it on
                            </span>
                            <span class="block text-sm font-medium">
                              Google Play
                            </span>
                          </span>
                        </a>
                      </div>
                      <div class="inline-block px-[10px]">
                        <a
                          href="javascript:void(0)"
                          class="mb-5 inline-flex items-center text-white rounded-md bg-black py-[10px] pl-4 pr-5 "
                        >
                          <span class="mr-[10px]">
                            <svg
                              width="34"
                              height="34"
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M26.5058 27.625C25.33 29.3817 24.0833 31.0959 22.185 31.1242C20.2867 31.1667 19.6775 30.005 17.5242 30.005C15.3567 30.005 14.6908 31.0959 12.8917 31.1667C11.0358 31.2375 9.63333 29.2967 8.44333 27.5825C6.02083 24.0834 4.165 17.6375 6.65833 13.3025C7.89083 11.1492 10.1008 9.78921 12.495 9.74671C14.3083 9.71837 16.0367 10.9792 17.1558 10.9792C18.2608 10.9792 20.3575 9.46337 22.5533 9.69004C23.4742 9.73254 26.0525 10.0584 27.71 12.495C27.5825 12.58 24.6358 14.3084 24.6642 17.8925C24.7067 22.1709 28.4183 23.6017 28.4608 23.6159C28.4183 23.715 27.8658 25.6559 26.5058 27.625ZM18.4167 4.95837C19.4508 3.78254 21.165 2.89004 22.5817 2.83337C22.7658 4.49087 22.1 6.16254 21.1083 7.35254C20.1308 8.55671 18.5158 9.49171 16.9292 9.36421C16.7167 7.73504 17.51 6.03504 18.4167 4.95837Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </span>
                          <span class="text-left">
                            <span class="block text-xs opacity-70">
                              Available Soon
                            </span>
                            <span class="block text-sm font-medium">
                              App Store
                            </span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full px-4 lg:w-1/2">
                  <div
                    class="wow fadeInUp relative z-10 mx-auto mt-20 max-w-[435px] lg:mt-0"
                    data-wow-delay=".3s"
                    // style="visibility: visible; animation-delay: 0.3s;"
                  >
                    <img
                      src={ctaLight}
                      alt="cta image"
                      class="mx-auto max-w-full"
                    />
                    <div class="absolute -top-5 left-0 right-0 -z-10 aspect-square w-full rounded-full bg-gradient-2"></div>
                    <div class="absolute top-0 right-0 -z-10">
                      <svg
                        width="60"
                        height="43"
                        viewBox="0 0 60 43"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_47_16)">
                          <path
                            d="M18.3392 1.12402C18.342 1.06386 18.3451 1.00425 18.3484 0.94519C18.3456 1.00499 18.3425 1.06459 18.3392 1.12402C18.0601 7.2551 21.0899 18.9962 35.5087 18.295C29.6192 18.7078 17.9481 22.8922 18.347 36.3249C18.164 30.4662 14.3716 18.8894 0.685511 18.8251C0.56152 18.827 0.43993 18.8273 0.320892 18.8261C0.443205 18.8249 0.564743 18.8245 0.685511 18.8251C6.49532 18.7353 17.5757 15.1217 18.3392 1.12402Z"
                            fill="#7083F5"
                          ></path>
                          <path
                            d="M48.8789 21.0097C48.8805 20.9735 48.8824 20.9378 48.8844 20.9023C48.8827 20.9382 48.8808 20.974 48.8789 21.0097C48.7114 24.6883 50.5293 31.733 59.1806 31.3122C55.6469 31.5599 48.6442 34.0705 48.8836 42.1302C48.7737 38.615 46.4983 31.6689 38.2867 31.6303C38.2123 31.6315 38.1393 31.6316 38.0679 31.6309C38.1413 31.6301 38.2142 31.6299 38.2867 31.6303C41.7725 31.5764 48.4208 29.4082 48.8789 21.0097Z"
                            fill="#19DEBB"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_47_16">
                            <rect
                              width="58.8596"
                              height="41.2017"
                              fill="white"
                              transform="translate(0.320892 0.94519)"
                            ></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div class="absolute left-0 bottom-0 -z-10">
                      <svg
                        width="54"
                        height="30"
                        viewBox="0 0 54 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M45.9917 2.36882C44.2769 2.15978 42.6317 2.85567 40.9325 4.31213C38.1361 6.70897 33.284 7.90131 28.9104 4.32298C27.0368 2.79004 25.4038 2.54769 24.0545 2.77256C22.6575 3.00539 21.4826 3.75015 20.559 4.36587C19.0962 5.34105 17.4854 6.36579 15.6302 6.60628C13.7152 6.85451 11.6704 6.25989 9.34099 4.31875C7.39062 2.69344 5.7217 2.38082 4.43974 2.57805C3.12805 2.77985 2.10604 3.53341 1.51791 4.22847C1.25454 4.53973 0.788703 4.57855 0.477442 4.31517C0.16618 4.0518 0.12736 3.58597 0.390736 3.2747C1.15611 2.37017 2.47833 1.38589 4.21521 1.11867C5.98181 0.846879 8.06579 1.33405 10.2863 3.18443C12.3865 4.93464 14.0331 5.32439 15.4403 5.14198C16.9074 4.95181 18.2496 4.13088 19.74 3.13731L20.1495 3.75159L19.74 3.13731C20.6621 2.52256 22.0711 1.60622 23.8118 1.3161C25.6002 1.01804 27.6585 1.39092 29.8454 3.18019C33.5527 6.21337 37.6 5.22376 39.9716 3.19104C41.8396 1.5899 43.8859 0.624641 46.1703 0.903125C48.432 1.17883 50.7386 2.65261 53.1915 5.48284C53.4585 5.79096 53.4252 6.25723 53.1171 6.52426C52.809 6.7913 52.3427 6.758 52.0757 6.44988C49.7297 3.74304 47.7293 2.58064 45.9917 2.36882Z"
                          fill="#F76D8D"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M45.9916 10.1093C44.2769 9.90026 42.6317 10.5961 40.9325 12.0526C38.1361 14.4494 33.2839 15.6418 28.9104 12.0635C27.0368 10.5305 25.4037 10.2882 24.0545 10.513C22.6575 10.7459 21.4825 11.4906 20.559 12.1063C19.0962 13.0815 17.4853 14.1063 15.6301 14.3468C13.7151 14.595 11.6704 14.0004 9.34098 12.0592C7.3906 10.4339 5.72169 10.1213 4.43972 10.3185C3.12804 10.5203 2.10603 11.2739 1.5179 11.9689C1.25452 12.2802 0.788688 12.319 0.477426 12.0557C0.166165 11.7923 0.127345 11.3264 0.39072 11.0152C1.15609 10.1107 2.47832 9.12637 4.2152 8.85915C5.98179 8.58736 8.06578 9.07453 10.2862 10.9249C12.3865 12.6751 14.0331 13.0649 15.4403 12.8825C16.9074 12.6923 18.2496 11.8714 19.7399 10.8778L20.1495 11.4921L19.7399 10.8778C20.6621 10.263 22.071 9.34669 23.8117 9.05658C25.6002 8.75852 27.6585 9.1314 29.8454 10.9207C33.5526 13.9538 37.6 12.9642 39.9716 10.9315C41.8396 9.33038 43.8858 8.36512 46.1703 8.6436C48.432 8.9193 50.7386 10.3931 53.1915 13.2233C53.4585 13.5314 53.4252 13.9977 53.1171 14.2647C52.809 14.5318 52.3427 14.4985 52.0757 14.1904C49.7297 11.4835 47.7292 10.3211 45.9916 10.1093Z"
                          fill="#F76D8D"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M45.9916 17.8577C44.2769 17.6487 42.6317 18.3446 40.9325 19.801C38.1361 22.1979 33.2839 23.3902 28.9104 19.8119C27.0368 18.2789 25.4037 18.0366 24.0545 18.2615C22.6575 18.4943 21.4825 19.239 20.559 19.8548C19.0962 20.8299 17.4853 21.8547 15.6301 22.0952C13.7151 22.3434 11.6704 21.7488 9.34098 19.8076C7.3906 18.1823 5.72169 17.8697 4.43972 18.0669C3.12804 18.2687 2.10603 19.0223 1.5179 19.7174C1.25452 20.0286 0.788688 20.0674 0.477426 19.8041C0.166165 19.5407 0.127345 19.0749 0.39072 18.7636C1.15609 17.8591 2.47832 16.8748 4.2152 16.6076C5.98179 16.3358 8.06578 16.8229 10.2862 18.6733C12.3865 20.4235 14.0331 20.8133 15.4403 20.6309C16.9074 20.4407 18.2496 19.6198 19.7399 18.6262L20.1495 19.2405L19.7399 18.6262C20.6621 18.0114 22.071 17.0951 23.8117 16.805C25.6002 16.5069 27.6585 16.8798 29.8454 18.6691C33.5526 21.7023 37.6 20.7127 39.9716 18.6799C41.8396 17.0788 43.8858 16.1135 46.1703 16.392C48.432 16.6677 50.7386 18.1415 53.1915 20.9717C53.4585 21.2799 53.4252 21.7461 53.1171 22.0132C52.809 22.2802 52.3427 22.2469 52.0757 21.9388C49.7297 19.2319 47.7292 18.0695 45.9916 17.8577Z"
                          fill="#F76D8D"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M45.9916 25.6056C44.2769 25.3966 42.6317 26.0925 40.9325 27.5489C38.1361 29.9458 33.2839 31.1381 28.9104 27.5598C27.0368 26.0269 25.4037 25.7845 24.0545 26.0094C22.6575 26.2422 21.4825 26.987 20.559 27.6027C19.0962 28.5779 17.4853 29.6026 15.6301 29.8431C13.7151 30.0913 11.6704 29.4967 9.34098 27.5556C7.3906 25.9303 5.72169 25.6176 4.43972 25.8149C3.12804 26.0167 2.10603 26.7702 1.5179 27.4653C1.25452 27.7765 0.788688 27.8154 0.477426 27.552C0.166165 27.2886 0.127345 26.8228 0.39072 26.5115C1.15609 25.607 2.47832 24.6227 4.2152 24.3555C5.98179 24.0837 8.06578 24.5709 10.2862 26.4212C12.3865 28.1715 14.0331 28.5612 15.4403 28.3788C16.9074 28.1886 18.2496 27.3677 19.7399 26.3741L20.1495 26.9884L19.7399 26.3741C20.6621 25.7594 22.071 24.843 23.8117 24.5529C25.6002 24.2549 27.6585 24.6277 29.8454 26.417C33.5526 29.4502 37.6 28.4606 39.9716 26.4279C41.8396 24.8267 43.8858 23.8615 46.1703 24.1399C48.432 24.4156 50.7386 25.8894 53.1915 28.7197C53.4585 29.0278 53.4252 29.494 53.1171 29.7611C52.809 30.0281 52.3427 29.9948 52.0757 29.6867C49.7297 26.9799 47.7292 25.8175 45.9916 25.6056Z"
                          fill="#F76D8D"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="absolute right-0 -top-[250px] -z-10">
            <svg
              width="610"
              height="1183"
              viewBox="0 0 610 1183"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.2" filter="url(#filter0_f_47_19)">
                <circle
                  cx="591.5"
                  cy="591.5"
                  r="341.5"
                  fill="url(#paint0_linear_47_19)"
                ></circle>
              </g>
              <defs>
                <filter
                  id="filter0_f_47_19"
                  x="0"
                  y="0"
                  width="1183"
                  height="1183"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  ></feBlend>
                  <feGaussianBlur
                    stdDeviation="125"
                    result="effect1_foregroundBlur_47_19"
                  ></feGaussianBlur>
                </filter>
                <linearGradient
                  id="paint0_linear_47_19"
                  x1="250"
                  y1="250"
                  x2="1057.46"
                  y2="718.481"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FF8FE8"></stop>
                  <stop offset="1" stop-color="#FFC960"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div class="absolute left-0 top-10 -z-10 hidden sm:block">
            <svg
              width="706"
              height="1405"
              viewBox="0 0 706 1405"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="w-[400px] md:w-[700px]"
            >
              <g opacity="0.25" filter="url(#filter0_f_47_21)">
                <circle
                  cx="3.5"
                  cy="702.5"
                  r="442.5"
                  fill="url(#paint0_linear_47_21)"
                ></circle>
              </g>
              <defs>
                <filter
                  id="filter0_f_47_21"
                  x="-699"
                  y="0"
                  width="1405"
                  height="1405"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  ></feBlend>
                  <feGaussianBlur
                    stdDeviation="130"
                    result="effect1_foregroundBlur_47_21"
                  ></feGaussianBlur>
                </filter>
                <linearGradient
                  id="paint0_linear_47_21"
                  x1="-545.385"
                  y1="1145"
                  x2="552.329"
                  y2="380.732"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8EA5FE"></stop>
                  <stop offset="0.541667" stop-color="#BEB3FD"></stop>
                  <stop offset="1" stop-color="#90D1FF"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
        </section>
        {/* body 7 end  */}

        {/* body 8 start testimonials*/}
        {/* <section className="bg-white">
        <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
          <h2 className="text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Read trusted reviews from our customers
          </h2>

          <div className="mt-8 [column-fill:_balance] sm:columns-2 sm:gap-6 lg:columns-3 lg:gap-8">
            <div className="mb-8 sm:break-inside-avoid">
              <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                <div className="flex items-center gap-4">
                  <img
                    alt="Man"
                    src="https://images.unsplash.com/photo-1595152772835-219674b2a8a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                    className="h-14 w-14 rounded-full object-cover"
                  />

                  <div>
                    <div className="flex justify-center gap-0.5 text-green-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    </div>

                    <p className="mt-0.5 text-lg font-medium text-gray-900">
                      Paul Starr
                    </p>
                  </div>
                </div>

                <p className="mt-4 text-gray-700">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Culpa sit rerum incidunt, a consequuntur recusandae ab saepe
                  illo est quia obcaecati neque quibusdam eius accusamus error
                  officiis atque voluptates magnam!
                </p>
              </blockquote>
            </div>

            <div className="mb-8 sm:break-inside-avoid">
              <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                <div className="flex items-center gap-4">
                  <img
                    alt="Man"
                    src="https://images.unsplash.com/photo-1595152772835-219674b2a8a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                    className="h-14 w-14 rounded-full object-cover"
                  />

                  <div>
                    <div className="flex justify-center gap-0.5 text-green-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    </div>

                    <p className="mt-0.5 text-lg font-medium text-gray-900">
                      Paul Starr
                    </p>
                  </div>
                </div>

                <p className="mt-4 text-gray-700">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad
                  mollitia rerum quo unde neque atque molestias quas pariatur!
                  Sint, maxime?
                </p>
              </blockquote>
            </div>

            <div className="mb-8 sm:break-inside-avoid">
              <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                <div className="flex items-center gap-4">
                  <img
                    alt="Man"
                    src="https://images.unsplash.com/photo-1595152772835-219674b2a8a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                    className="h-14 w-14 rounded-full object-cover"
                  />

                  <div>
                    <div className="flex justify-center gap-0.5 text-green-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    </div>

                    <p className="mt-0.5 text-lg font-medium text-gray-900">
                      Paul Starr
                    </p>
                  </div>
                </div>

                <p className="mt-4 text-gray-700">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Reprehenderit esse delectus, maiores fugit, reiciendis culpa
                  inventore sint accusantium libero dolore eos quasi a ex
                  aliquam molestiae. Tenetur hic delectus maxime.
                </p>
              </blockquote>
            </div>

            <div className="mb-8 sm:break-inside-avoid">
              <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                <div className="flex items-center gap-4">
                  <img
                    alt="Man"
                    src="https://images.unsplash.com/photo-1595152772835-219674b2a8a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                    className="h-14 w-14 rounded-full object-cover"
                  />

                  <div>
                    <div className="flex justify-center gap-0.5 text-green-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    </div>

                    <p className="mt-0.5 text-lg font-medium text-gray-900">
                      Paul Starr
                    </p>
                  </div>
                </div>

                <p className="mt-4 text-gray-700">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Fugit, fuga?
                </p>
              </blockquote>
            </div>

            <div className="mb-8 sm:break-inside-avoid">
              <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                <div className="flex items-center gap-4">
                  <img
                    alt="Man"
                    src="https://images.unsplash.com/photo-1595152772835-219674b2a8a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                    className="h-14 w-14 rounded-full object-cover"
                  />

                  <div>
                    <div className="flex justify-center gap-0.5 text-green-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    </div>

                    <p className="mt-0.5 text-lg font-medium text-gray-900">
                      Paul Starr
                    </p>
                  </div>
                </div>

                <p className="mt-4 text-gray-700">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Cupiditate officia natus blanditiis rerum incidunt ex autem
                  repudiandae doloribus eveniet quia? Culpa commodi quae atque
                  perspiciatis? Provident, magni beatae saepe porro aspernatur
                  facere neque sunt possimus assumenda perspiciatis aperiam
                  quisquam animi libero voluptatem fuga. Repudiandae, facere?
                  Nemo reprehenderit quas ratione quis.
                </p>
              </blockquote>
            </div>

            <div className="mb-8 sm:break-inside-avoid">
              <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                <div className="flex items-center gap-4">
                  <img
                    alt="Man"
                    src="https://images.unsplash.com/photo-1595152772835-219674b2a8a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                    className="h-14 w-14 rounded-full object-cover"
                  />

                  <div>
                    <div className="flex justify-center gap-0.5 text-green-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    </div>

                    <p className="mt-0.5 text-lg font-medium text-gray-900">
                      Paul Starr
                    </p>
                  </div>
                </div>

                <p className="mt-4 text-gray-700">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Non,
                  rerum. Nobis laborum praesentium necessitatibus vero.
                </p>
              </blockquote>
            </div>

            <div className="mb-8 sm:break-inside-avoid">
              <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                <div className="flex items-center gap-4">
                  <img
                    alt="Man"
                    src="https://images.unsplash.com/photo-1595152772835-219674b2a8a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                    className="h-14 w-14 rounded-full object-cover"
                  />

                  <div>
                    <div className="flex justify-center gap-0.5 text-green-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    </div>

                    <p className="mt-0.5 text-lg font-medium text-gray-900">
                      Paul Starr
                    </p>
                  </div>
                </div>

                <p className="mt-4 text-gray-700">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Maiores quaerat quasi ipsa repellendus quam! Beatae pariatur
                  quia distinctio fugit repellendus repudiandae nostrum
                  consectetur quibusdam quo.
                </p>
              </blockquote>
            </div>

            <div className="mb-8 sm:break-inside-avoid">
              <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                <div className="flex items-center gap-4">
                  <img
                    alt="Man"
                    src="https://images.unsplash.com/photo-1595152772835-219674b2a8a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                    className="h-14 w-14 rounded-full object-cover"
                  />

                  <div>
                    <div className="flex justify-center gap-0.5 text-green-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    </div>

                    <p className="mt-0.5 text-lg font-medium text-gray-900">
                      Paul Starr
                    </p>
                  </div>
                </div>

                <p className="mt-4 text-gray-700">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit,
                  modi!
                </p>
              </blockquote>
            </div>

            <div className="mb-8 sm:break-inside-avoid">
              <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                <div className="flex items-center gap-4">
                  <img
                    alt="Man"
                    src="https://images.unsplash.com/photo-1595152772835-219674b2a8a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                    className="h-14 w-14 rounded-full object-cover"
                  />

                  <div>
                    <div className="flex justify-center gap-0.5 text-green-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    </div>

                    <p className="mt-0.5 text-lg font-medium text-gray-900">
                      Paul Starr
                    </p>
                  </div>
                </div>

                <p className="mt-4 text-gray-700">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
                  numquam, unde molestiae commodi temporibus dicta.
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </section> */}

        {/* <section
          id="testimonials"
          class="relative z-10 pt-[110px] pb-[60px] flex flex-col justify-center items-center"
        >
          <div class="container">
            <div
              class="wow fadeInUp md:mx-auto mx-4 mb-14 max-w-[690px] text-center lg:mb-[70px]"
              data-wow-delay=".2s"
              // style="visibility: visible; animation-delay: 0.2s;"
            >
              <h2 class="mb-4 text-3xl font-bold text-black  sm:text-4xl md:text-[44px] md:leading-tight">
                What Client's Say
              </h2>
              <p class="text-base text-body">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                convallis tortor eros. Donec vitae tortor lacus. Phasellus
                aliquam ante in maximus.
              </p>
            </div>
          </div>
          <div class="container overflow-hidden lg:max-w-[1160px]">
            <div class="md:mx-auto mx-2 flex flex-wrap">
              <div class="w-full px-6 lg:w-1/2">
                <div
                  class="wow fadeInUp mb-[50px] rounded-lg bg-white py-9 px-7 drop-shadow-lg sm:px-9 lg:px-7 xl:px-9"
                  data-wow-delay=".2s"
                  // style="visibility: visible; animation-delay: 0.2s;"
                >
                  <div class="mb-5 border-b border-stroke dark:border-stroke-dark">
                    <p class="pb-9 text-base text-body">
                      Lorem ipsum dolor sit amet, consect adipiscing elit.
                      Pellentesque dignissim nisi a odio laoreet luctus. Ut sed
                      diam, quis bibendum ex.
                    </p>
                  </div>
                  <div class="items-center justify-between sm:flex lg:block xl:flex">
                    <div class="mb-4 flex items-center sm:mb-0 lg:mb-4 xl:mb-0">
                      <div class="mr-4 h-[56px] w-full max-w-[56px] rounded-full">
                        <img
                          src={atuh1}
                          alt="author"
                          class="h-full w-full object-cover object-center"
                        />
                      </div>
                      <div>
                        <h5 class="text-base font-medium text-black ">
                          Musharof Chowdhury
                        </h5>
                        <p class="text-sm text-body">Web Entrepreneur</p>
                      </div>
                    </div>
                    <div class="flex items-center space-x-3 sm:justify-end lg:justify-start xl:justify-end">
                      <p class="text-base font-medium text-black ">5.0</p>
                      <div class="flex items-center space-x-[6px]">
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full px-6 lg:w-1/2">
                <div
                  class="wow fadeInUp mb-[50px] rounded-lg bg-white py-9 px-7 drop-shadow-lg sm:px-9 lg:px-7 xl:px-9"
                  data-wow-delay=".3s"
                  // style="visibility: visible; animation-delay: 0.3s;"
                >
                  <div class="mb-5 border-b border-stroke dark:border-stroke-dark">
                    <p class="pb-9 text-base text-body">
                      Lorem ipsum dolor sit amet, consect adipiscing elit.
                      Pellentesque dignissim nisi a odio laoreet luctus. Ut sed
                      diam, quis bibendum ex.
                    </p>
                  </div>
                  <div class="items-center justify-between sm:flex lg:block xl:flex">
                    <div class="mb-4 flex items-center sm:mb-0 lg:mb-4 xl:mb-0">
                      <div class="mr-4 h-[56px] w-full max-w-[56px] rounded-full">
                        <img
                          src={atuh2}
                          alt="author"
                          class="h-full w-full object-cover object-center"
                        />
                      </div>
                      <div>
                        <h5 class="text-base font-medium text-black ">
                          Naimur Rahman
                        </h5>
                        <p class="text-sm text-body">Product Designer</p>
                      </div>
                    </div>
                    <div class="flex items-center space-x-3 sm:justify-end lg:justify-start xl:justify-end">
                      <p class="text-base font-medium text-black ">5.0</p>
                      <div class="flex items-center space-x-[6px]">
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full px-6 lg:w-1/2">
                <div
                  class="wow fadeInUp mb-[50px] rounded-lg bg-white py-9 px-7 drop-shadow-lg sm:px-9 lg:px-7 xl:px-9"
                  data-wow-delay=".2s"
                  // style="visibility: visible; animation-delay: 0.2s;"
                >
                  <div class="mb-5 border-b border-stroke dark:border-stroke-dark">
                    <p class="pb-9 text-base text-body">
                      Lorem ipsum dolor sit amet, consect adipiscing elit.
                      Pellentesque dignissim nisi a odio laoreet luctus. Ut sed
                      diam, quis bibendum ex.
                    </p>
                  </div>
                  <div class="items-center justify-between sm:flex lg:block xl:flex">
                    <div class="mb-4 flex items-center sm:mb-0 lg:mb-4 xl:mb-0">
                      <div class="mr-4 h-[56px] w-full max-w-[56px] rounded-full">
                        <img
                          src={atuh3}
                          alt="author"
                          class="h-full w-full object-cover object-center"
                        />
                      </div>
                      <div>
                        <h5 class="text-base font-medium text-black ">
                          Devid Miller
                        </h5>
                        <p class="text-sm text-body">App Developer</p>
                      </div>
                    </div>
                    <div class="flex items-center space-x-3 sm:justify-end lg:justify-start xl:justify-end">
                      <p class="text-base font-medium text-black ">5.0</p>
                      <div class="flex items-center space-x-[6px]">
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full px-6 lg:w-1/2">
                <div
                  class="wow fadeInUp mb-[50px] rounded-lg bg-white py-9 px-7 drop-shadow-lg sm:px-9 lg:px-7 xl:px-9"
                  data-wow-delay=".3s"
                  // style="visibility: visible; animation-delay: 0.3s;"
                >
                  <div class="mb-5 border-b border-stroke dark:border-stroke-dark">
                    <p class="pb-9 text-base text-body">
                      Lorem ipsum dolor sit amet, consect adipiscing elit.
                      Pellentesque dignissim nisi a odio laoreet luctus. Ut sed
                      diam, quis bibendum ex.
                    </p>
                  </div>
                  <div class="items-center justify-between sm:flex lg:block xl:flex">
                    <div class="mb-4 flex items-center sm:mb-0 lg:mb-4 xl:mb-0">
                      <div class="mr-4 h-[56px] w-full max-w-[56px] rounded-full">
                        <img
                          src={atuh4}
                          alt="author"
                          class="h-full w-full object-cover object-center"
                        />
                      </div>
                      <div>
                        <h5 class="text-base font-medium text-black ">
                          Justin Farnandes
                        </h5>
                        <p class="text-sm text-body">Seo Expert</p>
                      </div>
                    </div>
                    <div class="flex items-center space-x-3 sm:justify-end lg:justify-start xl:justify-end">
                      <p class="text-base font-medium text-black ">5.0</p>
                      <div class="flex items-center space-x-[6px]">
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_49_480)">
                              <path
                                d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                                fill="#EABF23"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_49_480">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.000488281)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* body 8 end testimonials*/}
        {/* body 9 start faq section  */}
        <section
          id="faq"
          class="relative z-10 bg-[#F8FAFB] py-[110px] flex flex-col justify-center items-center "
        >
          <div class="container  px-4">
            <div
              class="wow fadeInUp md:mx-auto mx-4 mb-14 max-w-[690px] text-center lg:mb-[70px]"
              data-wow-delay=".2s"
              // style="visibility: visible; animation-delay: 0.2s;"
            >
              <h2 class="mb-4 text-3xl font-bold text-slate-800  sm:text-4xl md:text-[44px] md:leading-tight">
                Frequently Asked Questions
              </h2>
              <p class="text-lg text-normal text-slate-400">
                Here are some of the most frequently asked questions from our
                clients
              </p>
            </div>

            <div className="faqs wow fadeInUp mx-auto  w-full max-w-[785px] rounded-lg bg-white px-6 py-[6px]">
              {[
                {
                  question: "What services do you provide?",
                  answer:
                    "Our innovative solution comprises of a mobile application with teacher and student module along with a web based dashboard for administration of the school.",
                },
                {
                  question: "How can I get started with your services?",
                  answer:
                    "You just need to contact us via the form givem below and soon our team will reach out to you for discussing the further process or information about the plans.",
                },
                {
                  question: "Do you offer ongoing support for your clients?",
                  answer:
                    "We offer on-call and email support to our clients. Also, the on-call support is dependent on the type of plan that your school is in.",
                },
              ].map((faq, index) => (
                <div
                  key={index}
                  className="faq border-b border-stroke last-of-type:border-none dark:border-stroke-dark "
                >
                  <button
                    className="faq-btn relative flex w-full justify-between py-6 px-[18px] text-left text-base font-medium text-black sm:px-[26px] sm:text-lg"
                    onClick={() => toggleFAQ(index)}
                  >
                    {faq.question}
                  </button>
                  <div
                    style={
                      visible === index
                        ? { ...faqStyle, ...visibleStyle }
                        : faqStyle
                    }
                    className={`faq-content h-auto overflow-hidden border-t border-stroke px-[18px] dark:border-stroke-dark sm:px-[26px] ${
                      visible === index ? "block" : "hidden"
                    }`}
                  >
                    <p className="text-base text-body">{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div class="absolute right-0 -top-24 -z-10">
            <svg
              width="95"
              height="190"
              viewBox="0 0 95 190"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="95"
                cy="95"
                r="77"
                stroke="url(#paint0_linear_49_603)"
                stroke-width="36"
              ></circle>
              <defs>
                <linearGradient
                  id="paint0_linear_49_603"
                  x1="0"
                  y1="0"
                  x2="224.623"
                  y2="130.324"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FF8FE8"></stop>
                  <stop offset="1" stop-color="#FFC960"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div class="absolute left-0 -bottom-24 -z-10">
            <svg
              width="95"
              height="190"
              viewBox="0 0 95 190"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cy="95"
                r="77"
                stroke="url(#paint0_linear_52_83)"
                stroke-width="36"
              ></circle>
              <defs>
                <linearGradient
                  id="paint0_linear_52_83"
                  x1="-117.84"
                  y1="190"
                  x2="117.828"
                  y2="25.9199"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8EA5FE"></stop>
                  <stop offset="0.541667" stop-color="#BEB3FD"></stop>
                  <stop offset="1" stop-color="#90D1FF"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
        </section>

        {/* body 9 end faq section  */}

        {/* body 10 end news aand blog start */}
        {/* <section
          id="blog"
          class="pt-[110px] pb-[60px] flex flex-col justify-center items-center"
        >
          <div class="container">
            <div
              class="wow fadeInUp mx-auto mb-14 max-w-[690px] text-center lg:mb-[70px]"
              data-wow-delay=".2s"
              // style="visibility: visible; animation-delay: 0.2s;"
            >
              <h2 class="mb-4 text-3xl font-bold text-black sm:text-4xl md:text-[44px] md:leading-tight">
                Latest News &amp; Blogs
              </h2>
              <p class="text-base text-body">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                convallis tortor eros. Donec vitae tortor lacus. Phasellus
                aliquam ante in maximus.
              </p>
            </div>
          </div>
          <div class="container overflow-hidden lg:max-w-[1250px]">
            <div class="md:mx-auto mx-4 flex flex-wrap justify-center   ">
              <div class="w-full px-4 md:w-1/2 md:px-7 lg:w-1/3 lg:px-5 ">
                <div
                  class="wow fadeInUp mb-10"
                  data-wow-delay=".2s"
                  // style="visibility: visible; animation-delay: 0.2s;"
                >
                  <div class="relative overflow-hidden rounded">
                    <img
                      src={blog1}
                      alt="blog post"
                      class="w-full object-cover object-center"
                    />
                    <div class="absolute top-0 left-0 flex h-full w-full items-end bg-gradient-3 px-[18px] py-4">
                      <div class="-mx-[10px] flex flex-wrap">
                        <p class="mt-3 flex items-center px-[10px] text-base font-medium text-white">
                          <span class="mr-2">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_52_46)">
                                <path
                                  d="M15 16.5H13.5V15C13.5 14.4033 13.2629 13.831 12.841 13.409C12.419 12.9871 11.8467 12.75 11.25 12.75H6.75C6.15326 12.75 5.58097 12.9871 5.15901 13.409C4.73705 13.831 4.5 14.4033 4.5 15V16.5H3V15C3 14.0054 3.39509 13.0516 4.09835 12.3484C4.80161 11.6451 5.75544 11.25 6.75 11.25H11.25C12.2446 11.25 13.1984 11.6451 13.9016 12.3484C14.6049 13.0516 15 14.0054 15 15V16.5ZM9 9.75C8.40905 9.75 7.82389 9.63361 7.27792 9.40746C6.73196 9.18131 6.23588 8.84984 5.81802 8.43198C5.40015 8.01412 5.06869 7.51804 4.84254 6.97208C4.6164 6.42611 4.5 5.84095 4.5 5.25C4.5 4.65905 4.6164 4.07389 4.84254 3.52793C5.06869 2.98196 5.40015 2.48588 5.81802 2.06802C6.23588 1.65016 6.73196 1.31869 7.27792 1.09254C7.82389 0.866396 8.40905 0.75 9 0.75C10.1935 0.75 11.3381 1.22411 12.182 2.06802C13.0259 2.91193 13.5 4.05653 13.5 5.25C13.5 6.44348 13.0259 7.58807 12.182 8.43198C11.3381 9.2759 10.1935 9.75 9 9.75ZM9 8.25C9.79565 8.25 10.5587 7.93393 11.1213 7.37132C11.6839 6.80871 12 6.04565 12 5.25C12 4.45435 11.6839 3.69129 11.1213 3.12868C10.5587 2.56607 9.79565 2.25 9 2.25C8.20435 2.25 7.44129 2.56607 6.87868 3.12868C6.31607 3.69129 6 4.45435 6 5.25C6 6.04565 6.31607 6.80871 6.87868 7.37132C7.44129 7.93393 8.20435 8.25 9 8.25Z"
                                  fill="white"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_52_46">
                                  <rect
                                    width="18"
                                    height="18"
                                    fill="white"
                                  ></rect>
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          Musharof Chy
                        </p>
                        <p class="mt-3 flex items-center px-[10px] text-base font-medium text-white">
                          <span class="mr-2">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_52_51)">
                                <path
                                  d="M12.75 2.25H15.75C15.9489 2.25 16.1397 2.32902 16.2803 2.46967C16.421 2.61032 16.5 2.80109 16.5 3V15C16.5 15.1989 16.421 15.3897 16.2803 15.5303C16.1397 15.671 15.9489 15.75 15.75 15.75H2.25C2.05109 15.75 1.86032 15.671 1.71967 15.5303C1.57902 15.3897 1.5 15.1989 1.5 15V3C1.5 2.80109 1.57902 2.61032 1.71967 2.46967C1.86032 2.32902 2.05109 2.25 2.25 2.25H5.25V0.75H6.75V2.25H11.25V0.75H12.75V2.25ZM11.25 3.75H6.75V5.25H5.25V3.75H3V6.75H15V3.75H12.75V5.25H11.25V3.75ZM15 8.25H3V14.25H15V8.25Z"
                                  fill="white"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_52_51">
                                  <rect
                                    width="18"
                                    height="18"
                                    fill="white"
                                  ></rect>
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          19 Mar, 2025
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-[30px]">
                    <h3>
                      <a
                        href="blog-details.html"
                        class="mb-3 inline-block text-xl font-semibold text-black  sm:text-2xl lg:text-xl xl:text-2xl"
                        previewlistener="true"
                      >
                        Free advertising for your online business
                      </a>
                    </h3>
                    <p class="text-base text-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum sed felis id tortor.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full px-4 md:w-1/2 md:px-7 lg:w-1/3 lg:px-5 xl:px-[35px]">
                <div
                  class="wow fadeInUp mb-10"
                  data-wow-delay=".3s"
                  // style="visibility: visible; animation-delay: 0.3s;"
                >
                  <div class="relative overflow-hidden rounded">
                    <img
                      src={blog2}
                      alt="blog post"
                      class="w-full object-cover object-center"
                    />
                    <div class="absolute top-0 left-0 flex h-full w-full items-end bg-gradient-3 px-[18px] py-4">
                      <div class="-mx-[10px] flex flex-wrap">
                        <p class="mt-3 flex items-center px-[10px] text-base font-medium text-white">
                          <span class="mr-2">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_52_46)">
                                <path
                                  d="M15 16.5H13.5V15C13.5 14.4033 13.2629 13.831 12.841 13.409C12.419 12.9871 11.8467 12.75 11.25 12.75H6.75C6.15326 12.75 5.58097 12.9871 5.15901 13.409C4.73705 13.831 4.5 14.4033 4.5 15V16.5H3V15C3 14.0054 3.39509 13.0516 4.09835 12.3484C4.80161 11.6451 5.75544 11.25 6.75 11.25H11.25C12.2446 11.25 13.1984 11.6451 13.9016 12.3484C14.6049 13.0516 15 14.0054 15 15V16.5ZM9 9.75C8.40905 9.75 7.82389 9.63361 7.27792 9.40746C6.73196 9.18131 6.23588 8.84984 5.81802 8.43198C5.40015 8.01412 5.06869 7.51804 4.84254 6.97208C4.6164 6.42611 4.5 5.84095 4.5 5.25C4.5 4.65905 4.6164 4.07389 4.84254 3.52793C5.06869 2.98196 5.40015 2.48588 5.81802 2.06802C6.23588 1.65016 6.73196 1.31869 7.27792 1.09254C7.82389 0.866396 8.40905 0.75 9 0.75C10.1935 0.75 11.3381 1.22411 12.182 2.06802C13.0259 2.91193 13.5 4.05653 13.5 5.25C13.5 6.44348 13.0259 7.58807 12.182 8.43198C11.3381 9.2759 10.1935 9.75 9 9.75ZM9 8.25C9.79565 8.25 10.5587 7.93393 11.1213 7.37132C11.6839 6.80871 12 6.04565 12 5.25C12 4.45435 11.6839 3.69129 11.1213 3.12868C10.5587 2.56607 9.79565 2.25 9 2.25C8.20435 2.25 7.44129 2.56607 6.87868 3.12868C6.31607 3.69129 6 4.45435 6 5.25C6 6.04565 6.31607 6.80871 6.87868 7.37132C7.44129 7.93393 8.20435 8.25 9 8.25Z"
                                  fill="white"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_52_46">
                                  <rect
                                    width="18"
                                    height="18"
                                    fill="white"
                                  ></rect>
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          Devid Jhoie
                        </p>
                        <p class="mt-3 flex items-center px-[10px] text-base font-medium text-white">
                          <span class="mr-2">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_52_51)">
                                <path
                                  d="M12.75 2.25H15.75C15.9489 2.25 16.1397 2.32902 16.2803 2.46967C16.421 2.61032 16.5 2.80109 16.5 3V15C16.5 15.1989 16.421 15.3897 16.2803 15.5303C16.1397 15.671 15.9489 15.75 15.75 15.75H2.25C2.05109 15.75 1.86032 15.671 1.71967 15.5303C1.57902 15.3897 1.5 15.1989 1.5 15V3C1.5 2.80109 1.57902 2.61032 1.71967 2.46967C1.86032 2.32902 2.05109 2.25 2.25 2.25H5.25V0.75H6.75V2.25H11.25V0.75H12.75V2.25ZM11.25 3.75H6.75V5.25H5.25V3.75H3V6.75H15V3.75H12.75V5.25H11.25V3.75ZM15 8.25H3V14.25H15V8.25Z"
                                  fill="white"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_52_51">
                                  <rect
                                    width="18"
                                    height="18"
                                    fill="white"
                                  ></rect>
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          25 Feb, 2025
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-[30px]">
                    <h3>
                      <a
                        href="blog-details.html"
                        class="mb-3 inline-block text-xl font-semibold text-black  sm:text-2xl lg:text-xl xl:text-2xl"
                        previewlistener="true"
                      >
                        9 simple ways to improve your design skills
                      </a>
                    </h3>
                    <p class="text-base text-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum sed felis id tortor.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full px-4 md:w-1/2 md:px-7 lg:w-1/3 lg:px-5 xl:px-[35px]">
                <div
                  class="wow fadeInUp mb-10"
                  data-wow-delay=".4s"
                  // style="visibility: visible; animation-delay: 0.4s;"
                >
                  <div class="relative overflow-hidden rounded">
                    <img
                      src={blog3}
                      alt="blog post"
                      class="w-full object-cover object-center"
                    />
                    <div class="absolute top-0 left-0 flex h-full w-full items-end bg-gradient-3 px-[18px] py-4">
                      <div class="-mx-[10px] flex flex-wrap">
                        <p class="mt-3 flex items-center px-[10px] text-base font-medium text-white">
                          <span class="mr-2">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_52_46)">
                                <path
                                  d="M15 16.5H13.5V15C13.5 14.4033 13.2629 13.831 12.841 13.409C12.419 12.9871 11.8467 12.75 11.25 12.75H6.75C6.15326 12.75 5.58097 12.9871 5.15901 13.409C4.73705 13.831 4.5 14.4033 4.5 15V16.5H3V15C3 14.0054 3.39509 13.0516 4.09835 12.3484C4.80161 11.6451 5.75544 11.25 6.75 11.25H11.25C12.2446 11.25 13.1984 11.6451 13.9016 12.3484C14.6049 13.0516 15 14.0054 15 15V16.5ZM9 9.75C8.40905 9.75 7.82389 9.63361 7.27792 9.40746C6.73196 9.18131 6.23588 8.84984 5.81802 8.43198C5.40015 8.01412 5.06869 7.51804 4.84254 6.97208C4.6164 6.42611 4.5 5.84095 4.5 5.25C4.5 4.65905 4.6164 4.07389 4.84254 3.52793C5.06869 2.98196 5.40015 2.48588 5.81802 2.06802C6.23588 1.65016 6.73196 1.31869 7.27792 1.09254C7.82389 0.866396 8.40905 0.75 9 0.75C10.1935 0.75 11.3381 1.22411 12.182 2.06802C13.0259 2.91193 13.5 4.05653 13.5 5.25C13.5 6.44348 13.0259 7.58807 12.182 8.43198C11.3381 9.2759 10.1935 9.75 9 9.75ZM9 8.25C9.79565 8.25 10.5587 7.93393 11.1213 7.37132C11.6839 6.80871 12 6.04565 12 5.25C12 4.45435 11.6839 3.69129 11.1213 3.12868C10.5587 2.56607 9.79565 2.25 9 2.25C8.20435 2.25 7.44129 2.56607 6.87868 3.12868C6.31607 3.69129 6 4.45435 6 5.25C6 6.04565 6.31607 6.80871 6.87868 7.37132C7.44129 7.93393 8.20435 8.25 9 8.25Z"
                                  fill="white"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_52_46">
                                  <rect
                                    width="18"
                                    height="18"
                                    fill="white"
                                  ></rect>
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          Andro Smith
                        </p>
                        <p class="mt-3 flex items-center px-[10px] text-base font-medium text-white">
                          <span class="mr-2">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_52_51)">
                                <path
                                  d="M12.75 2.25H15.75C15.9489 2.25 16.1397 2.32902 16.2803 2.46967C16.421 2.61032 16.5 2.80109 16.5 3V15C16.5 15.1989 16.421 15.3897 16.2803 15.5303C16.1397 15.671 15.9489 15.75 15.75 15.75H2.25C2.05109 15.75 1.86032 15.671 1.71967 15.5303C1.57902 15.3897 1.5 15.1989 1.5 15V3C1.5 2.80109 1.57902 2.61032 1.71967 2.46967C1.86032 2.32902 2.05109 2.25 2.25 2.25H5.25V0.75H6.75V2.25H11.25V0.75H12.75V2.25ZM11.25 3.75H6.75V5.25H5.25V3.75H3V6.75H15V3.75H12.75V5.25H11.25V3.75ZM15 8.25H3V14.25H15V8.25Z"
                                  fill="white"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_52_51">
                                  <rect
                                    width="18"
                                    height="18"
                                    fill="white"
                                  ></rect>
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          05 Jan, 2025
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-[30px]">
                    <h3>
                      <a
                        href="blog-details.html"
                        class="mb-3 inline-block text-xl font-semibold text-black  sm:text-2xl lg:text-xl xl:text-2xl"
                        previewlistener="true"
                      >
                        Tips to quickly improve your coding speed.
                      </a>
                    </h3>
                    <p class="text-base text-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum sed felis id tortor.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* body 10 end news aand blog end */}

        {/* contact us from start */}
        <section
          id="support"
          class="pt-[100px] pb-[110px] flex flex-col justify-center items-center"
        >
          <div class="container ">
            <div
              class="wow fadeInUp md:mx-auto mx-4 mb-10 max-w-[690px] text-center"
              data-wow-delay=".2s"
              // style="visibility: visible; animation-delay: 0.2s;"
            >
              <h2 class="mb-4 text-3xl font-bold text-black sm:text-4xl md:text-[44px] md:leading-tight">
                Let's Stay Connected
              </h2>
              <p class="text-base text-body">
                Fill out the form below to reach out to us! We will be very
                happy to answer your queries and have meaningful discussions
                with you.
              </p>
            </div>
          </div>
          <div class="container md:px-auto px-4">
            <div
              class="wow fadeInUp mx-auto w-full max-w-[925px] rounded-lg bg-[#F8FAFB] px-8 py-10 drop-shadow-lg sm:px-10"
              data-wow-delay=".3s"
              // style="visibility: visible; animation-delay: 0.3s;"
            >
              <form action="https://formspree.io/f/myyrgank" method="POST">
                <div class="-mx-[22px] flex flex-wrap">
                  <div class="w-full px-[22px] md:w-1/2">
                    <div class="mb-8">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter your name"
                        class="w-full rounded border border-stroke bg-white py-4 px-[30px] text-base text-body outline-none focus:border-primary "
                      />
                    </div>
                  </div>
                  <div class="w-full px-[22px] md:w-1/2">
                    <div class="mb-8">
                      <input
                        type="text"
                        name="School Name"
                        id="school"
                        placeholder="School Name"
                        class="w-full rounded border border-stroke bg-white py-4 px-[30px] text-base text-body outline-none focus:border-primary "
                      />
                    </div>
                  </div>
                  <div class="w-full px-[22px] md:w-1/2">
                    <div class="mb-8">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter Your email"
                        class="w-full rounded border border-stroke bg-white py-4 px-[30px] text-base text-body outline-none focus:border-primary "
                      />
                    </div>
                  </div>
                  <div class="w-full px-[22px] md:w-1/2">
                    <div class="mb-8">
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Enter your Phone Number"
                        class="w-full rounded border border-stroke bg-white py-4 px-[30px] text-base text-body outline-none focus:border-primary "
                      />
                    </div>
                  </div>
                  <div class="w-full px-[22px]">
                    <div class="mb-8">
                      <textarea
                        rows="6"
                        name="message"
                        id="message"
                        placeholder="Kindly let us know how we can help you"
                        class="w-full rounded border border-stroke bg-white py-4 px-[30px] text-base text-body outline-none focus:border-primary "
                      ></textarea>
                    </div>
                  </div>
                  <div class="w-full px-[22px]">
                    <div class="text-center">
                      <p class="mb-5 text-center text-base text-body">
                        By clicking contact us button, you agree our terms and
                        policy,
                      </p>
                      <button
                        type="submit"
                        class="inline-block rounded-md bg-blue-500 py-[14px] px-11 text-base font-medium text-white hover:bg-opacity-90"
                      >
                        Contact Us
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        {/* contact us from end */}

        {/* Footer */}
        <section className="py-10 bg-gray-50 sm:pt-16 lg:pt-24">
          <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div className="flex flex-row flex-wrap gap-y-16 gap-x-12 w-full justify-center">
              <div className="flex flex-col lg:pr-8 w-full min-w-[200px] max-w-[550px]">
                <img
                  className=" object-fit h-auto w-36"
                  src={logowithtext}
                  alt=""
                />

                <p className="text-base leading-relaxed text-gray-600 mt-7">
                  Ed Sanchaar: Bridging the gap between schools, teachers, and
                  parents for a collaborative and enriched educational journey.
                  Empowering every stakeholder with seamless communication and
                  intelligent insights.
                </p>

                <ul className="flex items-center space-x-3 mt-9">
                  <li>
                    <a
                      href="#"
                      title=""
                      className="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-blue-600 focus:bg-blue-600"
                    >
                      <svg
                        className="w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"></path>
                      </svg>
                    </a>
                  </li>

                  <li>
                    <a
                      href="#"
                      title=""
                      className="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-blue-600 focus:bg-blue-600"
                    >
                      <svg
                        className="w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                      </svg>
                    </a>
                  </li>

                  <li>
                    <a
                      href="#"
                      title=""
                      className="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-blue-600 focus:bg-blue-600"
                    >
                      <svg
                        className="w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path>
                        <circle cx="16.806" cy="7.207" r="1.078"></circle>
                        <path d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"></path>
                      </svg>
                    </a>
                  </li>

                  <li>
                    <a
                      href="#"
                      title=""
                      className="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-blue-600 focus:bg-blue-600"
                    >
                      <svg
                        className="w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.026 2c-5.509 0-9.974 4.465-9.974 9.974 0 4.406 2.857 8.145 6.821 9.465.499.09.679-.217.679-.481 0-.237-.008-.865-.011-1.696-2.775.602-3.361-1.338-3.361-1.338-.452-1.152-1.107-1.459-1.107-1.459-.905-.619.069-.605.069-.605 1.002.07 1.527 1.028 1.527 1.028.89 1.524 2.336 1.084 2.902.829.091-.645.351-1.085.635-1.334-2.214-.251-4.542-1.107-4.542-4.93 0-1.087.389-1.979 1.024-2.675-.101-.253-.446-1.268.099-2.64 0 0 .837-.269 2.742 1.021a9.582 9.582 0 0 1 2.496-.336 9.554 9.554 0 0 1 2.496.336c1.906-1.291 2.742-1.021 2.742-1.021.545 1.372.203 2.387.099 2.64.64.696 1.024 1.587 1.024 2.675 0 3.833-2.33 4.675-4.552 4.922.355.308.675.916.675 1.846 0 1.334-.012 2.41-.012 2.737 0 .267.178.577.687.479C19.146 20.115 22 16.379 22 11.974 22 6.465 17.535 2 12.026 2z"
                        ></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="flex flex-row justify-between w-full min-w-[200px] max-w-[550px]">
                <div>
                  <p className="text-sm font-semibold tracking-widest text-gray-400 uppercase">
                    Company
                  </p>

                  <ul className="mt-6 space-y-4">
                    <li>
                      <a
                        href="#home"
                        title=""
                        className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                      >
                        {" "}
                        About{" "}
                      </a>
                    </li>

                    <li>
                      <a
                        href="#features"
                        title=""
                        className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                      >
                        {" "}
                        Features{" "}
                      </a>
                    </li>

                    {/* <li>
                      <a
                        href="#"
                        title=""
                        className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                      >
                        {" "}
                        Works{" "}
                      </a>
                    </li> */}

                    {/* <li>
                      <a
                        href="#"
                        title=""
                        className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                      >
                        {" "}
                        Career{" "}
                      </a>
                    </li> */}
                  </ul>
                </div>

                <div>
                  <p className="text-sm font-semibold tracking-widest text-gray-400 uppercase">
                    Help
                  </p>

                  <ul className="mt-6 space-y-4">
                    {/* <li>
                      <a
                        href="#"
                        title=""
                        className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                      >
                        {" "}
                        Customer Support{" "}
                      </a>
                    </li> */}

                    <li>
                      <Link
                        to="/termsandconditions"
                        title=""
                        className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                      >
                        Terms & Conditions{" "}
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/privacyandcookiepolicy"
                        title=""
                        className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <hr className="mt-16 mb-10 border-gray-200" />

            <p className="text-sm text-center text-gray-600">
              © Copyright 2023, All Rights Reserved by Ed Sanchaar
            </p>
          </div>
        </section>
      </main>

      {/* End footer */}
    </>
  );
}
export default ResponsiveAppBar;
