import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import Cookies from "js-cookie";
import StudentMarksheetTable from "./StudentMarksheetData";

const StudentMarksheetDownload = () => {
  const [username, setUsername] = useState("grdak11");
  const [results, setResults] = useState([]);
  const [existingMarksheetData, setExistingMarksheetData] = useState("");
  const [existingUserData, setExistingUserData] = useState("");
  const [resultExists, setResultExists] = useState(false);

  const [selectedResult, setSelectedResult] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [examTypes, setExamTypes] = useState([]);
  const [resultIdExamTypePair, setResultIdExamTypePair] = useState([]);

  const handleResultSelect = (e) => {
    setSelectedResult(e);
  };
  useEffect(() => {
    // console.log(existingMarksheetData);
  }, [existingMarksheetData]);

  const fetchAvailableResults = async () => {
    const apiUrl = `https://edsanchaar.in/api/api_admin/MarksheetGeneration/StudentSection/getAvailableResultInfo.php?username=${username}&school_id=${schoolId}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
        },
      });

      const data = response.data;
      // console.log(data);

      if (Array.isArray(data)) {
        // Assuming that the response is already an array

        // Extracting ExamType values and creating the pair of result_id and ExamType
        const extractedExamTypes = data.map((result) => result.ExamType);
        const extractedResultIdExamTypePair = data.map((result) => ({
          result_id: result.result_id,
          ExamType: result.ExamType,
        }));

        setExamTypes(extractedExamTypes);
        setResultIdExamTypePair(extractedResultIdExamTypePair);
      } else {
        console.error("Invalid response format. Expected an array.");
      }
    } catch (error) {
      console.error("Error fetching class IDs:", error);
    } finally {
      setSelectedResult("");
    }
  };

  useEffect(() => {
    // console.log(selectedResult);

    const fetchMarksheetData = async () => {
      try {
        const response = await fetch(
          `https://edsanchaar.in/api/api_admin/MarksheetGeneration/StudentSection/getMarksheetData.php?school_id=${schoolId}&result_id=${selectedResult}&username=${username}`,
          {
            method: "GET",
            headers: {
              authorization: "b1sgVwwszjQM0iq6viyqYNrUzX3uaLWTUnkaOrq3OX0",
            },
          }
        );

        const data = await response.json();

        if (data.exists) {
          setResultExists(true);
        } else {
          setResultExists(false);
        }

        if (data.data) {
          const existingData = data.data;
          const userData = data.userData;
          setExistingUserData(userData);
          setExistingMarksheetData(existingData);
        } else {
          setExistingMarksheetData("");
        }
      } catch (error) {
        console.error("Error fetching result data:", error);
      } finally {
        // console.log(existingMarksheetData);
      }
    };
    fetchMarksheetData(selectedResult);
  }, [selectedResult]);

  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");
    setSchoolId(schoolIdFromCookie);
  }, []);

  return (
    <div className="w-full h-screen flex items-center flex-col overflow-auto justify-center ">
      <Toaster />
      <div className=" h-[90vh] w-[95.5vw] rounded-3xl ">
        <div className="text-center font-medium text-3xl">
          StudentMarksheetDownload{" "}
        </div>
        <div className="h-20 mt-4 w-full mb-6 flex flex-row items-center justify-evenly">
          <div className="w-fit mb-15 flex flex-row flex-wrap items-center">
            <label className="block font-bold mr-1" htmlFor="class_id">
              Username:{" "}
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </div>
          <div className="">
            <button
              className={`${
                username === ""
                  ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                  : "bg-blue-500 text-white"
              } px-4 py-2 rounded`}
              onClick={fetchAvailableResults}
              disabled={username === ""}
            >
              Get Available Results
            </button>
          </div>
          <div className="w-fit mb-15 flex flex-row flex-wrap items-center">
            <label className="block font-bold mr-1" htmlFor="result">
              Result:{" "}
            </label>
            <select
              name="result"
              id="result"
              className="w-fit rounded py-2"
              required
              value={selectedResult}
              onChange={(e) => {
                handleResultSelect(e.target.value);
              }}
            >
              <option value="" disabled hidden>
                Select Result
              </option>
              {examTypes.map((examType, index) => (
                <option
                  key={index}
                  value={resultIdExamTypePair[index].result_id}
                >
                  {examType}
                </option>
              ))}
            </select>
          </div>
        </div>
        {existingMarksheetData != "" && resultExists == true && (
          <div className="mt-6 mb-6">
            <h2 className="text-xl text-center font-semibold mb-2">
              {selectedResult}Marksheet
            </h2>
            <StudentMarksheetTable
              existingMarksheetData={existingMarksheetData}
              userData={existingUserData}
              schoolId={schoolId}
              resultId={selectedResult}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentMarksheetDownload;
