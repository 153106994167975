import React, { useState, useEffect } from "react";
import { Toolbar, Paper, Grid, Typography, Box } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import CircularProgress from "@mui/material/CircularProgress";
import toast, { Toaster } from "react-hot-toast";
import Modal from "@mui/material/Modal";
import imageCompression from "browser-image-compression";

import Fade from "@mui/material/Fade";
// ... (previous imports)

const EventManager = () => {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(10) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const [school_id, setSchoolId] = useState("");
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState(null);

  const [newEvent, setNewEvent] = useState({
    title: "",
    description: "",
    date: "",
  });
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    eventId: null,
  });
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  const openUpdateEventModal = (eventId) => {
    const selectedEventToUpdate = events.find((event) => event.id === eventId);

    setNewEvent({
      id: eventId,
      title: selectedEventToUpdate.title,
      description: selectedEventToUpdate.description,
      date: selectedEventToUpdate.date,
    });

    setSelectedEvent(selectedEventToUpdate); // Update selectedEvent immediately

    setUpdateModalOpen(true);
  };

  const closeUpdateEventModal = () => {
    setUpdateModalOpen(false);
  };

  const isFormDataValid = () => {
    // Implement your form data validation logic here
    return (
      newEvent.title.trim() !== "" &&
      newEvent.description.trim() !== "" &&
      newEvent.date.trim() !== "" &&
      newEvent.image !== undefined
    );
  };

  const handleUpdateEvent = async () => {
    try {
      // Check if selectedEvent is not null
      if (selectedEvent) {
        const updatedEventData = {
          id: selectedEvent.id,
          title: newEvent.title,
          description: newEvent.description,
        };

        const response = await fetch(
          `https://edsanchaar.in/api/api_admin/Events/updateEvent.php?school_id=${school_id}`,
          {
            method: "POST",
            headers: {
              Authorization: "ZVE2VQFin3of3TLkvn76Ibm3ntAkOz4SaqD3Da/2IUW3",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedEventData),
          }
        );

        if (response.ok) {
          toast.success("Event updated successfully");

          // Update the events state with the updated data
          setEvents((prevEvents) =>
            prevEvents.map((event) =>
              event.id === selectedEvent.id
                ? { ...event, ...updatedEventData }
                : event
            )
          );

          // Close the update modal
          closeUpdateEventModal();
        } else {
          const errorData = await response.json();
          console.error("Failed to update event:", errorData.message);
          toast.error("Failed to update event");
        }
      } else {
        console.error("Selected event is null");
        toast.error("Selected event is null");
      }
    } catch (error) {
      console.error("Error updating event:", error);
      toast.error("Error updating event");
    }
  };

  const openDeleteConfirmation = (eventId) => {
    setDeleteConfirmation({
      isOpen: true,
      eventId,
    });
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmation({
      isOpen: false,
      eventId: null,
    });
  };

  const handleDeleteConfirmation = async () => {
    if (deleteConfirmation.eventId) {
      await handleDeleteEvent(deleteConfirmation.eventId);
    }
    closeDeleteConfirmation();
  };

  const handleInputChange = (e) => {
    setNewEvent({
      ...newEvent,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setNewEvent({
      ...newEvent,
      image: e.target.files[0], // Change "banner" to "image"
    });
  };
  
  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      // Redirect to login or handle accordingly if schoolId is not available
      // You may want to add additional handling, such as showing an error message
      console.error("School ID not available");
      return;
    }

    // Set the school_id in the state
    setSchoolId(schoolIdFromCookie);
  }, []);
  const handleDeleteEvent = async (eventId) => {
    try {
      const response = await fetch(
        `https://edsanchaar.in/api/api_admin/Events/deleteEvent.php?id=${eventId}&school_id=${school_id}`,
        {
          method: "POST",
          headers: {
            Authorization: "ZVE2VQFin3of3TLkvn76Ibm3ntAkOz4SaqD3Da/2IUW3",
            // Add other headers if needed
          },
        }
      );

      if (response.ok) {
        toast.success("Event Deleted Successfully");

        setEvents((prevEvents) =>
          prevEvents.filter((event) => event.id !== eventId)
        );
      } else {
        const errorData = await response.json();
        console.error("Failed to delete event:", errorData.message);
        toast.error("Failed to delete event");
      }
    } catch (error) {
      console.error("Error deleting event:", error);
      toast.error("Error deleting event");
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("title", newEvent.title);
      formData.append("description", newEvent.description);
      formData.append("date", newEvent.date);
      formData.append("school_id", school_id);

      if (newEvent.image) {
        // Compress the image before appending it to formData
        const compressedImage = await compressImage(newEvent.image);
        formData.append("image", compressedImage);
      }

      await fetch("https://edsanchaar.in/api/api_admin/Events/addEvent.php", {
        method: "POST",
        headers: {
          Authorization: "ZVE2VQFin3of3TLkvn76Ibm3ntAkOz4SaqD3Da/2IUW3",
        },
        body: formData,
      });

      toast.success("Event added successfully");

      // Reset the form after successful submit
      setNewEvent({
        title: "",
        description: "",
        date: "",
      });

      // Clear the file input by manipulating the input element directly
      const fileInput = document.getElementById("banner");
      if (fileInput) {
        fileInput.value = ""; // Reset the file input
      }

      handleClose();
      fetchSchoolEvents();
    } catch (error) {
      if (error.message === "Only JPG, JPEG, PNG files are allowed.") {
        toast.error(error.message);
      } else {
        toast.error("Error adding event");
      }
    }
  };

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 0.5, // Adjust the size limit as needed
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);

      // Add the file extension to the compressed file
      const fileNameWithExtension = `${file.name.replace(/\.[^/.]+$/, "")}.jpg`;
      const compressedFileWithExtension = new File(
        [compressedFile],
        fileNameWithExtension,
        { type: "image/jpeg" }
      );

      return compressedFileWithExtension;
    } catch (error) {
      if (error.message === "Only JPG, JPEG, PNG files are allowed.") {
        toast.error(error.message);
      } else {
        toast.error("Error compressing image");
      }
    }
  };

  const fetchSchoolEvents = async () => {
    try {
      const response = await axios.get(
        `https://edsanchaar.in/api/api_admin/Events/fetchSchoolEvents.php?school_id=${school_id}`,
        {
          headers: {
            Authorization: "ZVE2VQFin3of3TLkvn76Ibm3ntAkOz4SaqD3Da/2IUW3",
          },
        }
      );
      setEvents(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleOpen = (notice) => {
    setSelectedEvent(notice);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedEvent(null);
  };
  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      console.error("School ID not available");
      return;
    }

    setSchoolId(schoolIdFromCookie);
  }, []);

  useEffect(() => {
    if (school_id !== "") {
      fetchSchoolEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [school_id]);

  return (
    <div className="flex items-center justify-center w-full h-full py-10 bg-white">
      <Toaster position="top-center" reverseOrder={true} />
      <Toolbar
        sx={{
          MinHeight: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            width: "95.5%",
            height: "90vh",
          }}
        >
          <div className="h-full w-full flex flex-row">
            <div className="w-3/4 overflow-y-auto">
              <div className="flex items-center justify-center">
                <div className="text-2xl font-medium">School Events</div>
              </div>
              {loading ? (
                <div className=" h-full flex justify-center items-center">
                  <CircularProgress size={60} />
                </div>
              ) : (
                <div className="w-full flex flex-row flex-wrap flex-grow py-2 ">
                  {events.length > 0 ? (
                    events.map((events) => (
                      <div className=" pb-2 pl-2 w-[33%] " key={events.id}>
                        <div
                          className="bg-white p-3 rounded-t-md min-h-[150px] shadow-lg"
                          onClick={() => handleOpen(events)}
                        >
                          <Typography variant="h6">{events.subject}</Typography>
                          <div className="text-lg font-medium">
                            {events.title.substring(0, 30)}
                          </div>
                          <div className="text-md font-normal">
                            {events.description.substring(0, 100)} {"...."}
                          </div>
                          <div>
                            <img
                              src={`https://edsanchaar.in/api/api_admin/Events/${events?.banner}`}
                              className="object-fill h-38 w-96"
                              alt=""
                            />
                          </div>
                          <Typography>Date: {events.date}</Typography>
                        </div>
                        <div className="flex flex-row justify-evenly bg-white pb-3 rounded-b-md">
                          <button
                            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-400 md:text-sm lg:text-base xl:text-lg"
                            onClick={() => openDeleteConfirmation(events.id)}
                          >
                            Delete Event
                          </button>

                          <button
                          
                            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 md:text-sm lg:text-base xl:text-lg"
                            onClick={() => openUpdateEventModal(events.id)}
                          >
                            Update Event
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <Grid item>
                      <Typography>No events available</Typography>
                    </Grid>
                  )}
                  <Dialog
                    open={deleteConfirmation.isOpen}
                    onClose={closeDeleteConfirmation}
                  >
                    <DialogTitle>Delete Confirmation</DialogTitle>
                    <DialogContent>
                      <p>Do you want to delete this event?</p>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={closeDeleteConfirmation}>Cancel</Button>
                      <Button
                        onClick={handleDeleteConfirmation}
                        variant="contained"
                        color="error"
                      >
                        Confirm Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              )}
            </div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
            >
              <Fade in={open}>
                <Box
                  sx={{
                    borderRadius: "8px 8px 8px 8px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    maxWidth: 500,
                  }}
                >
                  <Typography variant="h5" component="h2">
                    {selectedEvent?.title}
                  </Typography>
                  <div>
                    <img
                      src={`https://edsanchaar.in/api/api_admin/Events/${selectedEvent?.banner}`}
                      alt=""
                    />
                  </div>
                  <Typography sx={{ mt: 2 }}>
                    {selectedEvent?.description}
                  </Typography>
                </Box>
              </Fade>
            </Modal>
            <div className="h-full w-1/4 mx-2 p-2 pb-6">
              <div className="flex items-center justify-center">
                <div className="text-2xl font-medium">Add Event</div>
              </div>
              <div className="rounded-lg shadow-xl bg-white h-full p-4 ">
                <form onSubmit={handleFormSubmit}>
                  <div className="mb-3">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Title:
                    </label>
                    <input
                      type="text"
                      id="title"
                      name="title"
                      value={newEvent.title} // Add value attribute
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Description:
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      rows="4"
                      value={newEvent.description} // Add value attribute
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="banner"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Banner Image:
                    </label>
                    <input
                      type="file"
                      id="banner"
                      name="image"
                      accept="image/*"
                      // value="" // Add value attribute
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      onChange={handleFileChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Date:
                    </label>
                    <input
                      type="date"
                      id="date"
                      name="date"
                      value={newEvent.date} // Add value attribute
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex items-center justify-center">
                    <button
                      type="submit"
                      className={`bg-blue-500 text-white px-4 py-2 rounded ${
                        !isFormDataValid()
                          ? "cursor-not-allowed opacity-50"
                          : ""
                      }`}
                      disabled={!isFormDataValid()} // Disable the button if form data is not valid
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {updateModalOpen && (
              <Modal
                aria-labelledby="update-event-modal-title"
                aria-describedby="update-event-modal-description"
                open={updateModalOpen}
                onClose={closeUpdateEventModal}
                closeAfterTransition
              >
                <Fade in={updateModalOpen}>
                  <Box
                    sx={{
                      borderRadius: "8px 8px 8px 8px",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                      maxWidth: 500,
                    }}
                  >
                    <Typography variant="h5" component="h2">
                      Update Event
                    </Typography>
                    <div className="mb-3">
                      <label
                        htmlFor="update-title"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Title:
                      </label>
                      <input
                        type="text"
                        id="update-title"
                        name="title"
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                        value={newEvent.title}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="update-description"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Description:
                      </label>
                      <textarea
                        id="update-description"
                        name="description"
                        rows="4"
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                        value={newEvent.description}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                    <div className="flex items-center justify-center">
                      <button
                        type="button"
                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                        onClick={handleUpdateEvent}
                      >
                        Update
                      </button>

                      <button
                        type="button"
                        className="px-4 py-2 ml-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
                        onClick={closeUpdateEventModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </Box>
                </Fade>
              </Modal>
            )}
          </div>
        </Paper>
      </Toolbar>
    </div>
  );
};

export default EventManager;
