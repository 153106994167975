import React, { useState, useEffect } from "react";
import { jsPDF } from "jspdf";
import { useNavigate } from "react-router-dom";
import "jspdf-autotable";
import {
  Box,
  Toolbar,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  Select as MaterialSelect,
  Button,
} from "@mui/material";
import Cookies from "js-cookie";
import Modal from "@mui/material/Modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import toast, { Toaster } from "react-hot-toast";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const AttendanceTable = () => {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(11) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const [attendanceData, setAttendanceData] = useState(null);
  const [month, setMonth] = useState("");
  const [class_id, setClassId] = useState("");
  const [year, setYear] = useState("");

  const [yearsFromApi, setYearsFromApi] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedClassId, setSelectedClassId] = useState("");

  const [classIds, setClassIds] = useState([]);
  const [schoolId, setSchoolId] = useState("");
  const [AddingHoliday, setAddingHoliday] = useState(false);

  const [monthsAndYears, setMonthsAndYears] = useState([]);
  const [selectedClassesForHoliday, setSelectedClassesForHoliday] = useState(
    []
  );

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    // Clear edited values when closing the modal
  };

  const [startDate, setStartDate] = useState(new Date());
  const value = 2;

  const handleYearChange = (e) => {
    setYear(e.target.value);
    setSelectedYear(e.target.value);
  };

  function getCurrentMonth() {
    const currentDate = new Date();
    return currentDate.toLocaleString("en-US", { month: "long" });
  }

  const dates = Array.from({ length: 31 }, (_, index) => index + 1);

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
    setSelectedMonth(e.target.value);
  };

  const handleClassChange = (e) => {
    setClassId(e.target.value);
    setSelectedClassId(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchAttendance(class_id, month, year);
  };

  const handleRadioChange = (classId) => {
    setSelectedClassesForHoliday((prevSelectedClasses) => {
      const updatedClasses = new Set(prevSelectedClasses);

      if (updatedClasses.has(classId)) {
        // If the classId is already selected, remove it
        updatedClasses.delete(classId);
      } else {
        // If the classId is not selected, add it
        updatedClasses.add(classId);
      }

      return Array.from(updatedClasses);
    });
  };

  const handleDeselectAll = () => {
    // Clear the selectedClassesForHoliday array
    setSelectedClassesForHoliday([]);
  };

  useEffect(() => {
    const fetchSchoolId = () => {
      const schoolIdFromCookie = Cookies.get("schoolId");

      if (!schoolIdFromCookie) {
        console.error("School ID not available");
        return;
      }
      fetchAttendanceMonths(schoolIdFromCookie);
      setSchoolId(schoolIdFromCookie);
      fetchClassIds(schoolIdFromCookie);
    };

    fetchSchoolId();
  }, []);

  const getMonthString = (monthNumber) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return months[monthNumber - 1]; // Adjust for zero-based index
  };

  // mark holiday
  const handleAddHoliday = async () => {
    try {
      setAddingHoliday(true);
      // Use toast.promise to handle promises
      await toast.promise(
        // The promise to execute
        new Promise(async (resolve, reject) => {
          setTimeout(async () => {
            try {
              const formData = new FormData();
              formData.append("year", startDate.getFullYear());
              formData.append(
                "month",
                getMonthString(startDate.getMonth() + 1)
              );
              formData.append("date", startDate.getDate());
              formData.append("value", "2");

              // Convert selectedClassesForHoliday to a comma-separated string
              const classIdsString = selectedClassesForHoliday.join(",");
              formData.append("class_ids", classIdsString);

              // Append other necessary fields like schoolId
              formData.append("school_id", schoolId);

              const response = await fetch(
                // "http://localhost/react/api_admin/markHolidayForClasses.php",
                "https://edsanchaar.in/api/api_admin/AttendanceManager/markHolidayForClasses.php",
                {
                  method: "POST",
                  body: formData,
                  headers: {
                    authorization:
                      "b1sgVwwszjQM0iq6viyqYNrUzX3uaLWTUnkaOrq3OX0=",
                  },
                }
              );

              const data = await response.json();

              // Handle success or error messages
              console.log(data);
              resolve(); // Resolve the promise on success
            } catch (error) {
              console.error("Error adding holiday:", error);
              reject(); // Reject the promise on failure
            }
          }, 2000); // 2s timeout
        }),
        {
          // Toast options
          loading: "Adding holiday, please wait...",
          success: <b>Holiday added successfully!</b>,
          error: <b>Error adding holiday.</b>,
        }
      );
    } catch (error) {
      // Handle network or other errors
      console.error("Error adding holiday:", error);
    } finally {
      setAddingHoliday(false);
    }
  };

  // unmark holiday
  const unmarkHoliday = async () => {
    try {
      setAddingHoliday(true);
      // Use toast.promise to handle promises
      await toast.promise(
        // The promise to execute
        new Promise(async (resolve, reject) => {
          setTimeout(async () => {
            try {
              const formData = new FormData();
              formData.append("year", startDate.getFullYear());
              formData.append(
                "month",
                getMonthString(startDate.getMonth() + 1)
              );
              formData.append("date", startDate.getDate());
              formData.append("value", "3");

              // Convert selectedClassesForHoliday to a comma-separated string
              const classIdsString = selectedClassesForHoliday.join(",");
              formData.append("class_ids", classIdsString);

              // Append other necessary fields like schoolId
              formData.append("school_id", schoolId);

              const response = await fetch(
                // "http://localhost/react/api_admin/markHolidayForClasses.php",
                "https://edsanchaar.in/api/api_admin/AttendanceManager/markHolidayForClasses.php",
                {
                  method: "POST",
                  body: formData,
                  headers: {
                    authorization:
                      "b1sgVwwszjQM0iq6viyqYNrUzX3uaLWTUnkaOrq3OX0=",
                  },
                }
              );

              const data = await response.json();

              // Handle success or error messages
              console.log(data);
              resolve(); // Resolve the promise on success
            } catch (error) {
              console.error("Error adding holiday:", error);
              reject(); // Reject the promise on failure
            }
          }, 2000); // 2s timeout
        }),
        {
          // Toast options
          loading: "Adding holiday, please wait...",
          success: <b>Holiday added successfully!</b>,
          error: <b>Error adding holiday.</b>,
        }
      );
    } catch (error) {
      // Handle network or other errors
      console.error("Error adding holiday:", error);
    } finally {
      setAddingHoliday(false);
    }
  };

  // Modify the fetchAttendanceMonths function
  const fetchAttendanceMonths = (schoolId) => {
    // const apiUrl = `http://localhost/react/api_admin/getAttendanceMonthAndYear.php?school_id=${schoolId}`;
    const apiUrl = `https://edsanchaar.in/api/api_admin/getAttendanceMonthAndYear.php?school_id=${schoolId}`;
    fetch(apiUrl, {
      method: "GET",
      headers: {
        authorization: "b1sgVwwszjQM0iq6viyqYNrUzX3uaLWTUnkaOrq3OX0=",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Extract unique years from the API response
        const uniqueYears = Array.from(
          new Set(data.months.map((entry) => entry.year))
        );

        setYearsFromApi(uniqueYears);
        setMonthsAndYears(data.months || []); // Set to an empty array if data.months is not present or not an array
      })
      .catch((error) =>
        console.error("Error fetching attendance months:", error)
      );
  };

  const fetchClassIds = (schoolId) => {
    // const apiUrl = `http://localhost/react/api_admin/getDistinctClasses.php?school_id=${schoolId}`;
    const apiUrl = `https://edsanchaar.in/api/api_admin/getDistinctClasses.php?school_id=${schoolId}`;
    fetch(apiUrl, {
      method: "GET",
      headers: {
        authorization: "aV0xQRFizDkSwyzksn2lc0xi4Rytc8y4fT1ftwiL8ek",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Sort the class IDs before setting them in the state
        const sortedClassIds = data.sort((a, b) => {
          // Extract the numeric part and compare
          const aNumeric = parseInt(a.class_id.replace(/^\D+/g, ""), 10);
          const bNumeric = parseInt(b.class_id.replace(/^\D+/g, ""), 10);
          return aNumeric - bNumeric;
        });

        setClassIds(sortedClassIds);
      })
      .catch((error) => console.error("Error fetching class IDs:", error));
  };

  const fetchAttendance = (classID, month, year) => {
    // Split the selectedMonth to extract the actual month name
    const selectedMonthName = selectedMonth.split("-")[0];

    // const apiUrl = `http://localhost/react/api_admin/fetchAttendance.php?class_id=${classID}&month=${selectedMonthName}&year=${selectedYear}`;
    const apiUrl = `https://edsanchaar.in/api/api_admin/fetchAttendance.php?school_id=${schoolId}&class_id=${classID}&month=${selectedMonthName}&year=${selectedYear}`;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        authorization: "b1sgVwwszjQM0iq6viyqYNrUzX3uaLWTUnkaOrq3OX0=",
      },
    })
      .then((response) => response.json())
      .then((data) => setAttendanceData(data.attendance))
      .catch((error) =>
        console.error("Error fetching attendance data:", error)
      );
  };

  const truncateName = (name) => {
    const words = name.split(" ");

    if (words.length > 1) {
      return `${words[0].charAt(0)}. ${words[words.length - 1]}`;
    } else {
      return name;
    }
  };

  const Download = () => {
    if (!attendanceData || !Object.keys(attendanceData).length) {
      // If attendanceData is not available or empty, handle accordingly
      console.error("Attendance data is not available");
      return;
    }

    const pdf = new jsPDF("p", "pt");
    pdf.text("Attendance Report", 10, 10);

    // Extracting unique dates from the first entry
    const uniqueDates = Object.keys(
      attendanceData[Object.keys(attendanceData)[0]].attendanceText
    )
      .map(Number)
      .sort((a, b) => a - b);

    const tableData = Object.entries(attendanceData).map(
      ([studentId, { attendanceText, userInfo }]) => {
        const truncatedName = truncateName(userInfo.name);
        const rowData = [truncatedName];
        let totalAttendance = 0;

        uniqueDates.forEach((date) => {
          // Extracting attendance for each date
          const attendance = attendanceText[date] || "";
          let attendanceSymbol = "";

          if (attendance === "1") {
            attendanceSymbol = "P";
            totalAttendance += 1;
          } else if (attendance === "2") {
            attendanceSymbol = "H";
          } else if (attendance === "3" || attendance === "E") {
            attendanceSymbol = "";
          } else {
            attendanceSymbol = "A";
          }

          rowData.push(attendanceSymbol);
        });

        rowData.push(totalAttendance);
        return rowData;
      }
    );

    const fontSize = 5;
    const rowHeight = 5;

    const dateHeaders = [
      "Name",
      ...uniqueDates.map((date) => ` ${date}`),
      "Total Attendance",
    ];

    pdf.autoTable({
      head: [dateHeaders],
      body: tableData,
      columnWidth: "wrap",
      styles: {
        fontSize: fontSize,
        cellHeight: rowHeight,
      },
    });

    const blob = pdf.output("blob");
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "attendance.pdf";
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <div className="flex items-center justify-center w-full h-screen bg-white">
      <Toaster position="top-center" reverseOrder={true} />
      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="flex flex-col justify-start items-center w-full h-full">
            <div className="text-3xl font-bold">Student Attendance Data</div>
            <div className="flex flex-row w-full justify-center py-2 ">
              <form
                onSubmit={handleSubmit}
                className="flex flex-row items-center w-full justify-center"
              >
                {["Class", "Year", "Month"].map((label, index) => (
                  <div
                    key={index}
                    className="flex flex-row justify-center items-center"
                  >
                    <InputLabel>{`Select ${label}`}</InputLabel>
                    <FormControl>
                      <MaterialSelect
                        value={
                          index === 0
                            ? selectedClassId
                            : index === 1
                            ? selectedYear
                            : selectedMonth
                        }
                        onChange={
                          index === 0
                            ? handleClassChange
                            : index === 1
                            ? handleYearChange
                            : handleMonthChange
                        }
                        style={{
                          width: "fit",
                          height: "50px",
                          marginTop: 4,
                          marginInline: 10,
                        }}
                      >
                        {index === 0 &&
                          classIds.map((classItem) => (
                            <MenuItem
                              key={classItem.class_id}
                              value={classItem.class_id}
                            >
                              {classItem.class_id}
                            </MenuItem>
                          ))}
                        {index === 1 &&
                          yearsFromApi.map((year) => (
                            <MenuItem key={year} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                        {index === 2 &&
                          selectedYear &&
                          monthsAndYears &&
                          monthsAndYears
                            .filter(
                              ({ year }) => year === parseInt(selectedYear)
                            )
                            .map(({ year, months }) =>
                              months.map((month) => (
                                <MenuItem
                                  key={`${month}-${year}`}
                                  value={`${month}-${year}`}
                                >
                                  {month}
                                </MenuItem>
                              ))
                            )}
                      </MaterialSelect>
                    </FormControl>
                  </div>
                ))}

                <div className="flex flex-row w-[26%] justify-evenly">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      width: "48%",
                    }}
                  >
                    Get Attendance
                  </Button>
                  <Button
                    sx={{
                      width: "45%",
                    }}
                    type="button"
                    variant="contained"
                    color="success"
                    onClick={handleOpen}
                  >
                    Manage Holiday
                  </Button>
                </div>

                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{ ...style, padding: "20px", margin: "auto" }}
                    className="rounded-md"
                  >
                    <div className="flex flex-row">
                      <label style={{ marginBottom: "10px", display: "block" }}>
                        Selected Date:
                      </label>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        className="pr-2 ml-2 border rounded border-stone-950"
                        style={{ marginBottom: "10px" }}
                      />
                    </div>
                    <FormControl
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <label>Select Classes</label>
                      <div className="flex flex-column flex-wrap">
                        {classIds.map((classItem) => (
                          <FormControlLabel
                            key={classItem.class_id}
                            control={
                              <Radio
                                checked={selectedClassesForHoliday.includes(
                                  classItem.class_id
                                )}
                                onChange={() =>
                                  handleRadioChange(classItem.class_id)
                                }
                                value={classItem.class_id}
                                color="primary"
                              />
                            }
                            label={classItem.class_id}
                            style={{ marginBottom: "5px" }}
                          />
                        ))}
                      </div>
                    </FormControl>
                    <div className="flex flex-row justify-between">
                      <button
                        className="bg-blue-500 text-white px-4 py-2 rounded text-sm"
                        onClick={handleAddHoliday}
                        disabled={AddingHoliday}
                      >
                        Add Holiday
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-2 rounded text-sm"
                        onClick={unmarkHoliday} // Call the new function for unmarking holiday
                        disabled={AddingHoliday}
                      >
                        Unmark Holiday
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-2 rounded text-sm"
                        onClick={handleDeselectAll}
                        variant="contained"
                      >
                        Deselect All
                      </button>
                    </div>
                  </Box>
                </Modal>
              </form>
            </div>

            {attendanceData ? (
              <div className="w-full overflow-x-auto">
                <table className="w-full table-auto border-collapse border">
                  <thead>
                    <tr className="bg-blue-200">
                      <th className="border p-2">Name</th>
                      <th className="border p-2">Roll No</th>
                      {dates.map((date) => (
                        <th key={date} className="border p-2">
                          Date {date}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(attendanceData).map(
                      ([studentId, { attendanceText, userInfo }], index) => (
                        <tr
                          key={index}
                          className={
                            index % 2 === 0 ? "bg-blue-100" : "bg-white"
                          }
                        >
                          <td className="border p-2">
                            {userInfo ? truncateName(userInfo.name) : "N/A"}
                          </td>
                          <td className="border p-2">
                            {userInfo ? userInfo.roll_no : "N/A"}
                          </td>
                          {attendanceText && !attendanceText.error ? (
                            attendanceText
                              .split("")
                              .map((attendance, dateIndex) => (
                                <td
                                  key={dateIndex}
                                  className={`border p-2 ${
                                    attendance === "2" ? "bg-blue-300" : ""
                                  }`}
                                >
                                  {attendance === "1"
                                    ? "P"
                                    : attendance === "2"
                                    ? "H"
                                    : attendance === "3" || attendance === "E"
                                    ? ""
                                    : "A"}
                                </td>
                              ))
                          ) : (
                            <td colSpan={dates.length} className="border p-2">
                              {attendanceText && attendanceText.error
                                ? attendanceText.error
                                : "N/A"}
                            </td>
                          )}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="w-full h-full flex justify-center items-center text-2xl font-semibold">
                Please select year, month, and class ID to get attendance data.
              </div>
            )}
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={Download}
              sx={{
                marginY: 1,
              }}
            >
              Download Attendance
            </Button>
          </div>
        </Box>
      </Toolbar>
    </div>
  );
};

export default AttendanceTable;
