import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Toolbar,
  FormControl,
  Button,
  Dialog,
  DialogContent,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
const EditTeacher2 = () => {
  const navigate = useNavigate();
  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);

  const [classModalOpen, setClassModalOpen] = useState(false);
  const [subjectModalOpen, setSubjectModalOpen] = useState(false);

  const [classIds, setClassIds] = useState([]);
  const [classSubjectMap, setClassSubjectMap] = useState({});

  const [schoolId, setSchoolId] = useState("");

  const [checkedClassIds, setCheckedClassIds] = useState([]);
  const [selectedSubjectsMap, setSelectedSubjectsMap] = useState({});

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    console.log("Decrypted Role ID:", decryptedRoleId);
    console.log("LoadingRoleId:", loadingRoleId);

    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(1) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const { username: usrname } = useParams();

  const [formData, setFormData] = useState({
    username: usrname,
    school_id: "", // Set it to null initially
    roll_no: "",
    name: "",
    class_id: "",
    subject: "",
    contact: "",
    classteacher: "",
  });

  const handleClassSelection = (selectedClass) => {
    setCheckedClassIds((prevCheckedClassIds) => {
      if (prevCheckedClassIds.includes(selectedClass)) {
        // Remove the class if already selected
        return prevCheckedClassIds.filter(
          (classId) => classId !== selectedClass
        );
      } else {
        // Add the class if not selected
        return [...prevCheckedClassIds, selectedClass];
      }
    });
  };
  const handleSubjectSelection = (selectedSubject, classId) => {
    setSelectedSubjectsMap((prevSelectedSubjectsMap) => {
      // Create a copy of the previous state
      const newSelectedSubjectsMap = { ...prevSelectedSubjectsMap };
      // Get the selected subjects array for the class or initialize it if not present
      const selectedSubjectsForClass = newSelectedSubjectsMap[classId] || [];
      // Toggle the selected subject for the class
      if (selectedSubjectsForClass.includes(selectedSubject)) {
        // Remove the subject if already selected
        newSelectedSubjectsMap[classId] = selectedSubjectsForClass.filter(
          (subject) => subject !== selectedSubject
        );
      } else {
        // Add the subject if not selected
        newSelectedSubjectsMap[classId] = [
          ...selectedSubjectsForClass,
          selectedSubject,
        ];
      }
      return newSelectedSubjectsMap;
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const fetchTeacherData = async () => {
    try {
      const response = await axios.get(
        `https://edsanchaar.in/api/api_admin/TeacherManager/getTeacher.php?username=${usrname}&school_id=${schoolId}`,
        {
          headers: {
            Authorization: "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
          },
        }
      );
      if (response.data) {
        const { class_id, subject, ...teacherData } = response.data;

        // Parse class IDs and subjects
        const classIds = class_id.split(", ");
        const subjects = subject.split(", ");

        // Initialize checkedClassIds with unique class IDs
        const uniqueClassIds = [...new Set(classIds)];
        setCheckedClassIds(uniqueClassIds);

        // Initialize selectedSubjectsMap based on class IDs and subjects
        const selectedSubjectsMap = {};
        uniqueClassIds.forEach((classId) => {
          const subjectsForClass = subjects.filter(
            (_, index) => classIds[index] === classId
          );
          selectedSubjectsMap[classId] = subjectsForClass;
        });
        setSelectedSubjectsMap(selectedSubjectsMap);

        // Set other form data
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...teacherData,
        }));
      } else {
        console.error("Invalid data format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchClassIds = async (schoolId) => {
    const apiUrl = `https://edsanchaar.in/api/api_admin/TeacherManager/getSchoolClasses.php?school_id=${schoolId}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
        },
      });

      const data = response.data;

      if (Array.isArray(data)) {
        // console.log(data);
        const classSubjectMap = {};
        data.forEach((classInfo) => {
          const className = classInfo["class"];
          const subjects = classInfo["subjects"].split(",");
          classSubjectMap[className] = subjects;
        });

        setClassIds(Object.keys(classSubjectMap));
        setClassSubjectMap(classSubjectMap);
      } else {
        // console.error("Invalid response format for class IDs:", data);
      }
    } catch (error) {
      // console.error("Error fetching class IDs:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Combine class IDs and subjects into strings
      const updatedClassIdString = checkedClassIds
        .flatMap((classId) =>
          Array(selectedSubjectsMap[classId].length).fill(classId)
        ) // Repeat class ID for each subject
        .join(", "); // Join class IDs with a comma and space
      const subjectString = checkedClassIds
        .flatMap((classId) => selectedSubjectsMap[classId])
        .join(", "); // Join subjects with a comma and space

      // Remove leading comma and space if present
      const cleanedClassIdString = updatedClassIdString.replace(/^, /, "");
      const cleanedSubjectString = subjectString.replace(/^, /, "");

      // Update formData with the cleaned strings
      const updatedFormData = {
        ...formData,
        class_id: cleanedClassIdString,
        subject: cleanedSubjectString,
      };

      await axios.post(
        `https://edsanchaar.in/api/api_admin/TeacherManager/updateTeacher.php`,
        updatedFormData,
        {
          headers: {
            Authorization: "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
          },
        }
      );
      toast.success("Record updated successfully!");
      fetchTeacherData();
    } catch (error) {
      console.error("Error updating teacher:", error);
      toast.error("Error updating teacher. Please try again.");
    }
  };

  // dialogs open and close
  const handleClassModalOpen = () => {
    setClassModalOpen(true);
  };

  const handleClassModalClose = () => {
    setClassModalOpen(false);
  };

  const handleSubjectModalOpen = () => {
    setSubjectModalOpen(true);
  };

  const handleSubjectModalClose = () => {
    setSubjectModalOpen(false);
  };
  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      // Redirect to login or handle accordingly if schoolId is not available
      // You may want to add additional handling, such as showing an error message
      console.error("School ID not available");
      return;
    }

    formData.school_id = schoolIdFromCookie;

    setSchoolId(schoolIdFromCookie);

    fetchClassIds(schoolIdFromCookie);
  }, []);
  useEffect(() => {
    fetchTeacherData();
  }, [schoolId]);

  return (
    <div className="flex items-center justify-center w-full h-screen bg-white">
      <Toaster position="top-center" reverseOrder={true} />

      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="flex flex-col justify-center items-center">
            <form
              onSubmit={handleSubmit}
              className="w-screen max-w-md bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
            >
              <h2 className="text-2xl text-center font-semibold mb-4">
                EDIT {usrname}
              </h2>

              <div className="flex justify-between flex-wrap">
                <div className="w-[48%] mb-4">
                  <label className="font-bold mb-1" htmlFor="name">
                    Name:
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Enter Name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="w-[48%] mb-4">
                  <label className="font-bold mb-1" htmlFor="classteacher">
                    Class Teacher:
                  </label>
                  <input
                    type="text"
                    id="classteacher"
                    name="classteacher"
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Enter Class Teacher"
                    value={formData.class_teacher}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="w-[48%] mb-4">
                  <label className="font-bold mb-1" htmlFor="roll_no">
                    Roll No:
                  </label>
                  <input
                    type="text"
                    id="roll_no"
                    name="roll_no"
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Enter Roll No"
                    value={formData.roll_no}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="w-[45%] mb-15 flex flex-col items-start flex-wrap">
                  <label className="font-bold mb-1" htmlFor="class_id">
                    Class_id: *
                  </label>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClassModalOpen}
                  >
                    Select Classes
                  </Button>
                </div>

                <div className="w-[45%] mb-15 flex flex-col items-start  flex-wrap">
                  <label className="font-bold mb-1" htmlFor="subject">
                    Subjects: *
                  </label>
                  <div className="flex flex-col">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubjectModalOpen}
                    >
                      Select Subjects
                    </Button>
                    {/* You can display selected subjects here */}
                  </div>
                </div>

                <div className="w-[48%] mb-4">
                  <label className="font-bold mb-1" htmlFor="contact">
                    Contact:
                  </label>
                  <input
                    type="text"
                    id="contact"
                    name="contact"
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Enter Contact"
                    value={formData.contact}
                    onChange={handleInputChange}
                  />
                </div>

                {/* modals  */}

                <Dialog
                  open={classModalOpen}
                  onClose={handleClassModalClose}
                  aria-labelledby="class-modal-title"
                  aria-describedby="class-modal-description"
                >
                  <DialogContent>
                    <FormControl style={{ width: "100%" }}>
                      <label>Select Classes</label>
                      <div className="flex flex-column flex-wrap">
                        {classIds.map((classId) => (
                          <FormControlLabel
                            key={classId}
                            control={
                              <Checkbox
                                checked={checkedClassIds.includes(classId)}
                                onChange={() => handleClassSelection(classId)}
                                color="primary"
                              />
                            }
                            label={classId}
                            style={{ marginBottom: "5px" }}
                          />
                        ))}
                      </div>
                    </FormControl>
                    <div className="flex flex-row justify-center items-center">
                      <Button
                        onClick={handleClassModalClose}
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    </div>
                  </DialogContent>
                </Dialog>

                {/* Subject Modal */}
                <Dialog
                  open={subjectModalOpen}
                  onClose={handleSubjectModalClose}
                  aria-labelledby="subject-modal-title"
                  aria-describedby="subject-modal-description"
                >
                  <DialogContent>
                    <FormControl style={{ width: "100%" }}>
                      <label>Select Subjects</label>
                      <div className="flex flex-column flex-wrap">
                        {checkedClassIds.map((classId) => (
                          <div key={classId}>
                            <h4>{classId}</h4>
                            {classSubjectMap[classId]?.map((subject) => (
                              <FormControlLabel
                                key={subject}
                                control={
                                  <Checkbox
                                    checked={selectedSubjectsMap[
                                      classId
                                    ]?.includes(subject)}
                                    onChange={() =>
                                      handleSubjectSelection(subject, classId)
                                    }
                                    color="primary"
                                  />
                                }
                                label={subject}
                                style={{ marginBottom: "5px" }}
                              />
                            ))}
                          </div>
                        ))}
                      </div>
                    </FormControl>
                    <div className="flex flex-row justify-center items-center">
                      <Button
                        onClick={handleSubjectModalClose}
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    </div>
                  </DialogContent>
                </Dialog>
                {/* Repeat the above structure for other fields */}
              </div>

              <div className="flex justify-center my-3">
                <button
                  type="submit"
                  className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Toolbar>
    </div>
  );
};

export default EditTeacher2;
