import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
function FeeManager() {
  const navigate = useNavigate();

  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [verificationManager, setVerificationManager] = useState(false);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoading(false); // Set loading to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      console.error("School ID not available");
      return;
    }
    const config = {
      headers: {
        Authorization: "aUBnAANvzn4CzHO76Cr+IHnjs3tvnr680eQSSZG2sgw",
      },
    };

    axios
      .get(
        `https://edsanchaar.in/api/api_admin/FeeVerification/verificationEnabledStatus.php?school_id=${schoolIdFromCookie}`,
        config
      )
      .then((response) => {
        setVerificationManager(response.data.paymentGateway);
        console.log(response.data.paymentGateway);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    // Check the condition and navigate only if not loading and role is not "1"
    if (!loading && decryptedRoleId.charAt(4) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loading]);

  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  return (
    <div className="flex items-center justify-center h-full w-full">
      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 2,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "95.5%",
            height: "90%",
          }}
        >
          <div className="text-8xl font-bold text-slate-900">Fee Manager</div>
          <div className="flex flex-row flex-wrap w-full justify-center pt-20">
            <div className="mx-4 p-4 bg-white rounded-lg shadow-lg w-1/4 flex flex-col justify-between">
              <div className="text-2xl font-semibold">Manage Fee</div>
              <div className="text-md py-2">
                Create Fee for students or modify/edit existing fee details.
              </div>
              <Link
                to="ManageFee"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full flex items-center w-fit"
              >
                Manage
              </Link>
            </div>

            {verificationManager === 0 && (
              <div className="mx-4 p-4 bg-white rounded-lg shadow-lg w-1/4 flex flex-col justify-between">
                <div className="text-2xl font-semibold">Verify Fee payment</div>
                <div className="text-md py-2">
                  Verify Fee payment for fee paid through online mode using
                  transaction id
                </div>
                <Link
                  to="VerifyFee"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full flex items-center w-fit"
                >
                  Verify
                </Link>
              </div>
            )}

            <div className="mx-4 p-4 bg-white rounded-lg shadow-lg w-1/4 flex flex-col justify-between">
              <div className="text-2xl font-semibold">Mark Fee</div>
              <div className="text-md py-2">
                Directly update fee payment information for cash methods or
                reception payment.
              </div>
              <Link
                to="MarkFee"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full flex items-center w-fit"
              >
                Edit
              </Link>
            </div>

            <div
              className={`mx-4 ${
                verificationManager ? "" : "mt-12"
              } p-4 bg-white rounded-lg shadow-lg w-1/4 flex flex-col justify-between`}
            >
              <div className="text-2xl font-semibold">Ledger Generation</div>
              <div className="text-md py-2">
                Generate Yearly and Monthly Ledger. Option to Include
                Expenditure in Monthly Ledger
              </div>
              <Link
                to="FeeLedger"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full flex items-center w-fit"
              >
                Edit
              </Link>
            </div>
          </div>

          <div className="footer-bottom fixed bottom-0 w-full bg-lightblue-800 p-4 text-center text-black">
            <p>© 2023 Ed-Sanchaar. All rights reserved.</p>
          </div>
        </Box>
      </Toolbar>
    </div>
  );
}

export default FeeManager;
