import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import {
  Box,
  Toolbar,
  Dialog,
  Select,
  MenuItem,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  TextField,
  Button,
} from "@mui/material";
import Modal from "@mui/material/Modal";

import toast, { Toaster } from "react-hot-toast";

const planOptions = [
  { value: "Monthly", label: "Monthly" },
  { value: "Bi-Monthly", label: "Bi-Monthly" },
  { value: "Quarterly", label: "Quarterly" },
  { value: "Tri-Annually", label: "Tri-Annually" },
  { value: "Half-Yearly", label: "Half-Yearly" },
  { value: "Yearly", label: "Yearly" },
  { value: "Other", label: "Other" },
];
function AddStudent() {
  const navigate = useNavigate();

  // role id authentication
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(2) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };

  const [school_id, setSchoolId] = useState("");
  const [classIds, setClassIds] = useState([]);

  const [formData, setFormData] = useState({
    school_id: "", // school_id will be set dynamically
    roll_no: "",
    class_id: "",
    name: "",
    gender: "",
    father_name: "",
    mother_name: "",
    mobile: "",
    phone: "",
    Address: "",
    birthDate: "",
    email: "",
    attendance_start: "",
    attendance_end: "",
    plan: "",
    title: "",
    baseFee: "",
    miscellaneousCharges: "",
    totalFee: "",
    dueDate: "",
    Student_UID: "",
  });

  const [newFeeAddOpen, setNewFeeAddOpen] = useState(false);

  const handleNewFeeClose = () => {
    setNewFeeAddOpen(false);
  };
  const openAddFeeDialog = () => {
    setNewFeeAddOpen(true);
  };

  const handleAddFeeRecord = async () => {
    setNewFeeAddOpen(false);
  };

  useEffect(() => {
    // Fetch school_id from cookies
    const schoolId = Cookies.get("schoolId");

    if (!schoolId) {
      // Redirect to login or handle accordingly if schoolId is not available
      // You may want to add additional handling, such as showing an error message
      console.error("School ID not available");
      return;
    }

    // Set the school_id in the formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      school_id: schoolId,
    }));
  }, []);

  const formatAttendanceDates = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const result = {};

    for (
      let year = startDate.getFullYear();
      year <= endDate.getFullYear();
      year++
    ) {
      const startMonth =
        year === startDate.getFullYear() ? startDate.getMonth() : 0;
      const endMonth = year === endDate.getFullYear() ? endDate.getMonth() : 11;

      result[year] = {};

      for (let month = startMonth; month <= endMonth; month++) {
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const daysString = "3".repeat(daysInMonth);
        const monthName = new Intl.DateTimeFormat("en-US", {
          month: "long",
        }).format(new Date(year, month, 1));

        result[year][monthName] = daysString;
      }
    }

    return result;
  };

  const handleStudentAdd = async (e) => {
    e.preventDefault();

    try {
      const genderValue = formData.gender.toLowerCase() === "female" ? 1 : 0;
      // Format attendance dates using the function
      const formattedAttendance = formatAttendanceDates(
        formData.attendance_start,
        formData.attendance_end
      );

      await toast.promise(
        new Promise(async (resolve, reject) => {
          try {
            setTimeout(async () => {
              // Extract year and month from attendance_start and attendance_end
              const startYearMonth = formData.attendance_start
                .split("-")
                .slice(0, 2)
                .join("-");
              const endYearMonth = formData.attendance_end
                .split("-")
                .slice(0, 2)
                .join("-");

              const response = await fetch(
                "https://edsanchaar.in/api/api_admin/StudentManager/addStudent.php",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "ZVs2URNyn3oA0zH68DqiYZX4g46IXygITFXRE/cW5A",
                    Cookies: Cookies.get("encryptedApiKey"),
                  },
                  body: JSON.stringify({
                    ...formData,
                    gender: genderValue,
                    // Include the formatted attendance dates in the request
                    attendance_start: startYearMonth,
                    attendance_end: endYearMonth,
                    attendance: formattedAttendance,
                  }),
                }
              );

              if (response.ok) {
                setFormData({
                  school_id: "",
                  roll_no: "",
                  class_id: "",
                  Student_UID: "",
                  name: "",
                  gender: "",
                  father_name: "",
                  mother_name: "",
                  mobile: "",
                  phone: "",
                  Address: "",
                  birthDate: "",
                  email: "",
                  attendance_start: "",
                  attendance_end: "",
                  plan: "",
                  title: "",
                  baseFee: "",
                  miscellaneousCharges: "",
                  totalFee: "",
                  dueDate: "",
                });
                resolve();
              } else {
                reject(new Error("Failed to add student"));
              }
            }, 2000);
          } catch (error) {
            console.error("Error in fetch:", error);
            reject(error);
          }
        }),
        {
          loading: "Adding Student...",
          success: <b>Student added successfully!</b>,
          error: (error) => {
            if (
              error instanceof Error &&
              error.message === "Failed to add student"
            ) {
              // Custom error message for failed student addition
              return toast.error("Failed to add student. Please try again.");
            } else if (
              error instanceof TypeError &&
              error.message === "Failed to fetch"
            ) {
              // Handle the "Failed to fetch" error and show a toast
              return toast.error(
                "Network error. Check your internet connection or try again later."
              );
            } else {
              // Default error message
              return toast.error("Failed to add student.");
            }
          },
        }
      );
    } catch (error) {
      console.error("Error in handleFormSubmit:", error);
    }
  };

  const fetchClassIds = async (schoolId) => {
    const apiUrl = `https://edsanchaar.in/api/api_admin/TeacherManager/getSchoolClasses.php?school_id=${schoolId}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
        },
      });

      const data = response.data;

      if (Array.isArray(data)) {
        // console.log(data);
        const classSubjectMap = {};
        data.forEach((classInfo) => {
          const className = classInfo["class"];
          const subjects = classInfo["subjects"].split(",");
          classSubjectMap[className] = subjects;
        });

        setClassIds(Object.keys(classSubjectMap));
      } else {
        // console.error("Invalid response format for class IDs:", data);
      }
    } catch (error) {
      // console.error("Error fetching class IDs:", error);
    }
  };

  const isSubmitDisabled =
    Object.keys(formData).some(
      (key) =>
        formData[key] === "" &&
        key !== "email" &&
        key !== "Address" &&
        key !== "phone" &&
        key !== "plan" &&
        key !== "title" &&
        key !== "baseFee" &&
        key !== "miscellaneousCharges" &&
        key !== "totalFee" &&
        key !== "dueDate"

      // plan: "",
      // title: "",
      // baseFee: "",
      // miscellaneousCharges: "",
      // totalFee: "",
      // dueDate: "",
    ) || formData.gender === "";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    // Parse the values as floats (assuming they are numerical values)
    const baseFeeValue = parseFloat(formData.baseFee) || 0;
    const miscellaneousChargesValue =
      parseFloat(formData.miscellaneousCharges) || 0;

    // Calculate the totalFee
    const totalFeeValue = baseFeeValue + miscellaneousChargesValue;

    // Update the totalFee in the formData state
    setFormData((prevFormData) => ({
      ...prevFormData,
      totalFee: totalFeeValue.toString(), // Convert to string before setting
    }));
  }, [formData.baseFee, formData.miscellaneousCharges]);

  React.useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      console.error("School ID not available");
      return;
    }

    setSchoolId(schoolIdFromCookie);
    fetchClassIds(schoolIdFromCookie);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen bg-white">
      <Toaster position="top-center" reverseOrder={true} />
      <Toolbar
        sx={{
          height: "95vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "95.5%",
            height: "95%",
          }}
        >
          <div className="text-3xl font-bold text-slate-900 mx-0 align-center">
            Regester Student Details
          </div>
          <div className="bg-white p-8 rounded shadow-lg text-center w-5/12 opacity-90% flex flex-col">
            <div>
              <h1 className="text-2xl font-bold mb-6">Enter Student Details</h1>

              <form onSubmit={handleStudentAdd}>
                <p className="text-left mb-3">
                  required fields are followed by *
                </p>
                <div className="flex justify-between flex-wrap">
                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="name">
                      Student Name: *
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter full name"
                      required
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row flex-wrap">
                    <label className="block font-bold mb-1" htmlFor="gender">
                      Gender: *
                    </label>
                    <select
                      name="gender"
                      id="gender"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      required
                      value={formData.gender}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled hidden>
                        Select Gender
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row flex-wrap">
                    <label className="block font-bold mb-1" htmlFor="class_id">
                      Class_id: *
                    </label>
                    <select
                      name="class_id"
                      id="class_id"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      required
                      value={formData.class_id}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled hidden>
                        Select Class
                      </option>
                      {classIds.map((classId) => (
                        <option key={classId} value={classId}>
                          {classId}
                        </option>
                      ))}
                    </select>
                  </div>
                  

                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="Student_UID">
                      Student UID: *
                    </label>
                    <input
                      type="text"
                      id="Student_UID"
                      name="Student_UID"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter Student UID"
                      required
                      value={formData.Student_UID}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="roll_no">
                      Roll_no: *
                    </label>
                    <input
                      type="text"
                      id="roll_no"
                      name="roll_no"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter roll no"
                      required
                      value={formData.roll_no}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="roll_no">
                      Attendance Start: *
                    </label>
                    <input
                      type="date"
                      id="attendance_start"
                      name="attendance_start"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Select Attendance Start"
                      required
                      value={formData.attendance_start}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="roll_no">
                      Attendance End: *
                    </label>
                    <input
                      type="date"
                      id="attendance_end"
                      name="attendance_end"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Select"
                      required
                      value={formData.attendance_end}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="fee_data">
                      Fee Data:
                    </label>
                    <div
                      className="bg-blue-500 text-white px-4 my-0 py-2 rounded w-full"
                      onClick={openAddFeeDialog}
                    >
                      Admission Fee Details
                    </div>
                  </div>
                  <Modal
                    open={newFeeAddOpen}
                    onClose={handleNewFeeClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        width: "full",
                        borderRadius: 2,
                      }}
                    >
                      <DialogTitle>Fee Details</DialogTitle>
                      <DialogContent>
                        {/* Add your input fields for fee record details */}

                        <TextField
                          select
                          label="Plan"
                          name="plan"
                          value={formData.plan}
                          onChange={handleInputChange}
                          fullWidth
                          margin="normal"
                        >
                          {planOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>

                        <TextField
                          label="Title"
                          type="text"
                          name="title"
                          value={formData.title}
                          onChange={handleInputChange}
                          fullWidth
                          margin="normal"
                        />

                        <TextField
                          label="Base Fee"
                          type="number"
                          name="baseFee"
                          value={formData.baseFee}
                          onChange={handleInputChange}
                          fullWidth
                          margin="normal"
                        />

                        <TextField
                          label="Miscellaneous Fee"
                          type="number"
                          name="miscellaneousCharges"
                          value={formData.miscellaneousCharges}
                          onChange={handleInputChange}
                          fullWidth
                          margin="normal"
                        />

                        <TextField
                          label="Total Fee"
                          type="number"
                          name="totalFee"
                          value={formData.totalFee}
                          disabled
                          fullWidth
                          margin="normal"
                        />

                        <TextField
                          label="Due Date"
                          type="date"
                          name="dueDate"
                          value={formData.dueDate}
                          onChange={handleInputChange}
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={handleNewFeeClose}>Cancel</Button>
                        <Button onClick={handleAddFeeRecord} color="primary">
                          Save
                        </Button>
                      </DialogActions>
                    </Box>
                  </Modal>

                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="father_name">
                      Father Name:*
                    </label>
                    <input
                      type="text"
                      id="father_name"
                      name="father_name"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter father name"
                      required
                      value={formData.father_name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="contact">
                      Mother Name: *
                    </label>
                    <input
                      type="text"
                      id="mother_name"
                      name="mother_name"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter mother name"
                      required
                      value={formData.mother_name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="contact">
                      Address:
                    </label>
                    <input
                      type="text"
                      id="Address"
                      name="Address"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter Address name"
                      value={formData.Address}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="contact">
                      Date Of Birth: *
                    </label>
                    <input
                      type="date"
                      id="birthDate"
                      name="birthDate"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter Birth Date"
                      required
                      value={formData.birthDate}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="mobile">
                      Phone 1: *
                    </label>
                    <input
                      type="number"
                      id="mobile"
                      name="mobile"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter mobile number"
                      required
                      value={formData.mobile}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="phone">
                      Phone 2:
                    </label>
                    <input
                      type="number"
                      id="phone"
                      name="phone"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter phone number"
                      value={formData.phone}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row  flex-wrap">
                    <label className="font-bold mb-1" htmlFor="email">
                      email:
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>

                  {/* Repeat similar input fields for other form fields */}
                </div>
                <div className="flex justify-center my-3">
                  <button
                    type="submit"
                    className={`bg-blue-500 text-white px-4 py-2 rounded ${
                      isSubmitDisabled
                        ? "disabled:opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                    disabled={isSubmitDisabled}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Toolbar>
      <div className="footer-bottom w-full bg-lightblue-800 p-4 text-center text-black">
        <p>© 2023 Ed-Sanchaar. All rights reserved.</p>
      </div>
    </div>
  );
}

export default AddStudent;
