import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Carousel from "react-elastic-carousel";
import toast, { Toaster } from "react-hot-toast";

import DashboardModules from "./Dashboard/DashboardModules";
import StatisticsSection from "./Dashboard/DashboardStatistics";
import Cookies from "js-cookie";

import { styled } from "@mui/system";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import "chart.js/auto";
import { Doughnut, Pie, Bar } from "react-chartjs-2";

import AppBar from "@mui/material/AppBar";
import { Box, Grid, Toolbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import logo from "../Images/logo_with_white_background.png";

export default function ResponsiveDrawer(props) {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 500, itemsToShow: 1 },
    { width: 900, itemsToShow: 2 },
    { width: 1500, itemsToShow: 3 },
  ];

  const navigate = useNavigate();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingCarousel, setLoadingCarousel] = useState(true);

  const [open, setOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");

  const [openContactUs, setOpenContactUs] = useState(false);

  const [apiData, setApiData] = useState("");
  const [feeData, setFeeData] = useState("");
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [carouselData, setCarouselData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [syllabusData, setSyllabusData] = useState([]);
  const [totalComplaint, setTotalComplaint] = useState("");

  const schoolId = Cookies.get("schoolId"); // Fetch schoolId from cookies

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");

  const [formData, setFormData] = useState({
    message: "",
    subject: "",
  });

  const handleLogout = () => {
    // Get all cookies
    const cookies = Cookies.get();

    // Remove all cookies
    for (const cookie in cookies) {
      Cookies.remove(cookie);
    }

    // Navigate to the specified route
    navigate("/");
  };

  const handleOpen = (cardData) => {
    setSelectedCard(cardData);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedCard(null);
  };

  const handleOpenContactUs = () => {
    setOpenContactUs(true);
  };

  const handleCloseContactUs = () => {
    setOpenContactUs(false);
  };

  const handleSendWriteToUs = async () => {
    const schoolId = Cookies.get("schoolId");

    try {
      await toast.promise(
        // The promise to execute
        new Promise(async (resolve, reject) => {
          setTimeout(async () => {
            try {
              const response = await axios.post(
                // "http://localhost:80/react/api_admin/contactUs.php",
                "https://edsanchaar.in/api/api_admin/contactUs.php",
                {
                  ...formData,
                  school_id: schoolId,
                  inquiry_type: "WriteToUs",
                },
                {
                  headers: {
                    Authorization:
                      "aVchSlItjHUB3jD6sH/4IKdX9cC3WOyB/Qrf7v+eED8",
                    "Content-Type": "application/json",
                  },
                  withCredentials: true,
                }
              );

              if (response.status === 200) {
                resolve(); // Resolve the promise on success
              } else {
                reject(); // Reject the promise on failure
              }
            } catch (error) {
              reject(); // Reject the promise on error
            }
          }, 2000); // 2s timeout
        }),
        {
          // Toast options
          loading: "Please wait while we process your request...",
          success: (
            <b>Thanks for reaching out! Our team will contact you shortly.</b>
          ),
          error: <b>Error Requesting Callback. Please try again later.</b>,
        }
      );
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  const handleRequestCallback = async () => {
    const schoolId = Cookies.get("schoolId");

    try {
      await toast.promise(
        // The promise to execute
        new Promise(async (resolve, reject) => {
          setTimeout(async () => {
            try {
              const response = await axios.post(
                // "http://localhost:80/react/api_admin/contactUs.php",
                "https://edsanchaar.in/api/api_admin/contactUs.php",
                {
                  ...formData,
                  school_id: schoolId,
                  inquiry_type: "Callback",
                },
                {
                  headers: {
                    Authorization:
                      "aVchSlItjHUB3jD6sH/4IKdX9cC3WOyB/Qrf7v+eED8",
                    "Content-Type": "application/json",
                  },
                  withCredentials: true,
                }
              );

              if (response.status === 200) {
                resolve(); // Resolve the promise on success
              } else {
                reject(); // Reject the promise on failure
              }
            } catch (error) {
              reject(); // Reject the promise on error
            }
          }, 2000); // 2s timeout
        }),
        {
          // Toast options
          loading: "Please wait while we process your request...",
          success: (
            <b>Thanks for reaching out! Our team will contact you shortly.</b>
          ),
          error: <b>Error Requesting Callback. Please try again later.</b>,
        }
      );
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  useEffect(() => {
    // Fetch schoolId from cookies
    const schoolId = Cookies.get("schoolId");

    if (!schoolId) {
      // Redirect to login if schoolId is not available
      navigate("/");
      return;
    }

    // Fetch data from carousel API with dynamic schoolId
    const fetchCarouselData = async () => {
      try {
        const response = await fetch(
          // `http://localhost/react/api_admin/fetchCarousel.php?school_id=${schoolId}`,
          `https://edsanchaar.in/api/api_admin/fetchCarousel.php?school_id=${schoolId}`,
          {
            headers: {
              Authorization: "aUBnAANvzn4CzHO76Cr+IHnjs3tvnr680eQSSZG2sgw=",
              // Add other headers if needed
            },
          }
        );

        const data = await response.json();
        setLoadingCarousel(false);

        // Add an extra slide
        const extraSlide = {
          title: "ADD OR MANAGE CAROUSEL",
          description: "Click here to add new or manage existing Carousel.",
          url: "https://edsanchaar.in/imageuploads/plus-16.png", // Replace with the actual image path
        };

        // Set the carousel data with the fetched data and the extra slide
        setCarouselData([...data, extraSlide]);
      } catch (error) {
        setLoadingCarousel(false);
      }
    };

    // Fetch data from dashboard API with dynamic schoolId
    const fetchData = async () => {
      try {
        const response = await axios.get(
          // `http://localhost/react/api_admin/dashboard.php?school_id=${schoolId}`,
          `https://edsanchaar.in/api/api_admin/dashboard.php?school_id=${schoolId}`,
          {
            headers: {
              Authorization: "aVExUBBin2oPzz3l4SG0YUR/jkFqakYnjoHr47SxDhvV",
              // Add other headers if needed
            },
          }
        );
        const data = response.data;
        setApiData(data);
      } catch (error) {
      } finally {
        setLoading(false); // Set loading to false when data is fetched
        setIsDataFetched(true);
      }
    };

    const feeDetails = async () => {
      try {
        const response = await axios.get(
          `https://edsanchaar.in/api/api_admin/Dashboard/currAndPrevMonthTotalFee.php?school_id=${schoolId}`,
          {
            headers: {
              Authorization: "aVExUBBin2oPzz3l4SG0YUR/jkFqakYnjoHr47SxDhvV",
            },
          }
        );
        const data = response.data;
        setFeeData(data);
      } catch {
        setLoading(false); // Set loading to false when data is fetched
        setIsDataFetched(true);
      }
    };
    const attendanceData = async () => {
      try {
        const response = await axios.get(
          `https://edsanchaar.in/api/api_admin/Dashboard/attendanceData.php?school_id=${schoolId}`,
          {
            headers: {
              Authorization: "aVExUBBin2oPzz3l4SG0YUR/jkFqakYnjoHr47SxDhvV",
            },
          }
        );
        const data = response.data;
        setAttendanceData(data);
      } catch {
        setLoading(false); // Set loading to false when data is fetched
        setIsDataFetched(true);
      }
    };
    const fetchSyllabusData = async () => {
      try {
        const response = await axios.get(
          `https://edsanchaar.in/api/api_admin/Dashboard/syllabusCompletion.php?school_id=${schoolId}`,
          {
            headers: {
              Authorization: "aVExUBBin2oPzz3l4SG0YUR/jkFqakYnjoHr47SxDhvV",
            },
          }
        );
        const data = response.data;
        setSyllabusData(data.completion_data);
      } catch {
        setLoading(false); // Set loading to false when data is fetched
        setIsDataFetched(true);
      }
    };

    // Fetch data from APIs
    feeDetails();
    fetchCarouselData();
    fetchData();
    attendanceData();
    fetchSyllabusData();
  }, [navigate]);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        }
      }
    };

    decryptDataAndLog();
  });

  const handleNotificationNotShown = (toastId) => {
    Cookies.set("notifications", false);
    toast.dismiss(toastId);
    toast.success("Notification have been disabled.");
  };

  useEffect(() => {
    const notificationEnabled = Cookies.get("notifications"); // Fetch schoolId from cookies

    // console.log(notificationEnabled);

    const headers = {
      Authorization: "ZVE2VQFin3of3TLkvn76Ibm3ntAkOz4SaqD3Da/2IUW3",
    };
    if (
      decryptedRoleId.charAt(12) === "1" &&
      (notificationEnabled === false || notificationEnabled == undefined)
    ) {
      fetch(
        `https://edsanchaar.in/api/api_admin/Notification/ComplaintNotification.php?school_id=${schoolId}`,
        { headers }
      )
        .then((response) => response.json())
        .then((data) => {
          const hasUnverifiedFeePayments = data.has_unverified_fee_payments;
          const hasComplaints = data.has_complaints;

          if (hasUnverifiedFeePayments && hasComplaints) {
            toast.custom((t) => (
              <div className="w-screen h-screen flex justify-center items-center backdrop-blur-sm">
                <div
                  className={`${
                    t.visible ? "animate-enter" : "animate-leave"
                  } max-w-md w-fit bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                >
                  <div className="flex flex-col ">
                    <div className="flex items-start p-4">
                      <div className="flex-shrink-0 pt-0.5">
                        <span className="text-2xl">🔔</span>
                      </div>
                      <div className="ml-3 flex flex-row justify-center items-center">
                        <p className="text-sm font-medium text-gray-900">
                          {`complaints unattended and Unverified fee`}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-row justify-evenly">
                      <button
                        onClick={() => toast.dismiss(t.id)}
                        className="border border-transparent rounded-none rounded-r-lg p-2 ml-2 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      >
                        Dismiss
                      </button>
                      <button
                        onClick={() => handleNotificationNotShown(t.id)}
                        className="border border-transparent rounded-none rounded-r-lg p-2 ml-2 text-sm font-medium text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      >
                        Do not show again
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ));
          } else if (hasUnverifiedFeePayments) {
            toast.custom((t) => (
              <div className="w-screen h-screen flex justify-center items-center backdrop-blur-sm">
                <div
                  className={`${
                    t.visible ? "animate-enter" : "animate-leave"
                  } max-w-md w-fit bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                >
                  <div className="flex flex-col ">
                    <div className="flex items-start p-4">
                      <div className="flex-shrink-0 pt-0.5">
                        <span className="text-2xl">🔔</span>
                      </div>
                      <div className="ml-3 flex flex-row justify-center items-center">
                        <p className="text-sm font-medium text-gray-900">
                          {`${totalComplaint} ${"Fee Payment"} unverified`}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-row justify-evenly">
                      <button
                        onClick={() => toast.dismiss(t.id)}
                        className="border border-transparent rounded-none rounded-r-lg p-2 ml-2 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      >
                        Dismiss
                      </button>
                      <button
                        onClick={() => handleNotificationNotShown(t.id)}
                        className="border border-transparent rounded-none rounded-r-lg p-2 ml-2 text-sm font-medium text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      >
                        Do not show again
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ));
          } else if (hasComplaints) {
            toast.custom((t) => (
              <div className="w-screen h-screen flex justify-center items-center backdrop-blur-sm">
                <div
                  className={`${
                    t.visible ? "animate-enter" : "animate-leave"
                  } max-w-md w-fit bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                >
                  <div className="flex flex-col ">
                    <div className="flex items-start p-4">
                      <div className="flex-shrink-0 pt-0.5">
                        <span className="text-2xl">🔔</span>
                      </div>
                      <div className="ml-3 flex flex-row justify-center items-center">
                        <p className="text-sm p-2 font-medium text-gray-900">
                          {`${totalComplaint} ${"complaints"} unattended`}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-row justify-evenly">
                      <button
                        onClick={() => toast.dismiss(t.id)}
                        className="border border-transparent rounded-none rounded-r-lg p-2 ml-2 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      >
                        Dismiss
                      </button>
                      <button
                        onClick={() => handleNotificationNotShown(t.id)}
                        className="border border-transparent rounded-none rounded-r-lg p-2 ml-2 text-sm font-medium text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      >
                        Do not show again
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ));
          }
        })
        .catch((error) => {
          // Handle error
        });
    }
  }, [decryptedRoleId]);

  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <Toaster position="top-center" reverseOrder={true} />
      {/* <CssBaseline /> */}
      <AppBar
        position="absolute"
        sx={{
          backgroundColor: "#f5f5f5",
          boxShadow: "none",
        }}
      >
        <Toolbar className="container mx-auto flex justify-between items-center h-20">
          <div className="flex items-center">
            <img src={logo} className="w-16 h-16" alt="Logo" />
            <div className="text-slate-600 text-2xl font-medium ml-4">
              School Dashboard
            </div>
          </div>
          <div className="flex flex-row items-center justify-center">
            <div
              className="text-slate-600 text-2xl font-medium cursor-pointer mr-4"
              onClick={handleOpenContactUs}
            >
              Contact IT Admin
            </div>
            <Button color="error" size="large" onClick={handleLogout}>
              {" "}
              Logout
            </Button>
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openContactUs}
            onClose={handleCloseContactUs}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openContactUs}>
              <Box
                sx={{
                  borderRadius: "8px 8px 8px 8px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  maxWidth: 500,
                }}
              >
                <Typography variant="h5" component="h2" className="select-none">
                  Contact IT Admin
                </Typography>

                {/* Short description of the issue for Request a Callback */}
                <TextField
                  id="callback"
                  label="Kindly provide issue and contact no."
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formData.subject}
                  onChange={(e) =>
                    setFormData({ ...formData, subject: e.target.value })
                  }
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRequestCallback}
                  sx={{ mt: 2 }}
                >
                  Request Callback
                </Button>

                <TextField
                  id="writeToUs"
                  label="Write to Us"
                  multiline
                  rows={4}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formData.message}
                  onChange={(e) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendWriteToUs}
                  sx={{ mt: 2 }}
                >
                  Send
                </Button>

                {/* Not urgent message */}
                <Typography variant="h6" sx={{ mt: 2 }}>
                  If there is something not urgent, please write.
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  You can always contact us at the below contact number.
                </Typography>
                <Typography variant="body1">
                  Contact Number: 7217771362, 7668554870
                </Typography>
              </Box>
            </Fade>
          </Modal>
        </Toolbar>
      </AppBar>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100%)` },
          paddingTop: { sm: `80px` }, // Add top padding to accommodate the AppBar
        }}
      >
        <Toolbar className="flex justify-between h-22 m-4">
          <Toolbar className="w-1/2" sx={{}}>
            <Box
              sx={{
                backgroundColor: "#f5f5f5",
                p: 2,
                borderRadius: 4,
                textAlign: "center",
                display: "flex",
                width: "100%",
              }}
            >
              <div className="w-full h-full pr-6 flex flex-col justify-between ">
                <Typography
                  variant="h5"
                  noWrap
                  component="div"
                  className="flex align-center items-center pr-4"
                >
                  School Details
                </Typography>

                {loading ? (
                  <div className="flex flex-row items-center">
                    <CircularProgress size={60} />
                  </div>
                ) : (
                  <div className="flex  flex-row justify-between pt-4">
                    <div className="flex flex-row items-center">
                      <CardMedia
                        sx={{
                          height: 60,
                          width: 60,
                          borderRadius: "500px 500px 500px 500px",
                          objectFit: "contain",
                        }}
                        image={apiData?.school_details?.[0]?.logo_storage}
                      />

                      <div className="flex flex-col items-start pl-8">
                        <Typography
                          variant="h6"
                          noWrap
                          component="div"
                          className="flex align-center items-center pr-4"
                        >
                          {apiData?.school_details[0].school_name}
                        </Typography>
                        <p>
                          Joining Date:{" "}
                          {apiData?.school_details[0].joining_date}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Box>
          </Toolbar>

          <Toolbar className="w-1/2" sx={{}}>
            <Box
              sx={{
                backgroundColor: "#f5f5f5",
                p: 2,
                borderRadius: 4,
                textAlign: "center",
                display: "flex",
                width: "100%",
              }}
            >
              <div className=" w-full h-full pr-6 flex flex-col justify-between ">
                <Typography
                  variant="h5"
                  noWrap
                  component="div"
                  className="flex align-center items-center pr-4"
                >
                  Subscription Details
                </Typography>

                <div className="flex  flex-col justify-start">
                  {loading ? (
                    <CircularProgress size={60} /> // Display circular loading while data is being fetched
                  ) : (
                    <div className="flex flex-col items-start ">
                      <p>
                        Subscription Type:{" "}
                        {apiData?.school_details[0].subscription_type}
                      </p>
                      <p>
                        Subscription Ending:{" "}
                        {apiData?.school_details[0].subscription_ending}
                      </p>
                      <p>
                        Subscription Starting:{" "}
                        {apiData?.school_details[0].subscription_start}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </Toolbar>
        </Toolbar>

        {/* carousel */}

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { sm: `calc(100%)` },
            marginBottom: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Toolbar
            className="flex justify-center"
            sx={{
              height: "fit",
              width: "screen",
            }}
          >
            <Box
              sx={{
                // p: 2,
                paddingLeft: 2,
                paddingBottom: 2,
                borderRadius: 4,
                backgroundColor: "#f5f5f5",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "95.5%",
                height: "100%",
              }}
            >
              <Typography
                variant="h5"
                component="div"
                className="my-4 py-4  w-max flex items-center justify-start"
                sx={{
                  // backgroundColor: "#f5f5",
                  fontStyle: "bold",
                  height: "100%",
                  width: "100%",
                  borderRadius: 3,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                Top Events
              </Typography>

              <div className="w-full flex-grow pb-2">
                <div className="App flex">
                  {loadingCarousel ? (
                    <CircularProgress size={60} /> // Display circular loading while data is being fetched
                  ) : (
                    <Carousel
                      breakPoints={breakPoints}
                      pagination={false}
                      transitionMs={3000}
                      enableAutoPlay={true}
                      autoPlaySpeed={1500}
                      showArrows={false}
                      infiniteLoop={true}
                    >
                      {carouselData.map((item, index) => (
                        <div
                          key={index}
                          className={
                            index === carouselData.length - 1
                              ? "link-container"
                              : "card-container"
                          }
                          onClick={() =>
                            index !== carouselData.length - 1 &&
                            handleOpen(item)
                          }
                        >
                          {index === carouselData.length - 1 ? (
                            <Link
                              to="carouselManager"
                              style={{
                                textDecoration: "none",
                                display: "block",
                              }}
                            >
                              <Card
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: 520, // Set the desired fixed width
                                  height: 170,
                                  borderRadius: 4,
                                  cursor: "pointer", // Add cursor pointer on hover
                                }}
                              >
                                <CardMedia
                                  sx={{
                                    height: "max",
                                    minWidth: 200,
                                    objectFit: "fill",
                                  }}
                                  image={item.url}
                                />
                                <CardContent
                                  sx={{
                                    maxHeight: 144,
                                    // backgroundColor:"red",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                  >
                                    {item.title}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {item.description}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Link>
                          ) : (
                            <Card
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                width: 520, // Set the desired fixed width
                                height: 170,
                                borderRadius: 4,
                                cursor: "pointer", // Add cursor pointer on hover
                              }}
                            >
                              <CardMedia
                                sx={{
                                  height: "max",
                                  minWidth: 200,
                                }}
                                // image={item.url}
                                image={`https://edsanchaar.in/api/api_admin/${item.url}`}
                              />
                              <CardContent
                                sx={{
                                  maxHeight: 144,
                                  // backgroundColor:"red",
                                  overflow: "hidden",
                                }}
                              >
                                <Typography
                                  // gutterBottom
                                  variant="h5"
                                  component="div"
                                >
                                  {item.title}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {item.description}
                                </Typography>
                              </CardContent>
                            </Card>
                          )}
                        </div>
                      ))}
                    </Carousel>
                  )}

                  {/* <Button onClick={handleOpen}>Open modal</Button> */}
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={open}>
                      <Box
                        sx={{
                          borderRadius: "8px 8px 8px 8px",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          bgcolor: "background.paper",
                          boxShadow: 24,
                          p: 4,
                          maxWidth: 400,
                        }}
                      >
                        <Typography variant="h5" component="h2">
                          {selectedCard?.title}
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                          {selectedCard?.description}
                        </Typography>
                      </Box>
                    </Fade>
                  </Modal>
                </div>
              </div>
            </Box>
          </Toolbar>
        </Box>

        {/* manager and analytics */}

        <Toolbar
          className="flex justify-center"
          sx={{
            height: "fit",
            width: "screen",
          }}
        >
          <Box
            sx={{
              p: 2,
              borderRadius: 4,
              backgroundColor: "#f5f5f5",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "95.5%",
              height: "100%",
            }}
          >
            <div className="flex flex-row h-4/6 w-fit py-5">
              <DashboardModules decryptedRoleId={decryptedRoleId} />

              {decryptedRoleId.charAt(0) === "1" && (
                <StatisticsSection
                  loading={loading}
                  apiData={apiData}
                  feeData={feeData}
                  attendanceData={attendanceData}
                  syllabusData={syllabusData}
                />
              )}
            </div>
          </Box>
        </Toolbar>
      </Box>
    </Box>
  );
}
