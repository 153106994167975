import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Box, Toolbar } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
function AddStaff() {

    const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {

    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(3) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const [formData, setFormData] = useState({
    school_id: "", // school_id will be set dynamically
    roll_no: "",
    name: "",
    gender: "", // Updated to match the expected values in PHP (0 for male, 1 for female)
    dob: "",
    role: "",
  });

  useEffect(() => {
    // Fetch school_id from cookies
    const schoolId = Cookies.get("schoolId");

    if (!schoolId) {
      // Redirect to login or handle accordingly if schoolId is not available
      // You may want to add additional handling, such as showing an error message
      console.error("School ID not available");
      return;
    }

    // Set the school_id in the formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      school_id: schoolId,
    }));
  }, []);

  const isSubmitDisabled = () => {
    // Check if any of the required fields are empty
    return Object.values(formData).some((value) => value.trim() === "");
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await toast.promise(
        new Promise(async (resolve, reject) => {
          try {
            setTimeout(async () => {
              const response = await fetch(
                "https://edsanchaar.in/api/api_admin/Staff/addStaff.php",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(formData),
                }
              );

              if (response.ok) {
                setFormData({
                  school_id: "",
                  roll_no: "",
                  name: "",
                  gender: "",
                  dob: "",
                  role: "",
                });
                resolve();
              } else {
                reject(new Error("Failed to add staff"));
              }
            }, 2000);
          } catch (error) {
            console.error("Error in fetch:", error);
            reject(error);
          }
        }),
        {
          loading: "Adding Staff...",
          success: <b>Staff added successfully!</b>,
          error: (error) => {
            if (
              error instanceof Error &&
              error.message === "Failed to add staff"
            ) {
              // Custom error message for failed staff addition
              return toast.error("Failed to add staff. Please try again.");
            } else if (
              error instanceof TypeError &&
              error.message === "Failed to fetch"
            ) {
              // Handle the "Failed to fetch" error and show a toast
              return toast.error(
                "Network error. Check your internet connection or try again later."
              );
            } else {
              // Default error message
              return toast.error("Failed to add staff.");
            }
          },
        }
      );
    } catch (error) {
      console.error("Error in handleFormSubmit:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen bg-white">
      <Toaster position="top-center" reverseOrder={true} />
      <Toolbar
        sx={{
          height: "95vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "95.5%",
            height: "95%",
          }}
        >
          <div className="text-3xl font-bold text-slate-900 mx-0 align-center">
            Add Staff
          </div>
          <div className="bg-white p-8 rounded shadow-lg text-center w-5/12 opacity-90% flex flex-col">
            <div>
              <h1 className="text-2xl font-bold mb-6">Enter Staff Details</h1>

              <form onSubmit={handleFormSubmit}>
                <p className="text-left mb-3">
                  Required fields are followed by *
                </p>
                <div className="flex justify-between flex-wrap">
                  <div className="w-[45%] mb-15 flex flex-row flex-wrap">
                    <label className="font-bold mb-1" htmlFor="name">
                      Staff Name: *
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter full name"
                      required
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row flex-wrap">
                    <label className="block font-bold mb-1" htmlFor="gender">
                      Gender: *
                    </label>
                    <select
                      name="gender"
                      id="gender"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      required
                      value={formData.gender}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled hidden>
                        Select Gender
                      </option>
                      <option value="0">Male</option>
                      <option value="1">Female</option>
                    </select>
                  </div>

                  <div className="w-[45%] mb-15 flex flex-row flex-wrap">
                    <label className="font-bold mb-1" htmlFor="roll_no">
                      Roll_no: *
                    </label>
                    <input
                      type="text"
                      id="roll_no"
                      name="roll_no"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter roll no"
                      required
                      value={formData.roll_no}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row flex-wrap">
                    <label className="font-bold mb-1" htmlFor="birthDate">
                      Date Of Birth: *
                    </label>
                    <input
                      type="date"
                      id="dob"
                      name="dob"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter Birth Date"
                      required
                      value={formData.dob}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[45%] mb-15 flex flex-row flex-wrap">
                    <label className="font-bold mb-1" htmlFor="role">
                      Role: *
                    </label>
                    <input
                      type="text"
                      id="role"
                      name="role"
                      className="w-full border border-gray-300 rounded px-3 py-2"
                      placeholder="Enter role"
                      required
                      value={formData.role}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="flex justify-center my-3">
                  <button
                    type="submit"
                    className={`bg-green-500 text-white px-4 py-2 rounded ${
                      isSubmitDisabled() ? "cursor-not-allowed opacity-50" : ""
                    }`}
                    disabled={isSubmitDisabled()}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Toolbar>
      <div className="footer-bottom w-full bg-lightblue-800 p-4 text-center text-black">
        <p>© 2023 Ed-Sanchaar. All rights reserved.</p>
      </div>
    </div>
  );
}

export default AddStaff;
