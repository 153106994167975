import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {
  Box,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem, // Add this import
} from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "id", headerName: "Id", width: 50 },
  { field: "name", headerName: "Name", width: 150 },
  { field: "class_id", headerName: "Class", width: 150 },
  { field: "batch", headerName: "Batch", width: 150 },
  { field: "DOB", headerName: "DOB", width: 150 },
];

function CoachingStudentManager() {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);

  useEffect(() => {
    // Function to decrypt role data
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(14) !== "1") {
      // console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);

  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };

  const [school_id, setSchoolId] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [coachingStudentsData, setSchoolCoachingStudentsData] = useState([]);

  const [schoolClasses, setSchoolClasses] = useState([]);
  const [classStudents, setClassStudents] = useState([]);

  const [selectedStudent, setSelectedStudent] = useState(""); // Add this line

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const [batch, setBatch] = useState("");

  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [openModal, setAddStudentModalOpen] = useState(false);
  const [modalClosed, setModalClosed] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [
    selectedStudentUsernameForUnenrollCoaching,
    setselectedStudentUsernameForUnenrollCoaching,
  ] = useState(null);

  const handelAddStudentModelOpen = () => {
    getSchoolClasses();
    setAddStudentModalOpen(true);
  };

  const handleOpenDeleteConfirmation = () => {
    setOpenDeleteConfirmation(true);
  };

  // Function to handle closing the delete confirmation dialog
  const handleCloseDeleteConfirmation = () => {
    setOpenDeleteConfirmation(false);
  };

  const handleEnrollConfirmation = () => {
    // Find the selected student object from classStudents array
    const selectedStudentObj = classStudents.find(
      (student) => student.username === selectedStudent
    );

    // Check if the selected student object exists
    if (selectedStudentObj) {
      // Set confirmation message with name and batch in bold
      const message = (
        <p>
          Confirm the addition of <strong>{selectedStudentObj.name}</strong> in{" "}
          <strong>{batch}</strong> batch.
        </p>
      );
      setConfirmationMessage(message);
      // Open confirmation modal
      setConfirmationModalOpen(true);
    } else {
      // Handle the case where the selected student is not found
      console.error("Selected student not found");
    }
  };

  const getSchoolClasses = () => {
    if (school_id) {
      const config = {
        headers: {
          Authorization: "aV0xQRFizDkSwyzksn2lc0xi4Rytc8y4fT1ftwiL8ek",
        },
      };

      axios
        .get(
          `https://edsanchaar.in/api/api_admin/getDistinctClasses.php?school_id=${school_id}`,
          config
        )
        .then((response) => {
          // Extract class_id values into a new array
          const classIds = response.data.map((item) => item.class_id);
          setSchoolClasses(classIds);
        })
        .catch((error) => console.error(error));
    }
  };

  const getSchoolCoachingStudents = () => {
    if (school_id) {
      const config = {
        headers: {
          Authorization: "aUBnAANvzn4CzHO76Cr+IHnjs3tvnr680eQSSZG2sgw",
        },
      };

      axios
        .get(
          `https://edsanchaar.in/api/api_admin/CoachingManager/getCoachingStudents.php?school_id=${school_id}`,
          config
        )
        .then((response) => {
          setSchoolCoachingStudentsData(response.data);
          setFilteredData(response.data);
        })
        .catch((error) => console.error(error));
    }
  };
  const getClassStudents = (class_id) => {
    if (school_id) {
      const config = {
        headers: {
          Authorization: "aUBnAANvzn4CzHO76Cr+IHnjs3tvnr680eQSSZG2sgw",
        },
      };

      axios
        .get(
          `https://edsanchaar.in/api/api_admin/CoachingManager/getDistinctClassStudents.php?school_id=${school_id}&class_id=${class_id}`,
          config
        )
        .then((response) => {
          setClassStudents(response.data);
        })
        .catch((error) => console.error(error));
    }
  };
  const enrollStudentInSchoolCoaching = async () => {
    if (school_id && selectedStudent) {
      const postData = {
        school_id,
        username: selectedStudent,
        batch: batch,
      };

      try {
        const response = await axios.post(
          `https://edsanchaar.in/api/api_admin/CoachingManager/enrollStudentInSchoolCoaching.php`,
          JSON.stringify(postData),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "aUBnAANvzn4CzHO76Cr+IHnjs3tvnr680eQSSZG2sgw",
            },
          }
        );

        if (response.data.status === 1) {
          toast.success("Student enrolled in School Coaching");
          setClassStudents([]);
          setConfirmationModalOpen(false);
          setAddStudentModalOpen(false);

          // Reset state values
          setDataChanged(true);
          setModalClosed(true);

          setSelectedStudent("");
        } else {
          toast.error("Error enrolling student");
          setConfirmationModalOpen(false);
        }
      } catch (error) {
        // toast.error("Error enrolling student:", error);
        toast.error("Error enrolling student. Please try again.");
      }
    }
  };

  const handleDeleteRecord = () => {
    // Check if a single row is selected for delete
    if (rowSelectionModel.length === 1) {
      // Get the selected payment data for delete
      const selectedIndex = rowSelectionModel[0];

      const selectedStudent = coachingStudentsData.find(
        (data) => data.id === selectedIndex
      );
      // console.log(selectedStudent);

      // Set the ID of the selected record for deletion
      setselectedStudentUsernameForUnenrollCoaching(selectedStudent.username);

      // Set the ID of the selected record for deletion
      setselectedStudentUsernameForUnenrollCoaching(selectedStudent.username);

      // Open the delete confirmation dialog
      handleOpenDeleteConfirmation();
    } else if (rowSelectionModel.length > 1) {
      toast.error("Only one student can be deleeted at a time");
    } else {
      toast.error("Please select a student to delete");
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `https://edsanchaar.in/api/api_admin/CoachingManager/unenrollStudentFromCoaching.php?username=${selectedStudentUsernameForUnenrollCoaching}&school_id=${school_id}`
      );

      // Display success toast
      toast.success("Record deleted successfully");
      setClassStudents([]);

      setModalClosed(true);
      setDataChanged(true);
    } catch (error) {
      // Display error toast
      toast.error("Error deleting record. Please try again.");
    }

    handleCloseDeleteConfirmation();
  };
  React.useEffect(() => {
    getSchoolCoachingStudents();
  }, [classStudents]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Refetch the data after successful update or deletion
        getSchoolCoachingStudents();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // Reset the modalClosed state and rowSelectionModel
        setModalClosed(false);
        setRowSelectionModel([]);
      }
    };

    if (modalClosed || dataChanged) {
      fetchData();
    }
  }, [modalClosed, dataChanged]);

  useEffect(() => {
    // If the modal has been closed, refetch the data
    if (modalClosed) {
      getSchoolCoachingStudents();
      setModalClosed(false); // Reset the flag
    }
  }, [modalClosed]);

  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      console.error("School ID not available");
      return;
    }

    setSchoolId(schoolIdFromCookie);
    // Fetch and set coachingStudentsData and filteredData based on the school_id
    // Example:
    const config = {
      headers: {
        Authorization: "aUBnAANvzn4CzHO76Cr+IHnjs3tvnr680eQSSZG2sgw",
      },
    };

    axios
      .get(
        `https://edsanchaar.in/api/api_admin/CoachingManager/getCoachingStudents.php?school_id=${schoolIdFromCookie}`,
        config
      )
      .then((response) => {
        setSchoolCoachingStudentsData(response.data);
        setFilteredData(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="flex items-center justify-center h-screen w-full">
      <Toaster position="top-center" reverseOrder={true} />

      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 2,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="h-full w-full flex flex-col justify-center items-center">
            <div className="text-3xl font-bold py-4">Payroll Manager</div>
            <div className="w-full flex flex-row border ">
              <form className="w-full flex flex-row justify-evenly flex-wrap">
                <div className="flex flex-row justify-center items-center ">
                  <Button onClick={handelAddStudentModelOpen}>
                    Add Student
                  </Button>
                </div>

                <div className="flex flex-row justify-center items-center ">
                  <Button variant="red" onClick={handleDeleteRecord}>
                    Remove from Coaching
                  </Button>
                </div>
                <Dialog
                  open={openDeleteConfirmation}
                  onClose={handleCloseDeleteConfirmation}
                >
                  <DialogTitle>Confirm Delete</DialogTitle>
                  <DialogContent>
                    <p>
                      Are you sure you want to unenroll Selected student from
                      Coaching?
                    </p>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDeleteConfirmation}>
                      Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary">
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={confirmationModalOpen}
                  onClose={() => setConfirmationModalOpen(false)}
                >
                  <DialogTitle>Confirmation</DialogTitle>
                  <DialogContent>
                    <p>{confirmationMessage}</p>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setConfirmationModalOpen(false)}>
                      Cancel
                    </Button>
                    <Button
                      onClick={enrollStudentInSchoolCoaching}
                      color="primary"
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openModal}
                  onClose={() => setAddStudentModalOpen(false)}
                >
                  <DialogTitle>Add Student</DialogTitle>
                  <DialogContent>
                    <TextField
                      select
                      label="Class"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      onChange={(event) => getClassStudents(event.target.value)}
                    >
                      {schoolClasses.map((classId) => (
                        <MenuItem key={classId} value={classId}>
                          {classId}
                        </MenuItem>
                      ))}
                    </TextField>

                    {/* Display students of the selected class */}
                    <TextField
                      select
                      label="Student"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={selectedStudent} // state to store selected student
                      onChange={(event) =>
                        setSelectedStudent(event.target.value)
                      }
                    >
                      {classStudents.map((student) => (
                        <MenuItem
                          key={student.username}
                          value={student.username}
                        >
                          {student.name}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      label="Batch"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={batch} // Set the value to the state
                      onChange={(event) => setBatch(event.target.value)} // Update the batch state when user types
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setAddStudentModalOpen(false)}>
                      Cancel
                    </Button>
                    <Button
                      onClick={() => handleEnrollConfirmation()}
                      color="primary"
                    >
                      Add
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            </div>
            <div className="w-full h-full overflow-x-auto flex flex-col items-center">
              <DataGrid
                className="w-full"
                rows={filteredData}
                columns={columns}
                pageSize={5}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                {...filteredData}
              />
            </div>
          </div>
        </Box>
      </Toolbar>
    </div>
  );
}

export default CoachingStudentManager;
