import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Toolbar } from "@mui/material";
import { useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const EditStaffDetails = () => {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);
  const [schoolId, setSchoolId] = useState("");

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(3) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const { username: usrname } = useParams();
  const [formData, setFormData] = useState({
    username: usrname,
    name: "",
    dob: "",
    roll_no: 0,
    role: "",
    school_id: "", // Add school_id field
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // const [schoolId, setSchoolId]= useState("")
  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      // Redirect to login or handle accordingly if schoolId is not available
      // You may want to add additional handling, such as showing an error message
      console.error("School ID not available");
      return;
    }

    // Set the school_id in the state
    setSchoolId(schoolIdFromCookie);
  }, []);

  const fetchStaffData = async (schoolId) => {
    try {
      const response = await axios.get(
        `https://edsanchaar.in/api/api_admin/Staff/getSingleStaff.php?username=${usrname}&school_id=${schoolId}`,
        {
          headers: {
            Authorization: "ZVs2URNyn3oA0zH68DqiYZX4g46IXygITFXRE/cW5A",
          },
        }
      );
      if (response.data) {
        // Assuming there's only one staff member with the given username
        const staffData = response.data;
        console.log("Staff Data:", staffData);

        setFormData({
          username: staffData.username,
          name: staffData.name,
          dob: staffData.dob,
          roll_no: staffData.roll_no,
          role: staffData.role,
          school_id: staffData.school_id,
        });

        console.log("API Response:", response.data);
      } else {
        console.error("Invalid data format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      ...formData,
      school_id: schoolId, // Assuming schoolId is available in the component state
    };

    try {
      const response = await axios.post(
        `https://edsanchaar.in/api/api_admin/Staff/updateStaffDetails.php`,
        updatedFormData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "aV0xQRFizDkSwyzksn2lc0xi4Rytc8y4fT1ftwiL8ek",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Record updated successfully!");
        fetchStaffData();
      } else {
        // console.error("Error updating Staff:", response.data);
        toast.error("Error updating Staff. Please try again.");
      }
    } catch (error) {
      toast.error("Error updating Staff. Please try again.");
    }
  };

  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      console.error("School ID not available");
      return;
    }

    setSchoolId(schoolIdFromCookie);

    fetchStaffData(schoolIdFromCookie);
  }, [usrname]);

  return (
    <div className="flex items-center justify-center w-full h-screen bg-white">
      <Toaster position="top-center" reverseOrder={true} />

      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="flex flex-col justify-center items-center">
            <form
              onSubmit={handleSubmit}
              className="w-screen max-w-md bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
            >
              <h2 className="text-2xl text-center font-semibold mb-4">
                EDIT {usrname}
              </h2>

              <div className="flex justify-between flex-wrap">
                <div className="w-[48%] mb-4">
                  <label className="font-bold mb-1" htmlFor="name">
                    Name:
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Enter Name"
                    value={formData.name} // Make sure the value is properly set
                    onChange={handleInputChange}
                  />
                </div>

                <div className="w-[48%] mb-4">
                  <label className="font-bold mb-1" htmlFor="role">
                    Role:
                  </label>
                  <input
                    type="text"
                    id="role"
                    name="role"
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Enter Role"
                    value={formData.role} // Make sure the value is properly set
                    onChange={handleInputChange}
                  />
                </div>

                <div className="w-[48%] mb-4">
                  <label className="font-bold mb-1" htmlFor="dob">
                    Date of Birth:
                  </label>
                  <input
                    type="date"
                    id="dob"
                    name="dob"
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    value={formData.dob}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="w-[48%] mb-4">
                  <label className="font-bold mb-1" htmlFor="roll_no">
                    Roll No:
                  </label>
                  <input
                    type="number"
                    id="roll_no"
                    name="roll_no"
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Enter Roll No"
                    value={formData.roll_no}
                    onChange={handleInputChange}
                  />
                </div>
                {/* Repeat the above structure for other fields */}
              </div>

              <div className="flex justify-center my-3">
                <button
                  type="submit"
                  className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Toolbar>
    </div>
  );
};

export default EditStaffDetails;
