import React, { useEffect, useState } from "react";
import { Toolbar, Box, Select, MenuItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "js-cookie";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const columns = [
  { field: "id", headerName: "Id", width: 90 },
  { field: "username", headerName: "Username", width: 120 },
  // { field: "password", headerName: "Password", width: 200 },
  {
    field: "name",
    headerName: "Name",
    width: 200,
  },
  { field: "student_UID", headerName: "Student UID", width: 100 },
  { field: "class_id", headerName: "Class", width: 100 },
  { field: "roll_no", headerName: "Roll No", width: 100 },
  {
    field: "father_name",
    headerName: "Father's Name",
    width: 100,
  },
  { field: "mother_name", headerName: "Mother's Name", width: 100 },
  { field: "address", headerName: "Address", width: 100 },
  { field: "DOB", headerName: "DOB", width: 100 },
  {
    field: "email",
    headerName: "Email",
    width: 100,
  },
  {
    field: "mobile",
    headerName: "Phone 1",
    width: 100,
  },
  { field: "phone", headerName: "Phone 2", width: 100 },
];

export default function FetchStudent() {
  // role id authentication
  const navigate = useNavigate();

  // role and role id
  const [schoolId, setSchoolId] = useState("");
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);
  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  React.useEffect(() => {
    // Retrieve the selected rows based on the row indexes in rowSelectionModel
    const selectedRows = sortedData.filter((data) =>
      rowSelectionModel.includes(data.id)
    );
    setSelectedRowsData(selectedRows);
  }, [rowSelectionModel]);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(2) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };

  const [userData, setUserData] = useState([]);
  const [classFilter, setClassFilter] = useState("");

  const [selectedClass, setSelectedClass] = useState("");
  const [loading, setLoading] = useState(true);
  const [revealedPasswords, setRevealedPasswords] = useState([]);

  const getCookie = Cookies.get("encryptedApiKey");

  const downloadDataHandler = async () => {
    if (selectedRowsData.length > 1) {
      toast.error("Please select one student at a time");
      return;
    }
    if (selectedRowsData.length == 0) {
      toast.error("Atleast select one student");
      return;
    }

    const username = selectedRowsData[0].username
    // https://edsanchaar.in/api/api_admin/StudentManager/downloadStudentData.php?school_id=STGNWD24&username=ASTG2371
    try {
      window.open(
        `https://edsanchaar.in/api/api_admin/StudentManager/downloadStudentData.php?school_id=${schoolId}&username=${username}`
      );
      // Check if the response indicates success
    } catch (error) {
      // Handle network errors
      toast.error("Error Generation Receipt", error);
    }
  };

  const handleClassChange = (e) => {
    setClassFilter(e.target.value);
    setSelectedClass(e.target.value); // Update selectedClass when the class is selected
  };

  const renderClassOptions = () => {
    const distinctClasses = [...new Set(userData.map((user) => user.class_id))];
    return distinctClasses.map((classId) => (
      <MenuItem key={classId} value={classId}>
        {classId}
      </MenuItem>
    ));
  };

  function getUsers(schoolId) {
    fetch(
      // `http://localhost/react/api_admin/getSchoolStudents.php?school_id=${schoolId}`,
      `https://edsanchaar.in/api/api_admin/getSchoolStudents.php?school_id=${schoolId}`,

      {
        method: "GET",
        headers: {
          Authorization: "aVExUBBin2oPzz3l4SG0YUR/jkFqakYnjoHr47SxDhvV",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  const togglePasswordReveal = (index) => {
    setRevealedPasswords((prevRevealedPasswords) => {
      const updatedRevealedPasswords = [...prevRevealedPasswords];
      updatedRevealedPasswords[index] = !updatedRevealedPasswords[index];
      return updatedRevealedPasswords;
    });
  };

  useEffect(() => {
    const school_id = Cookies.get("schoolId");
    if (!school_id) {
      // Redirect to login if schoolId is not available
      // You may want to add additional handling, such as showing an error message
      return;
    }
    setSchoolId(school_id);

    getUsers(school_id);
  }, []);

  // Filter data based on class input
  const filteredData =
    classFilter === ""
      ? userData
      : userData.filter((user) => user.class_id.includes(classFilter));
  const sortedData = filteredData.sort((a, b) =>
    a.class_id.localeCompare(b.class_id)
  );
  return (
    <div className="flex items-center justify-center w-full h-screen bg-white">
      <Toaster position="top-center" reverseOrder={true} />

      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="flex flex-col justify-start items-center">
            <div className="text-3xl font-bold">School Students</div>

            <div className="flex my-2 flex-row justify-center items-center">
              <div className="flex my-2 flex-row justify-center items-center">
                <div varient="" className="mx-4 text-md font-semibold text-lg">
                  Select Class
                </div>
                <Select onChange={handleClassChange} value={selectedClass}>
                  {renderClassOptions()}
                </Select>
              </div>
              <div className="flex my-2 flex-row justify-center items-center">
                <div varient="" className="mx-4 text-md font-semibold text-lg">
                  Download Student Data
                </div>
                <button
                  onClick={downloadDataHandler}
                  className="bg-blue-500 text-white px-4 py-2 rounded w-fit font-medium hover:bg-blue-600"
                >
                  Download Now
                </button>
              </div>
            </div>
          </div>
          {classFilter === "" ? (
            <div className="w-full h-full flex items-center justify-center font-semibold text-xl">
              Please select a class to view students.
            </div>
          ) : (
            <div className="w-full h-full overflow-x-auto">
              <div className="w-full h-full overflow-x-auto flex flex-col items-center">
                <DataGrid
                  className="w-full"
                  rows={sortedData}
                  columns={columns}
                  pageSize={5}
                  checkboxSelection
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                  rowSelectionModel={rowSelectionModel}
                  {...filteredData}
                />
              </div>
            </div>
          )}
          {loading && (
            <div className="flex w-full items-center justify-center">
              <CircularProgress size={60} />
            </div>
          )}
        </Box>
      </Toolbar>
    </div>
  );
}
