import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {
  Box,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem, // Add this import
} from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "id", headerName: "Id", width: 20 },
  { field: "employee_type", headerName: "Employee", width: 150 },
  { field: "employee_name", headerName: "Name", width: 150 },
  { field: "base_salary", headerName: "Base Sallary", width: 150 },
  { field: "bonus_salary", headerName: "Bonus Sallary", width: 150 },
  { field: "additional_salary", headerName: "Additional Sallary", width: 140 },
  { field: "month", headerName: "Month", width: 80 },
  { field: "year", headerName: "Year", width: 100 },
  { field: "paid_at", headerName: "Paid Date", width: 140 },
  { field: "updated_at", headerName: "Update Date", width: 140 },
];

function PayrollManager() {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(5) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const [school_id, setSchoolId] = useState("");
  const [employeeType, setEmployeeType] = useState("");

  const [selectedEmployeeData, setSelectedEmployeeData] = useState({});

  const [filteredData, setFilteredData] = useState([]);

  const [payrollData, setPayrollData] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [modalClosed, setModalClosed] = useState(false);

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [selectedUpdateData, setSelectedUpdateData] = useState({});

  const [dataChanged, setDataChanged] = useState(false);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedRecordIdForDelete, setSelectedRecordIdForDelete] =
    useState(null);

  const [paymentDetails, setPaymentDetails] = useState({
    year: "",
    month: "",
    base_salary: "",
    bonus_salary: "",
    additional_salary: "",
    paymentDate: "",
  });

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenUpdateModal = () => {
    setOpenUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setOpenUpdateModal(false);
  };
  const handleOpenDeleteConfirmation = () => {
    setOpenDeleteConfirmation(true);
  };

  // Function to handle closing the delete confirmation dialog
  const handleCloseDeleteConfirmation = () => {
    setOpenDeleteConfirmation(false);
  };
  const handleUpdateInputChange = (e) => {
    setPaymentDetails((prevPaymentDetails) => ({
      ...prevPaymentDetails,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputChange = (e) => {
    if (e.target.name === "employeeType") {
      setEmployeeType(e.target.value);
    } else {
      setPaymentDetails({
        ...paymentDetails,
        [e.target.name]: e.target.value,
      });
    }
  };

  const getSchoolEmployeeData = () => {
    if (school_id && employeeType) {
      axios
        .get(
          `https://edsanchaar.in/api/api_admin/Payroll/getEmployees.php?school_id=${school_id}&employee_type=${employeeType}`
        )
        .then((response) => {
          setEmployees(response.data);
        })
        .catch((error) => console.error(error));
    }
  };

  const handleEmployeeSelectionChange = (e) => {
    const selectedEmployeeId = e.target.value;
    const selectedEmployee = employees.find(
      (employee) => employee.id === selectedEmployeeId
    );
    setSelectedEmployeeData(selectedEmployee || {});

    // Update the selected employee ID in paymentDetails
    setPaymentDetails({
      ...paymentDetails,
      selectedEmployee: selectedEmployeeId,
    });
  };

  const handleUpdatePayment = () => {
    // Check if a single row is selected for update
    if (rowSelectionModel.length === 1) {
      // Get the selected payment data for update
      const selectedIndex = rowSelectionModel[0];
      const selectedPayment = payrollData.find(
        (payment) => payment.id === selectedIndex
      );
      setSelectedUpdateData(selectedPayment);

      // Open the update modal
      setOpenUpdateModal(true);
    }
  };

  const handleDeleteRecord = () => {
    // Check if a single row is selected for delete
    if (rowSelectionModel.length === 1) {
      // Get the selected payment data for delete
      const selectedIndex = rowSelectionModel[0];
      const selectedPayment = payrollData.find(
        (payment) => payment.id === selectedIndex
      );

      // Set the ID of the selected record for deletion
      setSelectedRecordIdForDelete(selectedPayment.id);

      // Set the ID of the selected record for deletion
      setSelectedRecordIdForDelete(selectedPayment.id);

      // Open the delete confirmation dialog
      handleOpenDeleteConfirmation();
    }
  };

  const handleUpdatePaymentDetails = async () => {
    // Prepare the data to be sent to the API for update
    const updateData = {
      id: selectedUpdateData.id,
      school_id,
      year: paymentDetails.year || selectedUpdateData.year,
      month: paymentDetails.month || selectedUpdateData.month,
      base_salary: paymentDetails.base_salary || selectedUpdateData.base_salary,
      bonus_salary:
        paymentDetails.bonus_salary || selectedUpdateData.bonus_salary,
      additional_salary:
        paymentDetails.additional_salary ||
        selectedUpdateData.additional_salary,
      paid_at: paymentDetails.paymentDate || selectedUpdateData.paid_at,
    };

    // Send a POST request to the API with JSON data for update
    try {
      const response = await axios.post(
        "https://edsanchaar.in/api/api_admin/Payroll/updatePayrollDetails.php",
        JSON.stringify(updateData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setModalClosed(true);

      // Refetch the data after successful update
      await getSchoolEmployeeData();
    } catch (error) {
      console.error("Error updating payment:", error);
    }

    // Close the update modal after updating the payment
    handleCloseUpdateModal();
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `https://edsanchaar.in/api/api_admin/Payroll/deletePayrollDetails.php?payroll_id=${selectedRecordIdForDelete}&school_id=${school_id}`
      );

      // Display success toast
      toast.success("Record deleted successfully");

      setModalClosed(true);
      setDataChanged(true);
    } catch (error) {
      // Display error toast
      toast.error("Error deleting record. Please try again.");
    }

    handleCloseDeleteConfirmation();
  };

  const handleRegisterPayment = async () => {
    const requiredFieldsFilled =
      employeeType &&
      selectedEmployeeData.id &&
      paymentDetails.paymentDate &&
      paymentDetails.year &&
      paymentDetails.month &&
      paymentDetails.base_salary &&
      paymentDetails.bonus_salary &&
      paymentDetails.additional_salary;

    if (!requiredFieldsFilled) {
      // Display an error message or handle the case where required fields are not filled
      toast.error("Please fill in all required fields.");
      return;
    }

    // Prepare the data to be sent to the API
    const postData = {
      school_id,
      employee_type: employeeType,
      employee_name: selectedEmployeeData.name,
      employee_username: selectedEmployeeData.username,
      year: paymentDetails.year,
      month: paymentDetails.month,
      base_salary: paymentDetails.base_salary,
      bonus_salary: paymentDetails.bonus_salary,
      additional_salary: paymentDetails.additional_salary,
      paid_at: paymentDetails.paymentDate,
    };

    try {
      // Use toast.promise to handle promises
      await toast.promise(
        // The promise to execute
        new Promise(async (resolve, reject) => {
          try {
            // Intentional 2-second delay
            setTimeout(async () => {
              // Send a POST request to the API with JSON data
              const response = await axios.post(
                "https://edsanchaar.in/api/api_admin/Payroll/addSchoolPayrolls.php",
                JSON.stringify(postData),
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );

              // Check the API response and resolve/reject the promise accordingly
              if (response.data.status === 1) {
                resolve(response.data.message); // Resolve the promise on success

                setEmployeeType("");
                setSelectedEmployeeData({});
                setPaymentDetails({
                  year: "",
                  month: "",
                  base_salary: "",
                  bonus_salary: "",
                  additional_salary: "",
                  paymentDate: "",
                });
              } else {
                reject(response.data.message); // Reject the promise on failure
              }
            }, 2000); // Intentional 2-second delay
          } catch (error) {
            reject("Error registering payment. Please try again."); // Reject the promise on error
            console.error("Error registering payment:", error);
          }
        }),
        {
          // Toast options
          loading: "Registering Payment...",
          success: (message) => <b>{message}</b>,
          error: (message) => <b>{message}</b>,
        }
      );

      // Refetch the data after successful registration
      await getSchoolEmployeeData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    // Close the modal after registering the payment
    handleCloseModal();
    setModalClosed(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Refetch the data after successful update or deletion
        await getSchoolEmployeeData();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // Reset the modalClosed state and rowSelectionModel
        setModalClosed(false);
        setRowSelectionModel([]);
      }
    };

    if (modalClosed || dataChanged) {
      fetchData();
    }
  }, [modalClosed, dataChanged]);

  useEffect(() => {
    // If the modal has been closed, refetch the data
    if (modalClosed) {
      axios
        .get(
          `https://edsanchaar.in/api/api_admin/Payroll/getSchoolPayrolls.php?school_id=${school_id}`
        )
        .then((response) => {
          setPayrollData(response.data);
          setFilteredData(response.data);
        })
        .catch((error) => console.error(error));
      setModalClosed(false); // Reset the flag
    }
  }, [modalClosed]);

  useEffect(() => {
    // Call getSchoolEmployeeData when employeeType changes
    getSchoolEmployeeData();
  }, [employeeType]);
  useEffect(() => {
    // Retrieve the selected rows based on the row indexes in rowSelectionModel
    const selectedRows = rowSelectionModel.map(
      (index) => payrollData[index - 1]
    );
    setSelectedRowsData(selectedRows);
  }, [rowSelectionModel, payrollData]);

  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      console.error("School ID not available");
      return;
    }

    setSchoolId(schoolIdFromCookie);
    // Fetch and set payrollData and filteredData based on the school_id
    // Example:
    axios
      .get(
        `https://edsanchaar.in/api/api_admin/Payroll/getSchoolPayrolls.php?school_id=${schoolIdFromCookie}`
      )
      .then((response) => {
        setPayrollData(response.data);
        setFilteredData(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="flex items-center justify-center h-screen w-full">
      <Toaster position="top-center" reverseOrder={true} />

      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 2,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="h-full w-full flex flex-col justify-center items-center">
            <div className="text-3xl font-bold py-4">Payroll Manager</div>
            <div className="w-full flex flex-row border ">
              <form className="w-full flex flex-row justify-evenly flex-wrap">
                <div className="flex flex-row justify-center items-center ">
                  <Button onClick={handleOpenModal}>Register Payment</Button>
                </div>
                <div className="flex flex-row justify-center items-center ">
                  <Button onClick={handleUpdatePayment}>Update Payment</Button>
                </div>
                <div className="flex flex-row justify-center items-center ">
                  <Button variant="red" onClick={handleDeleteRecord}>
                    Delete Record
                  </Button>
                </div>
                <Dialog
                  open={openDeleteConfirmation}
                  onClose={handleCloseDeleteConfirmation}
                >
                  <DialogTitle>Confirm Delete</DialogTitle>
                  <DialogContent>
                    <p>Are you sure you want to delete the selected record?</p>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDeleteConfirmation}>
                      Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary">
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            </div>
            <div className="w-full h-full overflow-x-auto flex flex-col items-center">
              <DataGrid
                className="w-full"
                rows={filteredData}
                columns={columns}
                pageSize={5}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                {...filteredData}
              />
            </div>
          </div>
        </Box>
      </Toolbar>

      {/* Payment Registration Modal */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Register Payment</DialogTitle>
        <DialogContent>
          {/* Add your input fields for payment details */}
          <TextField
            select
            label="Employee Type"
            name="employeeType"
            value={employeeType}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          >
            {/* Add options for employee types */}
            <MenuItem value="teacher">Teacher</MenuItem>
            <MenuItem value="staff">Staff</MenuItem>
            {/* Add more options as needed */}
          </TextField>
          {/* Add employee selection */}
          <TextField
            select
            label="Select Employee"
            name="selectedEmployee"
            value={selectedEmployeeData.id || ""}
            onChange={handleEmployeeSelectionChange}
            fullWidth
            margin="normal"
          >
            {employees.map((employee) => (
              <MenuItem key={employee.id} value={employee.id}>
                {employee.username} - {employee.name}{" "}
                {employee.role && `(${employee.role})`}{" "}
                {employee.class_teacher && `(Class Teacher)`}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Payment Date"
            type="date"
            name="paymentDate"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              focused: true,
            }}
            value={paymentDetails.paymentDate || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />

          {/* Add "Year" and "Month" input fields */}
          <TextField
            label="Year"
            type="number"
            name="year"
            value={paymentDetails.year || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Month"
            type="number"
            name="month"
            value={paymentDetails.month || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />

          {/* Add additional payment fields */}
          <TextField
            label="Base Salary"
            type="number"
            name="base_salary"
            value={paymentDetails.base_salary || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Bonus Salary"
            type="number"
            name="bonus_salary"
            value={paymentDetails.bonus_salary || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Additional Salary"
            type="number"
            name="additional_salary"
            value={paymentDetails.additional_salary || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleRegisterPayment} color="primary">
            Register
          </Button>
        </DialogActions>
      </Dialog>

      {/*payment update modal*/}
      <Dialog open={openUpdateModal} onClose={handleCloseUpdateModal}>
        <DialogTitle>Update Payment</DialogTitle>
        <DialogContent>
          {/* Display the specific payment details in the update modal */}
          <TextField
            label="Base Salary"
            type="number"
            name="base_salary"
            value={
              paymentDetails.base_salary || selectedUpdateData.base_salary || ""
            }
            onChange={handleUpdateInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Bonus Salary"
            type="number"
            name="bonus_salary"
            value={
              paymentDetails.bonus_salary ||
              selectedUpdateData.bonus_salary ||
              ""
            }
            onChange={handleUpdateInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Additional Salary"
            type="number"
            name="additional_salary"
            value={
              paymentDetails.additional_salary ||
              selectedUpdateData.additional_salary ||
              ""
            }
            onChange={handleUpdateInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Month"
            type="number"
            name="month"
            value={paymentDetails.month || selectedUpdateData.month || ""}
            onChange={handleUpdateInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Year"
            type="number"
            name="year"
            value={paymentDetails.year || selectedUpdateData.year || ""}
            onChange={handleUpdateInputChange}
            fullWidth
            margin="normal"
          />
          {/* Add other fields as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdateModal}>Cancel</Button>
          <Button onClick={handleUpdatePaymentDetails} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PayrollManager;
