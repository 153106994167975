import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MaterialSelect,
} from "@mui/material";

const columns = [
  { field: "id", headerName: " S.No", width: 90 },
  { field: "fee_id", headerName: "Fee Id", width: 90 },
  { field: "username", headerName: "Username", width: 120 },
  { field: "name", headerName: "Name", width: 200 },
  { field: "class_id", headerName: "Class ID", width: 100 },
  { field: "roll_no", headerName: "Roll No", width: 100 },
  {
    field: "boarder",
    headerName: "Boarder",
    width: 100,
    renderCell: (params) => <span>{params.value === 1 ? "Yes" : "No"}</span>,
  },
  { field: "plan", headerName: "Plan", width: 100 },
  { field: "title", headerName: "Title", width: 100 },
  { field: "base_fee", headerName: "Base Fee", width: 100 },
  {
    field: "miscellaneous_charges",
    headerName: "Miscellaneous Charges",
    width: 100,
  },
  { field: "total_fees", headerName: "Total Fees", width: 100 },
];

const planOptions = [
  { value: "Monthly", label: "Monthly" },
  { value: "Bi-Monthly", label: "Bi-Monthly" },
  { value: "Quarterly", label: "Quarterly" },
  { value: "Tri-Annually", label: "Tri-Annually" },
  { value: "Half-Yearly", label: "Half-Yearly" },
  { value: "Yearly", label: "Yearly" },
  { value: "Other", label: "Other" },
];
function FeeManage() {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoading(false); // Set loading to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // Check the condition and navigate only if not loading and role is not "1"
    if (!loading && decryptedRoleId.charAt(4) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loading]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const [school_id, setSchoolId] = useState("");
  const [classIds, setClassIds] = useState([]);
  const [classIdsForFeeAdd, setclassIdsForFeeAdd] = useState([]);
  const [studentsForSelectedClass, setStudentsForSelectedClass] = useState([]);

  const [classFilter, setClassFilter] = useState("");

  const [planFilter, setPlanFilter] = useState("all");
  const [filteredData, setFilteredData] = useState([]);

  const [studentData, setStudentData] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    username: "",
  });

  const [feeAddOpen, setFeeAddOpen] = useState(false);
  const [modalData, setModalData] = useState({
    base_fee: "",
    miscellaneous_charges: "",
    totalfee: "",
    plan: "",
    title: "",
  });
  const [baseFee, setBaseFee] = useState(0);
  const [miscellaneousCharges, setMiscellaneousCharges] = useState(0);
  const [feeTitle, setFeeTitle] = useState("");
  const [feeIds, setFeeIds] = useState([]);

  // New state variables for the add fee record dialog
  const [classId, setClassId] = useState("");
  const [studentName, setStudentName] = useState("");
  const [dueDate, setDueDate] = useState("");

  
  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      // Redirect to login or handle accordingly if schoolId is not available
      // You may want to add additional handling, such as showing an error message
      console.error("School ID not available");
      return;
    }

    // Set the school_id in the state
    setSchoolId(schoolIdFromCookie);
  }, []);

  // Function to handle changes in the new input fields
  const handleClassIdChange = (e) => setClassId(e.target.value);
  const handleStudentNameChange = (e) => {
    const newStudentName = e.target.value;
    setStudentName(newStudentName);

    // Set the roll_no when the student name changes
    const selectedStudent = studentsForSelectedClass.find(
      (student) => student.name === newStudentName
    );
    if (selectedStudent) {
      setRollNo(selectedStudent.roll_no);
    }
  };
  const handleDueDateChange = (e) => setDueDate(e.target.value);

  const [totalFee, setTotalFee] = useState(0); // Add this line to define totalFee state

  const handleOpen = () => {
    setFeeAddOpen(true);

    // Display the data of the first selected row in the modal
    if (selectedRowsData.length > 0) {
      const firstRowData = selectedRowsData[0];
      setModalData({
        base_fee: firstRowData.base_fee,
        miscellaneous_charges: firstRowData.miscellaneous_charges,
        totalfee: firstRowData.total_fees,
        plan: firstRowData.plan,
        title: firstRowData.title,
      });
      setBaseFee(firstRowData.base_fee);
      setFeeTitle(firstRowData.title);
      setMiscellaneousCharges(firstRowData.miscellaneous_charges);

      // Set the initial totalFee based on the data
      setTotalFee(firstRowData.total_fees);
    }
  };

  const handleBaseFeeChange = (e) => {
    const newBaseFee = parseFloat(e.target.value) || 0;
    setBaseFee(newBaseFee);
    // Update totalFee when baseFee changes
    setTotalFee(newBaseFee + miscellaneousCharges);
  };

  const handelFeeTitleChange = (e) => {
    setFeeTitle(e.target.value); // Fix: add parentheses to setFeeTitle
  };

  const handleMiscellaneousChargesChange = (e) => {
    const newMiscellaneousCharges = parseFloat(e.target.value) || 0;
    setMiscellaneousCharges(newMiscellaneousCharges);
    // Update totalFee when miscellaneousCharges changes
    setTotalFee(baseFee + newMiscellaneousCharges);
  };

  const handelUpdateFeeDetails = async () => {
    // Create FormData
    const formData = new FormData();

    // Only append the title if all selected rows have the same title
    formData.append("title", feeTitle);
    formData.append("baseFee", baseFee);
    formData.append("miscellaneousCharges", miscellaneousCharges);
    formData.append("totalFee", totalFee);
    formData.append("fee_ids", JSON.stringify(feeIds));

    try {
      const response = await axios.post(
        `https://edsanchaar.in/api/api_admin/updateFeeDetails.php?school_id=${school_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // Add any other headers as needed
          },
        }
      );

      // Handle the API response as needed
      const selectedClass = classFilter;
      await getClassStudents(selectedClass);
      handleClose();
    } catch (error) {
      // Handle errors
      console.error("Error sending data to API:", error);
    }
  };

  const handleClose = () => {
    setFeeAddOpen(false);
    setTotalFee(0);
  };

  const handleClassFilterChange = async (e) => {
    const selectedClass = e.target.value;
    setClassFilter(selectedClass);
    await getClassStudents(selectedClass);
  };
  const handelPlanFiter = (value) => {
    setPlanFilter(value);
  };

  const getClassStudents = async (selectedClass) => {
    const apiUrl = `https://edsanchaar.in/api/api_admin/getClassStudents.php?school_id=${school_id}&class_id=${selectedClass}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: "aV0xQRFizDkSwyzksn2lc0xi4Rytc8y4fT1ftwiL8ek",
        },
      });

      const data = response.data;

      if (Array.isArray(data)) {
        // Manually set an 'id' property for each row based on the index
        const studentDataWithIds = data.map((row, index) => ({
          ...row,
          id: index + 1, // You can adjust this based on your requirements
        }));

        setStudentData(studentDataWithIds);
      } else {
        console.error("Invalid response format for student data:", data);
      }
    } catch (error) {
      console.error("Error fetching student data:", error);
    }
  };

  // add fee record
  React.useEffect(() => {
    fetchStudentsForClass();
  }, [classId]);

  const fetchStudentsForClass = async () => {
    if (classId) {
      const apiUrl = `https://edsanchaar.in/api/api_admin/FeeManager/getDistinctClassStudents.php?school_id=${school_id}&class_id=${classId}`;

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            authorization: "aV0xQRFizDkSwyzksn2lc0xi4Rytc8y4fT1ftwiL8ek",
          },
        });

        const data = response.data;

        if (Array.isArray(data)) {
          // Manually set an 'id' property for each row based on the index
          const studentsWithIds = data.map((row, index) => ({
            ...row,
            id: index + 1, // You can adjust this based on your requirements
          }));

          setStudentsForSelectedClass(studentsWithIds);
        } else {
          console.error("Invalid response format for students data:", data);
        }
      } catch (error) {
        console.error("Error fetching students data:", error);
      }
    }
  };
  const [plan, setPlan] = useState("");
  const [title, setTitle] = useState("");
  const [rollNo, setRollNo] = useState("");

  const handlePlanChange = (e) => setPlan(e.target.value);
  useEffect(() => {
    console.log(plan);
  }, [plan]);
  const handleTitleChange = (e) => setTitle(e.target.value);

  const [newFeeAddOpen, setNewFeeAddOpen] = useState(false);

  const handleNewFeeClose = () => {
    setNewFeeAddOpen(false);
    setStudentName("");
    setPlan("");
    setTitle("");
    setBaseFee(0);
    setMiscellaneousCharges(0);
    setTotalFee(0);
  };

  const openAddFeeDialog = () => {
    // Reset the new input fields
    setNewFeeAddOpen(false);
    setStudentName("");
    setPlan("");
    setTitle("");
    setBaseFee(0);
    setMiscellaneousCharges(0);
    setTotalFee(0);
    setClassId("");
    setStudentName("");
    setDueDate("");
    setNewFeeAddOpen(true);
  };

  const [schoolId, setSchool]= useState("");
  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      // Redirect to login or handle accordingly if schoolId is not available
      // You may want to add additional handling, such as showing an error message
      console.error("School ID not available");
      return;
    }

    // Set the school_id in the state
    setSchool(schoolIdFromCookie);
  }, []);
  
  const handleAddFeeRecord = async () => {
    // Create FormData
    const formData = new FormData();
    formData.append("school_id", school_id);
    formData.append("class_id", classId);
    formData.append("name", studentName);
    formData.append("plan", plan);
    formData.append("roll_no", rollNo);

    formData.append("title", title);
    formData.append("base_fee", baseFee);
    formData.append("miscellaneous_charges", miscellaneousCharges);
    formData.append("total_fee", totalFee);
    formData.append("due_date", dueDate);

    try {
      const response = await axios.post(
        "https://edsanchaar.in/api/api_admin/FeeManager/addFeeRecord.php",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            // Add any other headers as needed
          },
        }
      );

      // Handle the API response as needed
      const selectedClass = classFilter;
      await getClassStudents(selectedClass);
      handleNewFeeClose();
    } catch (error) {
      // Handle errors
      console.error("Error sending data to API:", error);
    }
  };

  // edit fee record
  const addFeeDetails = () => {
    // Check if there are selected rows
    if (selectedRowsData.length === 0) {
      console.error("No rows selected");
      return;
    }

    // Extract boarder, plan, and title values from the first selected row
    const { boarder, plan, title } = selectedRowsData[0];

    // Check if all selected rows have the same boarder, plan, and title values
    const isSameValues = selectedRowsData.every(
      (rowData) => rowData.boarder === boarder && rowData.plan === plan
    );

    if (!isSameValues) {
      console.error("Selected rows do not have the same boarder, plan values");
      return;
    }

    const feeIdArray = selectedRowsData.map((rowData) => rowData.fee_id);

    setFeeIds(feeIdArray);
    handleOpen();
  };

  const deleteFeeRecord = async () => {
    // Check if there are selected rows
    if (selectedRowsData.length === 0) {
      console.error("No rows selected");
      return;
    } else if (selectedRowsData.length > 1) {
      console.error("You cannot select more than one row to delete at once");
      return;
    }

    // Extract fee_ids from the selected row
    const feeId = selectedRowsData[0].fee_id;

    const formData = new FormData();
    formData.append("fee_ids", feeId);
   
    
    try {
      const response = await axios.post(
        `https://edsanchaar.in/api/api_admin/deleteFeeRecord.php?school_id=${schoolId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // Add any other headers as needed
          },
        }
      );

      // Update the data after deletion
      const selectedClass = classFilter;
      await getClassStudents(selectedClass);
      closeDeleteConfirmation();
    } catch (error) {
      // Handle errors
      console.error("Error deleting fee record:", error);
    }
  };

  const openDeleteConfirmation = () => {
    if (selectedRowsData.length === 0) {
      console.error("No rows selected");
      return;
    } else if (selectedRowsData.length > 1) {
      console.error("Your cannot select more than one row to delete at once");
      return;
    }

    setDeleteConfirmation({
      isOpen: true,
    });
    console.log(selectedRowsData);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmation({
      isOpen: false,
      username: "",
    });
  };

  const fetchClassIds = async (schoolId) => {
    const apiUrl = `https://edsanchaar.in/api/api_admin/getDistinctClasses.php?school_id=${schoolId}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: "aV0xQRFizDkSwyzksn2lc0xi4Rytc8y4fT1ftwiL8ek",
        },
      });

      const data = response.data;

      if (Array.isArray(data)) {
        const sortedClassIds = data
          .map((classInfo) => classInfo.class_id)
          .sort();
        setClassIds(sortedClassIds);
        setclassIdsForFeeAdd(sortedClassIds);
      } else {
        console.error("Invalid response format for class IDs:", data);
      }
    } catch (error) {
      console.error("Error fetching class IDs:", error);
    }
  };

  React.useEffect(() => {
    // Filter the data based on the selected plan
    const updatedData = studentData.filter((row) => {
      // If planFilter is 'all', show all rows
      if (planFilter === "all") {
        return true;
      }
      // Otherwise, filter based on the selected plan
      return row.plan === planFilter;
    });

    setFilteredData(updatedData);
  }, [studentData, planFilter]);

  React.useEffect(() => {
    // Retrieve the selected rows based on the row indexes in rowSelectionModel
    const selectedRows = rowSelectionModel.map(
      (index) => studentData[index - 1]
    );
    setSelectedRowsData(selectedRows);
  }, [rowSelectionModel, studentData]);

  React.useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      console.error("School ID not available");
      return;
    }

    setSchoolId(schoolIdFromCookie);
    fetchClassIds(schoolIdFromCookie);
  }, []);

  return (
    <div className="flex items-center justify-center h-screen w-full">
      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 2,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="h-full w-full flex flex-col justify-center items-center">
            <div className="text-3xl font-bold py-4">School Fee Data</div>
            <div className="w-full flex flex-row border ">
              <form
                // onSubmit={handleSubmit}
                className="w-full flex flex-row justify-evenly flex-wrap"
              >
                <div className="flex flex-row items-center mx-4">
                  <InputLabel id="class-filter-label">Class ID:</InputLabel>

                  <select
                    id="class-filter"
                    value={classFilter}
                    onChange={handleClassFilterChange}
                  >
                    <option value="">All Classes</option>
                    {classIds.map((classId) => (
                      <option key={classId} value={classId}>
                        {classId}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-row items-center mx-4">
                  <InputLabel id="class-filter-label">Payment Plan:</InputLabel>

                  <select
                    id="class-filter"
                    value={planFilter}
                    onChange={(e) => handelPlanFiter(e.target.value)}
                  >
                    <option value="all">All</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="Half-Yearly">Half Yearly</option>
                    <option value="Triannually">Triannually</option>{" "}
                    {/* Add the new option */}
                  </select>
                </div>

                {/* add fee records  */}
                <div className="flex flex-row justify-center items-center ">
                  <Button onClick={openAddFeeDialog}>Add Fee Record</Button>
                </div>
                <Modal
                  open={newFeeAddOpen}
                  onClose={handleNewFeeClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                      width: "full",
                      borderRadius: 2,
                    }}
                  >
                    <DialogTitle>Add New Fee Record</DialogTitle>
                    <DialogContent>
                      {/* Add your input fields for fee record details */}
                      <TextField
                        select
                        label="Class ID"
                        name="classId"
                        value={classId}
                        onChange={handleClassIdChange}
                        fullWidth
                        margin="normal"
                      >
                        <MenuItem value="">
                          <em>Select Class ID</em>
                        </MenuItem>
                        {classIdsForFeeAdd.map((classId) => (
                          <MenuItem key={classId} value={classId}>
                            {classId}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        select
                        label="Student Name"
                        name="studentName"
                        value={studentName}
                        onChange={handleStudentNameChange}
                        fullWidth
                        margin="normal"
                      >
                        <MenuItem value="">
                          <em>Select Student</em>
                        </MenuItem>
                        {studentsForSelectedClass.map((student) => (
                          <MenuItem key={student.id} value={student.name}>
                            {`${student.name} - ${student.roll_no} `}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        select
                        label="Plan"
                        name="plan"
                        value={plan}
                        onChange={handlePlanChange}
                        fullWidth
                        margin="normal"
                      >
                        {planOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        label="Title"
                        type="text"
                        name="title"
                        value={title}
                        onChange={handleTitleChange}
                        fullWidth
                        margin="normal"
                      />

                      <TextField
                        label="Base Fee"
                        type="text"
                        name="baseFee"
                        value={baseFee}
                        onChange={handleBaseFeeChange}
                        fullWidth
                        margin="normal"
                      />

                      <TextField
                        label="Miscellaneous Fee"
                        type="text"
                        name="miscellaneousFee"
                        value={miscellaneousCharges}
                        onChange={handleMiscellaneousChargesChange}
                        fullWidth
                        margin="normal"
                      />

                      <TextField
                        label="Total Fee"
                        type="text"
                        name="totalFee"
                        value={totalFee}
                        disabled
                        fullWidth
                        margin="normal"
                      />

                      <TextField
                        label="Due Date"
                        type="date"
                        name="dueDate"
                        value={dueDate}
                        onChange={handleDueDateChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DialogContent>

                    <DialogActions>
                      <Button onClick={handleNewFeeClose}>Cancel</Button>
                      <Button onClick={handleAddFeeRecord} color="primary">
                        Add Fee Record
                      </Button>
                    </DialogActions>
                  </Box>
                </Modal>

                {/* edit fee record component  */}
                <div className="flex flex-row justify-center items-center ">
                  <Button onClick={addFeeDetails}>Edit Fee Record</Button>
                </div>
                <Modal
                  open={feeAddOpen}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                      width: "full",
                      borderRadius: 2,
                    }}
                  >
                    <div className="flex flex-row justify-center text-xl">
                      <label style={{ marginBottom: "10px", display: "block" }}>
                        Enter Fee Details
                      </label>
                    </div>
                    <FormControl
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {/* Display the data in the modal */}
                      <div className="flex flex-col">
                        <label htmlFor="feeTitle">Fee Title</label>
                        <input
                          style={{
                            width: "100%",
                            padding: "8px",
                            borderRadius: "4px",
                            border: "0.5px solid #ccc",
                          }}
                          type="text"
                          name="feeTitle"
                          value={feeTitle}
                          onChange={handelFeeTitleChange}
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="baseFee">Base Fee</label>
                        <input
                          style={{
                            width: "100%",
                            padding: "8px",
                            borderRadius: "4px",
                            border: "0.5px solid #ccc",
                          }}
                          type="text"
                          name="feeAmount"
                          value={baseFee}
                          onChange={handleBaseFeeChange}
                        />
                      </div>

                      <div className="flex flex-col">
                        <label htmlFor="miscellaneousCharges">
                          Miscellaneous charges
                        </label>
                        <input
                          style={{
                            width: "100%",
                            padding: "8px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                          }}
                          type="text"
                          name="transportationCharges"
                          value={miscellaneousCharges}
                          onChange={handleMiscellaneousChargesChange}
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="totalFee">Total fee</label>
                        <input
                          disabled
                          style={{
                            width: "100%",
                            padding: "8px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                          }}
                          type="text"
                          name="totalFee"
                          value={totalFee}
                        />
                      </div>

                      {/* Add more fee details input fields as needed */}

                      <div style={{ marginTop: "16px" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{ width: "100%" }}
                          onClick={handelUpdateFeeDetails}
                        >
                          Update Fee Details
                        </Button>
                      </div>
                    </FormControl>

                    <div className="flex flex-row justify-between"></div>
                  </Box>
                </Modal>
                <div className="flex flex-row justify-center items-center ">
                  <Button color="error" onClick={openDeleteConfirmation}>
                    Delete Fee Record
                  </Button>
                </div>
                <Dialog
                  open={deleteConfirmation.isOpen}
                  onClose={closeDeleteConfirmation}
                >
                  <DialogTitle>Delete Confirmation</DialogTitle>
                  <DialogContent>
                    <p>
                      Do you want to delete fee record of selected user from
                      school records?
                    </p>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closeDeleteConfirmation}>Cancel</Button>
                    <Button
                      onClick={() => deleteFeeRecord()}
                      variant="contained"
                      color="error"
                    >
                      Confirm Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            </div>
            <div className="w-full h-full overflow-x-auto flex flex-col items-center">
              <DataGrid
                className="w-full"
                rows={filteredData}
                columns={columns}
                pageSize={5}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                {...filteredData}
              />
            </div>
          </div>
        </Box>
      </Toolbar>
    </div>
  );
}

export default FeeManage;
