import React from "react";
import logowithtext from "../Images/logo_with_text-3.png";
import { Link, useNavigate } from "react-router-dom";

const TermsAndConditions = () => {
  const navigate = useNavigate();

  const handleLoginForSchool = () => {
    navigate("/login");
  };

  const handleHomepage = () => {
    navigate("/");
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <header className="bg-white py-4 lg:px-4 px-6">
        <div className="container mx-auto flex items-center justify-between">
          <div className="cursor-pointer" onClick={handleHomepage}>
            <img src={logowithtext} width="180" alt="logo" className="block" />
          </div>
          <div className="hidden lg:flex items-center">
            <div
              onClick={handleLoginForSchool}
              className="rounded-md bg-blue-400 py-2 px-4 text-base font-medium text-white hover:bg-opacity-90"
            >
              Sign In
            </div>
          </div>
        </div>
      </header>
      <div className="container mx-auto mt-8 p-6 bg-white shadow-md rounded-md">
        <h1 className="text-3xl font-bold mb-6">
          Terms and Conditions for Ed-sanchaar
        </h1>
        <p className="mb-4">Effective Date: 17 December, 2023</p>

        <h2 className="text-xl font-semibold mb-4">1. Acceptance of Terms:</h2>
        <p className="mb-4">
          By downloading, accessing, or using the Ed-sanchaar mobile application
          ("the App") provided by Venuratech Solutions ("the Company," "we,"
          "our," or "us"), you agree to comply with and be bound by these Terms
          and Conditions ("Terms"). If you do not agree to these Terms, please
          do not use the App.
        </p>

        <h2 className="text-xl font-semibold mb-4">2. Use of the App:</h2>
        <p className="mb-4">
          a. License: The Company grants you a non-exclusive, non-transferable,
          revocable license to use the App solely for your personal and
          non-commercial purposes.
        </p>
        <p className="mb-4">
          b. User Accounts: You may be required to create an account to access
          certain features of the App. You are responsible for maintaining the
          confidentiality of your account information and are liable for all
          activities that occur under your account.
        </p>

        <h2 className="text-xl font-semibold mb-4">3. Data Privacy:</h2>
        <p className="mb-4">
          a. Collection of Information: By using the App, you consent to the
          collection, processing, and storage of personal information as
          outlined in the Privacy Policy.
        </p>
        <p className="mb-4">
          b. User Responsibilities: You are responsible for the accuracy and
          legality of the information you provide in the App, and you agree not
          to upload, share, or transmit any content that violates applicable
          laws or infringes on the rights of others.
        </p>

        <h2 className="text-xl font-semibold mb-4">
          4. Intellectual Property:
        </h2>
        <p className="mb-4">
          a. Ownership: The Company retains all rights, title, and interest in
          and to the App, including all intellectual property rights.
        </p>
        <p className="mb-4">
          b. Restrictions: You may not copy, modify, distribute, sell, or lease
          any part of the App or its content, nor may you reverse engineer or
          attempt to extract the source code.
        </p>

        <h2 className="text-xl font-semibold mb-4">5. User Conduct:</h2>
        <p className="mb-4">
          a. Prohibited Activities: You agree not to engage in any activities
          that may harm, disrupt, or interfere with the functioning of the App,
          including but not limited to introducing viruses, malware, or other
          harmful code.
        </p>
        <p className="mb-4">
          b. Compliance: You agree to comply with all applicable laws and
          regulations while using the App.
        </p>

        <h2 className="text-xl font-semibold mb-4">6. Termination:</h2>
        <p className="mb-4">
          a. Termination by User: You may terminate your account and use of the
          App at any time.
        </p>
        <p className="mb-4">
          b. Termination by Company: The Company reserves the right to suspend
          or terminate your access to the App at its sole discretion, without
          notice, for any reason.
        </p>

        <h2 className="text-xl font-semibold mb-4">
          7. Limitation of Liability:
        </h2>
        <p className="mb-4">
          The Company shall not be liable for any indirect, incidental, special,
          consequential, or punitive damages, or any loss of profits or
          revenues, whether incurred directly or indirectly.
        </p>

        <h2 className="text-xl font-semibold mb-4">8. Updates and Changes:</h2>
        <p className="mb-4">
          The Company reserves the right to modify, update, or discontinue the
          App at any time without notice. Users are encouraged to check for
          updates regularly.
        </p>

        <h2 className="text-xl font-semibold mb-4">9. Governing Law:</h2>
        <p className="mb-4">
          These Terms are governed by and construed in accordance with the laws
          of India. Any disputes arising out of or in connection with these
          Terms shall be subject to the exclusive jurisdiction of the courts in
          Pilibhit, Uttar Pradesh, India, 262122.
        </p>

        <h2 className="text-xl font-semibold mb-4">10. Contact Information:</h2>
        <p className="mb-6">
          For any questions or concerns regarding these Terms, please contact us
          at{" "}
          <a href="mailto:contactus@edsanchaar.in" className="text-blue-500">
            contactus@edsanchaar.in
          </a>
        </p>

        <p className="mb-4">
          By using the Ed-sanchaar App, you acknowledge that you have read,
          understood, and agreed to these Terms and Conditions.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
