import React, { useState, useEffect } from "react";

const StudentMarksheetTable = ({
  existingMarksheetData,
  userData,
  schoolId,
  resultId,
}) => {
  // Extract subjects and exams from the existingMarksheetData
  const subjects = existingMarksheetData[0].SubExamSubjects.map(
    (subject) => subject.subject
  );
  const exams = existingMarksheetData.map((exam) => `${exam.ExamName} `);

  // Initialize variables to store subject totals and overall total
  const subjectTotals = subjects.map(() => 0);
  let overallTotalMarks = 0;
  let overallMaxMarks = 0;

  const [tableStructure, setTableStructure] = useState(
    <div>
      <div>Hello world</div>
      <div>{subjects}</div>
    </div>
  );
  const replaceString = (inputString, searchString, replacementValue) => {
    // Use a regular expression with global flag to replace all occurrences
    const regex = new RegExp(searchString, "g");
    return inputString.replace(regex, replacementValue);
  };

  const getGrade = (marks) => {
    marks = parseInt(marks);

    if (schoolId === "GRDWS1223") {
      // Code specific to school_id = 4 and result_id = 4
      if (marks >= 91 && marks <= 100) {
        return "A+";
      } else if (marks >= 81 && marks <= 90) {
        return "A";
      } else if (marks >= 71 && marks <= 80) {
        return "A-";
      } else if (marks >= 61 && marks <= 70) {
        return "B+";
      } else if (marks >= 51 && marks <= 60) {
        return "B";
      } else if (marks >= 41 && marks <= 50) {
        return "B-";
      } else if (marks >= 35) {
        return "C";
      } else {
        return "D";
      }
    } else {
      // Code for other school_id and result_id values
      // Add your conditions and code here
    }
  };

  const GRDWS1223_8_FINAL_2023_2024 = (data) => {
    const PT1ENG = existingMarksheetData[0].SubExamSubjects[0].marks_obtained;
    const PT1COM = existingMarksheetData[0].SubExamSubjects[1].marks_obtained;
    const PT1MATH = existingMarksheetData[0].SubExamSubjects[2].marks_obtained;
    const PT1SCI = existingMarksheetData[0].SubExamSubjects[3].marks_obtained;
    const PT1SST = existingMarksheetData[0].SubExamSubjects[4].marks_obtained;
    const PT1SKT = existingMarksheetData[0].SubExamSubjects[5].marks_obtained;
    const PT1HIN = existingMarksheetData[0].SubExamSubjects[6].marks_obtained;

    // Term 1
    const modifiedTableCode = replaceString(
      data[0].element,
      "@Sub1@",
      subjects[0]
    );
    const modifiedTableCode1 = replaceString(
      modifiedTableCode,
      "@Sub2@",
      subjects[1]
    );
    const modifiedTableCode2 = replaceString(
      modifiedTableCode1,
      "@Sub3@",
      subjects[2]
    );
    const modifiedTableCode3 = replaceString(
      modifiedTableCode2,
      "@Sub4@",
      subjects[3]
    );
    const modifiedTableCode4 = replaceString(
      modifiedTableCode3,
      "@Sub5@",
      subjects[4]
    );

    const modifiedTableCode5 = replaceString(
      modifiedTableCode4,
      "@Sub6@",
      subjects[5]
    );
    const modifiedTableCode6 = replaceString(
      modifiedTableCode5,
      "@Sub7@",
      subjects[6]
    );

    // PT1 DATA

    const modifiedTableCode7 = replaceString(
      modifiedTableCode6,
      "@sp1@",
      PT1ENG
    );
    const modifiedTableCode8 = replaceString(
      modifiedTableCode7,
      "@s2p1@",
      PT1COM
    );
    const modifiedTableCode9 = replaceString(
      modifiedTableCode8,
      "@s3p1@",
      PT1MATH
    );
    const modifiedTableCode10 = replaceString(
      modifiedTableCode9,
      "@s4p1@",
      PT1SCI
    );
    const modifiedTableCode11 = replaceString(
      modifiedTableCode10,
      "@s5p1@",
      PT1SST
    );
    const modifiedTableCode12 = replaceString(
      modifiedTableCode11,
      "@s6p1@",
      PT1SKT
    );

    const modifiedTableCode13 = replaceString(
      modifiedTableCode12,
      "@s7p1@",
      PT1HIN
    );

    // NBA 1 MARKS

    const NBA1ENG = existingMarksheetData[1].SubExamSubjects[0].marks_obtained;
    const NBA1COM = existingMarksheetData[1].SubExamSubjects[1].marks_obtained;
    const NBA1MATH = existingMarksheetData[1].SubExamSubjects[2].marks_obtained;
    const NBA1SCI = existingMarksheetData[1].SubExamSubjects[3].marks_obtained;
    const NBA1SST = existingMarksheetData[1].SubExamSubjects[4].marks_obtained;
    const NBA1SKT = existingMarksheetData[1].SubExamSubjects[5].marks_obtained;
    const NBA1HIN = existingMarksheetData[1].SubExamSubjects[6].marks_obtained;

    const modifiedTableCode14 = replaceString(
      modifiedTableCode13,
      "@sp2@",
      NBA1ENG
    );
    const modifiedTableCode15 = replaceString(
      modifiedTableCode14,
      "@s2p2@",
      NBA1COM
    );
    const modifiedTableCode16 = replaceString(
      modifiedTableCode15,
      "@s3p2@",
      NBA1MATH
    );
    const modifiedTableCode17 = replaceString(
      modifiedTableCode16,
      "@s4p2@",
      NBA1SCI
    );
    const modifiedTableCode18 = replaceString(
      modifiedTableCode17,
      "@s5p2@",
      NBA1SST
    );
    const modifiedTableCode19 = replaceString(
      modifiedTableCode18,
      "@s6p2@",
      NBA1SKT
    );
    const modifiedTableCode20 = replaceString(
      modifiedTableCode19,
      "@s7p2@",
      NBA1HIN
    );

    // SEA 1 MARKS

    const SEA1ENG = existingMarksheetData[2].SubExamSubjects[0].marks_obtained;
    const SEA1COM = existingMarksheetData[2].SubExamSubjects[1].marks_obtained;
    const SEA1MATH = existingMarksheetData[2].SubExamSubjects[2].marks_obtained;
    const SEA1SCI = existingMarksheetData[2].SubExamSubjects[3].marks_obtained;
    const SEA1SST = existingMarksheetData[2].SubExamSubjects[4].marks_obtained;
    const SEA1SKT = existingMarksheetData[2].SubExamSubjects[5].marks_obtained;
    const SEA1HIN = existingMarksheetData[2].SubExamSubjects[6].marks_obtained;

    const modifiedTableCode21 = replaceString(
      modifiedTableCode20,
      "@sp3@",
      SEA1ENG
    );
    const modifiedTableCode22 = replaceString(
      modifiedTableCode21,
      "@s2p3@",
      SEA1COM
    );
    const modifiedTableCode23 = replaceString(
      modifiedTableCode22,
      "@s3p3@",
      SEA1MATH
    );
    const modifiedTableCode24 = replaceString(
      modifiedTableCode23,
      "@s4p3@",
      SEA1SCI
    );
    const modifiedTableCode25 = replaceString(
      modifiedTableCode24,
      "@s5p3@",
      SEA1SST
    );
    const modifiedTableCode26 = replaceString(
      modifiedTableCode25,
      "@s6p3@",
      SEA1SKT
    );

    const modifiedTableCode27 = replaceString(
      modifiedTableCode26,
      "@s7p3@",
      SEA1HIN
    );

    // HALFYEARLY MARKS

    const HALFYEARLYENG =
      existingMarksheetData[3].SubExamSubjects[0].marks_obtained;
    const HALFYEARLYCOM =
      existingMarksheetData[3].SubExamSubjects[1].marks_obtained;
    const HALFYEARLYMATH =
      existingMarksheetData[3].SubExamSubjects[2].marks_obtained;
    const HALFYEARLYSCI =
      existingMarksheetData[3].SubExamSubjects[3].marks_obtained;
    const HALFYEARLYSST =
      existingMarksheetData[3].SubExamSubjects[4].marks_obtained;
    const HALFYEARLYSKT =
      existingMarksheetData[3].SubExamSubjects[5].marks_obtained;
    const HALFYEARLYHIN =
      existingMarksheetData[3].SubExamSubjects[6].marks_obtained;

    const modifiedTableCode28 = replaceString(
      modifiedTableCode27,
      "@sp4@",
      HALFYEARLYENG
    );
    const modifiedTableCode29 = replaceString(
      modifiedTableCode28,
      "@s2p4@",
      HALFYEARLYCOM
    );
    const modifiedTableCode30 = replaceString(
      modifiedTableCode29,
      "@s3p4@",
      HALFYEARLYMATH
    );
    const modifiedTableCode31 = replaceString(
      modifiedTableCode30,
      "@s4p4@",
      HALFYEARLYSCI
    );
    const modifiedTableCode32 = replaceString(
      modifiedTableCode31,
      "@s5p4@",
      HALFYEARLYSST
    );
    const modifiedTableCode33 = replaceString(
      modifiedTableCode32,
      "@s6p4@",
      HALFYEARLYSKT
    );
    const modifiedTableCode34 = replaceString(
      modifiedTableCode33,
      "@s7p4@",
      HALFYEARLYHIN
    );

    // Total Term 1
    const T1ENG =
      parseFloat(PT1ENG) +
      parseFloat(NBA1ENG) +
      parseFloat(SEA1ENG) +
      parseFloat(HALFYEARLYENG);
    const T1COM =
      parseFloat(PT1COM) +
      parseFloat(NBA1COM) +
      parseFloat(SEA1COM) +
      parseFloat(HALFYEARLYCOM);
    const T1HIN =
      parseFloat(PT1HIN) +
      parseFloat(NBA1HIN) +
      parseFloat(SEA1HIN) +
      parseFloat(HALFYEARLYHIN);
    const T1SKT =
      parseFloat(PT1SKT) +
      parseFloat(NBA1SKT) +
      parseFloat(SEA1SKT) +
      parseFloat(HALFYEARLYSKT);
    const T1SST =
      parseFloat(PT1SST) +
      parseFloat(NBA1SST) +
      parseFloat(SEA1SST) +
      parseFloat(HALFYEARLYSST);
    const T1MATH =
      parseFloat(PT1MATH) +
      parseFloat(NBA1MATH) +
      parseFloat(SEA1MATH) +
      parseFloat(HALFYEARLYMATH);
    const T1SCI =
      parseFloat(PT1SCI) +
      parseFloat(NBA1SCI) +
      parseFloat(SEA1SCI) +
      parseFloat(HALFYEARLYSCI);

    const modifiedTableCode35 = replaceString(
      modifiedTableCode34,
      "@st1@",
      T1ENG
    );
    const modifiedTableCode36 = replaceString(
      modifiedTableCode35,
      "@s2t1@",
      T1COM
    );
    const modifiedTableCode37 = replaceString(
      modifiedTableCode36,
      "@s3t1@",
      T1MATH
    );

    const modifiedTableCode38 = replaceString(
      modifiedTableCode37,
      "@s4t1@",
      T1SCI
    );

    const modifiedTableCode39 = replaceString(
      modifiedTableCode38,
      "@s5t1@",
      T1SST
    );

    const modifiedTableCode40 = replaceString(
      modifiedTableCode39,
      "@s6t1@",
      T1SKT
    );

    const modifiedTableCode41 = replaceString(
      modifiedTableCode40,
      "@s7t1@",
      T1HIN
    );

    const modifiedTableCode42 = replaceString(
      modifiedTableCode41,
      "@s7t1@",
      T1HIN
    );

    const modifiedTableCode43 = replaceString(
      modifiedTableCode42,
      "@sg1@",
      getGrade(T1ENG)
    );

    const modifiedTableCode44 = replaceString(
      modifiedTableCode43,
      "@s2g1@",
      getGrade(T1COM)
    );

    const modifiedTableCode45 = replaceString(
      modifiedTableCode44,
      "@s3g1@",
      getGrade(T1MATH)
    );

    const modifiedTableCode46 = replaceString(
      modifiedTableCode45,
      "@s4g1@",
      getGrade(T1SCI)
    );

    const modifiedTableCode47 = replaceString(
      modifiedTableCode46,
      "@s5g1@",
      getGrade(T1SST)
    );

    const modifiedTableCode48 = replaceString(
      modifiedTableCode47,
      "@s6g1@",
      getGrade(T1SKT)
    );

    const modifiedTableCode49 = replaceString(
      modifiedTableCode48,
      "@s7g1@",
      getGrade(T1HIN)
    );

    // PT2 MARKS DATA
    const PT2ENG = existingMarksheetData[4].SubExamSubjects[0].marks_obtained;
    const PT2COM = existingMarksheetData[4].SubExamSubjects[1].marks_obtained;
    const PT2MATH = existingMarksheetData[4].SubExamSubjects[2].marks_obtained;
    const PT2SCI = existingMarksheetData[4].SubExamSubjects[3].marks_obtained;
    const PT2SST = existingMarksheetData[4].SubExamSubjects[4].marks_obtained;
    const PT2SKT = existingMarksheetData[4].SubExamSubjects[5].marks_obtained;
    const PT2HIN = existingMarksheetData[4].SubExamSubjects[6].marks_obtained;

    const modifiedTableCode50 = replaceString(
      modifiedTableCode49,
      "@sp5@",
      PT2ENG
    );
    const modifiedTableCode51 = replaceString(
      modifiedTableCode50,
      "@s2p5@",
      PT2COM
    );

    const modifiedTableCode52 = replaceString(
      modifiedTableCode51,
      "@s3p5@",
      PT2MATH
    );

    const modifiedTableCode53 = replaceString(
      modifiedTableCode52,
      "@s4p5@",
      PT2SCI
    );

    const modifiedTableCode54 = replaceString(
      modifiedTableCode53,
      "@s5p5@",
      PT2SST
    );

    const modifiedTableCode55 = replaceString(
      modifiedTableCode54,
      "@s6p5@",
      PT2SKT
    );

    const modifiedTableCode56 = replaceString(
      modifiedTableCode55,
      "@s7p5@",
      PT2HIN
    );

    // NBA 2 MARKS DATA

    const NBA2ENG = existingMarksheetData[5].SubExamSubjects[0].marks_obtained;
    const NBA2COM = existingMarksheetData[5].SubExamSubjects[1].marks_obtained;
    const NBA2MATH = existingMarksheetData[5].SubExamSubjects[2].marks_obtained;
    const NBA2SCI = existingMarksheetData[5].SubExamSubjects[3].marks_obtained;
    const NBA2SST = existingMarksheetData[5].SubExamSubjects[4].marks_obtained;
    const NBA2SKT = existingMarksheetData[5].SubExamSubjects[5].marks_obtained;
    const NBA2HIN = existingMarksheetData[5].SubExamSubjects[6].marks_obtained;

    const modifiedTableCode57 = replaceString(
      modifiedTableCode56,
      "@sp6@",
      NBA2ENG
    );

    const modifiedTableCode58 = replaceString(
      modifiedTableCode57,
      "@s2p6@",
      NBA2COM
    );

    const modifiedTableCode59 = replaceString(
      modifiedTableCode58,
      "@s3p6@",
      NBA2MATH
    );
    const modifiedTableCode60 = replaceString(
      modifiedTableCode59,
      "@s4p6@",
      NBA2SCI
    );
    const modifiedTableCode61 = replaceString(
      modifiedTableCode60,
      "@s5p6@",
      NBA2SST
    );
    const modifiedTableCode62 = replaceString(
      modifiedTableCode61,
      "@s6p6@",
      NBA2SKT
    );
    const modifiedTableCode63 = replaceString(
      modifiedTableCode62,
      "@s7p6@",
      NBA2HIN
    );

    // SEA 2 DATA
    const SEA2ENG = existingMarksheetData[6].SubExamSubjects[0].marks_obtained;
    const SEA2COM = existingMarksheetData[6].SubExamSubjects[1].marks_obtained;
    const SEA2MATH = existingMarksheetData[6].SubExamSubjects[2].marks_obtained;
    const SEA2SCI = existingMarksheetData[6].SubExamSubjects[3].marks_obtained;
    const SEA2SST = existingMarksheetData[6].SubExamSubjects[4].marks_obtained;
    const SEA2SKT = existingMarksheetData[6].SubExamSubjects[5].marks_obtained;
    const SEA2HIN = existingMarksheetData[6].SubExamSubjects[6].marks_obtained;

    const modifiedTableCode64 = replaceString(
      modifiedTableCode63,
      "@sp7@",
      SEA2ENG
    );

    const modifiedTableCode65 = replaceString(
      modifiedTableCode64,
      "@s2p7@",
      SEA2COM
    );

    const modifiedTableCode66 = replaceString(
      modifiedTableCode65,
      "@s3p7@",
      SEA2MATH
    );

    const modifiedTableCode67 = replaceString(
      modifiedTableCode66,
      "@s4p7@",
      SEA2SCI
    );

    const modifiedTableCode68 = replaceString(
      modifiedTableCode67,
      "@s5p7@",
      SEA2SST
    );

    const modifiedTableCode69 = replaceString(
      modifiedTableCode68,
      "@s6p7@",
      SEA2SKT
    );

    const modifiedTableCode70 = replaceString(
      modifiedTableCode69,
      "@s7p7@",
      SEA2HIN
    );

    // Yearly Data
    const YEARLYENG =
      existingMarksheetData[7].SubExamSubjects[0].marks_obtained;
    const YEARLYCOM =
      existingMarksheetData[7].SubExamSubjects[1].marks_obtained;
    const YEARLYMATH =
      existingMarksheetData[7].SubExamSubjects[2].marks_obtained;
    const YEARLYSCI =
      existingMarksheetData[7].SubExamSubjects[3].marks_obtained;
    const YEARLYSST =
      existingMarksheetData[7].SubExamSubjects[4].marks_obtained;
    const YEARLYSKT =
      existingMarksheetData[7].SubExamSubjects[5].marks_obtained;
    const YEARLYHIN =
      existingMarksheetData[7].SubExamSubjects[6].marks_obtained;
    const modifiedTableCode71 = replaceString(
      modifiedTableCode70,
      "@sp8@",
      YEARLYENG
    );

    const modifiedTableCode72 = replaceString(
      modifiedTableCode71,
      "@s2p8@",
      YEARLYCOM
    );

    const modifiedTableCode73 = replaceString(
      modifiedTableCode72,
      "@s3p8@",
      YEARLYMATH
    );
    const modifiedTableCode74 = replaceString(
      modifiedTableCode73,
      "@s4p8@",
      YEARLYSCI
    );

    const modifiedTableCode75 = replaceString(
      modifiedTableCode74,
      "@s5p8@",
      YEARLYSST
    );

    const modifiedTableCode76 = replaceString(
      modifiedTableCode75,
      "@s6p8@",
      YEARLYSKT
    );
    const modifiedTableCode77 = replaceString(
      modifiedTableCode76,
      "@s7p8@",
      YEARLYHIN
    );

    // Total Term 2
    const T2ENG =
      parseFloat(PT1ENG) +
      parseFloat(NBA1ENG) +
      parseFloat(SEA1ENG) +
      parseFloat(HALFYEARLYENG);
    const T2COM =
      parseFloat(PT1COM) +
      parseFloat(NBA1COM) +
      parseFloat(SEA1COM) +
      parseFloat(HALFYEARLYCOM);
    const T2HIN =
      parseFloat(PT1HIN) +
      parseFloat(NBA1HIN) +
      parseFloat(SEA1HIN) +
      parseFloat(HALFYEARLYHIN);
    const T2SKT =
      parseFloat(PT1SKT) +
      parseFloat(NBA1SKT) +
      parseFloat(SEA1SKT) +
      parseFloat(HALFYEARLYSKT);
    const T2SST =
      parseFloat(PT1SST) +
      parseFloat(NBA1SST) +
      parseFloat(SEA1SST) +
      parseFloat(HALFYEARLYSST);
    const T2MATH =
      parseFloat(PT1MATH) +
      parseFloat(NBA1MATH) +
      parseFloat(SEA1MATH) +
      parseFloat(HALFYEARLYMATH);
    const T2SCI =
      parseFloat(PT1SCI) +
      parseFloat(NBA1SCI) +
      parseFloat(SEA1SCI) +
      parseFloat(HALFYEARLYSCI);

    const modifiedTableCode78 = replaceString(
      modifiedTableCode77,
      "@st2@",
      T2ENG
    );

    const modifiedTableCode79 = replaceString(
      modifiedTableCode78,
      "@s2t2@",
      T2COM
    );

    const modifiedTableCode80 = replaceString(
      modifiedTableCode79,
      "@s3t2@",
      T2MATH
    );

    const modifiedTableCode81 = replaceString(
      modifiedTableCode80,
      "@s4t2@",
      T2SCI
    );

    const modifiedTableCode82 = replaceString(
      modifiedTableCode81,
      "@s5t2@",
      T2SST
    );

    const modifiedTableCode83 = replaceString(
      modifiedTableCode82,
      "@s6t2@",
      T2SKT
    );

    const modifiedTableCode84 = replaceString(
      modifiedTableCode83,
      "@s7t2@",
      T2HIN
    );
    const modifiedTableCode85 = replaceString(
      modifiedTableCode84,
      "@sg2@",
      getGrade(T2ENG)
    );

    const modifiedTableCode86 = replaceString(
      modifiedTableCode85,
      "@s2g2@",
      getGrade(T2COM)
    );

    const modifiedTableCode87 = replaceString(
      modifiedTableCode86,
      "@s3g2@",
      getGrade(T2MATH)
    );

    const modifiedTableCode88 = replaceString(
      modifiedTableCode87,
      "@s4g2@",
      getGrade(T2SCI)
    );

    const modifiedTableCode89 = replaceString(
      modifiedTableCode88,
      "@s5g2@",
      getGrade(T2SST)
    );

    const modifiedTableCode90 = replaceString(
      modifiedTableCode89,
      "@s6g2@",
      getGrade(T2SKT)
    );

    const modifiedTableCode91 = replaceString(
      modifiedTableCode90,
      "@s7g2@",
      getGrade(T2HIN)
    );

    // Total
    const TOTAL_ENG = T1ENG + T2ENG;
    const TOTAL_COM = T1COM + T2COM;
    const TOTAL_MATH = T1MATH + T2MATH;
    const TOTAL_SCI = T1SCI + T2SCI;
    const TOTAL_SST = T1SST + T2SST;
    const TOTAL_SKT = T1SKT + T2SKT;
    const TOTAL_HIN = T1HIN + T2HIN;

    const modifiedTableCode92 = replaceString(
      modifiedTableCode91,
      "@s2T1s2T2@",
      TOTAL_ENG
    );

    const modifiedTableCode93 = replaceString(
      modifiedTableCode92,
      "@s2T1s2T2@",
      TOTAL_COM
    );

    const modifiedTableCode94 = replaceString(
      modifiedTableCode93,
      "@s3T1s3T2@",
      TOTAL_MATH
    );

    const modifiedTableCode95 = replaceString(
      modifiedTableCode94,
      "@s4T1s4T2@",
      TOTAL_SCI
    );

    const modifiedTableCode96 = replaceString(
      modifiedTableCode95,
      "@s5T1s5T2@",
      TOTAL_SST
    );

    const modifiedTableCode97 = replaceString(
      modifiedTableCode96,
      "@s6T1s6T2@",
      TOTAL_SKT
    );

    const modifiedTableCode98 = replaceString(
      modifiedTableCode97,
      "@s7T1s7T2@",
      TOTAL_HIN
    );

    const modifiedTableCode99 = replaceString(
      modifiedTableCode98,
      "@s1Overall@",
      TOTAL_ENG / 2 + "%"
    );

    const modifiedTableCode100 = replaceString(
      modifiedTableCode99,
      "@s2Overall@",
      TOTAL_COM / 2 + "%"
    );

    const modifiedTableCode101 = replaceString(
      modifiedTableCode100,
      "@s3Overall@",
      TOTAL_MATH / 2 + "%"
    );

    const modifiedTableCode102 = replaceString(
      modifiedTableCode101,
      "@s4Overall@",
      TOTAL_SCI / 2 + "%"
    );

    const modifiedTableCode103 = replaceString(
      modifiedTableCode102,
      "@s5Overall@",
      TOTAL_SST / 2 + "%"
    );

    const modifiedTableCode104 = replaceString(
      modifiedTableCode103,
      "@s6Overall@",
      TOTAL_SKT / 2 + "%"
    );

    const modifiedTableCode105 = replaceString(
      modifiedTableCode104,
      "@s7Overall@",
      TOTAL_HIN / 2 + "%"
    );

    const modifiedTableCode106 = replaceString(
      modifiedTableCode105,
      "@s1overallGrade@",
      getGrade(TOTAL_ENG / 2)
    );

    const modifiedTableCode107 = replaceString(
      modifiedTableCode106,
      "@s2overallGrade@",
      getGrade(TOTAL_COM / 2)
    );

    const modifiedTableCode108 = replaceString(
      modifiedTableCode107,
      "@s3overallGrade@",
      getGrade(TOTAL_MATH / 2)
    );

    const modifiedTableCode109 = replaceString(
      modifiedTableCode108,
      "@s4overallGrade@",
      getGrade(TOTAL_SCI / 2)
    );

    const modifiedTableCode110 = replaceString(
      modifiedTableCode109,
      "@s5overallGrade@",
      getGrade(TOTAL_SST / 2)
    );

    const modifiedTableCode111 = replaceString(
      modifiedTableCode110,
      "@s6overallGrade@",
      getGrade(TOTAL_SKT / 2)
    );

    const modifiedTableCode112 = replaceString(
      modifiedTableCode111,
      "@s7overallGrade@",
      getGrade(TOTAL_HIN / 2)
    );

    // Term 1 totals
    const TOTAL_TERM_1 =
      parseFloat(T1ENG) +
      parseFloat(T1COM) +
      parseFloat(T1MATH) +
      parseFloat(T1SCI) +
      parseFloat(T1SST) +
      parseFloat(T1SKT) +
      parseFloat(T1HIN);

    const modifiedTableCode113 = replaceString(
      modifiedTableCode112,
      "@tt1@",
      TOTAL_TERM_1
    );
    const modifiedTableCode114 = replaceString(
      modifiedTableCode113,
      "@pt1@",
      (TOTAL_TERM_1 / 7).toFixed(2)
    );

    const modifiedTableCode115 = replaceString(
      modifiedTableCode114,
      "@pg1@",
      getGrade(TOTAL_TERM_1 / 7)
    );

    // Term 2 totals
    const TOTAL_TERM_2 =
      parseFloat(T2ENG) +
      parseFloat(T2COM) +
      parseFloat(T2MATH) +
      parseFloat(T2SCI) +
      parseFloat(T2SST) +
      parseFloat(T2SKT) +
      parseFloat(T2HIN);

    const modifiedTableCode116 = replaceString(
      modifiedTableCode115,
      "@tt2@",
      TOTAL_TERM_2
    );
    const modifiedTableCode117 = replaceString(
      modifiedTableCode116,
      "@pt2@",
      (TOTAL_TERM_2 / 7).toFixed(2)
    );

    const modifiedTableCode118 = replaceString(
      modifiedTableCode117,
      "@pg2@",
      getGrade(TOTAL_TERM_2 / 7)
    );

    // Term1 and 2 Totals

    // Term 2 totals
    const TOTAL_TERM_1_2 =
      parseFloat(TOTAL_ENG) +
      parseFloat(TOTAL_COM) +
      parseFloat(TOTAL_MATH) +
      parseFloat(TOTAL_SCI) +
      parseFloat(TOTAL_SST) +
      parseFloat(TOTAL_SKT) +
      parseFloat(TOTAL_HIN);

    const modifiedTableCode119 = replaceString(
      modifiedTableCode118,
      "@FinalT@",
      TOTAL_TERM_1_2
    );
    const modifiedTableCode120 = replaceString(
      modifiedTableCode119,
      "@finalPercentage@",
      (TOTAL_TERM_1_2 / 14).toFixed(2)
    );

    const modifiedTableCode121 = replaceString(
      modifiedTableCode120,
      "@finalGrade@",
      getGrade(TOTAL_TERM_1_2 / 14)
    );

    //  Setting User Data
    const modifiedTableCode122 = replaceString(
      modifiedTableCode121,
      "@School name@",
      userData[0].school_name
    );
    const modifiedTableCode123 = replaceString(
      modifiedTableCode122,
      "@School Address@",
      userData[0].school_address
    );
    const modifiedTableCode124 = replaceString(
      modifiedTableCode123,
      "@School name@",
      userData[0].school_name
    );
    const modifiedTableCode125 = replaceString(
      modifiedTableCode124,
      "@Student Name@",
      userData[0].name
    );
    const modifiedTableCode126 = replaceString(
      modifiedTableCode125,
      "@Student Class@",
      userData[0].class_id
    );
    const modifiedTableCode127 = replaceString(
      modifiedTableCode126,
      "@Student Rn@",
      userData[0].roll_no
    );
    const modifiedTableCode128 = replaceString(
      modifiedTableCode127,
      "@Father Name@",
      userData[0].father_name
    );
    const modifiedTableCode129 = replaceString(
      modifiedTableCode128,
      "@Student Address@",
      userData[0].address
    );
    const modifiedTableCode130 = replaceString(
      modifiedTableCode129,
      "@Student dob@",
      userData[0].DOB
    );

    setTableStructure(
      <div dangerouslySetInnerHTML={{ __html: modifiedTableCode130 }} />
      // <div dangerouslySetInnerHTML={{ __html: dataTOdISPLAY }} />
    );
  };
  useEffect(() => {
    const fetchTableStructure = async () => {
      try {
        const response = await fetch(
          `https://edsanchaar.in/api/api_admin/MarksheetGeneration/StudentSection/getMarksheetStructure.php?school_id=${schoolId}&result_id=${resultId}`,
          {
            method: "GET",
            headers: {
              authorization: "b1sgVwwszjQM0iq6viyqYNrUzX3uaLWTUnkaOrq3OX0",
            },
          }
        );

        const data = await response.json();
        console.log(schoolId, resultId);

        if (schoolId == "GRDWS1223") {
          if (resultId == "4") {
            GRDWS1223_8_FINAL_2023_2024(data);
          }
        } else {
          setTableStructure(
            <div
              dangerouslySetInnerHTML={{ __html: "<div>No Data for</div>" }}
            />
          );
        }

        console.log(userData[0]);
        // console.log(subjects);
        // console.log(exams)
        console.log(existingMarksheetData);
      } catch (error) {
        console.error("Error fetching result data:", error);
      } finally {
        // console.log(existingMarksheetData);
      }
    };
    fetchTableStructure();
  }, []);

  return (
    <div className="w-88vw flex justify-center items-center">
      <div className="px-4">{tableStructure}</div>
    </div>
  );
};

export default StudentMarksheetTable;
