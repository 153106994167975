import React, { useState, useEffect } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Cookies from "js-cookie";
import Modal from "@mui/material/Modal";

import { InputLabel, Button } from "@mui/material";

const CoachingLedgerGeneratation = () => {
  const [leasureModalOpen, setLeasureModalOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const [monthsForLedger, setMonthsForLedger] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [ledgerData, setLedgerData] = useState([]);
  const [school_id, setSchoolId] = useState("");
  const [feeMonthAndYearForLedger, setFeeMonthAndYearForLedger] = useState([]);
  const [yearlyFeeDataForLedger, setyearlyFeeDataForLedger] = useState([]);

  useEffect(() => {
    setLedgerData([]);
    setyearlyFeeDataForLedger([]);
  }, [selectedMonth]);
  //Yearly data

  // JSX for yearly data rows
  let cumulativeIncome = 0;
  const yearlyDataRows = Object.keys(yearlyFeeDataForLedger).map((month) => {
    const monthData = yearlyFeeDataForLedger[month];
    const income = parseInt(monthData.total_fee);
    const netIncome = parseInt(monthData.net_income);
    cumulativeIncome += netIncome;
    return (
      <tr key={month}>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {month}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {income}
        </td>
       
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {netIncome}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {cumulativeIncome}
        </td>
      </tr>
    );
  });

  // JSX for yearly total row
  const totalNetIncome = Object.values(yearlyFeeDataForLedger).reduce(
    (acc, curr) => acc + parseInt(curr.net_income),
    0
  );

  // JSX for yearly total rownpm
  const yearlyTotalRow = (
    <tr className="bg-gray-200">
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        Balance
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {/* Calculate total income */}
        {Object.values(yearlyFeeDataForLedger).reduce(
          (acc, curr) => acc + parseInt(curr.total_fee),
          0
        )}
      </td>
      
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {/* Display total net income */}
        {totalNetIncome}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {/* Placeholder for cumulative income */}
      </td>
    </tr>
  );
  const dailyTotals = {};
  let monthlyTotal = 0;

  // Calculate daily and monthly totals
  ledgerData.forEach((item) => {
    const date = item.paid_at;
    const totalPaid = parseInt(item.total_paid);

    // Calculate daily total
    if (dailyTotals[date]) {
      dailyTotals[date] += totalPaid;
    } else {
      dailyTotals[date] = totalPaid;
    }

    // Add to monthly total
    monthlyTotal += totalPaid;
  });
  // JSX for daily total rows
  const cumulativeAmounts = {};
  let cumulativeTotal = 0;
  ledgerData.forEach((item) => {
    const date = item.paid_at;
    cumulativeTotal += item.total_paid;
    cumulativeAmounts[date] = cumulativeTotal;
  });

  // JSX for daily total rows
  // JSX for daily total rows
  // JSX for daily total rows
  // Initialize cumulative income
  let cumulativeIncomeMonthly = 0;
  const dailyTotalRows = ledgerData.map((item, index) => {
    // Calculate net income for the current item
    const netIncome = parseInt(item.amount);

    // Update cumulative income
    cumulativeIncomeMonthly += netIncome;

    return (
      <tr key={index}>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {index + 1}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {item.date}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {item.title}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {item.amount >= 0 ? item.amount : 0}{" "}
          {/* Display 0 if item.amount is empty */}
        </td>
        
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {cumulativeIncomeMonthly}
        </td>
      </tr>
    );
  });
  // JSX for monthly total row
  const monthlyTotalRow = (
    <tr className="bg-gray-200">
      <td
        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
        colSpan="3"
      >
        Balance
      </td>
      <td
        colSpan={3}
        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
      >
        {ledgerData.reduce((total, item) => total + item.amount, 0)}
      </td>
    </tr>
  );

  // PDF Generation
  const generatePDF = () => {
    // Get the HTML element containing the table
    const table = document.getElementById("LedgerTable");
    // console.log(table);

    // Function to capture the table after a short delay
    const captureTable = () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          // Create a canvas from the HTML table
          html2canvas(table).then((canvas) => {
            // Get the dimensions of the table
            const tableWidth = table.offsetWidth;
            const tableHeight = table.offsetHeight;

            // Convert canvas to JPEG image
            const imgData = canvas.toDataURL("image/jpeg", 1.0);

            // Set dimensions for PDF
            const pdf = new jsPDF("p", "pt", "a2");

            // Add image to PDF
            pdf.addImage(imgData, "JPEG", 0, 0, tableWidth, tableHeight);

            // Download PDF
            pdf.save("ledger.pdf");
            resolve();
          });
        }, 10); // Adjust the delay as needed (milliseconds)
      });
    };

    // Capture the table after a delay
    captureTable();
  };

  //   Ledger modals
  const handleCloseLeasureModal = () => {
    setLeasureModalOpen(false);
  };
  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    const selectedEntry = feeMonthAndYearForLedger.find(
      (entry) => entry.year.toString() === selectedYear
    );
    if (selectedEntry) {
      setMonthsForLedger(selectedEntry.months);
      setSelectedMonth(""); // Reset the selected month
    } else {
      setMonthsForLedger([]); // If no matching entry found, reset monthsForLedger
    }
    setSelectedYear(selectedYear);
  };

  // Function to handle generation of leasure
  const generateLeasureOpen = () => {
    // Implement generation logic here
    setLeasureModalOpen(true);
    // This function will be called when the "Generate" button is clicked

    const fetchYearAndMonthDataForLedger = async () => {
      try {
        const response = await fetch(
          `https://edsanchaar.in/api/api_admin/CoachingManager/getDistinctMonthAndYearsCoachingFeePayment.php?school_id=${school_id}`,
          {
            method: "GET",
            headers: {
              Authorization: "aUBnAANvzn4CzHO76Cr+IHnjs3tvnr680eQSSZG2sgw=",
            },
          }
        );

        if (response.ok) {
          const data = await response.json(); // Parse response body as JSON
          setFeeMonthAndYearForLedger(data); // Set the fetched data in state
        } else {
          console.error("Failed to notify fee payment");
        }
      } catch (error) {
        console.error("Error notifying fee payment:", error);
      }
    };

    fetchYearAndMonthDataForLedger();
  };

  const handleGenerateLeasure = () => {
    let apiUrl;
    if (selectedMonth === "") {
      apiUrl = `https://edsanchaar.in/api/api_admin/CoachingManager/yearlyFeeDataForCoachingLedger.php?school_id=${school_id}&year=${selectedYear}`;
    } else {
      apiUrl = `https://edsanchaar.in/api/api_admin/CoachingManager/coachingFeeDataForLedger.php?school_id=${school_id}&year=${selectedYear}&month=${selectedMonth.toLowerCase()}`;
    }

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        if (
          data.message &&
          data.message === "No data found for the specified parameters."
        ) {
          // Handle case where no data is found
        } else {
          if (selectedMonth === "") {
            setyearlyFeeDataForLedger(data); // Store yearly fee data in state
          } else {
            // Update ledgerData state with transformed data
            setLedgerData(data.ledger); // No need for transformation, directly set the ledger data
          }
          setLeasureModalOpen(true); // Open the modal to display the data
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      console.error("School ID not available");
      return;
    }

    setSchoolId(schoolIdFromCookie);
  }, []);
  return (
    <div className="flex justify-center items-center h-screen w-screen">
      <div className=" h-[90vh] w-[93vw] rounded-2xl bg-zinc-100 flex flex-col justify-start items-center">
        <div className="text-3xl font-bold py-4">School Fee Data</div>
        <div className=" ">
          <Button onClick={generateLeasureOpen}>Generate Ledger</Button>
        </div>
        <Modal
          open={leasureModalOpen}
          onClose={handleCloseLeasureModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              width: "50vw",
              borderRadius: 2,
            }}
          >
            <div className="flex justify-center items-center flex-col">
              <div className="text-xl mb-4">Generate Ledger</div>

              <div className="flex">
                <div className="flex mr-2">
                  <InputLabel htmlFor="year">Year:</InputLabel>
                  <div className="flex flex-col mb-4">
                    <select
                      id="year"
                      value={selectedYear}
                      onChange={handleYearChange}
                    >
                      <option value="" disabled hidden>
                        Select Year
                      </option>
                      {feeMonthAndYearForLedger.map((entry) => (
                        <option key={entry.year} value={entry.year}>
                          {entry.year}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {selectedYear && (
                  <div className="flex flex-col pl-2 mb-4">
                    <div className="flex">
                      <InputLabel htmlFor="month">Month:</InputLabel>
                      <select
                        id="month"
                        value={selectedMonth}
                        onChange={(event) =>
                          setSelectedMonth(event.target.value)
                        }
                      >
                        <option value="" disabled hidden>
                          Select Month
                        </option>
                        {monthsForLedger.map((month) => (
                          <option key={month} value={month}>
                            {month}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
               
              </div>

              <div className="flex items-center justify-center h-[70vh] w-full">
                <div className="w-full h-full flex flex-col overflow-y-auto items-center">
                  {selectedMonth !== "" ? (
                    // If a month is selected, display monthly data
                    <table
                      id="LedgerTable"
                      className="min-w-full divide-y divide-gray-200"
                    >
                      {/* Table header */}
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            S.no
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Title
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Income
                          </th>
                        
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Cumulative Income
                          </th>
                        </tr>
                      </thead>
                      {/* Table body */}
                      <tbody className="divide-y divide-gray-200">
                        {/* Display daily total rows */}
                        {dailyTotalRows}
                        {/* Display monthly total row */}
                        {monthlyTotalRow}
                      </tbody>
                    </table>
                  ) : (
                    // If no month is selected, display yearly data
                    <table
                      id="LedgerTable"
                      className="min-w-full divide-y divide-gray-200"
                    >
                      {/* Table header */}
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Month
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Income
                          </th>
                         
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Net Income
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Cumulative Income
                          </th>
                        </tr>
                      </thead>
                      {/* Display yearly data rows */}
                      <tbody className="divide-y divide-gray-200">
                        {yearlyDataRows}
                        {/* Display yearly total row */}
                        {yearlyTotalRow}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>

              <div className="flex flex-row my-2 justify-center border-2">
                <button
                  className="border border-transparent rounded-md p-2 mr-2  px-4 text-md font-medium bg-blue-500 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={handleGenerateLeasure}
                >
                  Generate
                </button>
                <button
                  className="border border-transparent rounded-md p-2 ml-2  px-4 text-md font-medium bg-green-600 text-white focus:outline-none focus:ring-2 focus:ring-green-500"
                  onClick={generatePDF}
                >
                  Print PDF
                </button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default CoachingLedgerGeneratation;
