import React, { useMemo } from "react";
import { Grid, CircularProgress, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import { Doughnut } from "react-chartjs-2";
import { BarChart } from "@mui/x-charts";
const StatisticsSection = ({
  loading,
  apiData,
  feeData,
  attendanceData,
  syllabusData,
}) => {
  // Extracting class data from apiData
  const classData = apiData?.class_ratio || [];

  // Generating dataset for the chart
  const dataset = classData.map((classInfo) => ({
    classId: classInfo.class_id,
    totalStudents: classInfo.total_students,
  }));

  const attendance = attendanceData?.attendance || [];
  const defaulterTeachers = attendanceData?.defaulter || [];
  const teacherNames = attendanceData?.teacher_names || [];

  // Extracting class IDs and counts for totalCountZero and totalCountOne
  const classIds = attendance.map((item) => item.class_id);
  const totalCountZero = attendance.map((item) => item.totalCountZero);
  const totalCountOne = attendance.map((item) => item.totalCountOne);
  const totalAttendance = useMemo(() => {
    return totalCountOne.reduce((total, count) => total + count, 0);
  }, [totalCountOne]);

  // Define data for classwise population chart
  const classwisePopulationData = {
    labels: dataset.map((data) => data.classId),
    series: [
      {
        name: "classwise population",
        data: dataset.map((data) => data.totalStudents),
      },
    ],
  };

  // Define options for classwise population chart
  const classwisePopulationOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: classwisePopulationData.labels,
    },
    legend: {
      position: "top",
    },
    fill: {
      opacity: 1,
    },
  };

  // Extract syllabus completion data
  const syllabusCompletionData = syllabusData || [];

  // Extract class IDs and completion percentages
  const syllabusClassIds = syllabusCompletionData.map((item) => item.class_id);
  const syllabusCompletionPercentages = syllabusCompletionData.map(
    (item) => item.completion_percentage
  );

  // Define chart data for syllabus completion
  const syllabusChartData = {
    labels: syllabusClassIds,
    series: [
      {
        name: "Syllabus Completion",
        data: syllabusCompletionPercentages,
      },
    ],
  };

  // Define chart options for syllabus completion
  const syllabusChartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: syllabusClassIds,
    },
    yaxis: {
      max: 100, // Set the maximum value for y-axis to 100%
    },
    legend: {
      position: "top",
    },
    fill: {
      opacity: 1,
    },
  };

  // Define chart data
  const data = {
    labels: classIds,
    series: [
      {
        name: "Absent",
        data: totalCountZero,
      },
      {
        name: "Present",
        data: totalCountOne,
      },
    ],
  };

  // Define chart options
  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: classIds,
    },
    legend: {
      position: "top",
    },
    fill: {
      opacity: 1,
    },
    colors: ["#e74c3c", "#2ecc71"], // Red for Absent, Green for Present
  };
  return (
    <div className="w-full pl-4">
      <Grid container spacing={3}>
        {/* Total Students */}
        <Grid item xs={12} sm={4}>
          <div className="rounded-xl h-28 flex flex-col justify-center items-center border-2 bg-blue-100">
            <div className=" text-xl self-center"> Total Students</div>
            {loading ? (
              <CircularProgress size={60} />
            ) : (
              <div className=" text-2xl self-center">
                {" "}
                {apiData?.teacher_student_ratio?.total_students}
              </div>
            )}
          </div>
        </Grid>

        {/* Total Teachers */}
        <Grid item xs={12} sm={4}>
          <div className="rounded-xl h-28 flex flex-col justify-center items-center border-2 bg-blue-100">
            <div className=" text-xl self-center">Total Teachers</div>
            {loading ? (
              <CircularProgress size={60} />
            ) : (
              <div className=" text-2xl self-center">
                {apiData?.teacher_student_ratio?.total_teachers}
              </div>
            )}
          </div>
        </Grid>

        {/* Display Fee Details */}
        <Grid item xs={12} sm={4}>
          <div className="rounded-xl h-28 flex flex-col justify-center items-center border-2 bg-blue-100">
            {loading ? (
              <CircularProgress size={60} />
            ) : (
              <div className="flex flex-col justify-center items-center">
                <div className="flex flex-col items-baseline">
                  <div className=" text-lg self-center">
                    Current Month Collection
                  </div>
                  <div className=" text-lg self-center">
                    {feeData.current_month_total}
                  </div>
                </div>
                <div className="flex flex-col items-baseline">
                  <div className=" text-md self-center">
                    Previous Month Collection
                  </div>
                  <div className=" text-md self-center">
                    {feeData.previous_month_total}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Grid>

        {/* Student Data */}
        <Grid item xs={12} sm={4}>
          {loading ? (
            <div className="flex w-full items-center justify-center">
              <CircularProgress size={60} />
            </div>
          ) : (
            <div className="flex flex-col">
              <div className=" text-xl self-start">Student Data</div>
              <Doughnut
                data={{
                  labels: ["Male", "Female"],
                  datasets: [
                    {
                      data: [
                        apiData?.students_data?.male_count ?? 0,
                        apiData?.students_data?.female_count ?? 0,
                      ],
                      backgroundColor: ["#3498db", "#e74c3c"], // Flat colors for Male and Female
                      hoverBackgroundColor: ["#2980b9", "#c0392b"], // Hover colors
                    },
                  ],
                }}
              />
              <p>
                female-Male Ratio:{" "}
                {apiData?.students_data?.gender_ratio ?? "N/A"}
              </p>
            </div>
          )}
        </Grid>

        {/* Attendance Data */}
        <Grid item xs={12} sm={4}>
          {loading ? (
            <div className="flex w-full items-center justify-center">
              <CircularProgress size={60} />
            </div>
          ) : (
            <div>
              <div className=" text-xl self-start"> Attendance data</div>
              <Chart
                options={options}
                series={data.series}
                type="bar"
                height={350}
              />

              <p>Total Attendance: {totalAttendance}</p>
            </div>
          )}
        </Grid>

        {/* Class Ratio Data */}
        <Grid item xs={12} sm={4}>
          {loading ? (
            <div className="flex w-full items-center justify-center">
              <CircularProgress size={60} />
            </div>
          ) : (
            <div className=" w-full h-full">
              <div className=" text-xl self-start"> Classwise Population</div>
              <Chart
                options={classwisePopulationOptions}
                series={classwisePopulationData.series}
                type="bar"
                height={350}
              />
            </div>
          )}
        </Grid>

        {/* defaulter teachers attendance  */}
        <Grid item xs={12} sm={4}>
          {loading ? (
            <div className="flex w-full items-center justify-center">
              <CircularProgress size={60} />
            </div>
          ) : (
            <div className="">
              <div className=" text-xl self-start">
                Attendance Defaulter Teachers
              </div>
              <ul className="divide-y bg-blue-100 rounded-xl p-2">
                {defaulterTeachers.map((classId) => {
                  const teacher = teacherNames.find(
                    (item) => item.class_id === classId
                  );
                  return (
                    <li key={classId} className="py-2">
                      <span className=" text-black">
                        {teacher?.teacher_name}
                      </span>{" "}
                      - <span>{classId}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {loading ? (
            <div className="flex w-full items-center justify-center">
              <CircularProgress size={60} />
            </div>
          ) : (
            <div>
              <div className=" text-xl self-start"> Syllabus Completion</div>
              <Chart
                options={syllabusChartOptions}
                series={syllabusChartData.series}
                type="bar"
                height={350}
              />
            </div>
          )}
        </Grid>

        {/* Teacher-Student Ratio */}
        <Grid item xs={12} sm={4}>
          {loading ? (
            <div className="flex w-full items-center justify-center">
              <CircularProgress size={60} />
            </div>
          ) : (
            <div>
              <div className=" text-xl self-start"> Teacher-Student Ratio</div>
              <Doughnut
                data={{
                  labels: ["Teachers", "Students"],
                  datasets: [
                    {
                      data: [
                        apiData?.teacher_student_ratio.total_teachers ?? 0,
                        apiData?.teacher_student_ratio.total_students ?? 0,
                      ],
                      backgroundColor: ["#3498db", "#e74c3c"], // Flat colors for Teachers and Students
                      hoverBackgroundColor: ["#2980b9", "#c0392b"], // Hover colors
                    },
                  ],
                }}
              />
              <p>
                Teacher-Student Ratio:{" "}
                {apiData?.teacher_student_ratio.teacher_student_ratio}
              </p>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default StatisticsSection;
