import React, { useState, useEffect } from "react";
import { Box, Grid, Toolbar, Paper, Typography, Modal } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { Doughnut } from "react-chartjs-2"; // Import Doughnut from react-chartjs-2
import CircularProgress from "@mui/material/CircularProgress";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
export default function ComplaintManager() {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(12) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const [school_id, setSchoolId] = useState("");
  const [data, setData] = useState([]);
  const [dataClass, setDataClass] = useState([]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [selectedComplaint, setSelectedComplaint] = useState({
    complaint_id: "",
    status: "",
    message: "",
  });

  const [updateMessage, setUpdateMessage] = useState("");

  const [selectedComplaintStatus, setSelectedComplaintStatus] = useState("2");

  const totalComplaints = data.length;
  const actionTakenTotal = data.filter(
    (c) => c.status === "ActionTaken"
  ).length;

  const doughnutData = {
    labels: ["Submitted", "Action Taken"],
    datasets: [
      {
        data: [
          data.filter((c) => c.status === "Submitted").length,
          data.filter((c) => c.status === "ActionTaken").length,
        ],
        backgroundColor: ["#3498db", "#2ecc71"],
        hoverBackgroundColor: ["#2980b9", "#27ae60"],
      },
    ],
  };

  const fetchComplaints = async () => {
    try {
      const headers = {
        Authorization: "aVchSlItjHUB3jD6sH/4IKdX9cC3WOyB/Qrf7v+eED8",
      };
      const response = await axios.get(
        // `http://localhost/react/api_admin/fetchSchoolComplaints.php?school_id=${school_id}`,
        `https://edsanchaar.in/api/api_admin/fetchSchoolComplaints.php?school_id=${school_id}`,
        { headers }
      );
      // console.log(response.data);

      // Check if the response indicates no complaints
      if (response.data && response.data.message) {
        // console.log(response.data.message);
        setData([]); // Set data to an empty array
      } else {
        // Ensure that response.data is an array
        const responseData = Array.isArray(response.data) ? response.data : [];
        // Reverse the order of the data array
        setData(responseData.reverse());
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchClassComplaint = async () => {
    try {
      const headers = {
        Authorization: "aVchSlItjHUB3jD6sH/4IKdX9cC3WOyB/Qrf7v+eED8",
      };
      const response = await axios.get(
        // `http://localhost/react/api_admin/getClassComplaintStatus.php?school_id=${school_id}`,
        `https://edsanchaar.in/api/api_admin/getClassComplaintStatus.php?school_id=${school_id}`,
        { headers }
      );
      console.log(response.data);

      // Check if the response indicates no complaints
      if (response.data && response.data.message) {
        // console.log(response.data.message);
        setDataClass([]); // Set data to an empty array
      } else {
        // Ensure that response.data is an array
        const classDataMap = {};
        response.data.forEach((complaint) => {
          const { class_id, status } = complaint;
          if (!classDataMap[class_id]) {
            classDataMap[class_id] = {
              class_id,
              submittedCount: 0,
              actionTakenCount: 0,
              totalCount: 0,
            };
          }

          classDataMap[class_id].totalCount++;
          if (status === "Submitted") {
            classDataMap[class_id].submittedCount++;
          } else if (status === "ActionTaken") {
            classDataMap[class_id].actionTakenCount++;
          }
        });

        setDataClass(Object.values(classDataMap));
      }
    } catch (error) {
      console.error("Error fetching class complaints: ", error);
    }
  };

  const updateComplaintStatus = async () => {
    try {
      const formData = new FormData();
      formData.append("complaint_id", selectedComplaint.complaint_id);
      formData.append("status", selectedComplaintStatus);
      formData.append("message", updateMessage); // Add message to the form data
      formData.append("school_id", school_id); // Add message to the form data

      handleClose();

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: "aVExUBBin2oPzz3l4SG0YUR/jkFqakYnjoHr47SxDhvV",
      };

      // Use toast.promise to handle promises
      await toast.promise(
        // The promise to execute
        new Promise(async (resolve, reject) => {
          // Simulate a 2-second loading duration
          setTimeout(async () => {
            try {
              const response = await axios.post(
                "https://edsanchaar.in/api/api_admin/updateSchoolComplaint.php",
                // "http://localhost/react/api_admin/updateSchoolComplaint.php",
                formData,
                { headers }
              );

              if (response.status === 200) {
                console.log(
                  "Complaint status updated successfully",
                  response.data
                );

                fetchComplaints();
                handleClose();
                // Update the local state to reflect the new status
                setData((prevData) => {
                  const updatedData = prevData.map((complaint) =>
                    complaint.complaint_id === selectedComplaint.complaint_id
                      ? { ...complaint, status: selectedComplaintStatus }
                      : complaint
                  );
                  return updatedData;
                });
                resolve(); // Resolve the promise on success
              } else {
                toast.error("Failed to update complaint status");
                reject(); // Reject the promise on failure
              }
            } catch (error) {
              toast.error(
                "Error updating complaint status. Please try again later"
              );
              reject(); // Reject the promise on error
            }
          }, 2000); // Simulate a 2-second loading duration
        }),
        {
          // Toast options
          loading: "Updating Complaint Status...",
          success: <b>Complaint status updated successfully!</b>,
          error: <b>Failed to update complaint status.</b>,
        }
      );
    } catch (error) {
      console.error("Error updating complaint status: ", error);
    }
  };

  const handleUpdate = (complaint) => {
    setSelectedComplaint(complaint);
    handleOpen();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      console.error("School ID not available");
      return;
    }

    setSchoolId(schoolIdFromCookie);
  }, []);

  useEffect(() => {
    if (school_id !== "") {
      fetchComplaints();
      fetchClassComplaint();
    }
  }, [school_id]);

  return (
    <div className="flex items-center justify-center w-full h-screen bg-white">
      <Toaster position="top-center" reverseOrder={true} />
      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="text-3xl font-bold">Complaint Manager</div>
          <div className="flex flex-row w-full h-full py-4">
            <div className="w-1/5 flex flex-col justify-start px-2 overflow-x-hidden ">
              <div className="text-2xl font-bold mb-4 pl-2">
                Class Complaints
              </div>
              {dataClass.length > 0 ? (
                dataClass.map((classData) => (
                  <Paper
                    className="w-full "
                    key={classData.class_id}
                    sx={{
                      p: 2,
                      m: 0.5,
                      backgroundColor: "#ffe6e6",
                      borderRadius: 6,
                    }}
                  >
                    <Typography variant="h6">{`Class ${classData.class_id}`}</Typography>
                    <Typography>{`Submitted: ${classData.submittedCount}`}</Typography>
                    <Typography>{`Action Taken: ${classData.actionTakenCount}`}</Typography>
                    <Typography>{`Total Complaints: ${classData.totalCount}`}</Typography>
                  </Paper>
                ))
              ) : (
                <Typography variant="body1">
                  No class-wise complaints available.
                </Typography>
              )}
            </div>

            <div className="w-1/5 px-2 ">
              <Doughnut data={doughnutData} />
              <Typography variant="h6" gutterBottom>
                Total Complaints: {totalComplaints}
              </Typography>

              <Typography variant="h6" gutterBottom>
                Action Taken Total: {actionTakenTotal}
              </Typography>
            </div>
            <div className="flex flex-col justify-start items-start py-2 w-3/5 px-4 h-full overflow-x-auto">
              <div className="w-full ">
                <Grid container spacing={2} justifyContent="center">
                  {data.length > 0 ? (
                    data.map((complaint) => (
                      <Grid
                        item
                        key={complaint.complaint_id}
                        // xs={12}
                        sm={6}
                        md={10}
                        lg={6}
                      >
                        {loading ? (
                          <div className="flex flex-row items-center">
                            <CircularProgress size={60} />
                          </div>
                        ) : (
                          <Paper
                            sx={{
                              p: 2,
                              height: "100%",
                              width: "100%",
                              display: "flex",
                              borderRadius: 6,
                              flexDirection: "column",
                              alignItems: "start",
                              justifyContent: "space-between",
                              backgroundColor:
                                complaint.status === "ActionTaken"
                                  ? "#d8ffc6" // light green
                                  : complaint.status === "Submitted"
                                  ? "#ffe68c" // light orange
                                  : "inherit", // default color
                            }}
                          >
                            <div>
                              <Typography variant="h6">
                                {complaint.subject}
                              </Typography>
                              <Typography>
                                <span style={{ fontWeight: "600" }}>
                                  Username:
                                </span>{" "}
                                {complaint.username}
                              </Typography>
                              <Typography>
                                <span style={{ fontWeight: "600" }}>
                                  Status:
                                </span>{" "}
                                {complaint.status === "ActionTaken"
                                  ? "ActionTaken"
                                  : complaint.status}
                              </Typography>
                              <Typography>
                                <span style={{ fontWeight: "600" }}>
                                  Message:
                                </span>{" "}
                                {complaint.message}
                              </Typography>
                              <Typography>
                                <span style={{ fontWeight: "600" }}>
                                  Response:
                                </span>{" "}
                                {complaint.response}
                              </Typography>
                            </div>
                            <button
                              onClick={() => handleUpdate(complaint)}
                              className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
                            >
                              Update Status
                            </button>
                          </Paper>
                        )}
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        No complaints raised yet.
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                {selectedComplaint && (
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        width: 400,
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Edit Complaint
                      </Typography>

                      <form
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                        }}
                      >
                        <div>
                          <label htmlFor="editedStatus">Status:</label>
                          <select
                            id="editedStatus"
                            name="editedStatus"
                            value={selectedComplaintStatus}
                            onChange={(e) => {
                              setSelectedComplaintStatus(e.target.value);
                            }}
                            style={{
                              width: "100%",
                              padding: "8px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                            }}
                          >
                            <option value="2">Action Taken</option>
                          </select>
                        </div>

                        <label htmlFor="updateMessage">Update Message:</label>
                        <textarea
                          id="updateMessage"
                          name="updateMessage"
                          value={updateMessage}
                          onChange={(e) => setUpdateMessage(e.target.value)}
                          rows="4"
                          style={{
                            width: "100%",
                            padding: "8px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                          }}
                        />

                        <div style={{ marginTop: "16px" }}>
                          <button
                            variant="contained"
                            color="primary"
                            className="bg-blue-500 text-white px-4 py-2 rounded"
                            onClick={updateComplaintStatus}
                          >
                            Update Status
                          </button>
                        </div>
                      </form>
                    </Box>
                  </Modal>
                )}
              </div>
            </div>
          </div>
        </Paper>
      </Toolbar>
    </div>
  );
}
