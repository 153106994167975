import React, { useState, useEffect } from "react";
import { Box, Toolbar, Paper, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
const TeacherAnalysis = () => {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(9) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const [school_id, setSchoolId] = useState("");
  const [schoolTeachers, setSchoolTeachers] = useState([]);
  const [teacherData, setTeacherData] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null); // State to store the selected teacher

  const [state, setState] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        min: 0,
        max: 1,
      },
    },
    series: [],
  });

  const [teacherExamWiseAvg, setTeacherExamWiseAvg] = useState({
    options: {
      chart: {
        id: "exam-wise-bar",
        type: "bar",
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        min: 0,
        max: 1,
      },
    },
    series: [],
  });

  const [teacherAvgMarks, setTeacherAvgMarks] = useState({
    options: {
      chart: {
        id: "teacher-avg",
        type: "bar",
        height: 300,
      },
      xaxis: {
        categories: [],
        title: {
          text: "Average Marks",
        },
      },
      yaxis: {
        min: 0,
        max: 100,
      },
      plotOptions: {
        bar: {
          horizontal: true, // Set to true for horizontal bar chart
          borderRadius: 4,
        },
      },
    },
    series: [],
  });

  const [teacherComplaints, setTeacherComplaints] = useState([]);

  // ...

  const handelTeacherSelect = (username) => {
    fetch(
      `https://edsanchaar.in/api/api_admin/TeacherAnalysis/getTeacherAnalysis.php?teacher_username=${username}&school_id=${school_id}`,
      {
        method: "GET",
        headers: {
          Authorization: "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const subjects = Array.from(
          new Set(data.flatMap((entry) => Object.keys(entry.marks)))
        );

        setTeacherData(data);
        const categories = data.map((entry) => `${entry.class_id}`);

        const series = subjects.map((subject) => {
          return {
            name: subject,
            data: data.map((entry) => ({
              x: entry.class_id,
              y:
                entry.marks[subject] !== null
                  ? parseFloat((entry.marks[subject] * 100).toFixed(1))
                  : null,
            })),
          };
        });

        // exam wise marks

        axios
          .get(
            `https://edsanchaar.in/api/api_admin/TeacherAnalysis/teacherExamWiseAvg.php?school_id=${school_id}&teacher_username=${username}`
          )
          .then((response) => {
            const examWiseAvgData = response.data;

            // Handle the examWiseAvgData
            console.log("teacherExamWiseAvgData:", examWiseAvgData);

            // Update teacherExamWiseAvg state
            const categories = examWiseAvgData.map((entry) => entry.date);
            const series = [
              {
                name: "Average Marks",
                data: examWiseAvgData.map((entry) =>
                  parseFloat((entry.average_marks * 100).toFixed(1))
                ),
              },
            ];

            setTeacherExamWiseAvg({
              options: {
                chart: {
                  id: "exam-wise-bar",
                  type: "bar",
                },
                xaxis: {
                  categories,
                },
                yaxis: {
                  min: 0,
                  max: 100,
                },
              },
              series,
            });
          })
          .catch((error) => {
            console.error("Error fetching teacherExamWiseAvgData:", error);
          });

        // teachers average marks

        // Fetch teacher's average marks
        axios
          .get(
            `https://edsanchaar.in/api/api_admin/TeacherAnalysis/avgTeacherPerformances.php?school_id=${school_id}`
          )
          .then((response) => {
            const teacherAverage = response.data;

            // Handle the teacherAverage data
            console.log("teacherAverageData:", teacherAverage);

            // Update teacherAvgMarks state
            const categories = teacherAverage.map(
              (entry) => entry.teacher_name
            );
            const series = [
              {
                name: "Average Marks",
                data: teacherAverage.map((entry) =>
                  parseFloat((entry.teacher_average_marks * 100).toFixed(1))
                ),
              },
            ];

            setTeacherAvgMarks({
              options: {
                chart: {
                  id: "teacher-avg",
                  type: "bar",
                  height: 300, // Set the height as per your requirement
                },
                xaxis: {
                  categories,
                },
                yaxis: {
                  min: 0,
                  max: 100,
                },
              },
              series,
            });
          })
          .catch((error) => {
            console.error("Error fetching teacherAverageData:", error);
          });

        // TEACHER COMPLAINTS

        axios
          .get(
            `https://edsanchaar.in/api/api_admin/TeacherAnalysis/schoolTeacherComplaints.php?school_id=${school_id}`
          )
          .then((response) => {
            const complaintsData = response.data;

            // Handle the complaintsData
            console.log("teacherComplaintsData:", complaintsData);

            // Update teacherComplaints state
            setTeacherComplaints(complaintsData);
          })
          .catch((error) => {
            console.error("Error fetching teacherComplaintsData:", error);
          });

        const options = {
          chart: {
            id: "basic-bar",
            type: "bar",
          },
          xaxis: {
            categories,
          },
          yaxis: {
            min: 0,
            max: 100,
          },
        };

        setState({ options, series, teacherData: data });
        setSelectedTeacher(username);
      })
      .catch((err) => {
        // Handle error
      });
  };

  useEffect(() => {
    const schoolId = Cookies.get("schoolId");

    if (!schoolId) {
      // Redirect to login if schoolId is not available
      // navigate("/");
      return;
    }

    const fetchTeachers = async () => {
      try {
        const response = await fetch(
          `https://edsanchaar.in/api/api_admin/TeacherManager/getAllTeachers.php?school_id=${schoolId}`,
          {
            method: "GET",
            headers: {
              Authorization: "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setSchoolTeachers(data);
      } catch (error) {
        console.error("Error fetching teachers:", error);
      }
    };

    setSchoolId(schoolId);
    fetchTeachers();
  }, []);

  return (
    <div className="flex items-center justify-center w-full h-screen bg-white">
      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="flex flex-row w-full h-full">
            <div className="w-2/12 h-full  rounded-lg mr-2">
              <div className="flex flex-col items-start  p-1 overflow-x-auto h-full">
                <div className="text-lg font-medium">School Teachers</div>
                {schoolTeachers.map((teacher, index) => (
                  <div
                    key={index}
                    className={`rounded-lg p-2 bg-slate-50 my-2 hover:cursor-pointer shadow-md w-full ${
                      selectedTeacher === teacher.username
                        ? "bg-blue-200" // Apply your highlight style here
                        : ""
                    }`}
                    onClick={() => handelTeacherSelect(teacher.username)}
                    style={{
                      backgroundColor:
                        selectedTeacher === teacher.username
                          ? "#2196f3"
                          : "inherit", // Change color as per your requirement
                      color:
                        selectedTeacher === teacher.username
                          ? "#fff"
                          : "inherit", // Change text color as per your requirement
                    }}
                  >
                    <p>{teacher.name}</p>
                    <p>
                      {"Class_teacher "}: {teacher.class_teacher}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-10/12 h-full ml-2 overflow-x-auto flex flex-row flex-wrap">
              <div className="w-full">
                <div className="flex flex-col items-center">
                  <div className="text-lg font-medium">
                    Teacher Subject vs Other subjects
                  </div>
                </div>
                <Chart
                  options={state.options}
                  series={state.series}
                  type="bar"
                  width="100%"
                />
              </div>
              {/* <div>
                <div>teacher position in class using scatterplot</div>
                <Chart
                  options={state.options}
                  series={state.series}
                  type="scatter"
                  width="800"
                />
              </div> */}
              <div className="w-full mt-6">
                <div className="flex flex-col items-center">
                  <div className="text-lg font-medium">
                    {" "}
                    Progressive Student performance in subsequest exam taken by
                    teacher
                  </div>
                </div>

                <Chart
                  options={{
                    ...teacherExamWiseAvg.options,
                    chart: {
                      ...teacherExamWiseAvg.options.chart,
                      type: "line",
                    },
                    stroke: {
                      curve: "smooth", // Add this line to enable smooth curve
                    },
                  }}
                  series={teacherExamWiseAvg.series}
                  type="line"
                  width="100%"
                />
              </div>

              <div className="w-full flex flex-row">
                <div className="w-1/2 mt-6">
                  <div className="flex flex-col items-center">
                    <div className="text-lg font-medium">
                      Teacher Average Marks
                    </div>
                  </div>

                  <Chart
                    options={teacherAvgMarks.options}
                    series={teacherAvgMarks.series}
                    type="bar"
                    width="100%"
                  />
                </div>

                <div className="w-1/2 mt-6">
                  <div className="flex flex-col items-center">
                    <div className="text-lg font-medium">
                      Number of Complaints in Teacher Classes
                    </div>
                  </div>

                  <Chart
                    options={{
                      chart: {
                        id: "teacher-complaints",
                        type: "bar",
                      },
                      xaxis: {
                        categories: teacherComplaints.map(
                          (entry) =>
                            `${entry.teacher_name} (${entry.class_teacher})`
                        ),
                      },
                      yaxis: {
                        title: {
                          text: "Number of Complaints",
                        },
                        min: 0,
                        max:
                          Math.max(
                            ...teacherComplaints.map(
                              (entry) => entry.complaint_count
                            )
                          ) + 1,
                      },
                    }}
                    series={[
                      {
                        name: "Number of Complaints",
                        data: teacherComplaints.map(
                          (entry) => entry.complaint_count
                        ),
                      },
                    ]}
                    type="bar"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </Toolbar>
    </div>
  );
};

export default TeacherAnalysis;
