import {BrowserRouter, Routes, Route,} from 'react-router-dom'; //npm i react-router-dom --save
import './App.css';
// import CreateUser from './components/CreateUser';
// import EditUser from './components/EditUser';
// import ListUser from './components/ListUser';
// import ListSchool from './components/ListSchool'
// import CreateSchool from './components/CreateSchool'
// import CreateNotice from './components/CreateNotice';
// import FeeManagementComponent from './components/Fees';
// import EditFees from './components/EditFees';
import Login from './components/Login'
import Dashboard from './components/Dashboard';
// import SignupLogin from './components/SignupLogin';
// import NoticeComponent from './components/GetNotice';
// import CreateTeacher from './components/CreateTeacher';
// import TeacherComponent from './components/GetTeacher';
// import FeedbackComponent from './components/Feedback';
// import CreateVirtualClassLink from './components/CreateClassLink';
// import ClassLinks from './components/GetClassLink';
// import ListSyllabus from './components/Syllabus';
import AdmissionRegister from './components/admissionRegister.jsx';
import StudentManager from './components/StudentManager/StudentManager.jsx';
import StaffManager from './components/StaffManager/StaffManager.jsx';

// import TeacherManager from './components/TeacherManage';
// import AddStudent from './components/AddStudent';
import AddTeacher from './components/TeacherManager/Addteacher';
import TeacherManager from './components/TeacherManager/TeacherManager';
import EditTeacher1 from './components/TeacherManager/EditTeacher1.jsx';
import EditTeacher2 from './components/TeacherManager/EditTeacher2.jsx';
import TeacherComponent from './components/TeacherManager/FetchTeacher';


import AddStaff from './components/StaffManager/AddStaff.jsx'
import EditStaff from './components/StaffManager/EditStaff.jsx'
import FetchStaff from './components/StaffManager/FetchStaff.jsx'
import EditStaffDetails from './components/StaffManager/EditStaffDetails.jsx';

import FetchStudent from './components/StudentManager/FetchStudent.jsx';
import EditStudent from './components/StudentManager/EditStudent2.jsx';


import EditStudent2 from './components/StudentManager/EditStudent2.jsx';
import EditStudent1 from './components/StudentManager/EditStudent1.jsx';
import AddStudent from './components/StudentManager/AddStudent.jsx';

import FeeManager from './components/FeeManager/FeeManager.jsx';
import PayrollManager from './components/PayrollManager/PayrollManager.jsx';

import AttendanceTable from './components/attendenceDownload.jsx'

import CarouselManager from './components/carouselManager.jsx';
import EventManager from './components/EventManager/EventManager.jsx';

import ExamManager from './components/examManager.jsx';
import ComplaintManager from './components/ComplaintManager/ComplaintManager.jsx';
import StudentComplaint from './components/ComplaintManager/Complaint.jsx';
import TeacherComplaint from './components/ComplaintManager/TeacherComplaint.jsx';
import NoticeManager from './components/NoticeManager.jsx';
import Homepage from './components/Homepage.jsx';
import TeacherAnalysis from './components/TeacherAnalysis.jsx'
import PrivacyAndPolicy from './components/PrivacyAndPolicy.jsx';
import TermsAndCondition from './components/TermsAndCondition.jsx';
import FeeMarkManager from './components/FeeManager/MarkFee.jsx';
import FeeManage from './components/FeeManager/ManageFee.jsx';
import VerifyFeePayment from './components/FeeManager/VerifyFee.jsx';
import LedgerGenerate from './components/FeeManager/LedgerGenerate.jsx';


import CoachingManager from './components/CoachingManager/CoachingManager.jsx';
import CoachingStudentManager from './components/CoachingManager/CoachingStudentManager.jsx';
import CoachingFeeManager from './components/CoachingManager/CoachingFeeManager.jsx';
import CoachingLedgerGeneratation from './components/CoachingManager/CoachingLedgerCreation.jsx';


import ReportCardGeneration from './components/ReportCardGeneration/ReportCardGeneration.jsx';

// student marksheet fetch 
import LoginStudent from './components/StudentMarksheet/loginStudent.jsx';
import StudentMarksheetDownload from './components/StudentMarksheet/StudentMarksheetDownload.jsx';

function App() {
  return (
    
    // <div className="container">
    // <div className="App">
    //   <h1 class="page-header text-center">Ed-Sanchar Student Manager</h1>

      <BrowserRouter>
        <Routes>
         <Route exact path="dashboard" element={<Dashboard/>} />
          <Route exact path="dashboard/user/studentManage" element={<studentManage/>} />
          <Route exact path="/login" element={<Login/>} />
          <Route exact path="/" element={<Homepage/>} />

          <Route exact path="/privacyandcookiepolicy" element={<PrivacyAndPolicy/>} />
          <Route exact path="/termsandconditions" element={<TermsAndCondition/>} />

          <Route exact path="dashboard/FeeManager/MarkFee" element={<FeeMarkManager/>} />
          <Route exact path="dashboard/FeeManager/ManageFee" element={<FeeManage/>} />
          <Route exact path="dashboard/FeeManager/VerifyFee" element={<VerifyFeePayment/>} />
          <Route exact path="dashboard/FeeManager/FeeLedger" element={<LedgerGenerate/>} />

          
          <Route exact path="dashboard/StudentManager" element={<StudentManager/>} />
          <Route exact path="dashboard/TeacherManager" element={<TeacherManager/>} />

          {/* staff routes */}
          <Route exact path="dashboard/staffManager" element={<StaffManager/>} />
          <Route exact path='dashboard/staffManager/AddStaff' element={<AddStaff/>} />
          <Route exact path='dashboard/staffManager/fetchStaff' element={<FetchStaff/>} />
          <Route exact path='dashboard/staffManager/editStaff' element={<EditStaff/>} />
          <Route exact path="/editStaffDetails/:username" element={<EditStaffDetails/>}/>




          <Route exact path="dashboard/TeacherAnalysis" element={<TeacherAnalysis/>} />

           <Route exact path="dashboard/StudentManager/FetchStudent" element={<FetchStudent/>} />
           <Route exact path="/edit/:username" element={<EditStudent2/>}/>
           <Route exact path="dashboard/StudentManager/Editstudent1" element={<EditStudent1/>}/>
           <Route exact path="dashboard/studentManager/AddStudent" element={<AddStudent/>}/>

           <Route exact path="dashboard/teachermanager/fetchteacher" element={<TeacherComponent/>}/>
           <Route exact path="dashboard/TeacherManager/editTeacher1" element={<EditTeacher1/>}/>
           <Route exact path="dashboard/TeacherManager/AddTeacher" element={<AddTeacher/>}/>
           <Route exact path="/:username/Teacheredit" element={<EditTeacher2/>}/>

           <Route exact path="dashboard/FeeManager" element={<FeeManager/>}/>
           <Route exact path="dashboard/PayrollManager" element={<PayrollManager/>}/>


           <Route exact path="/:username/attendanceManager" element={<AttendanceTable/>}/>
           
           <Route exact path="dashboard/carouselManager" element={<CarouselManager/>}/>
           <Route exact path="dashboard/EventManager" element={<EventManager/>}/>

           <Route exact path="dashboard/ExamManager" element={<ExamManager/>}/>


           <Route exact path="dashboard/ComplaintManager" element={<ComplaintManager/>}/>
           <Route exact path="dashboard/ComplaintManager/StudentComplaint" element={<StudentComplaint/>}/>
           <Route exact path="dashboard/ComplaintManager/TeacherComplaint" element={<TeacherComplaint/>}/>

           <Route exact path="dashboard/NoticeManager" element={<NoticeManager/>}/>

           <Route exact path="dashboard/MarksheetGeneration" element={<ReportCardGeneration/>}/>


          {/* Coaching Manager route */}      
           <Route exact path="dashboard/CoachingManager" element={<CoachingManager/>}/>
           <Route exact path="dashboard/CoachingManager/StudentManage" element={<CoachingStudentManager/>}/>
           <Route exact path="dashboard/CoachingManager/FeeManage" element={<CoachingFeeManager/>}/>
           <Route exact path="dashboard/CoachingManager/LedgerGenerate" element={<CoachingLedgerGeneratation/>}/>


           {/* STUDENT MARKSHEET DOWNLAOD  */}
           <Route exact path="student/login" element={<LoginStudent/>}/>
           <Route exact path="student/MarksheetDownload" element={<StudentMarksheetDownload/>}/>

           {/* admission register  */}
           <Route exact path="dashboard/admissionRegister" element={<AdmissionRegister/>}/>



          
        </Routes>
      </BrowserRouter>
    // </div>
    // </div>
  );
}
 
export default App;

