import React, { useState, useEffect } from "react";
import { Box, Toolbar } from "@mui/material";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import Cookies from "js-cookie";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const columns = [
  { field: "id", headerName: "Id", width: 60 },
  { field: "username", headerName: "Username", width: 120 },
  { field: "name", headerName: "Name", width: 200 },
  { field: "roll_no", headerName: "Roll No", width: 80 },
  { field: "gender", headerName: "Gender", width: 60 },
  { field: "DOB", headerName: "Dob", width: 120 },
  { field: "student_UID", headerName: "Student UID", width: 100 },
  { field: "father_name", headerName: "Father's Name", width: 120 },
  { field: "mother_name", headerName: "Mother's Name", width: 180 },
  { field: "address", headerName: "Address", width: 200 },
  { field: "email", headerName: "Email", width: 100 },
  { field: "mobile", headerName: "Mobile", width: 100 },
  { field: "phone", headerName: "Phone", width: 100 },
  { field: "enrollment_date", headerName: "Enrollment Date", width: 140 },
  { field: "disenrollment_date", headerName: "Dissenrollment Date", width: 140 },
  { field: "regards", headerName: "Regards", width: 100 },
];

function AdmissionRegister() {
  const navigate = useNavigate();

  // role and role id
  const [schoolId, setSchoolId] = useState("");

  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);

  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  React.useEffect(() => {
    // Retrieve the selected rows based on the row indexes in rowSelectionModel

    if (teacherData.message != "No Admission Records found") {
      const selectedRows = teacherData.filter((data) =>
        rowSelectionModel.includes(data.id)
      );
      setSelectedRowsData(selectedRows);
    } else {
      return;
    }
  }, [rowSelectionModel]);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // console.log("Decrypted Role ID:", decryptedRoleId);
    // console.log("LoadingRoleId:", loadingRoleId);

    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(1) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const [teacherData, setTeacherData] = useState([]);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState(true);
  const [revealedPasswords, setRevealedPasswords] = useState([]);

  const rotationDeg = 90; // Adjust the rotation degree as needed

  const fetchTeacherData = (schoolId) => {
    fetch(
      // `http://localhost/react/api_admin/getAllTeachers.php?school_id=${schoolId}`,
      `https://edsanchaar.in/api/api_admin/AdmissionRegister/getAdmissionDetails.php?school_id=${schoolId}`,
      {
        method: "GET",
        headers: {
          Authorization: "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTeacherData(data);
        setError(null);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // Fetch schoolId from cookies
    const schoolId = Cookies.get("schoolId");

    if (!schoolId) {
      // Redirect to login if schoolId is not available
      // You may want to add additional handling, such as showing an error message
      return;
    }
    setSchoolId(schoolId);

    // Fetch teacher data with dynamic schoolId
    fetchTeacherData(schoolId);
  }, []);

  return (
    <div className="flex items-center justify-center w-full h-screen bg-white">
      <Toaster position="top-center" reverseOrder={true} />

      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="flex flex-col justify-center items-center">
            <div className="text-3xl font-bold mb-4">Admission Record</div>
          </div>
          {teacherData.length > 0 && (
            <div className="w-full h-full overflow-x-auto">
              <DataGrid
                className="w-full"
                rows={teacherData}
                columns={columns}
                pageSize={5}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
              />
            </div>
          )}

          {teacherData.message == "No Admission Records found" && (
            <div className="flex flex-col justify-center items-center">
              <div className="text-xl  mb-4">
                No Admisssion Record Available
              </div>
            </div>
          )}
        </Box>
      </Toolbar>
    </div>
  );
}

export default AdmissionRegister;
