import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Toolbar,
  Paper,
  Typography,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import CircularProgress from "@mui/material/CircularProgress";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
export default function NoticeManager() {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);
  const [schoolClasses, setSchoolClasses] = useState([]);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(7) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const [school_id, setSchoolId] = useState("");
  const [notices, setNotices] = useState([]);
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [open, setOpen] = useState(false);
  const [editedNotice, setEditedNotice] = useState(null);
  const [loading, setLoading] = useState(true);

  const [deletingNoticeId, setDeletingNoticeId] = useState(null);

  const [newNotice, setNewNotice] = useState({
    date_upto: "",
    message: "",
    class_id: "",
  });

  const isSubmitDisabled = () => {
    // Check if any of the required fields are empty
    return Object.values(newNotice).some((value) => value.trim() === "");
  };

  const fetchNotices = async () => {
    try {
      const response = await axios.get(
        // `http://localhost/react/api_admin/fetchSchoolNotices.php?school_id=${school_id}`,
        `https://edsanchaar.in/api/api_admin/fetchSchoolNotices.php?school_id=${school_id}`,
        {
          headers: {
            Authorization: "ZVE2VQFin3of3TLkvn76Ibm3ntAkOz4SaqD3Da/2IUW3",
          },
        }
      );
      setNotices(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchClassIds = async () => {
    try {
      const response = await axios.get(
        `https://edsanchaar.in/api/api_admin/TeacherManager/getSchoolClasses.php?school_id=${school_id}`,
        {
          headers: {
            Authorization: "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
          },
        }
      );

      const data = response.data;

      if (Array.isArray(data)) {
        // Extract class names from data and update schoolClasses state
        const classNames = data.map((classInfo) => classInfo["class"]);
        setSchoolClasses(classNames);
      } else {
        // Handle invalid response format
        console.error("Invalid response format for class IDs:", data);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching class IDs:", error);
      setLoading(false);
    }
  };

  const deleteNotice = async (id) => {
    // Use toast.promise to handle promises
    setDeletingNoticeId(id); // Set the notice ID being deleted

    await toast.promise(
      // The promise to execute
      new Promise(async (resolve, reject) => {
        setTimeout(async () => {
          try {
            // Make an asynchronous HTTP DELETE request to delete a notice
            const response = await axios.post(
              `https://edsanchaar.in/api/api_admin/deleteNotice.php?notice_id=${id}&school_id=${school_id}`,
              {},
              {
                headers: {
                  authorization: "ZVE2VQFin3of3TLkvn76Ibm3ntAkOz4SaqD3Da/2IUW3",
                  "Content-Type": "application/json", // Set the content type to JSON
                },
              }
            );

            if (response.status === 200) {
              fetchNotices();
              resolve(); // Resolve the promise on success
            } else {
              reject(); // Reject the promise on failure
            }
          } catch (error) {
            reject(); // Reject the promise on error
          }
        }, 2000); // 2s timeout
      }),
      {
        // Toast options
        loading: "Deleting notice, please wait...",
        success: <b>Notice deleted successfully!</b>,
        error: <b>Error deleting notice.</b>,
      }
    );
    setDeletingNoticeId(false); // Reset the notice ID after deletion is complete
  };

  const handleAddNotice = async (e) => {
    // e.preventDefault;
    e.preventDefault();

    try {
      const requestData = {
        school_id: school_id,
        message: newNotice.message,
        date_upto: newNotice.date_upto,
        classId: newNotice.class_id,
      };
      console.log(requestData);

      // Use toast.promise to handle promises
      await toast.promise(
        // The promise to execute
        new Promise(async (resolve, reject) => {
          setTimeout(async () => {
            try {
              const response = await axios.post(
                "https://edsanchaar.in/api/api_admin/addNotice.php",
                requestData,
                {
                  headers: {
                    Authorization:
                      "ZVE2VQFin3of3TLkvn76Ibm3ntAkOz4SaqD3Da/2IUW3",
                    "Content-Type": "application/json", // Set the content type to JSON
                  },
                }
              );

              if (response.data.status === 1) {
                fetchNotices();
                setNewNotice({
                  date_upto: "",
                  message: "",
                  class_id: "",
                });
                resolve(); // Resolve the promise on success
              } else {
                reject(); // Reject the promise on failure
              }
            } catch (error) {
              reject(); // Reject the promise on error
            } finally {
              setLoading(false); // Set loading to false after the promise is resolved or rejected
            }
          }, 4000); // 4s timeout
        }),
        {
          // Toast options
          loading: "Sending Notice to all Students, please wait...",
          success: <b>Notice added successfully!</b>,
          error: <b>Failed to add notice.</b>,
        }
      );
    } catch (error) {
      toast.error(`Failed to add notice. ${error.message || "Unknown error"}`);
    }
  };

  const handleOpen = (notice) => {
    setSelectedNotice(notice);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedNotice(null);
    setEditedNotice(null);
  };

  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      console.error("School ID not available");
      return;
    }

    setSchoolId(schoolIdFromCookie);
  }, []);

  useEffect(() => {
    if (school_id !== "") {
      fetchNotices();
      fetchClassIds();
    }
  }, [school_id]);

  return (
    <div className="flex items-center justify-center w-full h-full py-10 bg-white">
      <Toaster position="top-center" reverseOrder={true} />
      <Toolbar
        sx={{
          MinHeight: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="text-3xl font-bold">School Notice Manager</div>
          <div className="flex flex-row w-full py-4">
            <div className="w-3/4 px-2 pt-10">
              <div className="w-full ">
                {loading ? (
                  <div className="w-3/4 h-full flex justify-center items-center">
                    <CircularProgress size={60} />
                  </div>
                ) : (
                  <Grid
                    container
                    spacing={2}
                    className="w-full"
                    justifyContent="center"
                  >
                    {notices.length > 0 ? (
                      notices.map((notice) => (
                        <Grid item key={notice.id} xs={12} md={6} lg={4}>
                          <Paper
                            sx={{
                              p: 2,
                              minHeight: 180,
                              maxHeight: 280,
                              width: "100%",
                              borderTopLeftRadius: 6,
                              borderTopRightRadius: 6,
                            }}
                            onClick={() => handleOpen(notice)}
                          >
                            <Typography variant="h6">
                              {notice.subject}
                            </Typography>
                            <Typography>Upto: {notice.date_upto}</Typography>
                            <Typography>Message: {notice.message}</Typography>
                          </Paper>
                          <div className="flex flex-row justify-evenly bg-slate-200 rounded-b-md">
                            <button
                              className="text-lg text-red-600 font-normal"
                              onClick={() => deleteNotice(notice.id)}
                              disabled={deletingNoticeId === notice.id}
                            >
                              {deletingNoticeId === notice.id
                                ? "Deleting..."
                                : "Delete Notice"}
                            </button>
                          </div>
                        </Grid>
                      ))
                    ) : (
                      <Grid item>
                        <Typography>No notices available</Typography>
                      </Grid>
                    )}
                  </Grid>
                )}

                {selectedNotice && (
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        width: 400,
                        borderRadius: 2,
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Notice Details
                      </Typography>
                      <Typography>
                        Date From: {selectedNotice.date_from}
                      </Typography>
                      <Typography>
                        Date Upto: {selectedNotice.date_upto}
                      </Typography>
                      <Typography>Message: {selectedNotice.message}</Typography>
                    </Box>
                  </Modal>
                )}
              </div>
            </div>
            <div className="flex flex-col justify-start items-start w-1/4 px-4">
              <div className="w-full flex flex-col">
                <div className="text-center font-bold text-2xl">
                  Add New Notice
                </div>
                <form
                  className="my-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <TextField
                    id="newDateUpto"
                    label="Date Upto"
                    type="date"
                    value={newNotice.date_upto}
                    onChange={(e) =>
                      setNewNotice({
                        ...newNotice,
                        date_upto: e.target.value,
                      })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      focused: true,
                    }}
                  />
                  <TextField
                    select
                    label="Class"
                    value={newNotice.class_id || ""}
                    onChange={(e) =>
                      setNewNotice({
                        ...newNotice,
                        class_id: e.target.value,
                      })
                    }
                    InputProps={{
                      focused: true,
                    }}
                  >
                    <MenuItem value="0">School Level</MenuItem>
                    {schoolClasses.map((classId) => (
                      <MenuItem key={classId} value={classId}>
                        {classId}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    id="newMessage"
                    label="Message"
                    multiline
                    rows={4}
                    value={newNotice.message}
                    onChange={(e) =>
                      setNewNotice({
                        ...newNotice,
                        message: e.target.value,
                      })
                    }
                    InputProps={{
                      focused: true,
                    }}
                  />

                  <button
                    variant="contained"
                    color="primary"
                    onClick={(e) => handleAddNotice(e)}
                    style={{ width: "100%" }}
                    className={`bg-blue-500 text-white px-4 py-2 rounded ${
                      isSubmitDisabled() ? "cursor-not-allowed opacity-50" : ""
                    }`}
                    disabled={isSubmitDisabled()}
                  >
                    Add Notice
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Paper>
      </Toolbar>
    </div>
  );
}
