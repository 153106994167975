import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "remixicon/fonts/remixicon.css";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";

import {
  Toolbar,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function FetchStaff() {

  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {

    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(3) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const [staffData, setStaffData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [school_id, setSchoolId] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    username: "",
    name: "",
    rollNo: 0,
  });

  function fetchStaffData(schoolId) {
    fetch(
      `https://edsanchaar.in/api/api_admin/Staff/fetchStaff.php?school_id=${schoolId}`,
      {
        method: "GET",
        headers: {
          Authorization: "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setStaffData(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    const schoolId = Cookies.get("schoolId");

    if (!schoolId) {
      // Redirect to login if schoolId is not available
      // You may want to add additional handling, such as showing an error message
      return;
    }
    setSchoolId(schoolId)

    // Fetch Staff data with dynamic schoolId
    fetchStaffData(schoolId);
  }, []);

  const openDeleteConfirmation = (username, name, rollNo) => {
    setDeleteConfirmation({
      isOpen: true,
      username,
      name,
      rollNo,
    });
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmation({
      isOpen: false,
      username: "",
      name: "",
      rollNo: 0,
    });
  };

  const deleteUser = (username) => {
    fetch(
      `https://edsanchaar.in/api/api_admin/Staff/removeStaff.php?username=${username}&school_id=${school_id}`,
      {
        method: "POST",
        headers: {
          authorization: "ZVE2VQFin3of3TLkvn76Ibm3ntAkOz4SaqD3Da/2IUW3",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        toast.success("Staff Unenrolled Successfully");

        const schoolId = Cookies.get("schoolId");
        fetchStaffData(schoolId);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        closeDeleteConfirmation();
      });
  };

  return (
    <div className="flex items-center justify-center w-full h-screen bg-white">
      <Toaster position="top-center" reverseOrder={true} />
      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="flex flex-col justify-center items-center">
            <div className="text-3xl font-bold">Staff Data</div>
          </div>
          {staffData.length === 0 ? (
            <div className="w-full h-full flex items-center justify-center font-semibold text-xl">
              No Staffs found.
            </div>
          ) : (
            <div className="w-full h-full overflow-x-auto">
              <table className="w-full border-collapse border">
                <thead>
                  <tr className="bg-blue-200">
                    <th className="border p-2">
                      <i className="ri-user-line pr-2"></i>Name
                    </th>
                    <th className="border p-2">
                      <i className="ri-list-ordered pr-2"></i>Roll No
                    </th>
                    <th className="border p-2">
                      <i className="ri-calendar-line pr-2"></i>Date of Birth
                    </th>
                    <th className="border p-2">
                      <i className="ri-male-line pr-2"></i>Gender
                    </th>
                    <th className="border p-2">
                      <i className="ri-user-line pr-2"></i>Username
                    </th>
                    <th className="border p-2">
                      <i className="ri-user-star-line pr-2"></i>Role
                    </th>
                    {/* Add more table headers based on the API response */}
                    <th className="border p-2">
                      <i className="ri-delete-bin-6-line pr-2"></i>Actions
                    </th>
                  </tr>
                </thead>
                {loading ? (
                  <div className="flex w-full items-center justify-center">
                    <CircularProgress size={60} />
                  </div>
                ) : (
                  <tbody>
                    {Array.isArray(staffData) &&
                      staffData.map((staff, key) => (
                        <tr
                          key={key}
                          className={key % 2 === 0 ? "bg-blue-100" : "bg-white"}
                        >
                          <td className="border p-2">{staff.name}</td>
                          <td className="border p-2">{staff.roll_no}</td>
                          <td className="border p-2">{staff.dob}</td>
                          <td className="border p-2">
                            {staff.gender === 0 ? "Male" : "Female"}
                          </td>
                          <td className="border p-2">{staff.username}</td>
                          <td className="border p-2">{staff.role}</td>
                          {/* Add more table cells based on the API response */}
                          <td className="border p-2">
                            <Link
                              to={`/editStaffDetails/${staff.username}`}
                              className="text-blue-500 hover:underline mr-2"
                            >
                              Edit
                            </Link>
                            <button
                              onClick={() =>
                                openDeleteConfirmation(
                                  staff.username,
                                  staff.name,
                                  staff.roll_no
                                )
                              }
                              className="text-red-500 hover:underline"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
            </div>
          )}
        </Box>
      </Toolbar>
      {/* Delete Confirmation Modal */}
      <Dialog
        open={deleteConfirmation.isOpen}
        onClose={closeDeleteConfirmation}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <p>
            Do you want to remove {deleteConfirmation.name} with roll number{" "}
            {deleteConfirmation.rollNo} from school records?
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirmation}>Cancel</Button>
          <Button
            onClick={() => deleteUser(deleteConfirmation.username)}
            variant="contained"
            color="error"
          >
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
