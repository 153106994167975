import React, { useState, useEffect } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import toast, { Toaster } from "react-hot-toast";

import {
  FormControl,
  InputLabel,
  Select as MaterialSelect,
  Button,
} from "@mui/material";

const columns = [
  { field: "id", headerName: " S.No", width: 90 },
  { field: "fee_id", headerName: "Fee Id", width: 90 },
  { field: "username", headerName: "Username", width: 120 },
  { field: "name", headerName: "Name", width: 200 },
  { field: "class_id", headerName: "Class ID", width: 100 },
  { field: "roll_no", headerName: "Roll No", width: 100 },
  {
    field: "boarder",
    headerName: "Boarder",
    width: 100,
    renderCell: (params) => <span>{params.value === 1 ? "Yes" : "No"}</span>,
  },
  { field: "plan", headerName: "Plan", width: 100 },
  { field: "title", headerName: "Title", width: 100 },
  { field: "base_fee", headerName: "Base Fee", width: 100 },
  {
    field: "miscellaneous_charges",
    headerName: "Miscellaneous Charges",
    width: 100,
  },
  { field: "total_fees", headerName: "Total Fees", width: 100 },
  {
    field: "paid_status",
    headerName: "Paid Status",
    width: 100,
    renderCell: (params) => (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          // fontWeight: "400",
          fontSize: "14px",
          height: "100%",
          backgroundColor: "green",
          color: "white",
          backgroundColor: params.value === "paid" ? "green" : "red",
        }}
      >
        {params.value === "paid" ? "Paid" : "Unpaid"}
      </span>
    ),
  },
  { field: "total_paid", headerName: "Total Paid", width: 100 },

  { field: "receipt_no", headerName: "Receipt No", width: 100 },
  { field: "transaction_id", headerName: "Transaction Id", width: 100 },
  { field: "paid_at", headerName: "Paid At", width: 110 },
  { field: "due_date", headerName: "Due Date", width: 100 },
];

function FeeManage() {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [leasureModalOpen, setLeasureModalOpen] = useState(false);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoading(false); // Set loading to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // Check the condition and navigate only if not loading and role is not "1"
    if (!loading && decryptedRoleId.charAt(4) !== "1") {
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loading]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const [school_id, setSchoolId] = useState("");
  const [classIds, setClassIds] = useState([]);
  const [classFilter, setClassFilter] = useState("");

  const [planFilter, setPlanFilter] = useState("all");
  const [filteredData, setFilteredData] = useState([]);

  const [studentData, setStudentData] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const [selectedYear, setSelectedYear] = useState("");
  const [monthsForLedger, setMonthsForLedger] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [ledgerData, setLedgerData] = useState([]);

  const [feeAddOpen, setFeeAddOpen] = useState(false);
  const [notifyOpen, setNotifyOpen] = useState(false);
  const [modalData, setModalData] = useState({
    base_fee: "",
    miscellaneous_charges: "",
    totalfee: "",
    plan: "",
    title: "",
    transaction_id: "",
    receipt_no: "",
    total_paid: "",
  });
  const [feeIds, setFeeIds] = useState("");
  const [usernames, setUsernames] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const [feeTitle, setFeeTitle] = useState("");
  const [receiptNo, setReceiptNo] = useState("");
  const [transantionId, setTransactionId] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [notifyMessage, setNotifyMessage] = useState("");
  const [totalPaid, setTotalPaid] = useState("");

  const [totalFee, setTotalFee] = useState(0); // Add this line to define totalFee state

  const [feeMonthAndYearForLedger, setFeeMonthAndYearForLedger] = useState([]);
  const [yearlyFeeDataForLedger, setyearlyFeeDataForLedger] = useState([]);

  const generatePDF = () => {
    // Get the HTML element containing the table
    const table = document.getElementById("LedgerTable");
    // console.log(table);

    // Function to capture the table after a short delay
    const captureTable = () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          // Create a canvas from the HTML table
          html2canvas(table).then((canvas) => {
            // Get the dimensions of the table
            const tableWidth = table.offsetWidth;
            const tableHeight = table.offsetHeight;

            // Convert canvas to JPEG image
            const imgData = canvas.toDataURL("image/jpeg", 1.0);

            // Set dimensions for PDF
            const pdf = new jsPDF("p", "pt", "a2");

            // Add image to PDF
            pdf.addImage(imgData, "JPEG", 0, 0, tableWidth, tableHeight);

            // Download PDF
            pdf.save("ledger.pdf");
            resolve();
          });
        }, 10); // Adjust the delay as needed (milliseconds)
      });
    };

    // Capture the table after a delay
    captureTable();
  };

  // Calculate daily total
  const dailyTotals = {};
  let monthlyTotal = 0;

  // Calculate daily and monthly totals
  ledgerData.forEach((item) => {
    const date = item.paid_at;
    const totalPaid = parseInt(item.total_paid);

    // Calculate daily total
    if (dailyTotals[date]) {
      dailyTotals[date] += totalPaid;
    } else {
      dailyTotals[date] = totalPaid;
    }

    // Add to monthly total
    monthlyTotal += totalPaid;
  });
  // JSX for daily total rows
  const cumulativeAmounts = {};
  let cumulativeTotal = 0;
  ledgerData.forEach((item) => {
    const date = item.paid_at;
    cumulativeTotal += item.total_paid;
    cumulativeAmounts[date] = cumulativeTotal;
  });

  // JSX for daily total rows
  const dailyTotalRows = Object.entries(dailyTotals).map(
    ([date, total], index) => (
      <tr key={date}>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {index + 1}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {date}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {total}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {cumulativeAmounts[date]}
        </td>
      </tr>
    )
  );

  // JSX for monthly total row
  const monthlyTotalRow = (
    <tr className="bg-gray-200">
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        Monthly Total
      </td>
      <td
        colSpan="3"
        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
      >
        {monthlyTotal}
      </td>
    </tr>
  );

  useEffect(() => {
    // Reset ledgerData to an empty array
    setLedgerData([]);
  }, [selectedMonth]);
  useEffect(() => {
    // Reset ledgerData to an empty array
    setyearlyFeeDataForLedger([]);
  }, [selectedYear]);

  const handleOpen = () => {
    setFeeAddOpen(true);

    // Display the data of the first selected row in the modal
    if (selectedRowsData.length > 0) {
      const firstRowData = selectedRowsData[0];
      setModalData({
        totalfee: firstRowData.total_fees,
        plan: firstRowData.plan,
        title: firstRowData.title,
        receipt_no: "",
        transaction_id: "",
      });
      setUsernames(firstRowData.username);
      setFeeTitle(firstRowData.title);
      setSelectedPlan(firstRowData.plan);
      setTotalFee(firstRowData.total_fees);
    }
  };

  const handleNotifyOpen = () => {
    setNotifyOpen(true);

    // Display the data of the first selected row in the modal
    if (selectedRowsData.length > 0) {
      const firstRowData = selectedRowsData[0];
    }
  };
  const handleCloseLeasureModal = () => {
    setLeasureModalOpen(false);
  };
  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    const selectedEntry = feeMonthAndYearForLedger.find(
      (entry) => entry.year.toString() === selectedYear
    );
    if (selectedEntry) {
      setMonthsForLedger(selectedEntry.months);
      setSelectedMonth(""); // Reset the selected month
    } else {
      setMonthsForLedger([]); // If no matching entry found, reset monthsForLedger
    }
    setSelectedYear(selectedYear);
  };

  // Function to handle generation of leasure
  const generateLeasureOpen = () => {
    // Implement generation logic here
    setLeasureModalOpen(true);
    // This function will be called when the "Generate" button is clicked

    const fetchYearAndMonthDataForLedger = async () => {
      try {
        const response = await fetch(
          `https://edsanchaar.in/api/api_admin/FeeManager/getDistinctMonthAndYearsFeePayment.php?school_id=${school_id}`,
          {
            method: "GET",
            headers: {
              Authorization: "aUBnAANvzn4CzHO76Cr+IHnjs3tvnr680eQSSZG2sgw=",
            },
          }
        );

        if (response.ok) {
          const data = await response.json(); // Parse response body as JSON
          setFeeMonthAndYearForLedger(data); // Set the fetched data in state
        } else {
          console.error("Failed to notify fee payment");
        }
      } catch (error) {
        console.error("Error notifying fee payment:", error);
      }
    };

    fetchYearAndMonthDataForLedger();
  };

  // useEffect(() => {
  //   console.log(feeMonthAndYearForLedger);
  // }, [feeMonthAndYearForLedger]);

  const handleGenerateLeasure = () => {
    let apiUrl;
    if (selectedMonth === "") {
      apiUrl = `https://edsanchaar.in/api/api_admin/FeeManager/yearlyFeeDataForLedger.php?school_id=${school_id}&year=${selectedYear}`;
    } else {
      apiUrl = `https://edsanchaar.in/api/api_admin/FeeManager/feeDataForLedger.php?school_id=${school_id}&year=${selectedYear}&month=${selectedMonth.toLowerCase()}`;
    }

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        if (
          data.message &&
          data.message === "No data found for the specified parameters."
        ) {
          // Handle case where no data is found
        } else {
          if (selectedMonth === "") {
            setyearlyFeeDataForLedger(data); // Store yearly fee data in state
          } else {
            setLedgerData(data); // Store monthly fee data in state
          }
          setLeasureModalOpen(true); // Open the modal to display the data
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const generateFeeReceipt = async () => {
    // Check if there are selected rows
    if (selectedRowsData.length === 0) {
      toast.error("No rows selected");
      return;
    } else if (selectedRowsData.length > 1) {
      toast.error("You cannot select more than one row to mark paid at once");
      return;
    }
    const feeId = selectedRowsData[0].fee_id;

    if (selectedRowsData[0].paid_status == "unpaid") {
      toast.error("Can't Generate receipt of unpaid fees");
      return;
    }

    // console.log(selectedRowsData[0].paid_status);

    try {
      window.open(
        `https://edsanchaar.in/api/api_student/fees/feeReceiptDownload.php?school_id=${school_id}&fee_id=${feeId}&plan=school_fee`
      );
      // Check if the response indicates success
    } catch (error) {
      // Handle network errors
      toast.error("Error Generation Receipt", error);
    }
  };

  // useEffect(() => {
  //   console.log(ledgerData);
  // }, [ledgerData]);
  const openConfirmationModal = () => {
    setConfirmationOpen(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationOpen(false);
  };

  // Function to handle updating fee details
  const handleUpdateFeeDetails = () => {
    closeConfirmationModal(); // Close the confirmation modal
    // Call the API to update fee details
    handelUpdateFeeStatus();
  };

  const handleNotifyMessageChange = (e) => {
    setNotifyMessage(e.target.value);
  };

  const handleReceiptNoChange = (e) => {
    setReceiptNo(e.target.value);
  };

  const handleTransactionIdChange = (e) => {
    setTransactionId(e.target.value);
  };
  const handleTotalPaidChange = (e) => {
    setTotalPaid(e.target.value);
  };

  const handelSendPaymentNotification = async () => {
    const requestData = {
      message: notifyMessage,
      unpaid_usernames: usernames, // Assuming usernames is an array
      class_id: classFilter,
      school_id: school_id,
    };

    try {
      const response = await fetch(
        "https://edsanchaar.in/api/api_admin/notifyFeePayment.php",
        {
          method: "POST",
          headers: {
            Authorization: "aUBnAANvzn4CzHO76Cr+IHnjs3tvnr680eQSSZG2sgw=",
            "Content-Type": "application/json", // Set content type to JSON
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        handleClose();
      } else {
        console.error("Failed to notify fee payment");
      }
    } catch (error) {
      console.error("Error notifying fee payment:", error);
    }
  };

  const handelUpdateFeeStatus = async () => {
    if (totalFee < totalPaid) {
      toast.error("Paid fee cannot be greater than total fee");
      return;
    }

    const formData = new FormData();

    // Append data to FormData
    // formData.append("school_id", school_id);
    formData.append("transaction_id", transantionId);
    formData.append("receipt_no", receiptNo);
    formData.append("fee_id", feeIds);
    formData.append("total_paid", totalPaid);
    formData.append("school_id", school_id);

    try {
      const response = await axios.post(
        "https://edsanchaar.in/api/api_admin/updateFeePaidStatus.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // Add any other headers as needed
          },
        }
      );

      // Handle the API response as needed
      const selectedClass = classFilter;
      await getClassStudents(selectedClass);
      handleClose();

      // Reset transaction_id, receipt_no, and total_paid states
      setTransactionId("");
      setReceiptNo("");
      setTotalPaid("");
    } catch (error) {
      // Handle errors
      console.error("Error sending data to API:", error);
    }
  };

  const handleClose = () => {
    setFeeAddOpen(false);
    setTotalFee(0);
  };

  const handleNotifyClose = () => {
    setNotifyOpen(false);
    setNotifyMessage("");
  };

  const handleClassFilterChange = async (e) => {
    const selectedClass = e.target.value;
    setClassFilter(selectedClass);
    await getClassStudents(selectedClass);
  };
  const handelPlanFiter = (value) => {
    setPlanFilter(value);
  };

  const getClassStudents = async (selectedClass) => {
    const apiUrl = `https://edsanchaar.in/api/api_admin/getClassFeePaymentDetails.php?school_id=${school_id}&class_id=${selectedClass}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: "aV0xQRFizDkSwyzksn2lc0xi4Rytc8y4fT1ftwiL8ek",
        },
      });

      const data = response.data;

      if (Array.isArray(data)) {
        // Convert paid_status from 0 to "unpaid" and from 1 to "paid"
        const updatedData = data.map((row) => ({
          ...row,
          paid_status: row.paid_status === 0 ? "unpaid" : "paid",
        }));

        // Manually set an 'id' property for each row based on the index
        const studentDataWithIds = updatedData.map((row, index) => ({
          ...row,
          id: index + 1, // You can adjust this based on your requirements
        }));

        setStudentData(studentDataWithIds);
      } else {
        console.error("Invalid response format for student data:", data);
      }
    } catch (error) {
      console.error("Error fetching student data:", error);
    }
  };

  const addFeeDetails = () => {
    // Check if there are selected rows
    if (selectedRowsData.length === 0) {
      toast.error("No rows selected");
      return;
    }

    // Check if more than one row is selected
    if (selectedRowsData.length > 1) {
      toast.error("Only one row can be updated at a time");
      return;
    }

    // Check if all selected rows have the same plan, boarder, title, and paid_status
    // const isSameValues = selectedRowsData.every(
    //   (rowData) =>
    //     rowData.boarder === selectedRowsData[0].boarder &&
    //     rowData.paid_status === selectedRowsData[0].paid_status
    // );

    // if (!isSameValues) {
    //   console.error(
    //     "Selected rows do not have the same boarder, plan, title, and paid_status values"
    //   );
    //   return;
    // }

    const notPaid = selectedRowsData.every(
      (rowData) => rowData.paid_status === "unpaid"
    );

    if (!notPaid) {
      console.error("Selected rows contain fee data with Status Paid");
      return;
    }

    const feeIdsArray = selectedRowsData.map((rowData) => rowData.fee_id);

    setFeeIds(feeIdsArray);
    handleOpen();
  };

  const notifyModalOpem = () => {
    // console.log("NOTIFY OPEN")
    if (selectedRowsData.length === 0) {
      toast.error("No rows selected");
      return;
    }

    const notPaid = selectedRowsData.every(
      (rowData) => rowData.paid_status === "unpaid"
    );

    if (!notPaid) {
      console.error("Selected rows contain fee data with Status Paid");
      return;
    }

    const usernamesArray = selectedRowsData.map((rowData) => rowData.username);

    setUsernames(usernamesArray);
    handleNotifyOpen();
  };

  const fetchClassIds = async (schoolId) => {
    const apiUrl = `https://edsanchaar.in/api/api_admin/getDistinctClasses.php?school_id=${schoolId}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: "aV0xQRFizDkSwyzksn2lc0xi4Rytc8y4fT1ftwiL8ek",
        },
      });

      const data = response.data;

      if (Array.isArray(data)) {
        const sortedClassIds = data
          .map((classInfo) => classInfo.class_id)
          .sort();
        setClassIds(sortedClassIds);
      } else {
        console.error("Invalid response format for class IDs:", data);
      }
    } catch (error) {
      console.error("Error fetching class IDs:", error);
    }
  };

  React.useEffect(() => {
    // Filter the data based on the selected plan
    const updatedData = studentData.filter((row) => {
      // If planFilter is 'all', show all rows
      if (planFilter === "all") {
        return true;
      }
      // Otherwise, filter based on the selected plan
      return row.plan === planFilter;
    });

    setFilteredData(updatedData);
  }, [studentData, planFilter]);

  React.useEffect(() => {
    // Retrieve the selected rows based on the row indexes in rowSelectionModel
    const selectedRows = rowSelectionModel.map(
      (index) => studentData[index - 1]
    );
    setSelectedRowsData(selectedRows);
  }, [rowSelectionModel, studentData]);

  React.useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      console.error("School ID not available");
      return;
    }

    setSchoolId(schoolIdFromCookie);
    fetchClassIds(schoolIdFromCookie);
  }, []);

  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <Toaster position="top-center" reverseOrder={true} />

      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 2,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="h-full w-full flex flex-col justify-center items-center">
            <div className="text-3xl font-bold py-4">School Fee Data</div>
            <div className="w-full flex flex-row border ">
              <form
                // onSubmit={handleSubmit}
                className="w-full flex flex-row justify-evenly flex-wrap"
              >
                <div className="flex flex-row items-center mx-4">
                  <InputLabel id="class-filter-label">Class ID:</InputLabel>

                  <select
                    id="class-filter"
                    value={classFilter}
                    onChange={handleClassFilterChange}
                  >
                    <option value="">All Classes</option>
                    {classIds.map((classId) => (
                      <option key={classId} value={classId}>
                        {classId}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-row items-center mx-4">
                  <InputLabel id="class-filter-label">Payment Plan:</InputLabel>

                  <select
                    id="class-filter"
                    value={planFilter}
                    onChange={(e) => handelPlanFiter(e.target.value)}
                  >
                    <option value="all">All</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="Half-Yearly">Half Yearly</option>
                    <option value="Triannually">Triannually</option>{" "}
                    {/* Add the new option */}
                  </select>
                </div>

                <div className="flex flex-row justify-center items-center ">
                  <Button onClick={addFeeDetails}>Update Status</Button>
                </div>

                {/* <div className="flex flex-row justify-center items-center ">
                  <Button onClick={generateLeasureOpen}>Generate Ledger</Button>
                </div> */}
                <Modal
                  open={leasureModalOpen}
                  onClose={handleCloseLeasureModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                      width: "50vw",
                      borderRadius: 2,
                    }}
                  >
                    <div className="flex justify-center items-center flex-col">
                      <div className="text-xl mb-4">Generate Ledger</div>

                      <div className="flex">
                        <div className="flex mr-2">
                          <InputLabel htmlFor="year">Year:</InputLabel>
                          <div className="flex flex-col mb-4">
                            <select
                              id="year"
                              value={selectedYear}
                              onChange={handleYearChange}
                            >
                              <option value="" disabled hidden>
                                Select Year
                              </option>
                              {feeMonthAndYearForLedger.map((entry) => (
                                <option key={entry.year} value={entry.year}>
                                  {entry.year}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {selectedYear && (
                          <div className="flex flex-col pl-2 mb-4">
                            <div className="flex">
                              <InputLabel htmlFor="month">Month:</InputLabel>
                              <select
                                id="month"
                                value={selectedMonth}
                                onChange={(event) =>
                                  setSelectedMonth(event.target.value)
                                }
                              >
                                <option value="" disabled hidden>
                                  Select Month
                                </option>
                                {monthsForLedger.map((month) => (
                                  <option key={month} value={month}>
                                    {month}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="flex items-center justify-center h-[70vh] w-full">
                        <div className="w-full h-full flex flex-col overflow-y-auto items-center">
                          {selectedMonth !== "" ? (
                            // If a month is selected, display monthly data
                            <table
                              id="LedgerTable"
                              className="min-w-full divide-y divide-gray-200"
                            >
                              {/* Table header */}
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    colSpan="4"
                                    className="px-6 py-3 text-sm font-medium bg-gray-200 text-black uppercase tracking-wider"
                                  >
                                    Fee Ledger for {selectedYear}{" "}
                                    {selectedMonth}
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    S.no
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Date
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Amount
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Cumulative Amount
                                  </th>
                                </tr>
                              </thead>
                              {/* Table body */}
                              <tbody className="divide-y divide-gray-200">
                                {/* Iterate through monthly data to display rows */}
                                {/* Display daily total rows */}
                                {dailyTotalRows}
                                {/* Display monthly total row */}
                                {monthlyTotalRow}
                              </tbody>
                            </table>
                          ) : (
                            // If no month is selected, display yearly data
                            <table
                              id="LedgerTable"
                              className="min-w-full divide-y divide-gray-200"
                            >
                              {/* Table header */}
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    colSpan="4"
                                    className="px-6 py-3 text-sm font-medium bg-gray-200 text-black uppercase tracking-wider"
                                  >
                                    Fee Ledger for {selectedYear}
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    S.no
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Month
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Total Amount
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Cumulative Amount
                                  </th>
                                </tr>
                              </thead>
                              {/* Table body */}
                              <tbody className="divide-y divide-gray-200">
                                {/* Iterate through yearly data to display rows */}
                                {Object.keys(yearlyFeeDataForLedger).map(
                                  (year, index, array) => {
                                    let yearlyTotalFee = 0;
                                    let yearlyCumulativeFee = 0;
                                    return Object.keys(
                                      yearlyFeeDataForLedger[year]
                                    )
                                      .map((month, monthIndex) => {
                                        yearlyTotalFee += Number(
                                          yearlyFeeDataForLedger[year][month]
                                            .total_fee
                                        );
                                        yearlyCumulativeFee += Number(
                                          yearlyFeeDataForLedger[year][month]
                                            .cumulative_fee
                                        );
                                        return (
                                          <tr key={`${year}-${month}`}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                              {monthIndex + 1}{" "}
                                              {/* Serial Number */}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                              {month}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                              {
                                                yearlyFeeDataForLedger[year][
                                                  month
                                                ].total_fee
                                              }
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                              {
                                                yearlyFeeDataForLedger[year][
                                                  month
                                                ].cumulative_fee
                                              }
                                            </td>
                                          </tr>
                                        );
                                      })
                                      .concat(
                                        // Add the row for yearly total after iterating through all months
                                        index === array.length - 1 ? (
                                          <tr key={`yearly-total-${year}`}>
                                            <td
                                              colspan="2"
                                              className="px-6 py-4 text-center   whitespace-nowrap text-sm font-medium text-gray-900"
                                            >
                                              Yearly Total
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                              {yearlyTotalFee}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                              {
                                                yearlyFeeDataForLedger[year][
                                                  Object.keys(
                                                    yearlyFeeDataForLedger[year]
                                                  )[
                                                    Object.keys(
                                                      yearlyFeeDataForLedger[
                                                        year
                                                      ]
                                                    ).length - 1
                                                  ]
                                                ].cumulative_fee
                                              }
                                            </td>
                                          </tr>
                                        ) : null
                                      );
                                  }
                                )}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>

                      <div className="flex flex-row my-2 justify-center border-2">
                        <button
                          className="border border-transparent rounded-md p-2 mr-2  px-4 text-md font-medium bg-blue-500 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                          onClick={handleGenerateLeasure}
                        >
                          Generate
                        </button>
                        <button
                          className="border border-transparent rounded-md p-2 ml-2  px-4 text-md font-medium bg-green-600 text-white focus:outline-none focus:ring-2 focus:ring-green-500"
                          onClick={generatePDF}
                        >
                          Print PDF
                        </button>
                      </div>
                    </div>
                  </Box>
                </Modal>

                <div className="flex flex-row justify-center items-center ">
                  <Button onClick={notifyModalOpem}>Notify Payment</Button>
                </div>
                <div className="flex flex-row justify-center items-center ">
                  <Button color="primary" onClick={() => generateFeeReceipt()}>
                    Generate Receipt
                  </Button>
                </div>
                <Modal
                  open={notifyOpen}
                  onClose={handleNotifyClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div className="flex justify-center items-center">
                    <div className="h-screen w-screen flex flex-col items-center justify-center">
                      <div className="border w-[20vw] h-max bg-white rounded-xl p-6">
                        <div className="text-xl mb-4">
                          Fee Notification Message
                        </div>
                        <div>
                          <div className="flex flex-col w-full">
                            <label htmlFor="notificationMessage">
                              Notification Message
                            </label>
                            <textarea
                              style={{
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #ccc",
                              }}
                              type="text"
                              rows={5}
                              name="notificationMessage"
                              placeholder="Write a message to notify students"
                              value={notifyMessage}
                              onChange={handleNotifyMessageChange}
                            />
                          </div>

                          {/* Add more fee details input fields as needed */}

                          <div style={{ marginTop: "16px" }}>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              style={{ width: "100%" }}
                              onClick={handelSendPaymentNotification}
                            >
                              Send Notification
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>

                <Modal
                  open={feeAddOpen}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                      width: "full",
                      borderRadius: 2,
                    }}
                  >
                    <div className="flex flex-row justify-center text-xl">
                      <label style={{ marginBottom: "10px", display: "block" }}>
                        Enter Fee Details
                      </label>
                    </div>
                    <FormControl
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {/* Display the data in the modal */}
                      <div className="flex flex-col">
                        <label htmlFor="feeTitle">Fee Title</label>
                        <input
                          style={{
                            width: "100%",
                            padding: "8px",
                            borderRadius: "4px",
                            border: "0.5px solid #ccc",
                          }}
                          type="text"
                          name="feeTitle"
                          value={feeTitle}
                          readOnly
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="receiptNo">Receipt No</label>
                        <input
                          style={{
                            width: "100%",
                            padding: "8px",
                            borderRadius: "4px",
                            border: "0.5px solid #ccc",
                          }}
                          type="text"
                          name="feeAmount"
                          value={receiptNo}
                          onChange={handleReceiptNoChange}
                        />
                      </div>

                      <div className="flex flex-col">
                        <label htmlFor="miscellaneousCharges">
                          Transaction Id
                        </label>
                        <input
                          style={{
                            width: "100%",
                            padding: "8px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                          }}
                          type="text"
                          name="transportationCharges"
                          value={transantionId}
                          onChange={handleTransactionIdChange}
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="totalFee">Total fee</label>
                        <input
                          disabled
                          style={{
                            width: "100%",
                            padding: "8px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                          }}
                          type="text"
                          name="totalFee"
                          value={totalFee}
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="totalpaid">Total Paid</label>
                        <input
                          style={{
                            width: "100%",
                            padding: "8px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                          }}
                          type="text"
                          name="totalpaid"
                          value={totalPaid}
                          onChange={handleTotalPaidChange}
                        />
                      </div>

                      {/* Add more fee details input fields as needed */}

                      <div style={{ marginTop: "16px" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{ width: "100%" }}
                          onClick={openConfirmationModal} // Open the confirmation modal
                        >
                          Update Fee Details
                        </Button>

                        <Modal
                          open={confirmationOpen}
                          onClose={closeConfirmationModal}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              bgcolor: "background.paper",
                              boxShadow: 24,
                              p: 4,
                              width: "full",
                              borderRadius: 2,
                            }}
                          >
                            <div>
                              Are you sure you want to update fee details for{" "}
                              {selectedRowsData.length > 0 && (
                                <span>
                                  <span style={{ fontWeight: "bold" }}>
                                    {selectedRowsData[0].name}
                                  </span>{" "}
                                  in class{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {selectedRowsData[0].class_id}
                                  </span>{" "}
                                  for{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {selectedRowsData[0].title}
                                  </span>
                                </span>
                              )}
                              ?
                            </div>

                            <div
                              className="w-full flex justify-center"
                              style={{ marginTop: "16px" }}
                            >
                              <button
                                className="border border-transparent rounded-md p-2 px-4 text-sm font-medium bg-blue-500 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                onClick={handleUpdateFeeDetails}
                              >
                                Yes
                              </button>
                              <button
                                className="border border-transparent rounded-md p-2 px-4 ml-4 text-sm font-medium bg-red-500 text-white focus:outline-none focus:ring-2 focus:ring-red-500"
                                onClick={closeConfirmationModal}
                                style={{ marginLeft: "8px" }}
                              >
                                No
                              </button>
                            </div>
                          </Box>
                        </Modal>
                      </div>
                    </FormControl>

                    <div className="flex flex-row justify-between"></div>
                  </Box>
                </Modal>
              </form>
            </div>
            <div className="w-full h-full overflow-x-auto flex flex-col items-center">
              <DataGrid
                className="w-full"
                rows={filteredData}
                columns={columns}
                pageSize={5}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                {...filteredData}
              />
            </div>
          </div>
        </Box>
      </Toolbar>
    </div>
  );
}

export default FeeManage;
