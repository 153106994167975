import React from "react";

const ModifiedMarksheetTable = ({ existingMarksheetData }) => {
  // Extract subjects and exams from the existingMarksheetData
  const subjects = Object.keys(
    JSON.parse(existingMarksheetData[0].SubExamSubjects)
  );
  const exams = existingMarksheetData.map(
    (exam) => `${exam.ExamName} (${exam.ExamWeightage})`
  );

  return (
    <table className="border-collapse border w-full">
      <thead>
        <tr>
          <th className="border px-4 py-2">Subject</th>
          {exams.map((exam, index) => (
            <th key={index} className="border px-4 py-2">
              {exam}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {subjects.map((subject, subjectIndex) => (
          <tr key={subjectIndex}>
            <td className="border px-4 py-2">{subject}</td>
            {exams.map((exam, examIndex) => {
              // Parse the SubExamSubjects JSON for each exam
              const subExamSubjects = JSON.parse(
                existingMarksheetData[examIndex].SubExamSubjects
              );
              // Get the corresponding examName for the subject
              const examName = subExamSubjects[subject]
                ? subExamSubjects[subject].examName
                : "";
              return (
                <td key={examIndex} className="border px-4 py-2">
                  {examName}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ModifiedMarksheetTable;
