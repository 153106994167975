import React, { useState, useEffect, useRef } from "react";
import { Box, Toolbar } from "@mui/material";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Radio,
  Button,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import Modal from "@mui/material/Modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Cookies from "js-cookie";

import { useDownloadExcel } from "react-export-table-to-excel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ExamManager = () => {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Student Marks Data",
    sheet: "Marks Data",
  });

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(6) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };

  const [schoolId, setSchoolId] = useState("");
  const [examData, setExamData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [marksData, setMarksData] = useState("");
  // const [selectedExam, setSelectedExam] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state

  // multiple exam modal start
  const [selectedExamIds, setSelectedExamIds] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    // Clear edited values when closing the modal
  };

  const handleRadioChange = (ExamID) => {
    setSelectedExamIds((prevSelectedClasses) => {
      const updatedExams = new Set(prevSelectedClasses);

      if (updatedExams.has(ExamID)) {
        // If the classId is already selected, remove it
        updatedExams.delete(ExamID);
      } else {
        // If the classId is not selected, add it
        updatedExams.add(ExamID);
      }

      return Array.from(updatedExams);
    });
  };

  const handleDeselectAll = () => {
    // Clear the selectedClassesForHoliday array
    setSelectedExamIds([]);
  };

  const fetchMultipleMarks = async () => {
    try {
      const response = await fetch(
        `https://edsanchaar.in/api/api_admin/fetchGroupedExamMarks.php?exam_id=${selectedExamIds}&school_id=${schoolId}`,
        {
          method: "GET",
          headers: {
            authorization: "b1sgVwwszjQM0iq6viyqYNrUzX3uaLWTUnkaOrq3OX0",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      }

      const data = await response.json();

      // Map through each exam object and structure the data as desired
      const structuredData = data.map((exam) => ({
        exam_id: exam.exam_id,
        subject: exam.subject,
        exam_name: exam.exam_name,
        max_marks: exam.max_marks,
        school_id: exam.school_id,
        class_id: exam.class_id,
        students: exam.students.map((student) => ({
          student_id: student.student_id,
          id: student.id,
          marks_obtained: student.marks_obtained,
          name: student.name,
          roll_no: student.roll_no,
        })),
      }));

      setMarksData(structuredData);
      // console.log(structuredData)
    } catch (error) {
      console.error("Error fetching Marks data:", error);
    } finally {
      setLoading(false);
    }
  };

  // multiple exam modal close

  const handleClassChange = (e) => {
    const selectedClassId = e.target.value;
    setSelectedClass(selectedClassId);
    // Fetch exams based on the selected class
    fetchExam(selectedClassId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const fetchSchoolId = () => {
    // Fetch schoolId from cookies
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      // Redirect to login or handle accordingly if schoolId is not available
      // You may want to add additional handling, such as showing an error message
      console.error("School ID not available");
      return;
    }

    // Set the school_id in the state
    setSchoolId(schoolIdFromCookie);

    // Fetch class data after setting school_id
    fetchClassId(schoolIdFromCookie);
  };

  const fetchClassId = (schoolId) => {
    fetch(
      // `http://localhost/react/api_admin/getDistinctClasses.php?school_id=${schoolId}`,
      `https://edsanchaar.in/api/api_admin/getDistinctClasses.php?school_id=${schoolId}`,
      {
        method: "GET",
        headers: {
          authorization: "aV0xQRFizDkSwyzksn2lc0xi4Rytc8y4fT1ftwiL8ek",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // Assuming the API response contains an array of classes
        // If not, adjust this line based on the actual API response structure
        const classes = Array.isArray(data) ? data : [];
        setClassData(classes);
      })
      .catch((error) => console.error("Error fetching class data:", error))
      .finally(() => setLoading(false)); // Set loading to false after fetching data
  };

  const fetchExam = (classId) => {
    setSelectedExamIds([]);

    fetch(
      // `http://localhost/react/api_admin/getExams.php?school_id=${schoolId}&class_id=${classId}`,
      `https://edsanchaar.in/api/api_admin/getExams.php?school_id=${schoolId}&class_id=${classId}`,
      {
        method: "GET",
        headers: {
          authorization: "b1sgVwwszjQM0iq6viyqYNrUzX3uaLWTUnkaOrq3OX0",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => setExamData(data)) // Update exam data, not class data
      .catch((error) => console.error("Error fetching exam data:", error))
      .finally(() => setLoading(false)); // Set loading to false after fetching data
  };

  // const fetchMarks = (examId) => {
  //   fetch(
  //     // `http://localhost/react/api_admin/fetchExamMarks.php?exam_id=${examId}`,
  //     `https://edsanchaar.in/api/api_admin/fetchExamMarks.php?exam_id=${examId}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         authorization: "b1sgVwwszjQM0iq6viyqYNrUzX3uaLWTUnkaOrq3OX0",
  //       },
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // Sort the data by student name before setting it in the state
  //       const sortedData = data.sort((a, b) =>
  //         a.student_id.localeCompare(b.student_id)
  //       );
  //       setMarksData(sortedData);
  //     })
  //     .catch((error) => console.error("Error fetching Marks data:", error))
  //     .finally(() => setLoading(false)); // Set loading to false after fetching data
  // };

  //name truncation
  const truncateName = (name) => {
    if (!name) {
      return ""; // or handle it in a way that makes sense for your application
    }

    const words = name.split(" ");

    if (words.length > 1) {
      return `${words[0].charAt(0)}. ${words[words.length - 1]}`;
    } else {
      return name;
    }
  };

  const handleDownload = () => {
    // Create a new jsPDF instance
    const pdf = new jsPDF({
      orientation: "landscape", // Set the orientation to landscape
    });

    // Set the title for the PDF document
    pdf.text("Marks Report", 80, 15);

    // Get the columns and rows for the table
    const columns = [
      "Name",
      "Roll No",
      ...marksData.map(({ exam_name }) => exam_name),
    ];
    const rows = marksData[0]?.students.map(
      ({ name, roll_no, marks_obtained }) => [
        truncateName(name),
        roll_no,
        ...marksData.map(
          ({ students }) =>
            students.find((student) => student.name === name)?.marks_obtained ||
            "N/A"
        ),
      ]
    );

    // Add the table to the PDF document using autotable
    pdf.autoTable({
      head: [columns],
      body: rows,
      startY: 10, // Set the Y position for the table
      startX: 2,
      // tableWidth: 280, // Set the table width to auto
      tableWidth: "auto", // Set the table width to auto

      styles: { fontSize: 6 }, // Set the font size for the entire table
      columnStyles: { 0: { cellWidth: 20 }, 1: { cellWidth: 20 } }, // Adjust the column widths as needed
    });

    // Save the PDF as a blob
    const blob = pdf.output("blob");

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element and trigger a click event
    const a = document.createElement("a");
    a.href = url;
    a.download = "marks_report.pdf";
    document.body.appendChild(a);
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  useEffect(() => {
    fetchSchoolId();
  }, []);

  // useEffect(() => {
  //   if (selectedExam) {
  //     fetchMarks(selectedExam);
  //     fetchMultipleMarks();
  //   }
  // }, [selectedExam]);

  return (
    <div className="flex items-center justify-center w-full h-screen bg-white">
      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="flex flex-col justify-center items-center ">
            <div className="text-3xl font-bold">Student Marks</div>
            <form onSubmit={handleSubmit} className="flex flex-row my-6 ">
              <div className="flex flex-row justify-center items-center mr-2">
                <InputLabel>Select Class</InputLabel>
                <FormControl>
                  <Select
                    value={selectedClass}
                    onChange={handleClassChange}
                    style={{
                      width: "fit",
                      height: "50px",
                      marginTop: 4,
                      marginInline: 10,
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select a class
                    </MenuItem>
                    {Array.isArray(classData) &&
                      classData.map((classItem) => (
                        <MenuItem
                          key={classItem.class_id}
                          value={classItem.class_id}
                        >
                          {classItem.class_id}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div className="flex ml-2">
                <Button
                  sx={{
                    width: "100%",
                  }}
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                >
                  Create Marksheet
                </Button>{" "}
              </div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    ...style,
                    padding: "20px",
                    margin: "auto",
                    overflowY: "auto",
                  }}
                  className="rounded-md"
                >
                  {/* Map over unique subjects */}
                  {Array.from(
                    new Set(examData.map((exam) => exam.subject))
                  ).map((subject) => (
                    <FormControl
                      key={subject}
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <label className=" font-semibold">
                        {subject.charAt(0).toUpperCase() + subject.slice(1)}
                      </label>
                      <div className="flex flex-column flex-wrap">
                        {examData
                          .filter((exam) => exam.subject === subject)
                          .map((exams) => (
                            <FormControlLabel
                              key={exams.ExamID}
                              control={
                                <Radio
                                  checked={selectedExamIds.includes(
                                    exams.ExamID
                                  )}
                                  onChange={() =>
                                    handleRadioChange(exams.ExamID)
                                  }
                                  value={exams.ExamID}
                                  color="primary"
                                />
                              }
                              label={exams.ExamName}
                              style={{ marginBottom: "5px" }}
                            />
                          ))}
                      </div>
                    </FormControl>
                  ))}

                  <div className="flex flex-row justify-between">
                    <Button
                      onClick={fetchMultipleMarks}
                      // disabled={AddingHoliday}
                      variant="contained"
                      color="primary"
                    >
                      Generate Marksheet
                    </Button>
                    <Button
                      onClick={handleDeselectAll}
                      variant="contained"
                      size="small"
                      color="error"
                    >
                      Deselect All
                    </Button>
                  </div>
                </Box>
              </Modal>
            </form>
          </div>

          <Box className="w-full h-full overflow-x-auto my-2">
            <Toolbar
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Box className="w-full h-full overflow-x-auto">
                <Toolbar
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <div className="w-full h-full overflow-x-auto">
                    <div className="text-center">
                      {loading ? (
                        <CircularProgress size={60} /> // Display circular loading while data is being fetched
                      ) : marksData ? (
                        <table
                          className="w-full table-auto border-collapse border"
                          ref={tableRef}
                        >
                          <thead>
                            <tr className="bg-blue-200">
                              <th className="border p-2">Name</th>
                              <th className="border p-2">Roll No</th>
                              {marksData.map(
                                ({ exam_id, exam_name, max_marks }) => (
                                  <th key={exam_id} className="border p-2">
                                    {`${exam_name} ${max_marks}`}
                                  </th>
                                )
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {marksData[0]?.students.map(
                              ({ name, roll_no, marks_obtained }, index) => (
                                <tr
                                  key={index}
                                  className={
                                    index % 2 === 0 ? "bg-blue-100" : "bg-white"
                                  }
                                >
                                  <td className="border p-2">
                                    {truncateName(name)}
                                  </td>
                                  <td className="border p-2">{roll_no}</td>
                                  {marksData.map(({ students }) => (
                                    <td
                                      key={students[0].exam_id}
                                      className="border p-2"
                                    >
                                      {students[index].marks_obtained}
                                    </td>
                                  ))}
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <div className="w-full flex justify-center items-center h-[40vh]">
                          <p>Please Select Class And Exams to get marks</p>
                        </div>
                      )}
                    </div>
                  </div>
                </Toolbar>
              </Box>
            </Toolbar>
          </Box>
          <div>
            <Button
              sx={{ marginTop: 4, marginRight:2 }}
              variant="contained"
              color="primary"
              onClick={handleDownload}
            >
              Download Marks PDF
            </Button>
            <Button
              sx={{ marginTop: 4,  marginLeft:2 }}
              variant="contained"
              color="primary"
              onClick={onDownload}
            >
              Export excel
            </Button>
          </div>
        </Box>
      </Toolbar>
    </div>
  );
};

export default ExamManager;
