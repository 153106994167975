import React from "react";
import logowithtext from "../Images/logo_with_text-3.png";
import { Link, useNavigate } from "react-router-dom";

const PrivacyAndPolicy = () => {
  const navigate = useNavigate();

  const handleLoginForSchool = () => {
    navigate("/login");
  };

  const handleHomepage = () => {
    navigate("/");
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <header className="bg-white py-4 lg:px-4 px-6">
        <div className="container mx-auto flex items-center justify-between">
          <div className="cursor-pointer" onClick={handleHomepage}>
            <img
              src={logowithtext}
              width="180"
              alt="logo"
              className="block"
            />
          </div>
          <div className="hidden lg:flex items-center">
            <div
              onClick={handleLoginForSchool}
              className="rounded-md bg-blue-400 py-2 px-4 text-base font-medium text-white hover:bg-opacity-90"
            >
              Sign In
            </div>
          </div>
        </div>
      </header>
      <div className="container mx-auto mt-8 p-6 bg-white shadow-md rounded-md">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy for Ed-sanchaar</h1>
        <p className="mb-4">Effective Date: 17 December, 2023</p>

        <h2 className="text-xl font-semibold mb-4">Introduction:</h2>
        <p className="mb-4">
          Venuratech Solutions ("we," "our," or "us") is committed to protecting
          the privacy of users of our mobile application, Ed-sanchaar. This
          Privacy Policy outlines our practices regarding the collection, use,
          and disclosure of personal information related to the use of
          Ed-sanchaar. By accessing or using the Ed-sanchaar application, you
          consent to the terms of this Privacy Policy.
        </p>

        <h2 className="text-xl font-semibold mb-4">
          1. Information Collected:
        </h2>
        <p className="mb-4">
          a. Personal Information:
          <ul className="list-disc pl-6">
            <li>
              Student Information: Names, email IDs, phone numbers, exam marks,
              attendance records.
            </li>
            <li>
              Teacher Information: Names, email IDs, phone numbers,
              communication records with students.
            </li>
            <li>
              School Information: Syllabus, subjects taught, and other relevant
              academic data.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          b. Miscellaneous Information:
          <ul className="list-disc pl-6">
            <li>
              User-generated content, messages, and communications within the
              application.
            </li>
            <li>
              Device information, including IP address, operating system, and
              device type.
            </li>
            <li>
              Analytics data for improving the application's functionality.
            </li>
          </ul>
        </p>

        <h2 className="text-xl font-semibold mb-4">2. Data Usage:</h2>
        <p className="mb-4">
          We collect and use personal information for the following purposes:
          <ul className="list-disc pl-6">
            <li>
              Facilitating communication between teachers, students, and
              parents.
            </li>
            <li>
              Managing academic information, including exam marks and attendance
              records.
            </li>
            <li>
              Enhancing user experience and improving application features.
            </li>
            <li>Analyzing data to understand user preferences and trends.</li>
          </ul>
        </p>

        <h2 className="text-xl font-semibold mb-4">3. Data Security:</h2>
        <p className="mb-4">
          We employ industry-standard security measures to protect personal
          information from unauthorized access, disclosure, alteration, and
          destruction. This includes encryption, access controls, and regular
          security audits.
        </p>

        <h2 className="text-xl font-semibold mb-4">4. Third-Party Services:</h2>
        <p className="mb-4">
          Ed-sanchaar may integrate with third-party services for improved
          functionality. Users are encouraged to review the privacy policies of
          these third-party services, as they may have different practices.
        </p>

        {/* Continue adding other sections as needed... */}

        <h2 className="text-xl font-semibold mb-4">9. Contact Information:</h2>
        <p className="mb-6">
          For any questions, concerns, or requests related to this Privacy
          Policy, please contact us at{" "}
          <a href="mailto:contactus@edsanchaar.in" className="text-blue-500">
            contactus@edsanchaar.in
          </a>
        </p>

        <p className="mb-4">
          By using Ed-sanchaar, you acknowledge that you have read and
          understood this Privacy Policy and agree to the collection and use of
          your information as described herein.
        </p>
      </div>
    </div>
  );
};

export default PrivacyAndPolicy;
