import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Box,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import toast, { Toaster } from "react-hot-toast";

function EditTeacher1() {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    console.log("Decrypted Role ID:", decryptedRoleId);
    console.log("LoadingRoleId:", loadingRoleId);

    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(1) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const [school_id, setSchoolId] = useState();
  const [class_id, setClassId] = useState();
  const [teacherData, setTeacherData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    username: "",
  });

  const fetchTeacherData = (classId) => {
    fetch(
      // `http://localhost:80/react/api_admin/getAllTeachers.php?school_id=${schoolId}`,
      `https://edsanchaar.in/api/api_admin/TeacherManager/getAllTeachers.php?school_id=${school_id}`,
      {
        method: "GET",
        headers: {
          Authorization: "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTeacherData(data);
        setLoading(false);
        setError(null);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  const deleteTeacher = (username) => {
    fetch(
      // `http://localhost:80/react/api_admin/deleteTeacher.php?username=${username}`,
      `https://edsanchaar.in/api/api_admin/TeacherManager/deleteTeacher.php?username=${username}&school_id=${school_id}`,
      {
        method: "POST",
        headers: {
          authorization: "ZVE2VQFin3of3TLkvn76Ibm3ntAkOz4SaqD3Da/2IUW3",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        toast.success("Teacher UnEnrolled Successfully ");

        const schoolIdFromCookie = Cookies.get("schoolId");
        fetchTeacherData(schoolIdFromCookie);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        closeDeleteConfirmation();
      });
  };

  const openDeleteConfirmation = (username) => {
    const teacherToDelete = teacherData.find(
      (teacher) => teacher.username === username
    );

    if (teacherToDelete) {
      setDeleteConfirmation({
        isOpen: true,
        username,
        name: teacherToDelete.name,
      });
    }
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmation({
      isOpen: false,
      username: "",
    });
  };

  // Use useEffect to fetch teacher data on component mount
  useEffect(() => {
    // Fetch schoolId from cookies
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      // Redirect to login or handle accordingly if schoolId is not available
      // You may want to add additional handling, such as showing an error message
      console.error("School ID not available");
      return;
    }

    // Set the school_id in the state
    setSchoolId(schoolIdFromCookie);

    // Fetch teacher data with dynamic schoolId
    fetchTeacherData(class_id);
  }, [class_id, school_id]);

  return (
    <div className="flex items-center justify-center w-full h-screen bg-white">
      <Toaster position="top-center" reverseOrder={true} />
      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="flex flex-col justify-center items-center">
            <div className="text-3xl font-bold py-2">Teacher Data</div>
          </div>
          <div className="w-full h-full ">
            {teacherData.length > 0 && (
              <div className="w-full h-full overflow-x-auto">
                <table className="w-full table-auto border-collapse border">
                  <thead>
                    <tr className="bg-blue-200">
                      <th className="border p-2">Username</th>
                      <th className="border p-2">Name</th>
                      <th className="border p-2">Roll No</th>
                      <th className="border p-2">Subject</th>
                      <th className="border p-2">Qualification</th>
                      <th className="border p-2">Contact</th>
                      <th className="border p-2">class Teacher</th>
                      <th className="border p-2">Teaching Classes</th>
                      <th className="border p-2">Actions</th>
                    </tr>
                  </thead>
                  {loading ? (
                    <CircularProgress size={60} />
                  ) : (
                    <tbody>
                      {teacherData.map((teacher, key) => (
                        <tr
                          key={key}
                          className={key % 2 === 0 ? "bg-blue-100" : "bg-white"}
                        >
                          <td className="border p-2">{teacher.username}</td>
                          <td className="border p-2">{teacher.name}</td>
                          <td className="border p-2">{teacher.roll_no}</td>
                          <td className="border p-2">{teacher.subject}</td>
                          <td className="border p-2">
                            {teacher.qualification}
                          </td>
                          <td className="border p-2">{teacher.contact}</td>
                          <td className="border p-2">
                            {teacher.class_teacher !== 0
                              ? teacher.class_teacher
                              : "Not Applicable"}
                          </td>
                          <td className="border p-2">{teacher.class_id}</td>
                          <td className="border p-2">
                            <Link
                              to={`/${teacher.username}/Teacheredit`}
                              className="text-blue-500 hover:underline mr-2"
                            >
                              Edit
                            </Link>
                            <button
                              onClick={() =>
                                openDeleteConfirmation(teacher.username)
                              }
                              className="text-red-500 hover:underline"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            )}
          </div>
        </Box>
      </Toolbar>
      {/* Delete Confirmation Modal */}
      <Dialog
        open={deleteConfirmation.isOpen}
        onClose={closeDeleteConfirmation}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <p>
            Do you want to remove {deleteConfirmation.name} (
            {deleteConfirmation.username}) from school records?
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirmation}>Cancel</Button>
          <Button
            onClick={() => deleteTeacher(deleteConfirmation.username)}
            variant="contained"
            color="error"
          >
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditTeacher1;
