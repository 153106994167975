import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "remixicon/fonts/remixicon.css";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";

import {
  Toolbar,
  Box,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function FetchStudent() {
  const navigate = useNavigate();

  // role and role id
  const [decryptedRoleId, setDecryptedRoleId] = useState("");
  const [decryptedRole, setDecryptedRole] = useState("");
  const [loadingRoleId, setLoadingRoleId] = useState(true);

  useEffect(() => {
    const decryptDataAndLog = async () => {
      const encryptedRoleId = Cookies.get("roleId");
      const encryptedRole = Cookies.get("role");
      const roleIdIv = Cookies.get("roleIdIv");
      const roleIv = Cookies.get("roleIv");

      if (encryptedRoleId && encryptedRole && roleIdIv && roleIv) {
        const secretKey = "erNOnu89234uihwehnA08k3ihFjhnR6vdhI";

        try {
          const roleIdIvBuffer = hexStringToBuffer(roleIdIv);
          const roleIvBuffer = hexStringToBuffer(roleIv);

          const formData = new FormData();
          formData.append("encryptedRoleId", encryptedRoleId);
          formData.append("encryptedRole", encryptedRole);
          formData.append("roleIdIv", roleIdIv);
          formData.append("roleIv", roleIv);
          formData.append("secretKey", secretKey);

          const response = await fetch(
            "https://edsanchaar.in/api/api_admin/RoleApis/decryptRoleData.php",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Decryption API request failed");
          }

          const data = await response.json();

          const roleIdFromApi = data.decryptedRoleId;
          const roleFromApi = data.decryptedRole;
          setDecryptedRole(roleFromApi);
          setDecryptedRoleId(roleIdFromApi);
        } catch (error) {
          console.error("Error decrypting:", error);
        } finally {
          setLoadingRoleId(false); // Set loadingRoleId to false regardless of success or failure
        }
      }
    };

    decryptDataAndLog();
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // Check the condition and navigate only if not loadingRoleId and role is not "1"
    if (!loadingRoleId && decryptedRoleId.charAt(2) !== "1") {
      console.log("Navigating to /dashboard");
      navigate("/dashboard");
    }
  }, [decryptedRoleId, navigate, loadingRoleId]);
  const hexStringToBuffer = (hexString) => {
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  };
  const [userData, setUserData] = useState([]);
  const [classFilter, setClassFilter] = useState("");
  const getCookie = Cookies.get("encryptedApiKey");
  const [loading, setLoading] = useState(true);
  const [selectedClass, setSelectedClass] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    username: "",
    name: "",
    classId: "",
    rollNo: "",
    deleteFeeData: false,
    deleteAttendanceData: false,
    deleteMarksData: false,
    deleteCommunicationData: false,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    // Update state based on the checkbox being checked or unchecked
    setDeleteConfirmation((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleClassChange = (e) => {
    setClassFilter(e.target.value);
    setSelectedClass(e.target.value);
  };

  const renderClassOptions = () => {
    const distinctClasses = [...new Set(userData.map((user) => user.class_id))];
    return distinctClasses.map((classId) => (
      <MenuItem key={classId} value={classId}>
        {classId}
      </MenuItem>
    ));
  };

  function getUsers(schoolId) {
    fetch(
      `https://edsanchaar.in/api/api_admin/getSchoolStudents.php?school_id=${schoolId}`,
      {
        method: "GET",
        headers: {
          Authorization: "aVExUBBin2oPzz3l4SG0YUR/jkFqakYnjoHr47SxDhvV",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    const school_id = Cookies.get("schoolId");

    if (!school_id) {
      return;
    }

    getUsers(school_id);
  }, []);

  const openDeleteConfirmation = (username, name, classId, rollNo) => {
    setDeleteConfirmation({
      isOpen: true,
      username,
      name,
      classId,
      rollNo,
    });
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmation({
      isOpen: false,
      username: "",
      name: "",
      classId: "",
      rollNo: "",
    });
  };

  const [schoolId, setSchoolId] = useState("");
  useEffect(() => {
    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      // Redirect to login or handle accordingly if schoolId is not available
      // You may want to add additional handling, such as showing an error message
      console.error("School ID not available");
      return;
    }

    // Set the school_id in the state
    setSchoolId(schoolIdFromCookie);
  }, []);

  const deleteUser = (username) => {
    const {
      deleteFeeData,
      deleteAttendanceData,
      deleteMarksData,
      deleteCommunicationData,
    } = deleteConfirmation;

    const requestBody = new FormData();
    requestBody.append("username", username);
    requestBody.append("deleteFeeData", deleteFeeData);
    requestBody.append("deleteAttendanceData", deleteAttendanceData);
    requestBody.append("deleteMarksData", deleteMarksData);
    requestBody.append("deleteCommunicationData", deleteCommunicationData);
    requestBody.append("school_id", schoolId);

    fetch(`https://edsanchaar.in/api/api_admin/deleteStudent.php`, {
      method: "POST",
      headers: {
        authorization: "ZVE2VQFin3of3TLkvn76Ibm3ntAkOz4SaqD3Da/2IUW3",
      },
      body: requestBody,
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success("Student UnEnrolled Successfully");
        getUsers(schoolId);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        closeDeleteConfirmation();
      });
  };

  const filteredData =
    classFilter === ""
      ? userData
      : userData.filter((user) => user.class_id.includes(classFilter));

  const sortedData = filteredData.sort((a, b) =>
    a.class_id.localeCompare(b.class_id)
  );

  return (
    <div className="flex items-center justify-center w-full h-screen bg-white">
      <Toaster position="top-center" reverseOrder={true} />
      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            width: "95.5%",
            height: "100%",
          }}
        >
          <div className="flex flex-col justify-center items-center">
            <div className="text-3xl font-bold">School Students</div>

            <div className="flex my-2 flex-row justify-center items-center">
              <div className="mx-4 text-md font-semibold text-lg">
                Select Class
              </div>
              <Select onChange={handleClassChange} value={selectedClass}>
                {renderClassOptions()}
              </Select>
            </div>
          </div>
          {classFilter === "" ? (
            <div className="w-full h-full flex items-center justify-center font-semibold text-xl">
              Please select a class to view students.
            </div>
          ) : (
            <div className="w-full h-full overflow-x-auto">
              <table className="w-full border-collapse border">
                <thead>
                  <tr className="bg-blue-200">
                    <th className="border p-2">
                      <i className="ri-user-line pr-2"></i>Name
                    </th>
                    <th className="border p-2">
                      <i className="ri-school-fill pr-2"></i>Class
                    </th>
                    <th className="border p-2">
                      <i className="ri-list-ordered pr-2"></i>Roll No
                    </th>
                    <th className="border p-2">
                      <i className="ri-user-4-line pr-2"></i>Father's Name
                    </th>
                    <th className="border p-2">
                      <i className="ri-user-6-line pr-2"></i>Mother's Name
                    </th>
                    <th className="border p-2">
                      <i className="ri-mail-line pr-2"></i>Email
                    </th>
                    <th className="border p-2">
                      <i className="ri-phone-fill pr-2"></i>Phone 1
                    </th>
                    <th className="border p-2">
                      <i className="ri-phone-fill pr-2"></i>Phone 2
                    </th>
                    <th className="border p-2">
                      <i></i>Actions
                    </th>
                  </tr>
                </thead>
                {loading ? (
                  <div className="flex w-full items-center justify-center">
                    <CircularProgress size={60} />
                  </div>
                ) : (
                  <tbody>
                    {Array.isArray(sortedData) &&
                      sortedData.map((user, key) => (
                        <tr
                          key={key}
                          className={key % 2 === 0 ? "bg-blue-100" : "bg-white"}
                        >
                          <td className="border p-2">{user.name}</td>
                          <td className="border p-2">{user.class_id}</td>
                          <td className="border p-2">{user.roll_no}</td>
                          <td className="border p-2">{user.father_name}</td>
                          <td className="border p-2">{user.mother_name}</td>
                          <td className="border p-2">{user.email}</td>
                          <td className="border p-2">{user.mobile}</td>
                          <td className="border p-2">{user.phone}</td>
                          <td className="border p-2">
                            <Link
                              to={`/edit/${user.username}`}
                              className="text-blue-500 hover:underline mr-2"
                            >
                              Edit
                            </Link>
                            <button
                              onClick={() =>
                                openDeleteConfirmation(
                                  user.username,
                                  user.name,
                                  user.class_id,
                                  user.roll_no
                                )
                              }
                              className="text-red-500 hover:underline"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
            </div>
          )}
        </Box>
      </Toolbar>
      {/* Delete Confirmation Modal */}
      <Dialog
        open={deleteConfirmation.isOpen}
        onClose={closeDeleteConfirmation}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <p>
            Do you want to remove <strong>{deleteConfirmation.name}</strong>{" "}
            from class <strong>{deleteConfirmation.classId}</strong> with roll
            number <strong>{deleteConfirmation.rollNo}</strong>?
          </p>
          <p>
            <input
              type="checkbox"
              id="deleteFeeData"
              name="deleteFeeData"
              checked={deleteConfirmation.deleteFeeData}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="deleteFeeData"> Delete Fee Data</label>
          </p>
          <p>
            <input
              type="checkbox"
              id="deleteAttendanceData"
              name="deleteAttendanceData"
              checked={deleteConfirmation.deleteAttendanceData}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="deleteAttendanceData">
              {" "}
              Delete Attendance Data
            </label>
          </p>
          <p>
            <input
              type="checkbox"
              id="deleteMarksData"
              name="deleteMarksData"
              checked={deleteConfirmation.deleteMarksData}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="deleteMarksData"> Delete Marks Data</label>
          </p>
          <p>
            <input
              type="checkbox"
              id="deleteCommunicationData"
              name="deleteCommunicationData"
              checked={deleteConfirmation.deleteCommunicationData}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="deleteCommunicationData">
              {" "}
              Delete Communication Data
            </label>
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirmation}>Cancel</Button>
          <Button
            onClick={() => deleteUser(deleteConfirmation.username)}
            variant="contained"
            color="error"
          >
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
