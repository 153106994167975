import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import ExistingMarksheetTable from "./ExistingMarksheetTable";

const ReportCardGeneration = () => {
  const [schoolId, setSchoolId] = useState();
  const [classIds, setClassIds] = useState([]);
  const [classId, setClassId] = useState("");
  const [result, setResult] = useState("");
  const [existingMarksheetData, setExistingMarksheetData] = useState("");
  const [examIdToNameMap, setExamIdToNameMap] = useState({});

  const [mainResultId, setResultId] = useState("");
  const [examData, setExamData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedExams, setSelectedExams] = useState({});
  const [schoolData, setSchoolData] = useState("");
  const [schoolMarksheetFormat, setSchoolMarksheetFormat] = useState({});
  const [resultExists, setResultExists] = useState(false);
  const [resultOptions, setResultOptions] = useState([]);
  const [examNameWeightages, setExamNameWeightages] = useState({});

  const handleClassIdSelect = (e) => {
    setClassId(e);
    setResult(""); // Reset the result when classId changes
    setExamNameWeightages(0);
    setExistingMarksheetData(false);
    setResultExists("");
  };
  const fetchMarksheetData = async () => {
    try {
      setLoading(true); // Set loading to true before fetching
      const response = await fetch(
        `https://edsanchaar.in/api/api_admin/MarksheetGeneration/MarksheetExistanceValidation.php?school_id=${schoolId}&result_id=${mainResultId}`,
        {
          method: "GET",
          headers: {
            authorization: "b1sgVwwszjQM0iq6viyqYNrUzX3uaLWTUnkaOrq3OX0",
          },
        }
      );

      const data = await response.json();

      if (data.exists) {
        setResultExists(true);
      } else {
        setResultExists(false);
      }
      if (data.data) {
        const existingData = data.data;
        setExistingMarksheetData(existingData);
      } else {
        setExistingMarksheetData("");
      }
    } catch (error) {
      console.error("Error fetching result data:", error);
    } finally {
      setLoading(false);
      console.log(existingMarksheetData);
    }
  };
  const deleteExistingMarksheetFormat = async () => {
    console.log("delete existing marksheet");

    try {
      const apiUrl =
        "https://edsanchaar.in/api/api_admin/MarksheetGeneration/deleteExistingMarksheetData.php";

      // Create a FormData object
      const formData = new FormData();
      formData.append("result_id", mainResultId);
      formData.append("school_id", schoolId);
      formData.append("class_id", classId);

      // Post the form data
      const response = await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        toast.success("Marksheet Data Deleted Successfully");

        fetchMarksheetData();
      } else if (response.data.error) {
        toast.error(response.data.error);
      } else {
        toast.error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Error deleting data");
    }
  };

  const handleResultSelect = (e) => {
    setResult(e);

    // Search for the selected result in schoolMarksheetFormat
    const selectedResult = schoolMarksheetFormat.find(
      (item) => item.ExamType === e
    );

    // Log the id of the selected result
    setResultId(selectedResult.id);

    setExamNameWeightages(0);
    setSelectedExams({});

    // Move the marksheetExistenceValidation logic to useEffect
  };

  // ...

  const GenerateResults = async () => {
    // Validate all weightages are filled
    const isWeightagesFilled = Object.values(examNameWeightages).every(
      (weightage) => weightage !== ""
    );

    if (!isWeightagesFilled) {
      toast.error("Please fill in all weightages");
      return;
    }

    // Validate all exams are selected
    const isExamsSelected = Object.values(selectedExams).every((exams) =>
      exams.every((examId) => examId !== "")
    );

    if (!isExamsSelected) {
      toast.error("Please select all exams for each subject");
      return;
    }
    const selectedExamsWithNames = [];
    let totalWeightage = 0;
    const transformedData = {};

    for (const subject in selectedExams) {
      const examsForSubject = selectedExams[subject];

      examsForSubject.forEach((examId, index) => {
        if (examId) {
          const examName = schoolMarksheetFormat
            .filter((item) => item.ExamType === result)
            .map((item) => item.ExamNames.split(",")[index].trim())[0];

          const weightage = examNameWeightages[examName] || 0;

          // Extract the main exam name without percentage
          const mainExamName = examName.replace(/\(\d+%?\)/, "").trim();

          // Your logic to transform data
          if (!transformedData[mainExamName]) {
            transformedData[mainExamName] = {
              weightage: parseFloat(weightage),
            };
            totalWeightage += parseFloat(weightage);
          }

          if (!transformedData[mainExamName][subject]) {
            transformedData[mainExamName][subject] = {
              examId,
              examName: examIdToNameMap[examId], // Add examName using the map
            };
          }
        }
      });
    }

    // Round to 2 decimal places
    totalWeightage = Math.round(totalWeightage * 100) / 100;

    // Validate total weightage
    if (totalWeightage !== 100) {
      toast.error("Total weightage must be 100%");
      return;
    }
    // Create a FormData object
    const formData = new FormData();

    // Append data to FormData object
    formData.append("data", JSON.stringify(transformedData));
    formData.append("resultId", mainResultId);
    formData.append("school_id", schoolId);
    formData.append("class_id", classId);

    // Post the transformed data
    try {
      const apiUrl =
        "https://edsanchaar.in/api/api_admin/MarksheetGeneration/addMarksheetFormat.php";
      const response = await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        toast.success(response.data.success);

        fetchMarksheetData();
      } else if (response.data.error) {
        toast.error(response.data.error);
      } else {
        toast.error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error("Error saving data");
    }
  };
  // ...

  useEffect(() => {
    // Check if both schoolId and mainResultId are available
    if (schoolId && mainResultId) {
      const marksheetExistenceValidation = async () => {
        try {
          setLoading(true); // Set loading to true before fetching
          const response = await fetch(
            `https://edsanchaar.in/api/api_admin/MarksheetGeneration/MarksheetExistanceValidation.php?school_id=${schoolId}&result_id=${mainResultId}`,
            {
              method: "GET",
              headers: {
                authorization: "b1sgVwwszjQM0iq6viyqYNrUzX3uaLWTUnkaOrq3OX0",
              },
            }
          );

          const data = await response.json();

          if (data.exists) {
            setResultExists(true);
          } else {
            setResultExists(false);
          }
          if (data.data) {
            const existingData = data.data;
            setExistingMarksheetData(existingData);
          } else {
            setExistingMarksheetData("");
          }
        } catch (error) {
          console.error("Error fetching result data:", error);
        } finally {
          setLoading(false);
          console.log(existingMarksheetData);
        }
      };

      marksheetExistenceValidation();
    }
  }, [schoolId, mainResultId]);

  useEffect(() => {
    // Check if both schoolId and mainResultId are available
    if (schoolId && mainResultId) {
      const marksheetExistenceValidation = async () => {
        try {
          const response = await fetch(
            `https://edsanchaar.in/api/api_admin/MarksheetGeneration/MarksheetExistanceValidation.php?school_id=${schoolId}&result_id=${mainResultId}`,
            {
              method: "GET",
              headers: {
                authorization: "b1sgVwwszjQM0iq6viyqYNrUzX3uaLWTUnkaOrq3OX0",
              },
            }
          );

          const data = await response.json();

          if (data.exists) {
            setResultExists(true);
          } else {
            setResultExists(false);
          }
          if (data.data) {
            const existingData = data.data;
            setExistingMarksheetData(existingData);
          } else {
            setExistingMarksheetData("");
          }
        } catch (error) {
          console.error("Error fetching result data:", error);
        } finally {
          setLoading(false);
          console.log(existingMarksheetData);
        }
      };

      marksheetExistenceValidation();
    }
  }, [schoolId, mainResultId]);

  useEffect(() => {
    const fetchExam = (classId, school_id) => {
      fetch(
        `https://edsanchaar.in/api/api_admin/getExams.php?school_id=${school_id}&class_id=${classId}`,
        {
          method: "GET",
          headers: {
            authorization: "b1sgVwwszjQM0iq6viyqYNrUzX3uaLWTUnkaOrq3OX0",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setExamData(data);

          // Create the mapping of exam IDs to exam names
          const idToNameMap = {};
          data.forEach((exam) => {
            idToNameMap[exam.ExamID] = exam.ExamName;
          });
          setExamIdToNameMap(idToNameMap);
        })
        .catch((error) => console.error("Error fetching exam data:", error))
        .finally(() => setLoading(false));
    };

    const fetchMarksheetFormat = async (classId, schoolId) => {
      const apiUrl = `https://edsanchaar.in/api/api_admin/MarksheetGeneration/getSchoolExamFormat.php?school_id=${schoolId}&class_id=${classId}`;

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            authorization: "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
          },
        });

        const data = response.data;
        const examTypes = data.map((item) => item.ExamType);

        // Set ExamType values as options for the Result dropdown
        setResultOptions(examTypes);
        setSchoolMarksheetFormat(data);
      } catch (error) {
        console.error("Error fetching class IDs:", error);
      }
    };

    const schoolIdFromCookie = Cookies.get("schoolId");
    setSchoolId(schoolIdFromCookie);

    if (!schoolIdFromCookie) {
      console.error("School ID not available");
      return;
    }
    fetchExam(classId, schoolIdFromCookie);
    fetchMarksheetFormat(classId, schoolIdFromCookie);
  }, [classId]);

  useEffect(() => {
    const fetchClassIds = async (schoolId) => {
      const apiUrl = `https://edsanchaar.in/api/api_admin/TeacherManager/getSchoolClasses.php?school_id=${schoolId}`;

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            authorization: "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
          },
        });

        const data = response.data;

        if (Array.isArray(data)) {
          const classSubjectMap = {};
          data.forEach((classInfo) => {
            const className = classInfo["class"];
            const subjects = classInfo["subjects"].split(",");
            classSubjectMap[className] = subjects;
          });

          setClassIds(Object.keys(classSubjectMap));
        } else {
          console.error("Invalid response format for class IDs:", data);
        }
      } catch (error) {
        console.error("Error fetching class IDs:", error);
      }
    };

    const fetchSchoolDetails = async (schoolId) => {
      const apiUrl = `https://edsanchaar.in/api/api_admin/SchoolRelatedDetails/SchoolDetails.php?school_id=${schoolId}`;

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            authorization: "ZVE9Shdyn3hQjyz6sXClc0i59HlU6qqE2z2FEe95zeg",
          },
        });

        const data = response.data;

        setSchoolData(data);
      } catch (error) {
        console.error("Error fetching class IDs:", error);
      }
    };

    const schoolIdFromCookie = Cookies.get("schoolId");

    if (!schoolIdFromCookie) {
      console.error("School ID not available");
      return;
    }

    fetchClassIds(schoolIdFromCookie);
    fetchSchoolDetails(schoolIdFromCookie);
  }, []);
  useEffect(() => {
    console.log(existingMarksheetData);
  }, [existingMarksheetData]);

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <Toaster />
      <div className="bg-[#f5f5f5] h-[90vh] w-[95.5vw] rounded-3xl shadow-xl flex flex-col overflow-auto ">
        <div className="text-center font-medium text-3xl">
          Marksheet Generation
        </div>
        <div className="h-20 w-full mb-6 flex flex-row items-center justify-evenly">
          <div className="w-fit mb-15 flex flex-row flex-wrap items-center">
            <label className="block font-bold mr-1" htmlFor="class_id">
              Class:{" "}
            </label>
            <select
              name="class_id"
              id="class_id"
              className="w-fit border border-gray-300 rounded py-2"
              required
              value={classId}
              onChange={(e) => {
                handleClassIdSelect(e.target.value);
              }}
            >
              <option value="" disabled hidden>
                Select Class
              </option>
              {classIds.map((classId) => (
                <option key={classId} value={classId}>
                  {classId}
                </option>
              ))}
            </select>
          </div>
          <div className="w-fit mb-15 flex flex-row flex-wrap items-center">
            <label className="block font-bold mr-1" htmlFor="result">
              Result:{" "}
            </label>
            <select
              name="result"
              id="result"
              className="w-fit rounded py-2"
              required
              value={result}
              onChange={(e) => {
                handleResultSelect(e.target.value);
              }}
            >
              <option value="" disabled hidden>
                Select Result
              </option>
              {resultOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          {result != "" &&
            classId &&
            !resultExists &&
            !existingMarksheetData && (
              <div className="">
                <button
                  className={`${
                    resultExists
                      ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                      : "bg-blue-500 text-white"
                  } px-4 py-2 rounded`}
                  onClick={GenerateResults}
                  disabled={resultExists}
                >
                  Generate Marksheets
                </button>
              </div>
            )}
          {resultExists && existingMarksheetData && (
            <div className="">
              <div
                className={`bg-red-500 text-white px-4 py-2 rounded`}
                onClick={deleteExistingMarksheetFormat}
              >
                Delete Existing Marksheet
              </div>
            </div>
          )}
        </div>

        <div className="h-fit w-full  mb-6">
          <div className="w-full flex flex-row justify-evenly items-center  ">
            <div className="flex flex-col">
              <div className="flex flex-row items-center">
                <h2 className="text-xl font-semibold">
                  {schoolData.school_name}
                </h2>
              </div>

              <p>
                <div className="inline text-lg font-semibold mr-2">
                  Contact:
                </div>
                {schoolData.contact_1},{schoolData.contact_2}
              </p>
            </div>
            <img
              src={schoolData.logo_storage}
              alt="School Logo"
              className=" my-4 mr-4"
              style={{ maxWidth: "50px", maxHeight: "50px" }}
            />
            <div className="flex flex-col">
              <p>
                <div className="inline text-lg font-semibold mr-2">
                  Address:
                </div>

                {schoolData.address}
              </p>
              <p>
                <div className="inline text-lg font-semibold mr-2">Email:</div>
                {schoolData.email}
              </p>
            </div>
          </div>
          {resultExists && existingMarksheetData && (
            <div className="mt-6">
              <h2 className="text-xl font-semibold mb-2">
                Existing Marksheet Data
              </h2>
              <ExistingMarksheetTable
                existingMarksheetData={existingMarksheetData}
              />
            </div>
          )}

          {result &&
            !resultExists &&
            !existingMarksheetData &&
            schoolMarksheetFormat
              .filter((item) => item.ExamType === result)
              .map((item) => {
                const examNames = item.ExamNames.split(",").map((examName) =>
                  examName.trim()
                );
                return (
                  <tr key={item.ExamType}>
                    <td className="border px-4 py-2">Subject</td>
                    {examNames.map((examName, index) => (
                      <th key={index} className="border px-4 py-2">
                        {examName}
                        <input
                          type="number"
                          value={examNameWeightages[examName] || ""}
                          onChange={(e) => {
                            const newWeightages = { ...examNameWeightages };
                            newWeightages[examName] = e.target.value;
                            setExamNameWeightages(newWeightages);
                          }}
                          className="ml-2 p-1 border rounded"
                          placeholder="Weightage"
                        />
                      </th>
                    ))}
                  </tr>
                );
              })}

          {(classId && classId == "") ||
            (!resultExists && (
              <div className="bg-slate-300 h-full w-full p-10 mt-20 rounded-xl flex flex-col justify-center items-center">
                <div className="px-4 text-xl font-medium">
                  First select a class from class dropdown.
                  <br /><br /> After selecting class, select marksheet from Result
                  dropdown .<br /> <br /> 
                  If there is no result, kindly contact your IT admin and ask
                  them to generate a marksheet.
                </div>
              </div>
            ))}

          {classId &&
            resultExists &&
            !existingMarksheetData &&
            Array.from(
              new Set(
                examData
                  .filter((exam) => exam.class_id === classId)
                  .map((exam) => exam.subject)
              )
            ).map((subject) => (
              <tr key={subject}>
                <td className="border px-4 py-2">{subject}</td>
                {schoolMarksheetFormat
                  .filter((item) => item.ExamType === result)
                  .map((item) => {
                    const examNames = item.ExamNames.split(",").map(
                      (examName) => examName.trim()
                    );
                    return examNames.map((examName, index) => {
                      const selectedExamId =
                        selectedExams[subject]?.[index] || "";

                      return (
                        <td key={index} className="border px-4 py-2">
                          {/* Dropdown to select exam */}
                          <select
                            value={selectedExamId}
                            onChange={(e) => {
                              // Handle the dropdown change here
                              const newSelectedExams = { ...selectedExams };
                              if (!newSelectedExams[subject]) {
                                newSelectedExams[subject] = Array.from(
                                  { length: examNames.length },
                                  () => ""
                                );
                              }
                              newSelectedExams[subject][index] = e.target.value;
                              setSelectedExams(newSelectedExams);
                              // Perform any additional logic as needed
                            }}
                            style={{ width: "150px" }} // Set the desired width
                          >
                            <option value="" disabled hidden>
                              Select Exam
                            </option>
                            {examData
                              .filter(
                                (exam) =>
                                  exam.class_id === classId &&
                                  exam.subject === subject
                              )
                              .map((exam) => (
                                <option key={exam.ExamID} value={exam.ExamID}>
                                  {exam.ExamName}
                                </option>
                              ))}
                          </select>
                        </td>
                      );
                    });
                  })}
              </tr>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ReportCardGeneration;
